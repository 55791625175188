import { RadioButtonData } from 'app/zfb/ui/radio-select/radio-select.component';

export enum Industry {
  FINANCIAL_AND_INSURANCE_ACTIVITIES = 'FINANCIAL_AND_INSURANCE_ACTIVITIES',
  CONSTRUCTION_AND_REAL_ESTATE = 'CONSTRUCTION_AND_REAL_ESTATE',
  HAIRDRESSER_AND_CRAFTS = 'HAIRDRESSER_AND_CRAFTS',
  WHOLESALE_AND_RETAIL_TRADE = 'WHOLESALE_AND_RETAIL_TRADE',
  NON_PROFIT = 'NON_PROFIT',
  MEDIA_AND_TELECOMMUNICATIONS = 'MEDIA_AND_TELECOMMUNICATIONS',
  TECHNOLOGY = 'TECHNOLOGY',
  MANUFACTURING_AND_INDUSTRY = 'MANUFACTURING_AND_INDUSTRY',
  CARE_MEDICAL_AND_HEALTH = 'CARE_MEDICAL_AND_HEALTH',
  OTHER = 'OTHER',
}
export class IndustryOptions {
  static VALUES: RadioButtonData[] = [
    {
      value: Industry.FINANCIAL_AND_INSURANCE_ACTIVITIES,
      label: $localize`:@@industry.bank:Bank, finans & försäkring`,
    },
    {
      value: Industry.CONSTRUCTION_AND_REAL_ESTATE,
      label: $localize`:@@industry.construction:Bygg & fastigheter`,
    },
    {
      value: Industry.HAIRDRESSER_AND_CRAFTS,
      label: $localize`:@@industry.crafts:Frisör & hantverk`,
    },
    {
      value: Industry.WHOLESALE_AND_RETAIL_TRADE,
      label: $localize`:@@industry.retail:Handel & konsumentprodukter`,
    },
    {
      value: Industry.NON_PROFIT,
      label: $localize`:@@industry.nonProfit:Ideella organisationer`,
    },
    {
      value: Industry.MEDIA_AND_TELECOMMUNICATIONS,
      label: $localize`:@@industry.media:Media & telekom`,
    },
    {
      value: Industry.TECHNOLOGY,
      label: $localize`:@@industry.technology:Teknologi`,
    },
    {
      value: Industry.MANUFACTURING_AND_INDUSTRY,
      label: $localize`:@@industry.manufacturing:Tillverkning & industri`,
    },
    {
      value: Industry.CARE_MEDICAL_AND_HEALTH,
      label: $localize`:@@industry.medicin:Vård, medicin & hälsa`,
    },
  ];
}
