<div
  class="card"
  style="
    border: 1px solid #d2d7e1;
    display: flex;
    justify-content: space-between;
  "
>
  <div
    style="width: 50%; border-right: 1px solid #d2d7e1; padding: 24px"
    (click)="setFullRefund(true)"
    [ngClass]="{ selected: fullRefund }"
  >
    <div style="display: flex; align-items: center">
      <zaver-radio-button
        [selected]="fullRefund"
        style="margin-right: 12px"
      ></zaver-radio-button>
      <p
        style="
          font-weight: 700;
          font-size: 16px;
          line-height: 28px;
          color: #6a7381;
        "
      >
        Refund entire amount
      </p>
    </div>
    <p style="font-size: 13px">
      Refundable amount:
      <b style="margin-left: 4px"> {{ totalRefundableAmount }} {{ currencyCode }} </b>
    </p>
  </div>
  <div
    style="width: 50%; padding: 24px"
    (click)="setFullRefund(false)"
    [ngClass]="{ selected: !fullRefund }"
  >
    <div style="display: flex; align-items: center">
      <zaver-radio-button
        [selected]="!fullRefund"
        style="margin-right: 12px"
      ></zaver-radio-button>
      <p
        style="
          font-weight: 700;
          font-size: 16px;
          line-height: 28px;
          color: #6a7381;
        "
      >
        Refund part of the amount
      </p>
    </div>
    <div style="display: flex">
      <form [formGroup]="fixedAmountRefundForm" *ngIf="!!fixedAmountRefundForm">
        <zaver-form-input
          formControlName="refundAmount"
          [title]="'Amount, incl. VAT to refund'"
          [placeholder]="'Amount'"
          [error]="
            fixedAmountRefundForm.controls.refundAmount.invalid && showError
          "
          [errorMessage]="'Refund amount is higher than the original amount'"
          [size]="'medium'"
          [showContent]="true"
          [error]="false"
          (input)="changeRefundAmountButtonText()"
        >
          <span style="text-align: center; font-weight: 400; font-size: 14px">
            {{ currencyCode }}
          </span>
        </zaver-form-input>
      </form>
      <div style="width: 104px; margin-left: 8px">
        <zaver-dropdownv2
          [selectableItems]="taxRateOptions"
          [selectedItem]="selectedTaxRate"
          (inputModelChange)="setTaxRatePercent($event)"
          [title]="'VAT'"
          [size]="'medium'"
        ></zaver-dropdownv2>
      </div>
    </div>
    <p style="font-size: 13px; color: #6a7381">
      Max refundable amount:
      <span style="margin-left: 4px"> {{ totalRefundableAmount }} {{ currencyCode }} </span>
    </p>
  </div>
</div>
<div style="display: flex; justify-content: space-between; margin-top: 24px">
  <div style="width: 60%">
    <p style="font-size: 13px; columns: #6a7381">
      The refundable amount is equal to the captured amount on the order, with
      any previous refunds subtracted.
    </p>
  </div>
  <div>
    <zaver-primary-button
      (click)="onSubmit()"
      [size]="'x-small'"
      [text]="refundAmountButtonText"
      [disabled]="false"
    ></zaver-primary-button>
  </div>
</div>
