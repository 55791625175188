
import {map} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';



interface BlobOptions {
  headers: HttpHeaders;
  observe: 'response';
  responseType: 'blob';
  withCredentials?: boolean;
}

interface Options {
  headers: HttpHeaders;
  observe: 'response';
  responseType: 'json';
  withCredentials?: boolean;
}

export class RestBase {

  baseUrl;
  baseHeaders: HttpHeaders;

  constructor(private http: HttpClient, baseUrl: string) {
    this.baseUrl = baseUrl;
    this.baseHeaders = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append('Accept', 'application/json');
  }

  protected get(path: string) {
    return this.handleRequest(this.http.get(this.baseUrl + path, this.getOptions()));
  }

  protected post(path: string, data: string) {
    return this.handleRequest(this.http.post(this.baseUrl + path, data, this.getOptions()));
  }

  protected put(path: string, data: string) {
    return this.handleRequest(this.http.put(this.baseUrl + path, data, this.getOptions()));
  }

  protected delete(path: string) {
    return this.handleRequest(this.http.delete(this.baseUrl + path, this.getOptions()));
  }

  protected getBlob(url: string) {
    return this.http.get(this.baseUrl + url, this.blobOptions()).toPromise();
  }

  private handleRequest(request: Observable<Object>) {
    return request.pipe(map(this.extractData)).toPromise().catch(error => {
      throw error;
    });
  }

  private getOptions(): Options {
    return {
      headers: this.baseHeaders,
      observe: 'response',
      withCredentials: true,
      responseType: 'json'
    };
  }

  private blobOptions(): BlobOptions {
    return {
      headers: this.baseHeaders,
      observe: 'response',
      withCredentials: true,
      responseType: 'blob'
    };
  }

  /**
   *
   * @param res
   * @returns {any|{}}
   */
  private extractData(res: HttpResponse<any>) {
    // TODO change observe to let angular handle this conversion
    return res.body || {};
  }
}
