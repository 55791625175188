import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Currency } from '@core/dto/CurrencyCode';
import { PaymentRequestResponse } from '@core/dto/PaymentRequestResponse';
import { RefundReason } from '@core/dto/refund/Refund';
import { LocaleService } from '@core/service/locale.service';
import { RefundService } from '@core/service/refund.service';

@Component({
  selector: 'app-create-refund-confirm',
  templateUrl: './create-refund-confirm.component.html',
  styleUrls: ['./create-refund-confirm.component.css'],
})
export class CreateRefundConfirmComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() paymentRequest: PaymentRequestResponse;
  @Input() errorMessage: string; // Error message when submitting refund
  @Input() submitting = false;

  @Output() changeStep = new EventEmitter();
  @Output() submit = new EventEmitter();

  showTitleAndDescription = false;
  refundValue: Currency;
  refundedValue: Currency;

  selectedReason: RefundReason;

  constructor(
    private refundService: RefundService,
    public localeService: LocaleService
  ) {}

  ngOnInit(): void {
    const reasonForm = this.form.get('reasonForm') as FormGroup;
    this.selectedReason = reasonForm.controls.reason.value;

    const amountForm = this.form.get('amountForm') as FormGroup;
    this.refundValue = {
      amount: -amountForm.controls.amount.value.replace(',', '.'), // Replace , with . to display amount propoerly using pipe
      currencyCode: this.paymentRequest.value.currencyCode,
    };
    if (
      this.paymentRequest.refundedValue &&
      this.paymentRequest.refundedValue.amount > 0
    ) {
      this.refundedValue = {
        amount: -this.paymentRequest.refundedValue.amount,
        currencyCode: this.paymentRequest.value.currencyCode,
      };
    }
  }

  getReasonText(): string {
    return this.refundService.getRefundReasonText(this.selectedReason);
  }

  toggleTitleAndDescription() {
    this.showTitleAndDescription = !this.showTitleAndDescription;
  }
}
