import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CashoutTaskDto } from '@core/dto/cashout/incoming/CashoutTaskDto';
import { CashoutTaskListDto } from '@core/dto/cashout/incoming/CashoutTaskListDto';
import { environment } from '@environments/environment';
import { CreateCashoutTaskDto } from '@core/dto/cashout/outgoing/create-cashout-task.dto';
import { CashoutTaskCreationRespDto } from '@core/dto/cashout/incoming/CashoutTaskCreationRespDto';
import { EditAmountDto } from '@core/dto/cashout/outgoing/edit-amount.dto';
import { WebsocketListener, WsService, SubTopic } from './ws.service';
import { CashoutTaskUpdatedDto } from '@core/dto/cashout/incoming/CashoutTaskUpdatedDto';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CashoutTaskService
  implements WebsocketListener<CashoutTaskUpdatedDto>
{
  private readonly httpReqOptions = { withCredentials: true };
  private listeners: CashoutTaskListener[] = [];

  authenticatedByAuthCode: boolean = false;

  constructor(private http: HttpClient, private wsService: WsService) {
    wsService.registerTopicListener(SubTopic.CashoutTaskStatus, this);
  }

  public getTasks(
    search?: string,
    status?: string,
    dateFrom?: Date,
    dateTo?: Date,
    page?: number,
    pageSize?: number
  ): Observable<CashoutTaskListDto> {
    if (environment.useFixtures) {
      return this.http.get<CashoutTaskListDto>(
        '/assets/fixtures/cashoutTasks.json'
      );
    }

    const url = `${environment.apiUrl}/cashout/tasks`;

    let params = new HttpParams();
    if (search) {
      params = params.append('search', search);
    }
    if (status) {
      params = params.append('status', status);
    }
    if (dateFrom) {
      params = params.append('dateFrom', dateFrom.toISOString());
    }
    if (dateTo) {
      params = params.append('dateTo', dateTo.toISOString());
    }
    if (page || page === 0) {
      params = params.append('page', page.toString());
    }
    if (pageSize) {
      params = params.append('pageSize', pageSize.toString());
    }

    return this.http.get<CashoutTaskListDto>(url, {
      ...this.httpReqOptions,
      params: params,
    });
  }

  public getOne(id: string): Observable<CashoutTaskDto> {
    const url = `${environment.apiUrl}/cashout/tasks/${id}`;

    return this.http.get<CashoutTaskDto>(url, this.httpReqOptions);
  }

  public getRemoteWarnings(dto: CreateCashoutTaskDto): Observable<string[]> {
    const url = `${environment.apiUrl}/cashout/check`;

    return this.http.post<string[]>(url, dto, this.httpReqOptions);
  }

  public create(
    dto: CreateCashoutTaskDto
  ): Observable<CashoutTaskCreationRespDto> {
    const url = `${environment.apiUrl}/cashout/task`;

    if (environment.useFixtures) {
      return Observable.create((observer) =>
        observer.next(new CashoutTaskDto())
      );
    }

    return this.http.post<CashoutTaskCreationRespDto>(
      url,
      dto,
      this.httpReqOptions
    );
  }

  public cancel(id: string): Observable<CashoutTaskDto> {
    const url = `${environment.apiUrl}/cashout/tasks/cancel/${id}`;
    return this.http.post<CashoutTaskDto>(url, {}, this.httpReqOptions);
  }

  public resend(id: string): Observable<CashoutTaskDto> {
    const url = `${environment.apiUrl}/cashout/tasks/resend/${id}`;
    return this.http.post<CashoutTaskDto>(url, {}, this.httpReqOptions);
  }

  public update(
    updated: CreateCashoutTaskDto,
    id: string
  ): Observable<CashoutTaskDto> {
    console.error('Update not implemented', id);
    return null;
  }

  public registerAdminApproval(id: string): Observable<CashoutTaskDto> {
    const url = `${environment.apiUrl}/cashout/tasks/approve/${id}`;
    return this.http.post<CashoutTaskDto>(url, {}, this.httpReqOptions);
  }

  public executeDisbursement(
    id: string,
    verificationCode: string
  ): Observable<CashoutTaskDto> {
    const url = `${environment.apiUrl}/cashout/tasks/disburse/${id}`;
    return this.http.post<CashoutTaskDto>(
      url,
      verificationCode,
      this.httpReqOptions
    );
  }

  public editAmount(
    taskId: string,
    newAmount: number
  ): Observable<CashoutTaskDto> {
    const url = `${environment.apiUrl}/cashout/tasks/amount/${taskId}`;
    const body = new EditAmountDto(newAmount);
    return this.http.post<CashoutTaskDto>(url, body, this.httpReqOptions);
  }

  public registerTaskListener(listener: CashoutTaskListener): void {
    this.listeners.push(listener);
  }

  public unregisterTaskListener(listener: CashoutTaskListener): void {
    this.listeners = this.listeners.filter((l) => l !== listener);
  }

  public getBankName(clearingNumber: string) {
    let params = new HttpParams();
    if (clearingNumber) {
      params = params.append('clearingNumber', clearingNumber);
    }

    const url = `${environment.apiUrl}/cashout/bankname`;
    return this.http.get<BankNameDto>(url, {
      ...this.httpReqOptions,
      params: params,
    });
  }

  public getConfirmationPageDetails(
    personalNumber: string,
    clearingNumber: string,
    accountNumber: string
  ) {
    let params = new HttpParams();
    params = params.append('personalNumber', personalNumber);

    if (clearingNumber) {
      params = params.append('clearingNumber', clearingNumber);
    }
    if (accountNumber) {
      params = params.append('accountNumber', accountNumber);
    }

    const url = `${environment.apiUrl}/cashout/confirmationDetails`;
    return this.http.get<ConfirmationPageDetailsDto>(url, {
      ...this.httpReqOptions,
      params: params,
    });
  }

  handleMessage(message: CashoutTaskUpdatedDto): void {
    this.listeners.forEach((l) => l.onTaskUpdated(message));
  }

  sendVerificationCode(): Observable<any> {
    const url = `${environment.apiUrl}/cashout/sendTwoFactorAuthenticationEmail`;
    return this.http.post(url, {}, this.httpReqOptions);
  }

  sendVerificationCodeBySms(): Observable<any> {
    const url = `${environment.apiUrl}/cashout/sendTwoFactorAuthenticationSms`;
    return this.http.post(url, {}, this.httpReqOptions);
  }
}

export interface CashoutTaskListener {
  onTaskUpdated(message: CashoutTaskUpdatedDto): void;
}

export class BankNameDto {
  bankName: string;
}

export class ConfirmationPageDetailsDto {
  personName: string;
  validBankAccount: boolean;
}
