<div class="card">
  <h4>Oops, du verkar inte vara ansluten till något företag?</h4>
  <p>
    Du verkar inte vara ansluten till något företag. Om ditt företag redan
    använder Zaver så bör du kontakta företagets administratör.
  </p>
  <p id="last-paragraph">
    Om ditt företag <em>inte</em> använder Zaver, men vill bli kund hos oss, så
    är ni välkomna att registrera ett företagskonto genom att navigera tillbaka
    och trycka på <strong>“Registrera nytt företag”</strong>.
  </p>

  <zaver-primary-button [text]="'Tillbaka'" (click)="logout()">
  </zaver-primary-button>
</div>
