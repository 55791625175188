import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit {

  @Input() movingPartColor: string;
  @Input() staticPartColor: string;
  @Input() sizePx: number;
  @Input() margin: string;

  constructor() {}

  ngOnInit() {
    if (!this.staticPartColor) {
        this.staticPartColor = '#007BFF';
    }
    if (!this.movingPartColor) {
        this.movingPartColor = '#F8F9FB';
    }
    if (!this.sizePx) {
        this.sizePx = 10;
    }
    if (!this.margin) {
        this.margin = '0 auto';
    }
  }

  getStaticPartStyle() {
    return this.staticPartColor + ' 1.1em solid';
  }

  getMovingPartStyle() {
    return this.movingPartColor + ' 1.1em solid';
  }

  getSizePx() {
    return this.sizePx + 'px';
  }

}
