import {Injectable} from '@angular/core';
import {Store} from '../dto/Store';
import {RestBase} from '../rest-base';
import {HttpClient} from '@angular/common/http';
import {HrefService} from './href.service';
import {environment} from '@environments/environment';
import { CompanyRepresentative } from '@core/dto/CompanyRepresentative';

@Injectable()
export class StoreService extends RestBase {

  constructor(private httpClient: HttpClient, hrefService: HrefService) {
    super(httpClient, hrefService.getApiUrl());
  }

  public get(): Promise<Store[]> {
    if (environment.useFixtures) {
      return this.httpClient.get<Store[]>(
          '/assets/fixtures/stores.json'
        ).toPromise();
    }
    return super.get('/store');
  }

  public getOne(id: string): Promise<Store> {
    return super.get('/store/' + id);
  }

  public getRepresentatives(storeId: string): Promise<CompanyRepresentative[]> {
    return super.get('/store/' + storeId + '/representatives');
  }

  public create(store: Store): Promise<Store> {
    return super.post('/store', JSON.stringify(store));
  }

  public update(store: Store): Promise<Store> {
    return super.put('/store/' + store.id, JSON.stringify(store));
  }

  public deleteStore(store: Store): Promise<boolean> {
    return super.delete('/store/' + store.id);
  }

}
