import { RefundRepresentation, RefundRepresentations } from '@assets/language/RefundRepresentation';
import { RefundStatus } from '@core/params';
import { Currency } from '../CurrencyCode';

export class Refund {
    id: string;
    paymentRequestId: string;
    paymentRequestTitle: string;
    paymentRequestDescription: string;
    paymentRequestValue: Currency;
    refundValue: Currency;
    initializingRepresentativeId: string;
    initializingRepresentativeName: string;
    initializingRepresentativeEmail: string;
    approvingRepresentativeName: string;
    approvingRepresentativeEmail: string;
    cancellingRepresentativeName: string;
    cancellingRepresentativeEmail: string;
    created: Date;
    reason: RefundReason;
    note: string;
    status: RefundStatus;
    statusText: string;
    statusColor: string;
    backgroundColor: string

    static setup(refund: Refund): Refund {
        let statusInfo: RefundRepresentation;
        statusInfo = RefundRepresentations.get(refund.status);
        if (!statusInfo) {
          statusInfo = RefundRepresentations.get('_default_');
        }
        refund.statusText = statusInfo.statusText;
        refund.statusColor = statusInfo.statusColor;
        refund.backgroundColor = statusInfo.backgroundColor

        

        return refund;
    }
}

export enum RefundReason {
    RIGHT_OF_WITHDRAWAL = 'RIGHT_OF_WITHDRAWAL', // Ångerrätt
    PRODUCT_DEFECT = 'PRODUCT_DEFECT', // Defekt vara
    INCORRECTLY_EXECUTED_SERVICE = 'INCORRECTLY_EXECUTED_SERVICE', // Felaktigt genomförd tjänst
    OTHER = 'OTHER', // Annan orsak
    NO_REASON = 'NO_REASON',
  }

export interface RefundApprovalResponse {
  approved: boolean;
  error: RefundApprovalError;
}

export enum RefundApprovalError {
  SWISH_PAYMENT_TOO_OLD = 'SWISH_PAYMENT_TOO_OLD',
  SWISH_MERCHANT_NUMBER_CHANGED = 'SWISH_MERCHANT_NUMBER_CHANGED',
  SWISH_MERCHANT_ORG_NUMBER_CHANGED = 'SWISH_MERCHANT_ORG_NUMBER_CHANGED',
  SWISH_CUSTOMER_NUMBER_CHANGED = 'SWISH_CUSTOMER_NUMBER_CHANGED',
  SWISH_CUSTOMER_NOT_ACTIVE = 'SWISH_CUSTOMER_NOT_ACTIVE',
  AMOUNT_EXCEEDS_REFUNDABLE_AMOUNT = 'AMOUNT_EXCEEDS_REFUNDABLE_AMOUNT',
  SWISH_INSUFFICIENT_FUNDS = 'SWISH_INSUFFICIENT_FUNDS',
  SWISH_TEMPORARY_ERROR = 'SWISH_TEMPORARY_ERROR',
  OTHER = 'OTHER'
}
