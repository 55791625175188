import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AppStateService } from '@core/service/app-state.service';

@Injectable()
export class ContextIdInterceptor implements HttpInterceptor {

  constructor(private route: ActivatedRoute, private appStateService: AppStateService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add a context-id header so that we can follow all logged requests produced by one frontend instance
    const cloneReq = req.clone({ headers: req.headers.set('X-FRONTEND-CONTEXT-ID', this.appStateService.getContextID()) });

    return next.handle(cloneReq);
  }
}
