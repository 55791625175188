import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { AuthenticationService } from "../../core/service/authentication.service";

@Injectable()
export class SessionTimeoutInterceptor implements HttpInterceptor {

    constructor(
        private authService: AuthenticationService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .pipe(
                filter(event => event instanceof HttpResponse),
                tap(
                    (event: HttpResponse<any>): void => {
                        if (event.headers.get('X-Session-Timeout')) {
                            this.authService.setSessionTimeout(Number(event.headers.get('X-Session-Timeout')));
                        }
                    },
                    () => { })
            );
    }
}