import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {AppStateService} from '@core/service/app-state.service';


/**
 * A guard that redirects you to the homepage if you are already logged in.
 */
@Injectable()
export class MustNotBeLoggedIn implements CanActivateChild {

  constructor(private appStateService: AppStateService, private router: Router) {
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
    // Redirect to homepage if we already know who the user is.
    if (this.appStateService.hasCurrentUser()) {
      this.router.navigate(['/']);
      return false;
    }

    // Otherwise check if we are already authenticated against the backend.
    return this.appStateService.refreshCurrentUser()
        .then(() => {
          this.router.navigate(['/']);
          return false;
        })
        .catch(() => {
          return true;
        });
  }

}
