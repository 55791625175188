<h1 class="page-heading-with-subheader">
  <span i18n="@@users.title">Hantera användare</span>
</h1>

<section id="search-and-filter">
  <div id="input-fields">
    <zaver-search
      id="search-bar"
      placeholder="Sök ..."
      i18n-placeholder="@@users.search.placeholder"
      [inputModel]="filterQuery"
      (inputModelChange)="searchUpdatedQuery($event)"
    ></zaver-search>
  </div>
  <zaver-zfb-add-button
    *ngIf="mayCreateNewUser()"
    id="main-create-button"
    i18n-text="@@users.cta.newUser"
    text="Ny användare"
    (click)="create()"
  ></zaver-zfb-add-button>
</section>
<app-page-error
  *ngIf="errorMessage"
  [errorMessage]="errorMessage"
></app-page-error>

<app-page-tabs
  *ngIf="showAutoRegistrationApprovals()"
  [activeValue]="activePage"
  [tabs]="tabs"
  (tabClicked)="setActiveTab($event)"
></app-page-tabs>

<ng-container *ngIf="fetchingResponse">
  <div class="page-loader-container">
    <app-page-loader></app-page-loader>
  </div>
</ng-container>
<ng-container *ngIf="!fetchingResponse && activePage === UserPage.ALL_USERS">
  <app-table
    [columns]="columns"
    [values]="currentPageOfRepresentatives"
    (filterChange)="onFilterChange($event)"
    *ngIf="currentPageOfRepresentatives"
  ></app-table>

  <app-table-pagination
    *ngIf="currentPageOfRepresentatives.rows.length > 0"
    [paginationState]="paginationState"
    (pageChanged)="onPageChange($event)"
    (pageSizeChanged)="onPageSizeChange($event)"
  >
  </app-table-pagination>

  <app-empty-state *ngIf="displayEmptyState" [emptyState]="currentEmptyState">
  </app-empty-state>
</ng-container>

<app-approve-new-users
  [class.showTable]="
    showAutoRegistrationApprovals() && activePage === UserPage.PENDING_APPROVAL
  "
  (numOfPendingApprovals)="setNumOfPendingApprovals($event)"
>
</app-approve-new-users>

<!-- <div *ngIf="!fetchingResponse" class="sticky">
    <zaver-zfb-add-button [text]="'Ny användare'" (click)='create()'></zaver-zfb-add-button>
  </div> -->
<zaver-zfb-add-button
  *ngIf="mayCreateNewUser() && !fetchingResponse"
  i18n-text="@@users.cta.newUser"
  text="Ny användare"
  (click)="create()"
  class="mobile-cta"
></zaver-zfb-add-button>

<!-- Modal -->
<zaver-form-modal
  *ngIf="modalActive"
  (onClose)="closeModal()"
  [title]="modalTitle"
  [color]="modalColor"
  [modalBodyVisible]="createNewUser || editUser"
>
  <app-create-company-representative
    *ngIf="createNewUser"
    (submitClicked)="newCompanyRepresentativeCreated()"
    (roleChanged)="updateModalTextAndColor($event)"
  >
  </app-create-company-representative>
  <app-view-company-representative
    *ngIf="displayUser"
    [representative]="selectedRepresentative"
    (editClicked)="edit($event)"
  >
  </app-view-company-representative>
  <app-edit-company-representative
    *ngIf="editUser"
    [representative]="selectedRepresentative"
    (onUpdated)="companyRepresentativeEvent()"
    (roleChanged)="updateModalTextAndColor($event)"
  >
  </app-edit-company-representative>
</zaver-form-modal>
