import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '@core/base.component';
import { CashoutTaskDto } from '@core/dto/cashout/incoming/CashoutTaskDto';
import { Permission } from '@core/dto/user-details';
import { CashoutTaskStatus } from '@core/params';
import { NUMBER_PATTERN } from '@core/patterns';
import { AccessControlService } from '@core/service/access-control.service';
import { AppStateService } from '@core/service/app-state.service';
import { AuthenticationService } from '@core/service/authentication.service';
import { CashoutTaskService } from '@core/service/cashout-task.service';
import { AmountFormatterPipe } from '@shared/pipe/amount-formatter.pipe';
import { FormValidators } from '@shared/validators/validators';

@Component({
  selector: 'app-view-cashout-task',
  templateUrl: './view-cashout-task.component.html',
  styleUrls: ['./view-cashout-task.component.css'],
})
export class ViewCashoutTaskComponent extends BaseComponent implements OnInit {
  private readonly amountAdjustmentLimit = 40_000;

  @ViewChild('amountInput') amountInputElement: ElementRef;

  @Input() cashoutTask: CashoutTaskDto;

  mayApproveCashoutTasks: boolean;
  mayEditTaskAmounts: boolean;
  mayExecuteCashoutOrders: boolean;

  editAmountForm: FormGroup;
  editingAmount = false;
  showEditAmountError = false;
  savingUpdatedAmount = false;
  sendingApproval = false;

  minAllowedAmount: number;
  maxAllowedAmount: number;

  @Output() showCashoutCancelModal = new EventEmitter();
  @Output() showCashoutDisbursementConfirmationModal = new EventEmitter();
  @Output() onDone = new EventEmitter();

  constructor(
    private cashoutTaskService: CashoutTaskService,
    private appStateService: AppStateService,
    private accessControlService: AccessControlService,
    auth: AuthenticationService,
    private amountFormatterPipe: AmountFormatterPipe
  ) {
    super(auth);
  }

  ngOnInit(): void {
    this.mayApproveCashoutTasks = this.accessControlService.userMay(
      Permission.APPROVE_CASHOUT_ORDER
    );
    this.mayEditTaskAmounts = this.accessControlService.userMay(
      Permission.EDIT_CASHOUT_ORDER_AMOUNT
    );
    this.mayExecuteCashoutOrders = this.accessControlService.userMay(
      Permission.EXECUTE_CASHOUT_ORDER
    );
  }

  submitAdminAppproval() {
    if (!this.cashoutTask) {
      return;
    }

    const taskId = this.cashoutTask.taskId;

    this.sendingApproval = true;
    this.cashoutTaskService.registerAdminApproval(taskId).subscribe(
      (res: CashoutTaskDto) => {
        this.cashoutTask = res;
        this.sendingApproval = false;
        this.onDone.emit();
      },
      (err) => {
        this.handleError(err);
        this.sendingApproval = false;
      }
    );
  }

  // Determine of the current user may approve the CashoutTask
  shouldDisplayAdminApprovalButton(): boolean {
    return (
      this.mayApproveCashoutTasks &&
      this.userMayApproveTask() &&
      this.taskIsPendingApproval()
    );
  }

  // Determine if the current user may execute the CashoutOrder
  shouldDisplayExecutionButton(): boolean {
    return (
      this.mayExecuteCashoutOrders &&
      this.userMayExecuteCashoutOrder() &&
      this.taskIsPendingExecution()
    );
  }

  // A user must have the permission APPROVE_CASHOUT_ORDER to be able to approve a Cashout order
  // The user must also fulfill one of the following conditions:
  //  - The user has the APPROVE_OWN_CASHOUT_ORDER permission
  //  - The user did not create the cashout order
  userMayApproveTask() {
    if (this.accessControlService.userMay(Permission.APPROVE_CASHOUT_ORDER)) {
      if (
        this.accessControlService.userMay(Permission.APPROVE_OWN_CASHOUT_ORDER)
      ) {
        return true;
      }
      return !this.initiatedByCurrentUser();
    }
    return false;
  }

  // A user must have the permission EXECUTE_CASHOUT_ORDER to be able to approve a Cashout order
  // The user must also fulfill one of the following conditions:
  //  - The user has the EXECUTE_OWN_APPROVED_CASHOUT_ORDER permission
  //  - The user did not approve the cashout order
  userMayExecuteCashoutOrder() {
    if (this.accessControlService.userMay(Permission.EXECUTE_CASHOUT_ORDER)) {
      if (
        this.accessControlService.userMay(
          Permission.EXECUTE_OWN_APPROVED_CASHOUT_ORDER
        )
      ) {
        return true;
      }
      return !this.approvedByCurrentUser();
    }
    return false;
  }

  initiatedByCurrentUser() {
    if (this.cashoutTask) {
      return (
        this.appStateService.getCurrentUser().activeRepresentation.id ===
        this.cashoutTask.initializingRepresentativeId
      );
    }
    return false;
  }

  approvedByCurrentUser() {
    if (this.cashoutTask) {
      return (
        this.appStateService.getCurrentUser().activeRepresentation.id ===
        this.cashoutTask.approvingRepresentativeId
      );
    }
    return false;
  }

  taskIsPendingApproval(): boolean {
    return this.cashoutTask.status === CashoutTaskStatus.PENDING_ADMIN_APPROVAL;
  }

  shouldDisplayEditAmountButton(): boolean {
    return (
      this.mayEditTaskAmounts &&
      this.cashoutTask.status === CashoutTaskStatus.PENDING_EXECUTION
    );
  }

  editAmount() {
    this.maxAllowedAmount = this.cashoutTask.amount - 1;
    this.minAllowedAmount = Math.max(
      0,
      this.cashoutTask.originalAmount - this.amountAdjustmentLimit
    );
    this.editAmountForm = new FormGroup({
      amount: new FormControl(this.cashoutTask.amount.toString(), [
        Validators.required,
        FormValidators.number(this.minAllowedAmount, this.maxAllowedAmount),
      ]),
    });
    this.editingAmount = true;
    setTimeout(() => {
      this.amountInputElement.nativeElement.focus();
    }, 0);
  }

  undoEditAmount() {
    this.editAmountForm = null;
    this.editingAmount = false;
    this.showEditAmountError = false;
  }

  saveEditedAmount() {
    if (this.editAmountForm.valid) {
      this.savingUpdatedAmount = true;
      const newAmount = this.editAmountForm.controls.amount.value.replace(/\s/g, '');
      const taskId = this.cashoutTask.taskId;

      this.cashoutTaskService.editAmount(taskId, newAmount).subscribe(
        (res: CashoutTaskDto) => {
          this.savingUpdatedAmount = false;
          this.editingAmount = false;
          this.onDone.emit();
        },
        (error) => {
          this.handleError(error);
          this.savingUpdatedAmount = false;
        }
      );
    } else {
      this.showEditAmountError = true;
    }
  }

  shouldDisplayExecuteDisbursementButton(): boolean {
    return this.accessControlService.userMay(Permission.EXECUTE_CASHOUT_ORDER);
  }

  taskIsPendingExecution(): boolean {
    return this.cashoutTask.status === CashoutTaskStatus.PENDING_EXECUTION;
  }

  // This cancel button is only visible after the CashoutTask has been initiated, and before the CashoutTask is executed.
  // It shall not be visible id the CashoutTask has bounced or is already cancelled
  shouldDisplayCancelButton() {
    return (
      this.accessControlService.userMay(Permission.CANCEL_CASHOUT_ORDER) &&
      (this.cashoutTask.status === CashoutTaskStatus.PENDING_PAYEE_APPROVAL ||
        this.cashoutTask.status === CashoutTaskStatus.PENDING_ADMIN_APPROVAL ||
        this.cashoutTask.status === CashoutTaskStatus.PENDING_EXECUTION)
    );
  }

  cancelCashout() {
    this.showCashoutCancelModal.emit();
  }

  confirmDisbursement() {
    this.showCashoutDisbursementConfirmationModal.emit();
  }

  getEditingAmountError() {
    if (this.minAllowedAmount >= this.maxAllowedAmount) {
      return 'Beloppet för denna utbetalning kan inte justeras ytterligare.';
    } else {
      return (
        'Ange ett belopp mellan ' +
        this.amountFormatterPipe.transform(this.minAllowedAmount) +
        ' - ' +
        this.amountFormatterPipe.transform(this.maxAllowedAmount) +
        ' kr'
      );
    }
  }
}
