export enum CurrencyCode {
  SEK = 'SEK',
  DKK = 'DKK',
  EUR = 'EUR',
  NOK = 'NOK',
}

export type Currency = {
  amount: number;
  currencyCode: CurrencyCode;
}
