import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'zaver-status-pill-small',
  templateUrl: './status-pill-small.component.html',
  styleUrls: ['./status-pill-small.component.css']
})
export class StatusPillSmallComponent implements OnInit {

  @Input() text: string;
  @Input() backgroundHexColor: string;
  @Input() extraSmall: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
