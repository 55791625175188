<form [formGroup]="formGroup" novalidate>
  <div class="input-fields">
    <zaver-form-input
      [title]="'Personnummer'"
      [placeholder]="'Personnummer'"
      [error]="
        formGroup.controls.personalNumber.invalid &&
        (showError || startPersonalNumberValidation)
      "
      [errorMessage]="'Vänligen ange ett korrekt personnummer (yyyymmdd-xxxx)'"
      formControlName="personalNumber"
      [inputMode]="'tel'"
      [size]="'large'"
    >
    </zaver-form-input>
  </div>
  <div class="input-fields" [class.hide]="!correctPersonalNumber">
    <zaver-form-input
      [title]="'Namn'"
      [placeholder]="namePlaceholder"
      formControlName="name"
      [error]="formGroup.controls.name.invalid && showError"
      [errorMessage]="'Fältet får inte lämnas tomt'"
      [maxLength]="255"
      [size]="'large'"
    >
    </zaver-form-input>
  </div>
  <ng-container *ngIf="!nonOwner">
    <div class="input-fields" [class.hide]="!correctPersonalNumber">
      <zaver-form-input
        [showContent]="true"
        [title]="'Aktieinnehav'"
        [placeholder]="'Aktieinnehav'"
        [error]="formGroup.controls.holdingShare.invalid && showError"
        [errorMessage]="'Ange aktieinnehavet'"
        formControlName="holdingShare"
        [inputMode]="'decimal'"
        [size]="'large'"
      >
        <span style="text-align: center; font-weight: 400; font-size: 14px">
          %
        </span>
      </zaver-form-input>
    </div>
    <div class="input-fields" [class.hide]="!correctPersonalNumber">
      <zaver-form-input
        [showContent]="true"
        [title]="'Innehav av röster'"
        [placeholder]="'Innehav av röster'"
        [error]="formGroup.controls.votesShare.invalid && showError"
        [errorMessage]="'Ange innehavet av röster'"
        formControlName="votesShare"
        [inputMode]="'decimal'"
        [size]="'large'"
      >
        <span style="text-align: center; font-weight: 400; font-size: 14px">
          %
        </span>
      </zaver-form-input>
    </div>
  </ng-container>
  <div class="label" [class.hide]="!correctPersonalNumber">
    Är personen, eller har personen inom de senaste månaderna varit, en person i
    politisk utsatt ställning, nära anhörig eller känd medarbetare till en
    sådan?
  </div>
  <app-radio-select
    [class.hide]="!correctPersonalNumber"
    [value]="formGroup.controls.pep.value"
    (valueChange)="formGroup.controls.pep.setValue($event)"
    [options]="pepOptions"
    [errorMessage]="'Vänligen ange ett alternativ'"
    [showRequiredError]="showError && formGroup.controls.pep.invalid"
    [displayOtherOption]="false"
  >
  </app-radio-select>
</form>
