import { FormGroup } from '@angular/forms';

export interface LineItemVM {
  id: string;
  titleEditable: boolean;
  initialQuantity: number;
  taxAmount: number;
  totalAmount: number;
  form: FormGroup;
  editMode: boolean;
  maxQuantity: number;
  maxUnitPrice: number;
  taxAmountEditable: boolean;
}

export interface SimpleLineItemVM {
  title: string;
  quantity: number | string;
  taxAmount: number;
  totalAmount: number;
  unitPrice: number;
  taxRatePercent: number;
}

export enum View {
  ORDER_DETAILS = 'ORDER_DETAILS',
  PARTIAL_CAPTURE = 'PARTIAL_CAPTURE',
  CONFIRM_CAPTURE = 'CONFIRM_CAPTURE',
  PAYMENT_UPDATE = 'PAYMENT_UPDATE',
  CANCEL_ORDER = 'CANCEL_ORDER',
  PARTIAL_CANCEL_ORDER = 'PARTIAL_CANCEL_ORDER',
  VIEW_ORDER = 'VIEW_ORDER',
  REFUND_LINE_ITEM = 'REFUND_LINE_ITEM',
  REFUND_FIXED_AMOUNT = 'REFUND_FIXED_AMOUNT',
  CONFIRM_REFUND = 'CONFIRM_REFUND',
  CONFIRM_FIXED_AMOUNT_REFUND = 'CONFIRM_FIXED_AMOUNT_REFUND',
}

export enum WidgetContext {
  ZFB = 'ZFB',
  STANDALONE = 'STANDALONE',
}

export interface PaymentRequestStatusPil {
  text: string;
  color: string;
  textColor: string;
}

export interface PaymentRequestStatusPilData {
  uncaptured: PaymentRequestStatusPil;
  partiallyCaptured: PaymentRequestStatusPil;
  captured: PaymentRequestStatusPil;
  cancelled: PaymentRequestStatusPil;
  expired: PaymentRequestStatusPil;
  open: PaymentRequestStatusPil;
}

export interface QuantityChangeEvent {
  value: any;
  lineItemVM: LineItemVM;
}
