import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-page-error',
  templateUrl: './page-error.component.html',
  styleUrls: ['./page-error.component.css']
})
export class PageErrorComponent implements OnInit {

  @Input() errorMessage: string;
  @Input() dismissable = false;

  @Output() onDismiss = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
