<div class="confirm-capture-wrapper">
  <div class="confirm-capture-container">
    <p class="heading">Confirm capture</p>

    <div>
      <div class="row">
        <p>Subtotal</p>
        <p>{{ selectedPreTaxAmount | amountPipe : currency }}</p>
      </div>

      <div class="row">
        <p>VAT</p>
        <p>{{ selectedTaxAmount | amountPipe : currency }}</p>
      </div>

      <div class="row">
        <p>Amount ready to capture(incl. VAT)</p>
        <p>{{ selectedAmountToCapture | amountPipe : currency }}</p>
      </div>

      <div class="row">
        <p>Amount left to capture</p>
        <p>{{ uncapturedAmount | amountPipe : currency }}</p>
      </div>
    </div>

    <div class="buttons-wrapper">
      <zaver-secondary-button-subtle
        [text]="'Go back'"
        [size]="'x-small'"
        (click)="setView(VIEW.PARTIAL_CAPTURE)"
      ></zaver-secondary-button-subtle>

      <zaver-primary-button
        [loading]="inProgress"
        [text]="'Capture'"
        [size]="'x-small'"
        (click)="onCapture()"
      ></zaver-primary-button>
    </div>
  </div>
</div>
