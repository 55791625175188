import { CashoutTaskStatus } from '@core/params';

export class CashoutTaskDto {
  lastEvent: Date;
  reference: string;
  amount: number;
  originalAmount: number;
  payeePhone: string;
  payeeNin: string;
  payeeName: string;
  taskId: string;
  status: CashoutTaskStatus;
  statusText: string;
  statusColor: string;
  bankAccount: string;
  initializingRepresentativeName: string;
  initializingRepresentativeId: string;
  approvingRepresentativeName: string;
  approvingRepresentativeId: string;
  executingRepresentativeName: string;
  executingRepresentativeId: string;
  backgroundColor?: string;
}
