import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  public transform(value, keys: string, term: string) {
    if (!term) {
      return value;
    }
    const dateRange = this.parseAndCreateDateRange(term);
    const keysToSearch = keys.split(',');

    return (value || []).filter(
      (item) => keysToSearch
          .some(key => {
            const fieldValue = this.getPropertyPathValue(item, key);
            if (fieldValue === undefined) {
              return false;
            }
            if (dateRange
              && typeof fieldValue === 'number'
              && dateRange.lower <= fieldValue
              && fieldValue < dateRange.upper
            ) {
              return true;
            }

            return new RegExp(this.escapeRegExp(term), 'gi').test(fieldValue);
          })
    );

  }

  // this helper function enables searching in nested objects
  // Example:
  // let exampleItem = { a: 1, b: { inside: 5 } }
  // getPropertyPathValue(exampleItem, "a") gives: 1
  // getPropertyPathValue(exampleItem, "b") gives: { inside: 5 }
  // getPropertyPathValue(exampleItem, "b.inside") gives: 5
  // getPropertyPathValue(exampleItem, "intel.inside") gives: undefined
  private getPropertyPathValue(item: Object, keyPath: string) {
    if (item.hasOwnProperty(keyPath)) {
      return item[keyPath];
    }
    return keyPath.split('.').reduce((traversedObj, partOfPath) =>
      traversedObj !== undefined && traversedObj !== null && traversedObj.hasOwnProperty(partOfPath)
        ? traversedObj[partOfPath]
        : undefined,
    item);
  }

  // search in date strings by first parsing input as a date and
  private parseAndCreateDateRange(maybeDate: string): any {
    const parsed = new Date(maybeDate);
    if (parsed.getMonth() === NaN) {
      // not a valid date
      return null;
    }
    if (maybeDate.length === 4) { // e.g. '2018'
      return {
        lower: parsed.getTime(),
        upper: parsed.setFullYear(parsed.getFullYear() + 1),
      };
    }
    if (maybeDate.length === 6 || maybeDate.length === 7) { // e.g. '2018-7' '2018-07'
      return {
        lower: parsed.getTime(),
        // adding 1 to a month actually works(!), even in December
        upper: parsed.setMonth(parsed.getMonth() + 1)
      };
    }
    if (maybeDate.length === 9 || maybeDate.length === 10) { // e.g. '2018-07-10'
      return {
        lower: parsed.getTime(),
        // this works with end of the month & end of the year,
        // it takes length of month into account
        upper: parsed.setDate(parsed.getDate() + 1)
      };
    }
    if (maybeDate.length >= 12) { // e.g. '2018-07-10 9' '2018-07-10 15' '2018-07-10 15:30' etc
      return {
        lower: parsed.getTime(),
        // this works with end of the day, times are in the browser's time zone
        upper: parsed.setHours(parsed.getHours() + 1)
      };
    }
    // we do not currently support any other cases
    return null;
  }

  private escapeRegExp(string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

}
