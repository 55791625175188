<div class="container">
  <ng-container *ngFor="let dot of dots">
    <div
      [class]="dot"
      [style.backgroundColor]="color"
      [style.height]="diameter + 'px'"
      [style.width]="diameter + 'px'"
    ></div>
  </ng-container>
</div>
