import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild} from '@angular/router';
import {Observable} from 'rxjs';
import {AppStateService} from '@core/service/app-state.service';

@Injectable()
export class MustBeApprovedCompany implements CanActivateChild {

  constructor(private router: Router, private appState: AppStateService) {
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.appState.isApproved()) {
      return true;
    } else {
      this.router.navigate(['/pendingApproval'], {replaceUrl: true});
      return false;
    }
  }
}
