<div id="create-container" class="spacing-container">
  <form [formGroup]="form" (ngSubmit)="submit()" novalidate>
    <div
      class="input-fields"
      [class.error]="form.controls.phone.invalid && showError"
    >
      <zaver-form-input
        title="Mobilnummer"
        placeholder="Mobilnummer"
        [error]="form.controls.phone.invalid && showError"
        [errorMessage]="getPhoneErrorMessage()"
        formControlName="phone"
        [inputMode]="'tel'"
        [size]="'large'"
        i18n-title="@@createPaymentRequestConsumer.form.phone.title"
        i18n-placeholder="@@createPaymentRequestConsumer.form.phone.placeholder"
        [showContent]="true"
      >
        <zaver-select-country
          [buttonId]="'select-country-code'"
          [items]="AVAILABLECOUNTRYCODES"
          (itemSelected)="setCountry($event)"
          [selectedItem]="selectedcountry"
        ></zaver-select-country>
      </zaver-form-input>
    </div>

    <div
      class="input-fields"
      [class.error]="form.controls.price.invalid && showError"
    >
      <zaver-form-input
        [showContent]="true"
        title="Belopp"
        placeholder="Belopp"
        [error]="form.controls.price.invalid && showError"
        errorMessage="Ange ett belopp mellan 1,00 - 999 999,00 kr (upp till två decimaler)"
        formControlName="price"
        [inputMode]="'decimal'"
        [size]="'large'"
        i18n-title="@@createPaymentRequestConsumer.form.price.title"
        i18n-placeholder="@@createPaymentRequestConsumer.form.price.placeholder"
        i18n-errorMessage="
          @@createPaymentRequestConsumer.form.price.errorMessage"
      >
        <span class="default-currency">
          {{ defaultCurrency }}
        </span>
      </zaver-form-input>
    </div>

    <div class="input-fields" *ngIf="displayAmountLimitsLink()">
      <a
        target="_blank"
        href="https://zaver.com/se/beloppsgranser"
        id="payment-limits-link"
        class="link"
      >
        <span class="limit-link">Visa beloppsgränser</span>
        <img src="assets/icons/Icon-External-Link.svg" />
      </a>
    </div>

    <div
    *ngIf="showInstallmentsToggle"
    class="input-fields installments-toggle"
    >
      <zaver-checkbox
        formControlName="toggleInstallmentOptions"
        [text]="getAliasForToggleInstallments()"
      ></zaver-checkbox>
    </div>

    <div
      class="input-fields"
      [class.error]="form.controls.title.invalid && showError"
    >
      <zaver-form-input
        [title]="prFormTitleAlias"
        [placeholder]="prFormTitleAlias"
        formControlName="title"
        [error]="form.controls.title.invalid && showError"
        [maxLength]="255"
        [lengthOfValue]="form.controls.title.value.length"
        [showCounter]="true"
        [size]="'large'"
        errorMessage="Fältet får inte lämnas tomt, ange max 255 tecken"
        i18n-errorMessage="
          @@createPaymentRequestConsumer.form.title.errorMessage"
      ></zaver-form-input>
    </div>

    <div
      class="input-fields-last"
      [class.error]="form.controls.description.invalid && showError"
    >
      <zaver-zfb-textarea
        formControlName="description"
        [error]="form.controls.description.invalid && showError"
        [title]="prFormDescriptionAlias"
        [placeholder]="prFormDescriptionAlias"
        errorMessage="Ange beskrivning (max 50000 tecken)"
        i18n-errorMessage="
          @@createPaymentRequestConsumer.form.description.errorMessage"
      ></zaver-zfb-textarea>
    </div>

    <div class="responsive-flex-container">
      <div class="radio-button-container">
        <zaver-zfb-radio-button
          [color]="'green'"
          [selected]="!copyLinkSelected"
          (click)="setCopyLink(false)"
          text="Skicka som SMS"
          i18n-text="@@createPaymentRequestConsumer.sendSms"
        ></zaver-zfb-radio-button>
      </div>

      <div class="radio-button-container">
        <label>
          <zaver-zfb-radio-button
            [color]="'green'"
            [selected]="copyLinkSelected"
            (click)="setCopyLink(true)"
            text="Kopiera länken"
            i18n-text="@@createPaymentRequestConsumer.copyLink"
          ></zaver-zfb-radio-button>
        </label>
      </div>
    </div>

    <div class="input-fields" *ngIf="formError">
      <app-form-error [errorText]="formError"> </app-form-error>
    </div>
  </form>

  <div class="continue-back-buttons-container">
    <zaver-primary-button
      class="continue-button"
      text="Skicka förfrågan"
      i18n-text="@@createPaymentRequestConsumer.button.submit"
      [loading]="processing"
      (click)="submit()"
    ></zaver-primary-button>

    <zaver-secondary-button-subtle
      *ngIf="merchantHasB2B"
      class="back-button"
      [text]="'Tillbaka'"
      [disabled]="processing"
      (click)="returnToSelection.emit()"
    ></zaver-secondary-button-subtle>

    <zaver-secondary-button-subtle
      *ngIf="!merchantHasB2B && deviceService.isMobile()"
      text="Stäng"
      i18n-text="@@createPaymentRequestConsumer.button.close"
      (click)="onClose.emit()"
    ></zaver-secondary-button-subtle>
  </div>
</div>
