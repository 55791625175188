import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import {
  CompanyStatus,
  Permission,
  UserDetails,
  UserSessionUpdateMessage,
} from '@core/dto/user-details';
import { AppStateService } from '@core/service/app-state.service';
import { AccessControlService } from '@core/service/access-control.service';
import {
  WsService,
  SubTopic,
  WebsocketListener,
} from '@core/service/ws.service';
import { CustomizationService } from '@core/service/customization.service';
import { CompanyRepresentationRoleColorRepresentations } from '@assets/language/RoleColorRepresentation';
import { NavigationEnd, Router } from '@angular/router';
import { ColorService } from '@core/service/color.service';
import { LanguageSelection } from '@zfb/ui/language-selection/language-selection.component';
import { filter } from 'rxjs/operators';
import { LocaleService } from '@core/service/locale.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent
  implements
    OnInit,
    AfterViewInit,
    OnChanges,
    WebsocketListener<UserSessionUpdateMessage>
{
  constructor(
    // private modalService: NgbModal,
    private appStateService: AppStateService,
    private access: AccessControlService,
    private wsService: WsService,
    private customizationService: CustomizationService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private localeService: LocaleService,
    public colorService: ColorService
  ) {
    this.wsService.registerTopicListener(SubTopic.UserSwitchedProfile, this);
    router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe(() => this.resolveDisplayOfLanguageSelection());
  }

  @Input() user: UserDetails;
  @Output() logout = new EventEmitter();

  modalActive = false;
  modalSmallActive = false;
  modalText: string;
  logoutModalActive = false;
  profileSelectModalActive = false;
  profileChangedModalActive = false;

  allowLanguageSelection: boolean;
  showLanguageSelection = false;

  storeAliasName = 'Hej';

  public menuShowing = '';
  showAdminSubMenu = false;
  showMobileMenu = false;
  showProfileMenu = false;
  mobileMainMenu = false;
  mobileProfileMenu = false;

  readonly NO_ROUTE = '';

  @HostListener('document:click', ['$event.target'])
  public onClick(target) {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside) {
      if (this.showProfileMenu) {
        this.showProfileMenu = false;
        this.renderer.removeClass(document.body, 'disable-overflow-body');
      }
    }
  }

  handleMessage(message: UserSessionUpdateMessage): void {
    if (!this.user || !this.user.activeRepresentation) {
      // not logged in
      return;
    }
    if (
      this.user.activeRepresentation &&
      message.userDetails &&
      message.userDetails.activeRepresentation &&
      this.user.activeRepresentation.id ===
        message.userDetails.activeRepresentation.id
    ) {
      // same user => do nothing
      return;
    }
    if (message.userDetails === null) {
      // The user is being logged out
      return;
    }

    this.notifyAboutProfileUpdate();
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    // To avoid NG0100: ExpressionChangedAfterItHasBeenCheckedError, set timeout to 0 seconds.
    // This will basically push the method call to the end of the current execution queue
    // It is needed to do the following in this life cycle hook because we might get to /login
    // after a redirect from /, and the pathname is not /login until in this lifecycle
    this.resolveDisplayOfLanguageSelection();
  }

  resolveDisplayOfLanguageSelection(): void {
    setTimeout(() => {
      // Language selection should only be allowed in the very first page,
      // where the email is entered.
      this.allowLanguageSelection = location.pathname.endsWith('/login');
    }, 0);
  }

  ngOnChanges(changes) {
    if (
      this.user &&
      this.user.activeRepresentation &&
      this.user.activeRepresentation.role
    ) {
      this.storeAliasName = this.customizationService.getStoreAlias(
        true,
        false,
        false,
        true
      );
    }
  }

  getLogoPath(): string {
    
      return 'assets/logo/zaver_logotype_white.svg';
    
  }

  isLoggedIn(): boolean {
    return !!this.user;
  }

  hasSelectedProfile(): boolean {
    return !!this.user && !!this.user.activeRepresentation;
  }

  hasMultipleApprovedProfiles() {
    return (
      !!this.user &&
      this.user.availableRepresentations.filter((rep) =>
        rep.company.status.find((status) => status === CompanyStatus.APPROVED)
      ).length > 1
    );
  }

  isApproved(): boolean {
    return this.appStateService.isApproved();
  }

  navigateOnOpenAdminMenu(): string {
    if (this.mayViewUsers()) {
      return '/admin/users';
    }
    if (this.mayUseStores()) {
      return '/admin/stores';
    }
    if (this.mayViewRefunds()) {
      return '/admin/refunds';
    }
    if (this.mayViewReport()) {
      return '/admin/report';
    }
    if (this.mayViewCompanyGeneralInfo()) {
      return '/admin/company';
    }
    if (this.mayViewZfbMerchantSettings()) {
      return '/admin/zfbMerchantSettings';
    }
    return this.NO_ROUTE;
  }

  mayDisplayAdminMenu(): boolean {
    return this.navigateOnOpenAdminMenu() !== this.NO_ROUTE;
  }

  mayViewUsers(): boolean {
    return this.access.userMay(Permission.GET_USERS);
  }

  mayUseStores() {
    return (
      this.access.userMay(Permission.GET_STORES) &&
      this.appStateService.merchantHasStoreAccess()
    );
  }

  mayViewCompanyGeneralInfo(): boolean {
    return this.access.userMay(Permission.GET_COMPANY_INFORMATION);
  }

  mayViewZfbMerchantSettings(): boolean {
    return this.access.userMay(Permission.GET_ZFB_MERCHANT_SETTINGS);
  }

  mayViewCashout(): boolean {
    return (
      this.access.userMay(Permission.GET_CASHOUT_ORDERS) &&
      this.appStateService.merchantHasCashoutAccess()
    );
  }

  mayViewReport(): boolean {
    return this.access.userMay(Permission.GET_REPORTS);
  }

  mayViewPrecheck(): boolean {
    return (
      this.access.userMay(Permission.CREATE_PRECHECK) &&
      this.appStateService.merchantHasPrecheckAccess()
    );
  }

  mayViewRefunds(): boolean {
    return (
      this.access.userMay(Permission.GET_REFUNDS) &&
      this.appStateService.merchantHasRefundAccess()
    );
  }

  closeModal() {
    this.profileSelectModalActive = false;
    this.deactivateModal();
  }
  closeSmallModal() {
    this.logoutModalActive = false;
    this.profileChangedModalActive = false;
    this.deactivateSmallModal();
  }

  openLogoutModalMobile() {
    this.toggleMobileMenu();
    this.openLogoutModal();
  }

  openLogoutModal() {
    this.modalText = $localize`:@@header.modal.title.logout:Logga ut`;
    this.logoutModalActive = true;
    this.closeMobileMenu();
    this.closeProfileMenu();
    this.activateSmallModal();
  }

  openProfileSelectModal() {
    this.modalText = $localize`:@@header.modal.title.profileSelect:Välj profil`;
    this.profileSelectModalActive = true;
    this.closeMobileMenu();
    this.closeProfileMenu();
    this.activateModal();
  }

  openProfileChangedModal() {
    this.modalText = '';
    this.profileChangedModalActive = true;
    this.activateSmallModal();
  }

  activateModal() {
    this.modalActive = true;
  }
  activateSmallModal() {
    this.modalSmallActive = true;
  }

  deactivateModal() {
    this.modalActive = false;
  }
  deactivateSmallModal() {
    this.modalSmallActive = false;
  }

  logoutUser() {
    this.closeSmallModal();
    this.closeModal();
    this.logout.emit();
  }

  // hooked up to event
  onProfileSelected() {
    this.closeModal();
    this.closeMobileMenu();
    this.closeProfileMenu();
  }

  notifyAboutProfileUpdate() {
    this.closeModal();
    this.closeMobileMenu();
    this.openProfileChangedModal();
  }

  contactLink(): string {
    switch (this.localeService.getCurrentLocale()) {
      case 'de':
      case 'de-AT':
        return 'https://zaver.com/de/kontakt';
      case 'sv':
        return 'https://zaver.com/se/kontakt';
      default:
        return 'https://zaver.com/en/contact';
    }
  }

  toggleAdminList() {
    this.showAdminSubMenu = !this.showAdminSubMenu;
  }

  toggleMobileMenu() {
    if (this.showMobileMenu && this.mobileProfileMenu) {
      this.mobileMainMenu = true;
      this.mobileProfileMenu = false;
      this.renderer.addClass(document.body, 'disable-overflow-body');
    } else if (this.showMobileMenu && this.mobileMainMenu) {
      this.showMobileMenu = false;
      this.mobileMainMenu = false;
      this.mobileProfileMenu = false;
      this.renderer.removeClass(document.body, 'disable-overflow-body');
    } else if (!this.showMobileMenu) {
      this.showMobileMenu = true;
      this.mobileMainMenu = true;
      this.renderer.addClass(document.body, 'disable-overflow-body');
    }
  }

  closeMobileMenu() {
    this.showMobileMenu = false;
    this.mobileMainMenu = false;
    this.mobileProfileMenu = false;
    this.renderer.removeClass(document.body, 'disable-overflow-body');
  }

  closeProfileMenu() {
    this.showProfileMenu = false;
    this.renderer.removeClass(document.body, 'disable-overflow-body');
  }

  toggleProfileMenu() {
    this.showProfileMenu = !this.showProfileMenu;
    this.mobileProfileMenu = this.showProfileMenu;

    if (this.showProfileMenu && !this.showMobileMenu) {
      this.showMobileMenu = true;
      this.renderer.addClass(document.body, 'disable-overflow-body');
    } else if (this.showMobileMenu && this.showProfileMenu) {
      this.mobileMainMenu = false;
      this.renderer.removeClass(document.body, 'disable-overflow-body');
    } else if (!this.showProfileMenu) {
      this.showMobileMenu = false;
      this.renderer.removeClass(document.body, 'disable-overflow-body');
    }
  }

  logoClicked() {
    this.showAdminSubMenu = false;
    this.showMobileMenu = false;
    this.showProfileMenu = false;
    this.mobileMainMenu = false;
    this.mobileProfileMenu = false;
    this.renderer.removeClass(document.body, 'disable-overflow-body');
  }

  roleColor(): string {
    let colorInfo = CompanyRepresentationRoleColorRepresentations.get(
      this.user.activeRepresentation.role.color
    );
    if (!colorInfo) {
      colorInfo =
        CompanyRepresentationRoleColorRepresentations.get('_default_');
    }
    return colorInfo.hexColor;
  }

  toggleLanguageSelection() {
    this.showLanguageSelection = !this.showLanguageSelection;
  }

  localeChanged(languge: LanguageSelection) {
    this.showLanguageSelection = false;
    if (this.localeService.getCurrentLocale() === languge.locale) {
      return;
    }
    location.href = '/' + languge.subPath + this.router.url;
  }
}
