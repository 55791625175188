<ng-container *ngIf="!loading">
  <div class="card">
    <div class="card-heading">
      <h2>{{ showCancelledPaymentRequestForm.title }}</h2>
    </div>
    <div class="card-body">
      <form [formGroup]="settingsForm">
        <div class="form-wrapper full-width">
          <div class="flex-wrapper">
            <div class="form-title-wrapper">
              <div class="form-title">
                <span
                  i18n="
                    @@zfbMerchantSettings.paymentRequests.form.showCancelledPaymentRequests.title"
                  >Visa avbrutna betallänkar som är äldre än 48h</span
                >
              </div>
            </div>
            <app-switch
              [disabled]="!mayUpdatePaymentRequestSettings"
              (change)="onPaymentRequestSettingChange($event)"
              [formControlName]="showCancelledPaymentRequestForm.name"
            >
            </app-switch>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div id="admin-buttons">
    <zaver-secondary-button-outlined
      *ngIf="formChanged"
      style="width: 110px"
      (click)="promptCancelModal()"
      [text]="cancelZfbMerchantSettingsButtonText"
    ></zaver-secondary-button-outlined>
    <zaver-primary-button
      style="width: 110px; margin-left: 12px"
      (click)="onSave()"
      [text]="saveZfbMerchantSettingsButtonText"
      [disabled]="!formChanged"
      [color]="'black'"
    ></zaver-primary-button>
  </div>
  <zaver-details-modal
    *ngIf="modalActive"
    (onClose)="closeModal()"
    [title]="modalTitle"
    [color]="modalColor"
  >
    <app-zfb-merchant-settings-confirmation
      *ngIf="modalView === ModalViewType.SUBMIT"
      (onClose)="closeModal()"
      (onSubmit)="submit()"
      [serverError]="serverError"
      [errorMessage]="errorMessage"
      [submitting]="submitting"
    >
    </app-zfb-merchant-settings-confirmation>

    <div class="small-modal-wrapper" *ngIf="modalView === ModalViewType.CANCEL">
      <span i18n="@@zfbMerchantSettings.cancel-settings.modal.text"
        >Är du säker på att du vill avbryta? Dina ändringar kommer inte sparas.
        😯</span
      >
      <div class="button-container">
        <zaver-secondary-button-subtle
          style="width: auto"
          [text]="cancelModalZfbMerchantSettingsButtonText"
          (click)="closeModal()"
          [size]="'x-small'"
        ></zaver-secondary-button-subtle>
        <zaver-primary-button
          style="width: auto"
          [text]="yesCancelModalBackZfbMerchantSettingsButtonText"
          [color]="'black'"
          (click)="cancel()"
          [size]="'x-small'"
        ></zaver-primary-button>
      </div>
    </div>
  </zaver-details-modal>
</ng-container>
