<div *ngIf="checkedAccess && accessGranted">
  <h1 class="page-heading">Utbetalningar</h1>

  <div id="search-and-create-container">
    <div class="input-container">
      <zaver-search
        id="search-bar"
        [inputModel]="searchQuery"
        (inputModelChange)="searchUpdatedQuery($event)"
      ></zaver-search>
    </div>
    <zaver-zfb-add-button
      *ngIf="shouldDisplayCreateButton()"
      [text]="'Ny utbetalning'"
      (click)="create()"
      id="main-create-button"
    ></zaver-zfb-add-button>
  </div>
  <app-page-error
    *ngIf="errorMessage"
    [errorMessage]="errorMessage"
  ></app-page-error>

  <div id="balance-container">
    <div class="balance-wrapper">
      <span class="balance-label">Saldo:</span>&nbsp;
      <span id="balance-text">{{
        fetchingBalance
          ? ' Uppdateras...'
          : (balance | currency: 'SEK ':'code':'1.2-2':'sv')
      }}</span>
    </div>
    <div id="spacer"></div>
    <div class="balance-wrapper">
      <span class="pending-label">Pågående utbetalningar:</span>&nbsp;
      <span class="cerise-text">{{
        pendingExecutionSum | currency: 'SEK ':'code':'1.2-2':'sv'
      }}</span>
    </div>
  </div>

  <div id="mobile-balance">
    <div class="balance-wrapper">
      <span class="balance-label">Saldo:</span>&nbsp;
      <span id="balance-text">{{
        fetchingBalance
          ? ' Uppdateras...'
          : (balance | currency: 'SEK ':'code':'1.2-2':'sv')
      }}</span>
    </div>
    <div class="balance-wrapper">
      <span class="pending-label">Pågående utbetalningar:</span>&nbsp;
      <span class="cerise-text">{{
        pendingExecutionSum | currency: 'SEK ':'code':'1.2-2':'sv'
      }}</span>
    </div>
  </div>

  <div *ngIf="fetchingResponse" class="page-loader-container">
    <app-page-loader></app-page-loader>
  </div>

  <ng-container *ngIf="!fetchingResponse">
    <app-table
      [columns]="columns"
      [values]="cashoutTasks"
      (filterChange)="onActiveStatusChange($event)"
    >
    </app-table>

    <app-empty-state *ngIf="displayEmptyState" [emptyState]="currentEmptyState">
    </app-empty-state>

    <app-table-pagination
      *ngIf="cashoutTasks.rows.length > 0"
      [paginationState]="paginationState"
      (pageChanged)="onPageChange($event)"
      (pageSizeChanged)="onPageSizeChange($event)"
    >
    </app-table-pagination>
  </ng-container>

  <zaver-zfb-add-button
    *ngIf="!fetchingResponse && shouldDisplayCreateButton()"
    [text]="'Ny utbetalning'"
    (click)="create()"
    class="mobile-cta"
  ></zaver-zfb-add-button>

  <!-- Modal -->
  <zaver-form-modal
    *ngIf="modalActive"
    (onClose)="closeModal()"
    [title]="modalTitle"
    [color]="modalColor"
  >
    <app-create-cashout-task
      *ngIf="showCashoutTaskCreation"
      (onCashoutTaskCreated)="displayConfirmationText()"
      (updateModalTextAndColor)="updateModalTextAndColor($event)"
      (onClose)="closeModal()"
    >
    </app-create-cashout-task>

    <app-view-cashout-task
      *ngIf="showCashoutTaskView"
      [cashoutTask]="selectedTask"
      (onDone)="closeModalAndRefreshData()"
      (showCashoutCancelModal)="displayCancelContent()"
      (showCashoutDisbursementConfirmationModal)="displayConfirmation()"
    >
    </app-view-cashout-task>
  </zaver-form-modal>
  <zaver-details-modal
    *ngIf="modalSmallActive"
    (onClose)="closeSmallModal()"
    [title]="modalTitle"
    [color]="modalColor"
  >
    <app-cancel-cashout-task
      *ngIf="showCashoutCancel"
      [cashoutTask]="selectedTask"
      (onReturnToMainModal)="returnToViewModal()"
      (onCloseModal)="closeSmallModal()"
      (onCloseAfterCancel)="closeSmallModalAndRefreshData()"
    >
    </app-cancel-cashout-task>
    <app-confirm-cashout-task
      *ngIf="showCashoutConfirm"
      [cashoutTask]="selectedTask"
      (onReturnToMainModal)="returnToViewModal()"
      (onCloseModal)="closeSmallModal()"
      (onConfirm)="closeSmallModalAndRefreshData()"
    >
    </app-confirm-cashout-task>
    <div class="small-modal-wrapper" *ngIf="displayCashoutTaskSentConfirmation">
      <span>Klart! Din förfrågan har skickats. 🙂 </span>

      <div class="button-container">
        <zaver-primary-button
          [text]="'Stäng'"
          [color]="'black'"
          (click)="closeSmallModal()"
        >
        </zaver-primary-button>
      </div>
    </div>
  </zaver-details-modal>
</div>

<div *ngIf="checkedAccess && !accessGranted">
  <h4 id="access-denied-text">Åtkomst nekad.</h4>
</div>
