import { PrecheckStatus } from '@core/dto/precheck/PrecheckStatus';

export interface PrecheckRepresentation {
  statusText: string;
  statusColor: string;
  backgroundColor?: string;
}

export const PrecheckRepresentations: Map<string, PrecheckRepresentation> =
  new Map([
    [
      PrecheckStatus.PENDING,
      {
        statusText: 'SMS gesendet',
        statusColor: '#4466EE',
        backgroundColor: '#DBE7FF'
      },
    ],
    [
      PrecheckStatus.GRANTED,
      {
        statusText: 'Genehmigt',
        statusColor: '#009773',
        backgroundColor: '#E5F5F2'
      },
    ],
    [
      PrecheckStatus.DENIED,
      {
        statusText: 'Abgelehnt',
        statusColor: '#DB305E',
        backgroundColor: '#FCE8F2'
      },
    ],
    [
      PrecheckStatus.CANCELLED,
      {
        statusText: 'Cancelled',
        statusColor: '#0A1018',
        backgroundColor: '#E6E9EF'
      },
    ],
  ]);
