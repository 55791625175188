import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'zaver-zfb-textarea',
  templateUrl: './zfb-textarea.component.html',
  styleUrls: ['./zfb-textarea.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ZfbTextareaComponent),
      multi: true,
    },
  ],
})
export class ZfbTextareaComponent implements ControlValueAccessor {
  @Input() title: string;
  @Input() helpTitle: string;
  @Input() placeholder: string;
  @Input() error: boolean;
  @Input() errorMessage: string;
  @Input() disabled: boolean;
  @Input() numRows: number;
  @Input() small: boolean;
  @Input() value: string;

  /**
   * ControlValueAccessor methods (This is boiler plate)
   */
  onChange: any = () => {}; // Populated by Angular
  onTouch: any = () => {}; // Populated by Angular
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  /**
   * Method called when the FormControl is updated from outside this component
   */
  writeValue(value: string): void {
    this.value = value;
  }

  /**
   * Write form disabled state to the DOM element (model => view)
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
