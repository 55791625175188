import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RestBase } from '@core/rest-base';
import { HrefService } from './href.service';

@Injectable()
export class CheckoutEventsService extends RestBase {
  constructor(private httpClient: HttpClient, hrefService: HrefService) {
    super(httpClient, hrefService.getApiUrl());
  }

  async getUserFlowEvents(id: string): Promise<any> {
    return await super.get(`/userFlowEvents?paymentRequestId=${id}`);
  }
}
