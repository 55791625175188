<div
  *ngIf="event.firstEventOfDay"
  style="
    margin-bottom: 16px;
    font-size: 13px;
    line-height: 22px;
    font-weight: 500;
    text-align: center;
  "
>
  {{ createdDate }}
</div>

<div class="event-container">
  <div class="event-header">
    <div class="time-container">
      <img src="assets/icons/Icon-Clock.svg" class="clock-img" />
      <p class="event-time">{{ createdTime }}</p>
    </div>
    <div class="types-container">
      <app-event-type-pill
        [textColor]="'#DF5809'"
        [backgroundColor]="'#FCEEE6'"
        text="User log"
      ></app-event-type-pill>
    </div>
  </div>
  <div>
    <span _ngcontent-sgh-c128="" class="event-type">
      {{ event.type }}
    </span>
  </div>
  <hr class="event-divider" />
  <div class="event-description">{{ event.description }}</div>
</div>
