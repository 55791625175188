import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

export interface DropdownListItemv2 {
  label: string;
  value: any;
}

@Component({
  selector: 'zaver-dropdownv2',
  templateUrl: './dropdownv2.component.html',
  styleUrls: ['./dropdownv2.component.scss'],
})
export class Dropdownv2Component implements OnInit {
  @Output() inputModelChange = new EventEmitter<any>();
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() title: string = '';
  @Input() shorterDropdown: boolean = false;
  @Input() selectableItems: DropdownListItemv2[] = null;
  @Input() selectedItem: any = null;
  @Input() error: boolean = false;
  @Input() errorMessage: string = '';
  @Input() disabled: boolean = false;
  @Input() helpText: string = '';

  showDropDown: boolean = false;

  constructor() {}

  ngOnInit(): void {
    if (!this.selectedItem) {
      this.selectedItem = this.selectableItems[0];
    }
  }

  onKeydown(event: KeyboardEvent): void {
    if (event.code === 'ArrowDown' || event.code === 'ArrowUp') {
      this.toggleDropDown();
      event.preventDefault();
    }
  }

  selectItem(item): void {
    this.selectedItem = item;
    this.inputModelChange.emit(item.value);
  }

  toggleDropDown(): void {
    if (!this.disabled) {
      this.showDropDown = !this.showDropDown;
    }
  }
}
