import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ElementRef,
  SimpleChanges,
  OnChanges,
} from '@angular/core';

export interface DropdownListItem {
  label: string;
  value: any;
}
@Component({
  selector: 'zaver-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css'],
})
export class DropdownComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() inputModel: any;
  @Input() selectOptions: DropdownListItem[];
  @Input() shorterDropdown: boolean;
  @Input() error: boolean;
  @Input() errorMessage: string;
  @Input() disabled = false;
  @Input() initialLabel = 'Välj';
  @Output() inputModelChange = new EventEmitter<any>();
  @Input() size: 'small' | 'medium' | 'large' = 'medium';

  selectedOption: DropdownListItem;
  showDropDown = false;

  @HostListener('document:click', ['$event.target'])
  public onClick(target): void {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside) {
      if (this.showDropDown) {
        this.showDropDown = false;
      }
    }
  }

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.setup();
  }

  setup() {
    if (this.inputModel || this.inputModel === 0 || this.inputModel === '') {
      this.selectedOption = this.selectOptions.find(
        (obj) => JSON.stringify(obj.value) === JSON.stringify(this.inputModel)
      );
    } else {
      this.selectedOption = {
        label: this.initialLabel,
        value: null,
      };
    }
  }

  toggleDropDownList(): void {
    if (this.disabled) {
      return;
    }
    this.showDropDown = !this.showDropDown;
  }

  onSelect(option: DropdownListItem): void {
    if (this.disabled) {
      return;
    }
    this.selectedOption = option;
    this.inputModel = option.value;
    this.inputModelChange.emit(this.inputModel);
    this.toggleDropDownList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setup();
  }
}
