import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BaseComponent } from '@core/base.component';
import { Currency, CurrencyCode } from '@core/dto/CurrencyCode';
import { PrecheckRequest } from '@core/dto/precheck/PrecheckRequest';
import {
  PrecheckSummary,
  PrecheckSummaryEntry,
} from '@core/dto/precheck/PrecheckSummary';
import { Permission } from '@core/dto/user-details';
import { AccessControlService } from '@core/service/access-control.service';
import { AppStateService } from '@core/service/app-state.service';
import { AuthenticationService } from '@core/service/authentication.service';
import { PrecheckService } from '@core/service/precheck.service';
import { swedishNationalIdentificationNumberValidator, swedishNationalIdentificationRegex } from '@shared/validators/identification-number-validators';
import { FormValidators } from '@shared/validators/validators';
import {
  formatPhoneNumber,
  phoneNumberValidator,
} from '../../../../../../web-component-library/projects/component-library/src/common/phone-number-validator.directive';

@Component({
  selector: 'app-precheck-se',
  templateUrl: './precheck-se.component.html',
  styleUrls: ['./precheck-se.component.scss'],
})
export class PrecheckSeComponent extends BaseComponent implements OnInit {
  constructor(
    protected auth: AuthenticationService,
    private accessControlService: AccessControlService,
    private precheckService: PrecheckService,
    private appStateService: AppStateService
  ) {
    super(auth);
    this.defaultCurrency = this.appStateService.getDefaultCurrency();
  }
  form: FormGroup;

  displayResults = false;
  precheckAmount: number;
  fetchingResponse: boolean;
  errorMessage = '';
  showError = false;

  atLeastOneGranted = false;
  grantedInvoice = false;

  public accessGranted = false;
  public checkedAccess = false;

  precheckSummary: PrecheckSummary;

  public min = 2000;
  public max = 200000;

  defaultCurrency: CurrencyCode;

  ngOnInit() {
    this.accessGranted = this.accessControlService.userMay(
      Permission.CREATE_PRECHECK
    );

    this.checkedAccess = true;

    this.setupForm();
  }

  setupForm() {
    this.form = new FormGroup({
      personalNumber: new FormControl('', [
        Validators.required,
        swedishNationalIdentificationNumberValidator(),
        Validators.pattern(
          swedishNationalIdentificationRegex
        ),
      ]),
      phoneNumber: new FormControl('', [
        Validators.required,
        phoneNumberValidator(this.appStateService.getSupportedCountryCodes()),
      ]),
      amount: new FormControl('', [
        Validators.required,
        FormValidators.number(this.min, this.max),
      ]),
    });
  }

  compareByMonthlyCost(
    left: PrecheckSummaryEntry,
    right: PrecheckSummaryEntry
  ) {
    const a: number = parseInt(left.monthlyCost);
    const b: number = parseInt(right.monthlyCost);
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  }

  submit() {
    this.clearError();
    this.displayResults = false;
    if (this.form.valid) {
      this.fetchingResponse = true;
      let personalNumber: string =
        this.form.controls.personalNumber.value.toString();
      personalNumber = personalNumber.replace(/-/g, '');

      const value: Currency = {
        amount: this.form.controls.amount.value.replace(/\s/g, ''),
        currencyCode: CurrencyCode.SEK,
      };

      const precheckToCreate = new PrecheckRequest(
        personalNumber,
        null,
        formatPhoneNumber(this.form.controls.phoneNumber.value),
        value
      );

      return this.precheckService
        .create(precheckToCreate)
        .then((res) => {
          this.displayResults = true;
          this.precheckAmount = this.form.controls.amount.value.replace(/\s/g, '');

          this.precheckSummary = res;
          this.precheckSummary.summaryEntries.sort(this.compareByMonthlyCost);

          this.fetchingResponse = false;
        })
        .catch((err) => {
          super.handleError(err);
          const httpError: HttpErrorResponse = JSON.parse(JSON.stringify(err));
          if (httpError.status === 400) {
            this.errorMessage =
              'Vi kan tyvärr inte presentera svaret av vår bedömning för personen i fråga. Vänligen kontakta vår kundservice.';
          } else {
            this.errorMessage =
              'Någonting gick fel när kreditprövningen skulle hämtas';
          }
          this.fetchingResponse = false;
        });
    } else {
      this.showError = true;
    }
  }
}
