import { Injectable } from '@angular/core';
import { RestBase } from '@core/rest-base';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { HrefService } from './href.service';
import { ReportType } from '@core/dto/ReportInfo';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { FilePreference } from 'app/zfb/page/report/accounting-report/accounting-report.component';
import { Currency, CurrencyCode } from '@core/dto/CurrencyCode';
import { ReportDisbursement, ReportDisbursementTotal } from '@core/dto/report/ReportDisbursement';
import { PaginatedList } from '@core/dto/PaginatedList';
import { PaginatedListWithSummary } from '@core/dto/PaginatedListWithSummary';

@Injectable()
export class ReportService extends RestBase {
  baseHeaders: HttpHeaders;

  private readonly httpReqOptions = { withCredentials: true };

  constructor(private httpClient: HttpClient, hrefService: HrefService) {
    super(httpClient, hrefService.getApiUrl());
  }

  public getDailySales(
    dateFrom?: Date,
    dateTo?: Date,
    page?: number,
    pageSize?: number
  ): Observable<PaginatedList<DailySummary>> {
    {
      const url = `${environment.apiUrl}/report/sales-report-web`;

      let params = new HttpParams();

      params = params.append('dateFrom', dateFrom.toISOString());
      params = params.append('dateTo', dateTo.toISOString());
      if (page || page === 0) {
        params = params.append('page', page.toString());
      }
      if (pageSize) {
        params = params.append('pageSize', pageSize.toString());
      }

      return this.httpClient.get<PaginatedList<DailySummary>>(url, {
        ...this.httpReqOptions,
        params: params,
      });
    }
  }

  public getFile(dateFrom?: Date, dateTo?: Date) {
    let params = new HttpParams();
    params = params.append('dateFrom', dateFrom.toISOString());
    params = params.append('dateTo', dateTo.toISOString());

    const url = `${environment.apiUrl}/report/sales-report-file`;

    return this.httpClient.get(url, {
      headers: this.baseHeaders,
      observe: 'response',
      ...this.httpReqOptions,
      params: params,
      responseType: 'arraybuffer',
    });
  }

  public getMerchantReportSummary(dateFrom?: Date, dateTo?: Date) {
    const url = `${environment.apiUrl}/report/merchant-report-web`;

    let params = new HttpParams();

    params = params.append('dateFrom', dateFrom.toISOString());
    params = params.append('dateTo', dateTo.toISOString());

    return this.httpClient.get<ReportSummaryDto>(url, {
      ...this.httpReqOptions,
      params: params,
    });
  }

  public getMerchantReport(
    dateFrom?: Date,
    dateTo?: Date,
    preference?: FilePreference,
    reportType?: ReportType
  ) {
    const url = `${environment.apiUrl}/report/merchant-report-file`;

    let params = new HttpParams();
    params = params.append('dateFrom', dateFrom.toISOString());
    params = params.append('dateTo', dateTo.toISOString());
    params = params.append('preference', preference);
    params = params.append('reportType', reportType);

    return this.httpClient.get(url, {
      headers: this.baseHeaders,
      observe: 'response',
      ...this.httpReqOptions,
      params: params,
      responseType: 'arraybuffer',
    });
  }

  public getMerchantReportV2(
    search?: string,
    dateFrom?: Date,
    dateTo?: Date,
    preference?: DisbursementReportFormat,
    reportType?: ReportType,
    includeRefunds?: Boolean
  ) {
    const url = `${environment.apiUrl}/report/merchant-report-file-v2`;

    let params = new HttpParams();
    if (search !== '') params = params.append('search', search);
    params = params.append('dateFrom', dateFrom.toISOString());
    params = params.append('dateTo', dateTo.toISOString());
    params = params.append('preference', preference);
    params = params.append('reportType', reportType);
    params = params.append('includeRefunds', String(includeRefunds));

    return this.httpClient.get(url, {
      headers: this.baseHeaders,
      observe: 'response',
      ...this.httpReqOptions,
      params: params,
      responseType: 'arraybuffer',
    });
  }

  public getReportDisbursements(
    search?: string,
    dateFrom?: Date,
    dateTo?: Date,
    page?: number,
    pageSize?: number
  ): Observable<PaginatedListWithSummary<ReportDisbursement,ReportDisbursementTotal>> {
    {
      const url = `${environment.apiUrl}/report/merchant-report-disbursement`;

      let params = new HttpParams();

      if (search !== '') params = params.append('search', search);
      params = params.append('dateFrom', dateFrom.toISOString());
      params = params.append('dateTo', dateTo.toISOString());
      if (page || page === 0) {
        params = params.append('page', page.toString());
      }
      if (pageSize) {
        params = params.append('pageSize', pageSize.toString());
      }

      return this.httpClient.get<PaginatedListWithSummary<ReportDisbursement,ReportDisbursementTotal>>(url, {
        ...this.httpReqOptions,
        params: params,
      });
    }
  }

  public getReportPaymentRequests(
    search?: string,
    reportDisbursementId?: string
  ): Observable<ReportPaymentRequest[]> {
    {
      const url = `${environment.apiUrl}/report/merchant-report-payment-request`;

      let params = new HttpParams();

      if (search !== '') params = params.append('search', search);
      params = params.append('reportDisbursementId', reportDisbursementId);

      return this.httpClient.get<ReportPaymentRequest[]>(url, {
        ...this.httpReqOptions,
        params: params,
      });
    }
  }

  public getMerchantReportPreference() {
    const url = `${environment.apiUrl}/report/merchant-report-preferences`;
    return this.httpClient.get<Boolean>(url, {
      observe: 'response',
      ...this.httpReqOptions,
    });
  }

  public getMerchantReportFormats() {
    const url = `${environment.apiUrl}/report/merchant-report-formats`;
    return this.httpClient.get<ReportFormats>(url, {
      observe: 'response',
      ...this.httpReqOptions,
    });
  }
}

export interface DailySummary {
  date: Date;
  transactions: number;
  sum: number;
  currency: CurrencyCode;
  totalTransactions: number;
  totalSum: number;
}

export interface ReportPaymentRequest {
  paymentRequestId: string;
  paymentRequestValue: Currency;
  disbursementDate: Date;
  value: Currency;
  title: string;
  description: string;
}

export class ReportSummaryDto {
  checkoutPayoutVolume: Currency;
  numberOfCheckoutEntries: number;
  cashoutPayoutVolume: Currency;
  numberOfCashoutEntries: number;
}

export class ReportFormats {
  formats: DisbursementReportFormat[];
}

export enum DisbursementReportFormat {
  EXCEL_XLSX = 'EXCEL_XLSX',
  CSV_SEMICOLON = 'CSV_SEMICOLON',
  CSV_COMMA = 'CSV_COMMA',
}
