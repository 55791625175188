<div class="confirm-capture-wrapper">
  <div class="confirm-capture-container">
    <p class="heading">Confirm Refund</p>

    <div>
      <div class="row">
        <p>Total order amount</p>
        <p>{{ totalOrderAmount | amountPipe : currency }}</p>
      </div>

      <div class="row">
        <p>VAT</p>
        <p>{{ selectedFixedAmountRefundTaxAmount | amountPipe : currency }}</p>
      </div>

      <div class="row">
        <p>Amount to refund</p>
        <p>
          {{ fixedAmountRefundForm.value.refundAmount | amountPipe : currency }}
        </p>
      </div>

      <div style="margin-top: 24px">
        <form [formGroup]="confirmRefundForm">
          <zaver-dropdownv2
            style="margin-right: 16px"
            [size]="'small'"
            [title]="'Reason for the return (optional)'"
            (inputModelChange)="setReason($event)"
            [selectableItems]="refundReasons"
            [selectedItem]="selectedReason"
            [shorterDropdown]="true"
            [errorMessage]="'errorMessage'"
          ></zaver-dropdownv2>

          <zaver-zfb-textarea
            formControlName="note"
            [title]="'Note (optional. For internal use)'"
            [placeholder]="'Leave a description or a reason for the refund.'"
            [error]="false"
            [errorMessage]="'Ange beskrivning (max 50000 tecken)'"
          ></zaver-zfb-textarea>
        </form>
      </div>

      <div class="buttons-wrapper">
        <zaver-secondary-button-subtle
          [text]="'Go back'"
          [size]="'x-small'"
          (click)="setView(VIEW.REFUND_FIXED_AMOUNT)"
        ></zaver-secondary-button-subtle>

        <zaver-primary-button
          [loading]="inProgress"
          [text]="'Confirm'"
          [size]="'x-small'"
          (click)="
            onCreateRefund(
              selectedReason.value,
              confirmRefundForm.value.note,
              'FIXED_AMOUNT'
            )
          "
        ></zaver-primary-button>
      </div>

      <p style="font-size: 13px; color: #6a7381; margin-top: 24px">
        The refund needs to be approved by an authorized user before it can be
        processed by Zaver.
      </p>
    </div>
  </div>
</div>
