export interface EnvironmentSettings {
  production: boolean;
  useFixtures: boolean;
  logSettings: LogSettings;
  apiUrl: string;
  environment: EnvIdentifier;
  wsUri: string;
}

export enum LogLevel {
  ALL = -1,
  DEBUG = 0,
  LOG = 1,
  WARN = 2,
  ERROR = 3,
  NONE = 10
}

export interface LogSettings {
  minLevels: {
    'console': LogLevel,
    'telemetry': LogLevel,
    'stash': LogLevel
  };
  routerLog: boolean;
}

export enum EnvIdentifier {
  LOCAL = 'LOCAL',
  STAGING = 'STAGING',
  DEV = 'DEV',
  TEST = 'TEST',
  PROD = 'PROD'
}

