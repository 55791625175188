<div class="small-modal-wrapper">
  <ng-container *ngIf="!serverError && !missingEmailError">
    <div class="confirm-cashout-authentication-container">
      <h3>Bekräfta utbetalningen</h3>
      <p>Skriv in den 4-siffriga kod som skickats till {{ emailAdress }}</p>

      <zaver-token-input
        [showError]="showError"
        (valueChange)="inputValue = $event"
        [maxLength]="4"
      ></zaver-token-input>

      <p *ngIf="!!authorizatonMessage" class="unauthorized-error">
        {{ authorizatonMessage }}
      </p>

      <div *ngIf="resentAuthenticationCode; else showResendButton">
        <p class="resentAuthenticationCode">Skickat</p>
      </div>

      <div *ngIf="sentAuthenticationCodeBySms; else showSendSmsButton">
        <p class="resentAuthenticationCode">Skickat SMS</p>
      </div>

      <ng-template #showResendButton>
        <div
          class="resend-btn-container"
          *ngIf="!resendingAuthCodeLoading && !sendingAuthenticationCodeBySms"
        >
          <button (click)="resendAuthenticationCode()">
            Fick ingen kod? Skicka igen
          </button>
        </div>
      </ng-template>

      <ng-template #showSendSmsButton>
        <div
          class="resend-btn-container"
          *ngIf="!sendingAuthenticationCodeBySms && !resendingAuthCodeLoading"
        >
          <button (click)="sendCodeBySmsToRepresentatives()">
            Skicka kod som SMS istället
          </button>
        </div>
      </ng-template>

      <div
        class="loader-container"
        *ngIf="resendingAuthCodeLoading || sendingAuthenticationCodeBySms"
      >
        <zaver-loading-dots
          [color]="'#6A7381'"
          [diameter]="10"
        ></zaver-loading-dots>
      </div>
    </div>
    <app-form-error *ngIf="errorMessage" [errorText]="errorMessage">
    </app-form-error>

    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        [text]="'Tillbaka'"
        [disabled]="disbursementInProgress"
        (click)="returnToMainModal()"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        [text]="'Bekräfta'"
        [loading]="disbursementInProgress"
        [color]="'black'"
        (click)="executeDisbursement()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </ng-container>

  <ng-container *ngIf="missingEmailError">
    <div class="confirm-cashout-authentication-container">
      <p class="unauthorized-error">Email address not authorised for this</p>
    </div>
  </ng-container>

  <ng-container *ngIf="serverError">
    <app-server-error [errorMessage]="serverErrorMessage"></app-server-error>
    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        [text]="'Stäng'"
        [disabled]="disbursementInProgress"
        (click)="closeModal()"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        [text]="'Försök igen'"
        [loading]="disbursementInProgress"
        [color]="'black'"
        (click)="executeDisbursement()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </ng-container>
</div>
