export const LANG_SV = {
  weekDays: [
    {
      letter: 'M',
      short: 'Mån',
      full: 'Måndag',
    },
    {
      letter: 'T',
      short: 'Tis',
      full: 'Tisdag',
    },
    {
      letter: 'O',
      short: 'Ons',
      full: 'Onsdag',
    },
    {
      letter: 'T',
      short: 'Tor',
      full: 'Torsdag',
    },
    {
      letter: 'F',
      short: 'Fre',
      full: 'Fredag',
    },
    {
      letter: 'L',
      short: 'Lör',
      full: 'Lördag',
    },
    {
      letter: 'S',
      short: 'Sön',
      full: 'Söndag',
    },
  ],
  months: [
    {
      index: 0,
      short: 'Jan',
      full: 'Januari',
    },
    {
      index: 1,
      short: 'Feb',
      full: 'Februari',
    },
    {
      index: 2,

      short: 'Mar',
      full: 'Mars',
    },
    {
      index: 3,

      short: 'Apr',
      full: 'April',
    },
    {
      index: 4,

      short: 'Maj',
      full: 'Maj',
    },
    {
      index: 5,

      short: 'Jun',
      full: 'Juni',
    },
    {
      index: 6,

      short: 'Jul',
      full: 'Juli',
    },
    {
      index: 7,

      short: 'Aug',
      full: 'Augusti',
    },
    {
      index: 8,

      short: 'Sep',
      full: 'September',
    },
    {
      index: 9,

      short: 'Okt',
      full: 'Oktober',
    },
    {
      index: 10,

      short: 'Nov',
      full: 'November',
    },
    {
      index: 11,
      short: 'Dec',
      full: 'December',
    },
  ],
};
