<div class="small-modal-wrapper">
  <ng-container *ngIf="!serverError">
    <span
      >Ett meddelande kommer skickas till vår support som kontaktar dig inom
      kort. 🙂</span
    >
    <app-form-error *ngIf="errorMessage" [errorText]="errorMessage">
    </app-form-error>
    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        [text]="'Nej, avbryt'"
        [disabled]="submitting"
        (click)="closeModal()"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        [text]="'Ja, skicka'"
        [loading]="submitting"
        [color]="'black'"
        (click)="submit()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </ng-container>
  <ng-container *ngIf="serverError">
    <app-server-error [errorMessage]="serverErrorMessage"></app-server-error>
    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        [text]="'Stäng'"
        [disabled]="submitting"
        (click)="closeModal()"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        [text]="'Försök igen'"
        [loading]="submitting"
        [color]="'black'"
        (click)="submit()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </ng-container>
</div>
