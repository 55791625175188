import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-accordion',
  templateUrl: './card-accordion.component.html',
  styleUrls: ['./card-accordion.component.css']
})
export class CardAccordionComponent implements OnInit {

  @Input() heading: string;
  open = true;

  constructor() { }

  ngOnInit(): void {
  }

  toggleOpen() {
    this.open = !this.open;
  }

  forceOpen() {
    this.open = true;
  }

}
