<div *ngIf="values && columns" id="table-container">
  <div class="table">
    <!-- Table header -->
    <div class="header">
      <div
        class="col"
        [attr.style]="getColumnHeadingStyling(column)"
        *ngFor="let column of columns"
      >
        <app-table-filter-dropdown
          *ngIf="!!column.filterable"
          [filterOptions]="column.filterOptions"
          (filterClicked)="
            filterChange.emit({
              type: column.filterOptions.value,
              filterOption: $event
            })
          "
        ></app-table-filter-dropdown>
        <span *ngIf="!column.filterable && !column.sortable">{{
          column.text
        }}</span>
      </div>
      <div *ngIf="showQuickActions" class="quick-actions-header"></div>
    </div>

    <!-- Table body-->
    <div
      class="row"
      [class.hover-color]="!!values.rowClickFunction"
      *ngFor="let row of values.rows"
    >
      <div
        class="col"
        [ngClass]="column.cssClasses"
        [attr.style]="getColumnStyling(column, row.data)"
        *ngFor="let column of columns"
        (click)="rowClicked(row.data)"
      >
        <app-widget-event-type-pill
          *ngIf="!!column.type && column.type === 'status'"
          [text]="column.columnDataTransformer(row.data)"
          [backgroundColor]="backgroundColor(row.data)"
          [textColor]="values.getStatusBarColor(row.data)"
        ></app-widget-event-type-pill>

        <!-- <zaver-status-pill
          *ngIf="!!column.type && column.type === 'status'"
          [text]="column.columnDataTransformer(row.data)"
          [backgroundHexColor]="values.getStatusBarColor(row.data)"
        ></zaver-status-pill> -->

        <ng-container *ngIf="!!column.type && column.type === 'handleAction'">
          <div
            class="handle-button handle"
            (click)="actionClicked(row.data, column)"
          >
            Åtgärda
          </div>
        </ng-container>

        <ng-container *ngIf="!!column.type && column.type === 'sendSupport'">
          <div
            *ngIf="!!column.value && column.value(row.data) === 'UNHANDLED'"
            class="support-button not-sent"
            (click)="actionClicked(row.data, column)"
          >
            <span>Meddela support</span>
          </div>
          <div
            *ngIf="
              !!column.value && column.value(row.data) === 'CS_MESSAGE_SENT'
            "
            class="support-button sent"
          >
            <img src="assets/icons/Icon_Checkmark_White.svg" alt="" />
            <span>Skickad</span>
          </div>
        </ng-container>

        <ng-container *ngIf="!!column.type && column.type === 'user-name'">
          <div class="user-store-name-icon">
            <app-avatar [color]="column.columnColor(row.data)"></app-avatar>
            <span class="user-name medium2 avoid-overflow">{{
              column.columnDataTransformer(row.data)
            }}</span>
          </div>
        </ng-container>

        <ng-container *ngIf="!!column.type && column.type === 'store-name'">
          <div class="user-store-name-icon">
            <img
              src="assets/icons/Icon-E-commerce.svg"
              *ngIf="!!column.value && column.value(row.data) === 'E_COMMERCE'"
            />
            <img
              src="assets/icons/Icon-Store.svg"
              *ngIf="!!column.value && column.value(row.data) === 'STORE'"
            />
            <span class="store-name medium2 avoid-overflow">{{
              column.columnDataTransformer(row.data)
            }}</span>
          </div>
        </ng-container>

        <ng-container *ngIf="!column.type">
          <span [innerHTML]="column.columnDataTransformer(row.data)"></span>
        </ng-container>
      </div>

      <div *ngIf="showQuickActions" class="quick-actions-container">
        <app-quick-actions
          [data]="row.data"
          [actions]="row.quickActions"
          [verticalButton]="true"
        >
        </app-quick-actions>
      </div>
    </div>
  </div>

  <div class="div-table-mobile" [class.no-header]="values.hideMobileHeader">
    <div class="div-table-mobile-header" *ngIf="!values.hideMobileHeader">
      <app-table-filter-dropdown-mobile
        *ngIf="columns[columns.length - 1].filterable"
        [filterOptions]="columns[columns.length - 1].filterOptions"
        (filterClicked)="
          filterChange.emit({
            type: columns[columns.length - 1].filterOptions.value,
            filterOption: $event
          })
        "
      >
      </app-table-filter-dropdown-mobile>
    </div>

    <div
      class="div-table-row-mobile"
      *ngFor="let row of values.rows"
      [class.user-row]="isUserRow(row.data)"
    >
      <div
        class="mobile-status-bar"
        [style.background-color]="values.getStatusBarColor(row.data)"
        (click)="rowClicked(row.data)"
      ></div>

      <div
        class="div-table-row-mobile-content"
        [class.quick-actions]="showQuickActions"
        (click)="rowClicked(row.data)"
      >
        <div
          class="div-table-col-mobile-text"
          [attr.style]="getMobileTextStyling(row.data)"
          [class.store-text]="isStoreRow(row.data)"
          *ngIf="isUserOrStoreRow(row.data)"
        >
          <div class="primary-text avoid-overflow">
            <span [innerHTML]="values.getPrimaryText(row.data)"></span>
          </div>
          <div class="secondary-text avoid-overflow">
            <div
              class="mobile-role-dot"
              [style.background-color]="columns[0].columnColor(row.data)"
              *ngIf="isUserRow(row.data)"
            ></div>
            <span [innerHTML]="values.getSecondaryText(row.data)"></span>
          </div>
        </div>

        <div
          class="div-table-col-mobile-text"
          [attr.style]="getMobileTextStyling(row.data)"
          *ngIf="!isUserOrStoreRow(row.data)"
        >
          <div class="secondary-text avoid-overflow">
            <span [innerHTML]="values.getSecondaryText(row.data)"></span>
          </div>
          <div class="primary-text avoid-overflow">
            <span [innerHTML]="values.getPrimaryText(row.data)"></span>
          </div>
        </div>

        <div class="div-table-col-mobile-status">
          <app-widget-event-type-pill
            *ngIf="columns[columns.length - 1].type === 'status'"
            [text]="columns[columns.length - 1].columnDataTransformer(row.data)"
            [backgroundColor]="backgroundColor(row.data)"
            [textColor]="values.getStatusBarColor(row.data)"
          ></app-widget-event-type-pill>

          <div
            *ngIf="columns[0].type === 'store-name'"
            class="user-store-name-icon"
          >
            <img
              src="assets/icons/Icon-E-commerce.svg"
              *ngIf="
                !!columns[0].value &&
                columns[0].value(row.data) === 'E_COMMERCE'
              "
            />
            <img
              src="assets/icons/Icon-Store.svg"
              *ngIf="
                !!columns[0].value && columns[0].value(row.data) === 'STORE'
              "
            />
          </div>

          <ng-container
            *ngIf="
              !!columns[columns.length - 1].type &&
              columns[columns.length - 1].type === 'sendSupport'
            "
          >
            <div
              *ngIf="
                !!columns[columns.length - 1].value &&
                columns[columns.length - 1].value(row.data) === 'UNHANDLED'
              "
              class="support-button not-sent"
              (click)="actionClicked(row.data, columns[columns.length - 1])"
            >
              <span>Meddela support</span>
            </div>
            <div
              *ngIf="
                !!columns[columns.length - 1].value &&
                columns[columns.length - 1].value(row.data) ===
                  'CS_MESSAGE_SENT'
              "
              class="support-button sent"
            >
              <img
                src="assets/icons/Icon_Checkmark_White.svg"
                alt=""
                style="margin-right: 8px"
              />
              <span>Skickad</span>
            </div>
          </ng-container>

          <ng-container
            *ngIf="
              !!columns[columns.length - 1].type &&
              columns[columns.length - 1].type === 'handleAction'
            "
          >
            <div
              class="handle-button handle cerise-text cerise-border"
              (click)="actionClicked(row.data, columns[columns.length - 1])"
            >
              Åtgärda
            </div>
          </ng-container>
        </div>
      </div>
      <div *ngIf="showQuickActions" class="quick-actions-container-mobile">
        <app-quick-actions
          [data]="row.data"
          [actions]="row.quickActions"
          [verticalButton]="true"
        >
        </app-quick-actions>
      </div>
    </div>
  </div>
</div>
