import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { VerificationTokenService } from '@core/service/verification-token.service';
import { EMAIL_PATTERN } from '@core/patterns';
import { ColorService } from '@core/service/color.service';

@Component({
  selector: 'app-signup-start',
  templateUrl: './start-signup.component.html',
  styleUrls: [
    '../signup-shared.css',
    '../../login/login-pages.scss',
    './start-signup.component.css',
  ],
})
export class StartSignupComponent implements OnInit {
  public shouldRememberMe = true;
  public form: FormGroup;

  EMAIL_KEY = 'email';

  public processingSignup: boolean;
  formError = '';
  showError = false;

  ngOnInit(): void {
    this.form = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(EMAIL_PATTERN),
      ]),
      rememberMe: new FormControl(this.shouldRememberMe),
    });
  }

  constructor(
    private router: Router,
    private tokenService: VerificationTokenService,
    public colorService: ColorService
  ) {}

  continueSignup() {
    if (this.form.disabled || this.processingSignup) {
      return;
    }
    if (this.form.valid) {
      this.processingSignup = true;
      const email = this.form.value.email.trim();
      if (this.form.value.rememberMe && email) {
        localStorage.setItem(this.EMAIL_KEY, email);
      }
      this.tokenService.email = email;
      this.tokenService
        .identifySignatory(email)
        .then(() =>
          this.router.navigate(['/identify-signatory-token'], {
            skipLocationChange: false,
            queryParams: { email: encodeURIComponent(email) },
          })
        )
        .catch((error) => {
          this.formError = 'Någonting gick fel.';
          this.enableForm();
        });
    } else {
      this.showError = true;
      this.enableForm();
    }
  }

  private enableForm() {
    this.form.enable();
    this.processingSignup = false;
  }

  rememberMeClicked(): void {
    if (!this.form.value.rememberMe) {
      // if the user un-checks the remember-me-checkbox we will set the value to '' (in contrast to just undefined);
      // this indicates that the user does not want to be remembered
      localStorage.setItem(this.EMAIL_KEY, '');
    }
  }

  back() {
    history.back();
  }
}
