import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CurrencyCode } from '@core/dto/CurrencyCode';
import { DropdownListItemv2 } from '../../../../../web-component-library/projects/component-library/src/lib/dropdownv2/dropdownv2.component';
import {
  LineItemVM,
  QuantityChangeEvent,
  View,
} from '../payment-management/payment-management.interfaces';

@Component({
  selector: 'app-line-item-table',
  templateUrl: './line-item-table.component.html',
  styleUrls: ['./line-item-table.component.scss'],
})
export class LineItemTableComponent implements OnInit {
  constructor() {}
  @Output() setTaxRatePrecent = new EventEmitter<QuantityChangeEvent>();
  @Output() setAllChecked = new EventEmitter<boolean>();
  @Output() changeQuantity = new EventEmitter<QuantityChangeEvent>();
  @Output() setEditMode = new EventEmitter<LineItemVM>();
  @Output() removeLineItem = new EventEmitter<LineItemVM>();

  @Input() lineItemsVM: LineItemVM[];
  @Input() allChecked: boolean;
  @Input() currentView: View;
  @Input() currency: CurrencyCode;
  @Input() taxRateOptions: DropdownListItemv2[];

  public VIEW = View;
  currencyCode = CurrencyCode;

  ngOnInit(): void {}

  isMinQuantity(val: number, min: number): boolean {
    return Number(min) >= Number(val);
  }

  isMaxQuantity(val: number, max: number): boolean {
    return Number(max) <= Number(val);
  }

  onChangeQuantity(value: string, lineItemVM: LineItemVM): void {
    this.changeQuantity.emit({ value, lineItemVM });
  }

  onSetAllChecked(checked: boolean): void {
    this.allChecked = checked;
    this.setAllChecked.emit(checked);
  }

  onSelectTaxRate(value: string, lineItemVM: LineItemVM): void {
    this.setTaxRatePrecent.emit({ value, lineItemVM });
  }

  isEditable(editMode: boolean): boolean {
    return editMode && this.currentView !== View.PAYMENT_UPDATE;
  }

  onSetEdit(lineItemVM: LineItemVM): void {
    this.setEditMode.emit(lineItemVM);
  }

  onRemoveLineItem(lineItemVM: LineItemVM): void {
    this.removeLineItem.emit(lineItemVM);
  }

  findOption(option: DropdownListItemv2[], value: any): DropdownListItemv2 {
    return option.find((item) => item.value === value);
  }
}
