<div class="small-modal-wrapper">
  <ng-container *ngIf="!serverError">
    <span
      >Är du säker på att du vill skicka en påminnelse till
      {{ paymentRequest.b2bPaymentData.buyerCompanyName }}? 🙂</span
    >
    <app-form-error *ngIf="errorMessage" [errorText]="errorMessage">
    </app-form-error>

    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        [text]="'Tillbaka'"
        [disabled]="confirmationInProgress"
        (click)="returnToMainModal()"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        [text]="'Ja, skicka'"
        [loading]="confirmationInProgress"
        [color]="'black'"
        (click)="sendReminder()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </ng-container>

  <ng-container *ngIf="serverError">
    <app-server-error [errorMessage]="serverErrorMessage"></app-server-error>
    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        [text]="'Stäng'"
        [disabled]="confirmationInProgress"
        (click)="closeModal()"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        [text]="'Försök igen'"
        [loading]="confirmationInProgress"
        [color]="'black'"
        (click)="sendReminder()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </ng-container>
</div>
