import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@core/base.component';
import { ReportDisbursement } from '@core/dto/report/ReportDisbursement';
import { AuthenticationService } from '@core/service/authentication.service';
import { LocaleService } from '@core/service/locale.service';
import {
  ReportPaymentRequest,
  ReportService,
} from '@core/service/report.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-view-report-disbursement',
  templateUrl: './view-report-disbursement.component.html',
  styleUrls: ['./view-report-disbursement.component.css'],
})
export class ViewReportDisbursementComponent
  extends BaseComponent
  implements OnInit
{
  @Input() reportDisbursement: ReportDisbursement;
  @Output() onViewPaymentRequest = new EventEmitter<ReportPaymentRequest>();

  public searchQuery = '';
  private searchQueryChanged: Subject<string> = new Subject<string>();

  public reportPaymentRequests: ReportPaymentRequest[];
  public selectedReportPaymentRequest: ReportPaymentRequest = null;

  constructor(
    protected auth: AuthenticationService,
    private reportService: ReportService,
    public localeService: LocaleService
  ) {
    super(auth);

    this.searchQueryChanged
      .pipe(
        map((q) => q.trim()),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((query) => {
        this.searchReportDisbursements(query);
      });
  }

  ngOnInit(): void {
    this.refreshReportPaymentRequests();
  }

  refreshReportPaymentRequests() {
    if (this.reportDisbursement) {
      this.reportService
        .getReportPaymentRequests(this.searchQuery, this.reportDisbursement.id)
        .subscribe((res: ReportPaymentRequest[]) => {
          this.reportPaymentRequests = res;
        }),
        (err) => {
          super.handleError(err);
        };
    }
  }

  searchUpdatedQuery(query: string) {
    this.searchQueryChanged.next(query);
  }

  searchReportDisbursements(query: string) {
    this.searchQuery = query.trim();

    this.refreshReportPaymentRequests();
  }

  openReportPaymentRequest(rp: ReportPaymentRequest) {
    if (rp !== this.selectedReportPaymentRequest) {
      this.selectedReportPaymentRequest = rp;
    } else {
      this.selectedReportPaymentRequest = null;
    }
  }

  viewPaymentRequest(rp: ReportPaymentRequest) {
    this.onViewPaymentRequest.emit(rp);
  }
}
