import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

export interface DropdownListItemv2 {
  label: string;
  value: any;
}

@Component({
  selector: 'zaver-vat-dropdown',
  templateUrl: './vat-dropdown.component.html',
  styleUrls: ['./vat-dropdown.component.scss'],
})
export class VatDropdownComponent implements OnInit {
  @Output() inputModelChange = new EventEmitter<any>();
  @Input() size: 'x-small' | 'small' | 'medium' | 'large' = 'medium';
  @Input() title: string = '';
  @Input() shorterDropdown: boolean = false;
  @Input() selectableItems: DropdownListItemv2[] = null;
  @Input() selectedItem: any = null;
  @Input() error: boolean = false;
  @Input() errorMessage: string = '';
  @Input() disabled: boolean = false;

  showDropDown: boolean = false;

  constructor() {}

  ngOnInit(): void {
    if (!this.selectedItem) {
      this.selectedItem = this.selectableItems[0];
    }
  }

  selectItem(item) {
    this.selectedItem = item;
    this.inputModelChange.emit(item.value);
  }

  toggleDropDown() {
    if (this.disabled) {
      return;
    }
    this.showDropDown = !this.showDropDown;
  }
}
