import { Component, OnInit, Input, Self, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'zaver-animated-form-input',
  templateUrl: './animated-form-input.component.html',
  styleUrls: ['./animated-form-input.component.scss'],
})
export class AnimatedFormInputComponent
  implements OnInit, ControlValueAccessor
{
  @Input() value: string;
  @Input() disabled: boolean;
  @Input() placeholder: string;
  @Input() ariaLabel: string;
  @Input() inputMode: string = 'text';
  @Input() title: string;
  @Input() error: boolean;
  @Input() errorMessage: string;
  @Input() backgroundColor: string = '#FFFFFF';
  @Input() maxLength: number;
  @Input() autoComplete: string;
  @Input() inputType: string = 'text';
  @Input() showContent: boolean;
  @Input() tooltipText: string;
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() infoIcon: string;
  @Input() errorIcon: string;
  @Input() readonly: boolean;
  showPassword = false;

  constructor(
    @Self()
    @Optional()
    private ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {}

  /**
   * Write form value to the DOM element (model => view)
   */
  writeValue(value: any): void {
    this.value = value;
  }

  /**
   * Write form disabled state to the DOM element (model => view)
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Update form when DOM element value changes (view => model)
   */
  registerOnChange(fn: any): void {
    // Store the provided function as an internal method.
    this.onChange = fn;
  }

  /**
   * Update form when DOM element is blurred (view => model)
   */
  registerOnTouched(fn: any): void {
    // Store the provided function as an internal method.
    this.onTouched = fn;
  }

  onChange($event: any) {}
  onTouched() {}

  toggleVisibility(): void {
    this.showPassword = !this.showPassword;
  }
}
