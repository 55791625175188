import { ThrowStmt } from '@angular/compiler';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-signup-company-owner',
  templateUrl: './company-owner.component.html',
  styleUrls: [
    './company-owner.component.css']
})
export class CompanyOwnerComponent implements OnInit, OnDestroy {

  @Input() nonOwner: boolean;
  @Input() showError: boolean;
  @Input() displayingInCompanyAdminPage: boolean;
  public formGroup: FormGroup;

  correctOrganizationNumber = false;

  selectedOwnerType: 'legal'|'natural' = null;
  ownerTypeOptions = [
    {
      label: 'Juridisk person',
      value: 'legal'
    },
    {
      label: 'Fysisk person',
      value: 'natural'
    },
  ];

  selectedOwnerNumberOption: number = null;
  ownerNumberOptions = [
    {
      label: '0',
      value: 0
    },
    {
      label: '1',
      value: 1
    },
    {
      label: '2',
      value: 2
    },
    {
      label: '3',
      value: 3
    },
  ];

  constructor(private controlContainer: ControlContainer) {
  }

  ngOnInit() {
    // this.nrOwners = this.owner.owners.length;
    // this.ownerTypeChange();
    this.formGroup = <FormGroup>this.controlContainer.control;
    this.formGroup.addControl('ownerType', new FormControl(null, [Validators.required]));
    this.selectedOwnerType = this.formGroup.get('ownerType').value;
    if (this.selectedOwnerType === 'legal' && !this.nonOwner) {
      this.selectedOwnerNumberOption = this.formGroup.get('numOwners').value;
    }
    if (this.formGroup.contains('name') && this.formGroup.controls['name'].value !== '') {
      this.correctOrganizationNumber = true;
    }
  }

  ngOnDestroy() {
    this.formGroup.removeControl('ownerType');
    if (this.selectedOwnerType === 'legal' && !this.nonOwner) {
      this.formGroup.removeControl('owners');
      this.formGroup.removeControl('numOwners');
    }
  }

  setupForm() {
    if (this.selectedOwnerType === 'legal' && !this.nonOwner) {
      this.formGroup.addControl('owners', new FormArray([]));
      this.formGroup.addControl('numOwners', new FormControl(this.selectedOwnerNumberOption, [Validators.required]));
    } else if (this.selectedOwnerType === 'natural' && !this.nonOwner) {
      this.formGroup.removeControl('owners');
      this.formGroup.removeControl('numOwners');
    }
  }

  nrOwnerChange(event) {
    this.selectedOwnerNumberOption = event;
    this.formGroup.controls.numOwners.setValue(event);
    if (this.selectedOwnerNumberOption < this.owners.length) {
      let controlsToRemove = this.owners.length - this.selectedOwnerNumberOption;
      while (controlsToRemove > 0) {
        this.owners.removeAt(this.owners.length - 1);
        controlsToRemove -= 1;
      }
    } else {
      for (let i = this.owners.length; i < this.selectedOwnerNumberOption; i++) {
        this.owners.push(new FormGroup({}));
      }
    }
  }

  get owners() {
    return this.formGroup.get('owners') as FormArray;
  }

  getOwner(index: number): FormGroup {
    return this.owners.at(index) as FormGroup;
  }

  removeOwner(index: number) {
    this.owners.removeAt(index);
    this.selectedOwnerNumberOption -= 1;
    this.formGroup.controls.numOwners.setValue(this.selectedOwnerNumberOption);
  }

  ownerTypeChange(event) {
    this.formGroup.controls.ownerType.setValue(event);
    this.selectedOwnerType = event;
    this.setupForm();
  }

  getOwnerName(index: number): string {
    const formGroup = this.owners.at(index) as FormGroup;
    if (formGroup.contains('name')) {
      return formGroup.controls.name.value;
    } else {
      return '';
    }
  }
}
