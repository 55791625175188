import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PaymentRequestResponse } from '@core/dto/PaymentRequestResponse';
import { EMAIL_PATTERN } from '@core/patterns';
import { AppStateService } from '@core/service/app-state.service';
import { VirtualInvoiceReminderRequest } from '@core/service/payment-request.service';
import { PhoneNumberService } from '@core/service/phone-number.service';
import {
  formatPhoneNumber,
  phoneNumberValidator,
} from '../../../../../../web-component-library/projects/component-library/src/common/phone-number-validator.directive';

@Component({
  selector: 'app-create-payment-request-reminder',
  templateUrl: './create-payment-request-reminder.component.html',
  styleUrls: ['./create-payment-request-reminder.component.scss'],
})
export class CreatePaymentRequestReminderComponent implements OnInit {
  @Input() paymentRequest: PaymentRequestResponse;
  @Input() reminderRequest: VirtualInvoiceReminderRequest;

  reminderForm: FormGroup;
  showError = false;
  formError = '';
  initialDate: Date;

  processing = false;

  @Output() returnToView = new EventEmitter();
  @Output() promptReminderConfirmation =
    new EventEmitter<VirtualInvoiceReminderRequest>();

  constructor(
    private phoneNumberService: PhoneNumberService,
    private appStateService: AppStateService
  ) {}

  ngOnInit(): void {
    this.setup();
  }

  setup() {
    if (this.reminderRequest) {
      this.reminderForm = new FormGroup({
        phone: new FormControl(this.reminderRequest.phoneNumber, [
          Validators.required,
          phoneNumberValidator(this.appStateService.getSupportedCountryCodes()),
        ]),
        email: new FormControl(this.reminderRequest.email, [
          Validators.required,
          Validators.pattern(EMAIL_PATTERN),
        ]),
        sendAsEmail: new FormControl(this.reminderRequest.sendByEmail),
        sendAsSMS: new FormControl(this.reminderRequest.sendByText),
      });
      this.initialDate = new Date(this.reminderRequest.dueDate);
    } else {
      this.reminderForm = new FormGroup({
        phone: new FormControl(
          this.phoneNumberService.toInternationalFormat(
            this.paymentRequest.phone
          ),
          [
            Validators.required,
            phoneNumberValidator(
              this.appStateService.getSupportedCountryCodes()
            ),
          ]
        ),
        email: new FormControl(this.paymentRequest.b2bPaymentData.buyerEmail, [
          Validators.required,
          Validators.pattern(EMAIL_PATTERN),
        ]),
        sendAsEmail: new FormControl(true),
        sendAsSMS: new FormControl(true),
      });
      this.initialDate = new Date(this.paymentRequest.b2bPaymentData.dueDate);
    }
  }

  submit() {
    this.formError = '';
    if (this.reminderForm.valid) {
      if (
        !(
          this.reminderForm.controls['sendAsEmail'].value ||
          this.reminderForm.controls['sendAsSMS'].value
        )
      ) {
        this.formError =
          'Välj minst ett alternativ för hur du vill att påminnelsen&nbsp;skickas';
        return;
      }
      this.processing = true;
      let email: string = this.reminderForm.controls['email'].value;
      email = email.trim();
      const reminderRequest: VirtualInvoiceReminderRequest = {
        paymentRequestId: this.paymentRequest.id,
        phoneNumber: formatPhoneNumber(
          this.reminderForm.controls['phone'].value
        ),
        email: email,
        sendByEmail: this.reminderForm.controls['sendAsEmail'].value,
        sendByText: this.reminderForm.controls['sendAsSMS'].value,
        message: '',
        dueDate: this.reminderForm.controls['dueDate'].value,
      };
      this.promptReminderConfirmation.emit(reminderRequest);
    } else {
      this.showError = true;
    }
  }
}
