import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'zaver-secondary-button-outlined',
  templateUrl: './secondary-button-outlined.component.html',
  styleUrls: ['./secondary-button-outlined.component.scss'],
})
export class SecondaryButtonOutlinedComponent implements OnInit {
  @Input() text: string;
  @Input() iconUrl: string;
  @Input() disabled: boolean;
  @Input() loading: boolean;
  @Input() size: 'x-small' | 'small' | 'medium' | 'large' = 'medium';
  @Input() color: 'black' | 'cerise' | 'de-cerise' = 'black';
  @Input() rounded = false;

  constructor() {}

  ngOnInit(): void {
    this.getLoadingDotsColor();
  }

  stopIfDisabled(event: MouseEvent) {
    if (this.disabled || this.loading) {
      event.stopPropagation();
    }
  }

  getLoadingDotsColor() {
    switch (this.color) {
      case 'black':
        return '#0a1018';
      case 'cerise':
        return '#DB305E';
      case 'de-cerise':
        return '#db305e';
      default:
        return '#0a1018';
    }
  }
}
