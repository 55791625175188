<div class="container" [class.info]="type === 'INFO'" [class.warning]="type === 'WARNING'">
    
    <div *ngIf="shouldLogoAndExitButtonBeIncluded">
        Logo
    </div>

    <div class="text" [class.italic]="type==='INFO'" *ngIf="!!text">
        {{text}}
    </div>

    <div class="text" *ngIf="!!messageList">
        <ng-container *ngFor="let message of messageList">
        {{message}} <br/>
        </ng-container>
    </div>

    <div *ngIf="shouldLogoAndExitButtonBeIncluded">
        Exit button
    </div>

</div>
