import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup-step2',
  templateUrl: './step-2.component.html',
  styleUrls: ['../../page-shared.scss', '../signup-shared.css'],
})
export class Step2Component implements OnInit {
  signupQuestionsDoneFetching = false;

  constructor(private router: Router) {}

  ngOnInit() {}

  saved(event: any) {
    this.router.navigate(['/signup/step3'], { skipLocationChange: false });
  }

  back() {
    history.back();
  }
}
