import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'CurrencyCodeFormatter',
})
export class CurrencyCodeFormatter implements PipeTransform {
  transform(value: string): string {
    if (value === 'EUR') {
      return '€';
    } else {
      return value; // Return unchanged if not 'EUR'
    }
  }
}
