import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Market } from '@core/dto/Market';
import { LocaleService } from '@core/service/locale.service';

@Component({
  selector: 'app-oops',
  templateUrl: './email-not-found.component.html',
  styleUrls: ['../login-pages.scss', './email-not-found.component.css'],
})
export class EmailNotFoundComponent implements OnInit {
  email: string;
  isDeAtMarket: boolean = false;

  @HostBinding('class')
  cssClasses = 'hide-footer';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.email = this.route.snapshot.queryParamMap.get('email');
    this.isDeAtMarket =
      this.localeService.getCurrentLocaleMarket() === Market.DE ||
      this.localeService.getCurrentLocaleMarket() === Market.AT;
  }

  goBack() {
    this.router.navigateByUrl('/');
  }
}
