<div class="create-container spacing-container" *ngIf="showCompanyInfoForm">
  <form [formGroup]="companyInfoForm" novalidate>
    <div
      class="input-fields"
      [class.error]="
        companyInfoForm.controls.companyNumber.invalid && showError
      "
    >
      <zaver-form-input
        [showContent]="true"
        [title]="'Organisationsnummer'"
        [placeholder]="'Organisationsnummer'"
        [error]="companyInfoForm.controls.companyNumber.invalid && showError"
        [errorMessage]="'Vänligen ange ett korrekt organisationsnummer'"
        formControlName="companyNumber"
        [inputMode]="'tel'"
        [size]="'large'"
      >
        <img src="assets/icons/Icon-Office-Business-Card.svg" />
      </zaver-form-input>
    </div>
    <div
      *ngIf="companyInfoResponse && companyInfoResponse.companyName"
      id="company-name"
    >
      {{ companyInfoResponse.companyName }}
    </div>
    <div
      class="input-fields"
      [class.error]="companyInfoForm.controls.phone.invalid && showError"
    >
      <zaver-form-input
        [showContent]="true"
        [title]="'Mobilnummer'"
        [placeholder]="'Ange mobilnummer'"
        [error]="companyInfoForm.controls.phone.invalid && showError"
        [errorMessage]="'Vänligen kontrollera mobilnumret'"
        formControlName="phone"
        [inputMode]="'tel'"
        [inputType]="'tel'"
        [size]="'large'"
      >
        <img src="assets/icons/Icon-Phone.svg" />
      </zaver-form-input>
    </div>
    <div
      class="input-fields"
      [class.error]="companyInfoForm.controls.email.invalid && showError"
    >
      <zaver-form-input
        [title]="'E-postadress'"
        [showContent]="true"
        [placeholder]="'Ange e-postadress'"
        [error]="companyInfoForm.controls.email.invalid && showError"
        [errorMessage]="'Vänligen ange en giltig e-postadress'"
        formControlName="email"
        [maxLength]="255"
        [inputMode]="'email'"
        [inputType]="'email'"
        [size]="'large'"
      >
        <img src="assets/icons/Icon-E-mail.svg" />
      </zaver-form-input>
    </div>
    <div class="input-fields" *ngIf="formError">
      <app-form-error [errorText]="formError"> </app-form-error>
    </div>
  </form>
  <div *ngIf="!processing" class="continue-back-buttons-container">
    <zaver-primary-button
      [text]="'Fortsätt'"
      (click)="continueToInvoiceInfoForm()"
      style="margin-bottom: 16px"
    ></zaver-primary-button>
    <zaver-secondary-button-subtle
      [text]="'Tillbaka'"
      (click)="returnToSelection.emit()"
    ></zaver-secondary-button-subtle>
  </div>
  <app-page-loader *ngIf="processing"></app-page-loader>
</div>
<div class="create-container spacing-container" *ngIf="showInvoiceInfoForm">
  <form [formGroup]="invoiceInfoForm" novalidate>
    <div
      class="input-fields"
      [class.error]="invoiceInfoForm.invalid && showError"
    >
      <app-date-picker
        [title]="'Förfallodatum'"
        [placeholder]="'åååå-mm-dd'"
        [minDateInput]="minDueDate"
        [maxDateInput]="maxDueDate"
        [formGroup]="invoiceInfoForm"
        [showError]="showError"
        [formName]="'dueDate'"
        [initialDate]="initialDate"
      >
      </app-date-picker>
    </div>
    <div
      class="input-fields"
      [class.error]="invoiceInfoForm.controls.price.invalid && showError"
    >
      <zaver-form-input
        [showContent]="true"
        [title]="'Belopp'"
        [placeholder]="'Belopp'"
        [error]="invoiceInfoForm.controls.price.invalid && showError"
        [errorMessage]="
          'Ange ett belopp mellan 1,00 - 999 999,00 kr (upp till två decimaler)'
        "
        formControlName="price"
        [inputMode]="'decimal'"
        [size]="'large'"
      >
        <span style="text-align: center; font-weight: 400; font-size: 14px">
          SEK
        </span>
      </zaver-form-input>
    </div>
    <div
      class="input-fields"
      [class.error]="invoiceInfoForm.controls.title.invalid && showError"
    >
      <zaver-form-input
        [title]="prFormTitleAlias"
        [placeholder]="prFormTitleAlias"
        formControlName="title"
        [error]="invoiceInfoForm.controls.title.invalid && showError"
        [errorMessage]="'Fältet får inte lämnas tomt, ange max 255 tecken'"
        [maxLength]="255"
        [lengthOfValue]="invoiceInfoForm.controls.title.value.length"
        [showCounter]="true"
        [size]="'large'"
      >
      </zaver-form-input>
    </div>
    <div
      class="input-fields"
      [class.error]="invoiceInfoForm.controls.description.invalid && showError"
    >
      <zaver-zfb-textarea
        [title]="'Beskrivning'"
        [placeholder]="'Beskrivning'"
        formControlName="description"
        [error]="invoiceInfoForm.controls.description.invalid && showError"
        [errorMessage]="'Ange beskrivning (max 50000 tecken)'"
      >
      </zaver-zfb-textarea>
    </div>
    <div class="input-fields" *ngIf="formError">
      <app-form-error [errorText]="formError"> </app-form-error>
    </div>
  </form>
  <div *ngIf="!processing" class="continue-back-buttons-container">
    <zaver-primary-button
      style="margin-bottom: 16px"
      [text]="'Skicka förfrågan'"
      [loading]="processing"
      (click)="submit()"
    ></zaver-primary-button>
    <zaver-secondary-button-subtle
      [text]="'Tillbaka'"
      [disabled]="processing"
      (click)="returnToCompanyInfoForm()"
    ></zaver-secondary-button-subtle>
  </div>
</div>
