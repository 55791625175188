import {Pipe} from '@angular/core';

@Pipe({
  name: 'nameofperson'
})
export class NameFormatterPipe {
  transform(value: string): string {
    if (value && !value.match(/[À-ÿ]/g)) {
      value = value.toLocaleLowerCase();
      value = value.replace(/\b\w/g, l => l.toUpperCase());
    }
    return value;
  }
}

