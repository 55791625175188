import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SignupQuestions} from '@core/dto/signup/SignupQuestions';
import {RestBase} from '@core/rest-base';
import {HrefService} from '@core/service/href.service';

@Injectable()
export class QuestionsService extends RestBase {

  constructor(httpClient: HttpClient, hrefService: HrefService) {
    super(httpClient, hrefService.getApiUrl());
  }

  public get (): Promise<SignupQuestions> {
    return super.get('/questions');
  }

  public create(signupQuestions: SignupQuestions): Promise<SignupQuestions> {
    return super.post('/questions', JSON.stringify(signupQuestions));
  }

}
