import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { BaseComponent } from '@core/base.component';
import { AuthenticationService } from '@core/service/authentication.service';
import { HttpErrorResponse } from '@angular/common/http';
import {
  DisbursementReportFormat,
  ReportPaymentRequest,
  ReportService,
} from '@core/service/report.service';
import * as FileSaver from 'file-saver';
import { EmptyState } from '@zfb/ui/empty-state/empty-state.component';
import { Currency, CurrencyCode } from '@core/dto/CurrencyCode';
import { AppStateService } from '@core/service/app-state.service';
import { PaginationState } from '@zfb/ui/table-pagination/table-pagination.component';
import { LocaleService } from '@core/service/locale.service';
import { TableRow } from '@zfb/ui/table/table.component';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { ColorService } from '@core/service/color.service';
import { PaymentRequestService } from '@core/service/payment-request.service';
import { PaymentRequestResponse } from '@core/dto/PaymentRequestResponse';
import {
  ReportDisbursement,
  ReportDisbursementTotal,
} from '@core/dto/report/ReportDisbursement';
import {
  ReportColumnDefinition,
  ReportTableRows,
} from '@zfb/ui/report-table/report-table.component';
import { PaginatedListWithSummary } from '@core/dto/PaginatedListWithSummary';

export enum FileType {
  EXCEL_XLSX = 'EXCEL_XLSX',
  CSV_COMMA = 'CSV_COMMA',
}

enum ModalComponent {
  VIEW_PAYMENT_REQUEST = 'VIEW_PAYMENT_REQUEST',
  VIEW_DISBURSEMENT = 'VIEW_DISBURSEMENT',
  VIEW_DOWNLOAD_FILE = 'VIEW_DOWNLOAD_FILE',
}

enum ProductPage {
  CHECKOUT = 'CHECKOUT',
  CASHOUT = 'CASHOUT',
}

@Component({
  selector: 'app-accounting-report-v2',
  templateUrl: './accounting-report-v2.component.html',
  styleUrls: ['./accounting-report-v2.component.css'],
})
export class AccountingReportV2Component
  extends BaseComponent
  implements OnInit, OnChanges
{
  ProductPage = ProductPage;

  @Input() fromDate: Date;
  @Input() toDate: Date;
  @Input() searchQuery: string;

  activeModalComponent: ModalComponent;
  activeSmallModalComponent: boolean;

  modalTitle: string;

  modalColor: string;

  fetchingResponse: boolean;

  activePage: ProductPage = ProductPage.CHECKOUT;

  hasCashoutEnabled: Boolean;

  selectedReportDisbursement: ReportDisbursement = null;
  selectedPaymentRequest: PaymentRequestResponse = null;

  reportDisbursements: ReportTableRows<ReportDisbursement> = {
    data: [],
    rowClickFunction: (it: ReportDisbursement) =>
      this.openViewReportDisbursementModal(it),
  };

  reportDisbursementColumns: ReportColumnDefinition<ReportDisbursement>[] = [
    {
      cssClasses: 'medium2',
      columnDataTransformer: (reportDisbursement: ReportDisbursement) =>
        reportDisbursement.date.toString(),
      text: $localize`:@@accountingReport.disbursement.date:Datum`,
    },
    {
      cssClasses: 'hide-on-mobile large handle-overflow medium',
      columnDataTransformer: (reportDisbursement: ReportDisbursement) =>
        reportDisbursement.paymentReference,
      text: $localize`:@@accountingReport.disbursement.reference:Bankreferens`,
    },
    {
      cssClasses: 'hide-on-mobile small',
      columnDataTransformer: (reportDisbursement: ReportDisbursement) =>
        reportDisbursement.reportStatusText,
      text: $localize`:@@accountingReport.disbursement.type:Typ (alla)`,
    },
    {
      cssClasses: 'align-right',
      columnDataTransformer: (reportDisbursement: ReportDisbursement) =>
        reportDisbursement.count.toString(),
      text: $localize`:@@accountingReport.disbursement.transactions:Transaktioner`,
    },
    {
      cssClasses: 'align-right medium2',
      columnDataTransformer: (reportDisbursement: ReportDisbursement) =>
        new CurrencyPipe(this.localeService.getCurrentLocale()).transform(
          reportDisbursement.value.amount,
          reportDisbursement.value.currencyCode,
          ''
        ),
      columnStylingTransformer: (reportDisbursement: ReportDisbursement) =>
        `color: ${reportDisbursement.reportTypeColor}`,
      text: $localize`:@@accountingReport.disbursement.amount:Belopp`,
    },
    {
      cssClasses: 'align-right small',
      columnDataTransformer: (reportDisbursement: ReportDisbursement) =>
        `(${reportDisbursement.value.currencyCode.toString()})`,
      text: $localize`:@@salesReport.table.column.currency:Valuta`,
    },
  ];

  totalTransactions: number;
  totalSum: Currency;
  public paginationState: PaginationState = {
    page: 1,
    pageSize: 10,
    total: 0,
    numOfItems: 0,
  };

  emptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Emoji-Glasses.png',
    headingText: $localize`:@@salesReport.emptyState.headingText:Inga rapporter att&nbsp;hämta`,
    bodyText: $localize`:@@salesReport.emptyState.bodyText:Det verkar inte finnas några rapporter under det valda&nbsp;datumintervallet.`,
  };

  merchantDefaultCurrency: CurrencyCode;
  reportFormats: DisbursementReportFormat[];
  preSelectedReportFormat: DisbursementReportFormat;

  constructor(
    private appState: AppStateService,
    protected auth: AuthenticationService,
    private reportService: ReportService,
    private paymentRequestService: PaymentRequestService,
    private appStateService: AppStateService,
    public localeService: LocaleService,
    public colorService: ColorService
  ) {
    super(auth);

    this.merchantDefaultCurrency = this.appStateService.getDefaultCurrency();
  }

  ngOnInit() {
    this.hasCashoutEnabled = this.appState.merchantHasCashoutAccess();
    this.refreshReportDisbursements();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changeFromDate = changes['fromDate'];
    const changeToDate = changes['toDate'];
    const changeSearchQuery = changes['searchQuery'];

    if (
      (changeFromDate && !changeFromDate.firstChange) ||
      (changeToDate && !changeToDate.firstChange) ||
      (changeSearchQuery && !changeSearchQuery.firstChange)
    ) {
      this.paginationState.page = 1;
      this.refreshReportDisbursements();
    }
  }

  onPageChange($event) {
    this.paginationState.page = $event;
    this.refreshReportDisbursements();
  }

  onPageSizeChange($event) {
    this.paginationState.pageSize = $event;
    this.paginationState.page = 1;
    this.refreshReportDisbursements();
  }

  openViewReportDisbursementModal(rd: ReportDisbursement) {
    this.selectedReportDisbursement = rd;
    this.updateModalTextAndColor({
      text: rd.date.toString(),
      color: rd.reportTypeColor,
    });

    this.activateModal(ModalComponent.VIEW_DISBURSEMENT);
  }

  openViewPaymentRequestModal(rp: ReportPaymentRequest) {
    this.paymentRequestService
      .getOne(rp.paymentRequestId)
      .then((paymentRequest) => {
        PaymentRequestResponse.setup(paymentRequest);
        this.updateModalTextAndColor({
          text: paymentRequest.statusText,
          color: paymentRequest.statusColor,
        });
        this.selectedPaymentRequest = paymentRequest;
        this.activateModal(ModalComponent.VIEW_PAYMENT_REQUEST);
      })
      .catch((err) => this.handleError(err));
  }

  openViewDownloadFileModal() {
    this.activeSmallModalComponent = true;
    this.updateModalTextAndColor({
      text:
        new DatePipe(this.localeService.getCurrentLocale()).transform(
          this.fromDate
        ) +
        ' - ' +
        new DatePipe(this.localeService.getCurrentLocale()).transform(
          this.toDate
        ),
      color: '#4466EE',
    });
  }

  activateModal(component: ModalComponent) {
    this.activeModalComponent = component;
  }

  deactivateModal() {
    this.activeSmallModalComponent = false;
    this.activeModalComponent = null;
  }

  updateModalTextAndColor(event: { text: string; color: string }) {
    if (event.color) {
      this.modalColor = event.color;
    }
    if (event.text) {
      this.modalTitle = event.text;
    }
  }

  setActivePage(page: ProductPage) {
    if (this.activePage === page) {
      return;
    }
    this.activePage = page;
  }

  async refreshReportDisbursements() {
    if (
      this.fromDate &&
      this.toDate &&
      this.fromDate instanceof Date &&
      this.toDate instanceof Date
    ) {
      this.fetchingResponse = true;
      return this.reportService
        .getReportDisbursements(
          this.searchQuery,
          this.fromDate,
          this.toDate,
          this.paginationState.page - 1, // page-numbering is zero based on the server
          this.paginationState.pageSize
        )
        .subscribe(
          (res) => {
            this.handleResponse(res);
            this.fetchingResponse = false;
          },
          (err) => {
            super.handleError(err);
            this.fetchingResponse = false;
          }
        );
    }
  }

  handleResponse(
    res: PaginatedListWithSummary<ReportDisbursement, ReportDisbursementTotal>
  ) {
    res.elements.forEach((rd) => ReportDisbursement.setup(rd));
    this.reportDisbursements.data = res.elements;
    this.paginationState.page = res.page + 1; // page-numbering is zero based on the server
    this.paginationState.total = res.total;
    this.paginationState.numOfItems = this.reportDisbursements.data.length;
    this.totalTransactions = res.total;
    try {
      // TODO both this and the layout should support multiple currencies
      this.totalSum = res.summary.byCurrency.find(
        (x) => x.sum.currencyCode == this.merchantDefaultCurrency
      ).sum;
    } catch {
      // fallback in case the summary is empty
      this.totalSum = {
        amount: 0,
        currencyCode: this.merchantDefaultCurrency,
      };
    }
  }

  deviceIsMobile() {
    return window.innerWidth <= 520;
  }

  setupQuickActionsForReportDisbursement(
    rd: ReportDisbursement
  ): TableRow<ReportDisbursement> {
    return {
      data: rd,
      quickActions: null,
    };
  }

  async downloadFile() {
    if (this.fromDate && this.toDate) {
      this.clearError();
      this.refreshReportDisbursements();
      this.fetchingResponse = true;

      return this.reportService.getFile(this.fromDate, this.toDate).subscribe(
        (res) => {
          FileSaver.saveAs(
            new Blob([res.body], {
              type: 'applications/vnc.openxmlformats-officedument.spreadsheetxml.sheet',
            }),
            res.headers.get('content-disposition').split('"')[1]
          ); // Filename encased in quotes, get middle object of 3
        },
        (err) => {
          super.handleError(err);
          this.fetchingResponse = false;
          const httpError: HttpErrorResponse = JSON.parse(JSON.stringify(err));
          if (httpError.status === 400) {
            this.errorMessage = $localize`:@@salesReport.getFile.error.tooLarge:Filen du försöker ladda ned innehåller för många rader. Vänligen välj ett tidsintervall med färre än 5000 transaktioner.`;
          } else {
            this.errorMessage = $localize`:@@salesReport.getFile.error.other:Något gick fel när rapporten skulle laddas ned`;
          }
        }
      );
    }
  }
}
