<div class="tabs-wrapper">
  <div
    class="tab"
    *ngFor="let tab of tabs"
    (click)="clickedTab(tab.value)"
    [class.active]="tab.value === activeValue"
  >
    {{ tab.label }}
    <div *ngIf="!!tab.badgeCount && tab.badgeCount > 0" class="badge cerise-bg">
      {{ tab.badgeCount }}
    </div>
  </div>
</div>
