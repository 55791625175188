<div class="add-line-item-wrapper">
  <p class="heading" i18n="@@addLineItem.heading">Lägg till line item</p>

  <div class="card-wrapper" *ngFor="let form of formArray; let i = index">
    <div class="card-header">
      <span class="card-header-title">
        <p i18n="@@addLineItem.articleNumber">Art.Nr/Referens</p>
        <p>#{{ i + 1 }}</p>
      </span>
      <button
        (click)="removeLineItem(form)"
        *ngIf="i > 0 || formArray.length > 1"
      >
        <img src="assets/icons/Icon-Navigation-Exit-Close-Black.svg" />
      </button>
    </div>
    <div class="card-body">
      <form [formGroup]="form">
        <div
          class="input-fields"
          [class.error]="form.controls.title.invalid && showError"
        >
          <zaver-form-input
            title="Produkttitel"
            i18n-title="@@addLineItem.inputfield.product.title"
            [placeholder]="inputFieldProductPlaceholder"
            formControlName="title"
            [size]="'small'"
            [error]="form.controls.title.invalid && showError"
            [errorMessage]="inputFieldProductErrorMessage"
          ></zaver-form-input>
        </div>
        <div
          class="input-fields"
          [class.error]="form.controls.description.invalid && showError"
        >
          <zaver-form-input
            [title]="inputFieldArticleTitle"
            [placeholder]="inputFieldArticlePlaceholder"
            [formControlName]="'description'"
            [size]="'small'"
            [error]="form.controls.description.invalid && showError"
            [errorMessage]="inputFieldArticleErrorMessage"
          ></zaver-form-input>
        </div>
        <div
          class="input-fields"
          [class.error]="form.controls.unitPrice.invalid && showError"
        >
          <zaver-form-input
            [showContent]="true"
            [title]="inputFieldUnitTitle"
            [placeholder]="inputFieldUnitPlaceholder"
            [formControlName]="'unitPrice'"
            [inputMode]="'decimal'"
            [size]="'small'"
            [error]="form.controls.unitPrice.invalid && showError"
            [errorMessage]="inputFieldUnitErrorMessage"
          >
            <span
              class="unit-price-currency"
            >
            {{ currencyCode }}
            </span>
          </zaver-form-input>
        </div>

        <div *ngIf="market === 'SE'" class="input-fields">
          <a
            target="_blank"
            href="https://zaver.com/se/beloppsgranser"
            id="payment-limits-link"
            class="link"
          >
            <span class="limit-link">Visa beloppsgränser</span>
            <img src="assets/icons/Icon-External-Link.svg" />
          </a>
        </div>

        <div class="dropdown-container">
          <zaver-dropdownv2
            class="zaver-dropdown"
            [size]="'small'"
            [title]="dropdownVatTitle"
            [selectableItems]="taxRateOptions"
            [selectedItem]="
              findOption(taxRateOptions, form.value.taxRatePercent)
            "
            [shorterDropdown]="true"
            (inputModelChange)="setTaxRatePrecent($event, i)"
            [error]="form.controls.taxRatePercent.invalid && showError"
            [errorMessage]="'errorMessage'"
          ></zaver-dropdownv2>
          <zaver-dropdownv2
            class="zaver-dropdown"
            [size]="'small'"
            [title]="dropdownCountTitle"
            [selectableItems]="quantityOptions"
            [selectedItem]="findOption(quantityOptions, form.value.quantity)"
            [shorterDropdown]="true"
            (inputModelChange)="setQuantity($event, i)"
            [error]="form.controls.quantity.invalid && showError"
            [errorMessage]="'errorMessage'"
          ></zaver-dropdownv2>
        </div>

        <div class="total-amount">
          {{ totalValueString }}
          {{
            calculateTotalAmount(
              form.value.unitPrice,
              form.value.quantity
            ) | value: locale
          }}
        </div>
      </form>
    </div>
  </div>

  <div class="add-product-button-wrapper" id="addLineItemButton">
    <button (click)="addLineItem()">
      <svg
        class="iconAddPlus"
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="#4466ef"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.1025 8.99996C1.1025 4.63829 4.63833 1.10246 9 1.10246C13.3617 1.10246 16.8975 4.63829 16.8975 8.99996C16.8975 13.3616 13.3617 16.8975 9 16.8975C4.63833 16.8975 1.1025 13.3616 1.1025 8.99996ZM9 0.0224609C4.04186 0.0224609 0.0224991 4.04182 0.0224991 8.99996C0.0224991 13.9581 4.04186 17.9775 9 17.9775C13.9581 17.9775 17.9775 13.9581 17.9775 8.99996C17.9775 4.04182 13.9581 0.0224609 9 0.0224609ZM9 5.08496C9.29823 5.08496 9.54 5.32673 9.54 5.62496V8.45996H12.375C12.6732 8.45996 12.915 8.70173 12.915 8.99996C12.915 9.29819 12.6732 9.53996 12.375 9.53996H9.54V12.375C9.54 12.6732 9.29823 12.915 9 12.915C8.70177 12.915 8.46 12.6732 8.46 12.375V9.53996H5.625C5.32677 9.53996 5.085 9.29819 5.085 8.99996C5.085 8.70173 5.32677 8.45996 5.625 8.45996H8.46V5.62496C8.46 5.32673 8.70177 5.08496 9 5.08496Z"
          fill="#4466ef"
        />
      </svg>
      <span i18n="@@addLineItem.button.addLineItem"> Lägg till artikel </span>
    </button>
  </div>

  <div class="continue-back-buttons-container">
    <zaver-primary-button
      [class]="merchantHasB2B ? 'action-button' : 'action-button-single'"
      [text]="continueButton"
      [loading]="false"
      (click)="submit()"
    ></zaver-primary-button>
    <zaver-secondary-button-subtle
      *ngIf="merchantHasB2B"
      class="action-button"
      [text]="backButton"
      (click)="returnToSelection.emit()"
    ></zaver-secondary-button-subtle>
  </div>
</div>
