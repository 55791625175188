import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'zaver-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss', '../zaver-colors.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ]
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {

  @Input() text: string;
  @Input() disabled = false;

  isChecked: boolean;

  onBlur = (_) => {};
  onChange = (_) => {};


  onChanged($event: any): void {
    this.isChecked = $event && $event.target && $event.target.checked;
    this.onChange(this.isChecked);
  }

  constructor() {
  }

  ngOnInit(): void {
  }


  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onBlur = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.isChecked = obj;
  }


}
