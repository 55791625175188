<div class="intro-paragraph">
  <span i18n="@@accountingReport.introText"
    >Här skapas återredovisningsfiler över utförda transaktioner för angivet
    tidsintervall. Ladda ned rapporterna för mer detaljerad information om
    transaktionerna.</span
  >
</div>
<div id="tab-select-container" *ngIf="hasCashoutEnabled">
  <app-navigation-tab
    [text]="'Checkout'"
    [active]="activePage === ProductPage.CHECKOUT"
    (clicked)="setActivePage(ProductPage.CHECKOUT)"
  >
  </app-navigation-tab>
  <app-navigation-tab
    [text]="'Cashout'"
    [active]="activePage === ProductPage.CASHOUT"
    (clicked)="setActivePage(ProductPage.CASHOUT)"
  >
  </app-navigation-tab>
</div>

<app-page-error
  *ngIf="errorMessage"
  [errorMessage]="errorMessage"
></app-page-error>

<div id="checkout-container" *ngIf="activePage === ProductPage.CHECKOUT">
  <div *ngIf="fetchingResponse" class="page-loader-container">
    <app-page-loader></app-page-loader>
  </div>

  <div id="summary-container" *ngIf="!fetchingResponse">
    <div id="summaries">
      <div>
        <span class="medium2" i18n="@@accountingReport.summary.transactions"
          >Transaktioner:</span
        >
        <span
          id="transactions-span"
          i18n="@@accountingReport.summary.transactionsAmount"
          >{{ totalTransactions }}&nbsp;st</span
        >
      </div>
      <div class="hide-on-mobile" id="spacer"></div>
      <div>
        <span class="medium2" i18n="@@accountingReport.summary.volume"
          >Utbetalningsvolym:</span
        >
        <span id="sum-span">{{
          totalSum | value: localeService.getCurrentLocale()
        }}</span>
      </div>
    </div>

    <button
      type="button"
      id="fetch-report-button"
      (click)="openViewDownloadFileModal()"
      *ngIf="reportDisbursements.data.length != 0"
    >
      <span i18n="@@accountingReport.fetchReport">Hämta rapport</span>
      <img class="icons" src="assets/icons/Icon-Download-Circle.svg" alt="" />
    </button>
  </div>

  <app-report-table
    *ngIf="!fetchingResponse && reportDisbursements.data.length != 0"
    [columns]="reportDisbursementColumns"
    [rows]="reportDisbursements"
  >
  </app-report-table>

  <app-table-pagination
    *ngIf="!fetchingResponse && reportDisbursements.data.length != 0"
    [paginationState]="paginationState"
    (pageChanged)="onPageChange($event)"
    (pageSizeChanged)="onPageSizeChange($event)"
  >
  </app-table-pagination>

  <!-- Empty state when no reports exist for the given date-range -->
  <app-empty-state
    *ngIf="totalTransactions === 0 && !fetchingResponse"
    [emptyState]="emptyState"
  >
  </app-empty-state>

  <zaver-form-modal
    *ngIf="!!activeModalComponent"
    (onClose)="deactivateModal()"
    [title]="modalTitle"
    [color]="modalColor"
    [ngSwitch]="activeModalComponent"
  >
    <app-view-report-disbursement
      *ngSwitchCase="'VIEW_DISBURSEMENT'"
      [reportDisbursement]="selectedReportDisbursement"
      (onViewPaymentRequest)="openViewPaymentRequestModal($event)"
    >
    </app-view-report-disbursement>

    <app-view-payment-request
      *ngSwitchCase="'VIEW_PAYMENT_REQUEST'"
      [paymentRequest]="selectedPaymentRequest"
      [showGoBackButton]="true"
      (onGoBack)="openViewReportDisbursementModal(selectedReportDisbursement)"
    ></app-view-payment-request>
  </zaver-form-modal>

  <zaver-details-modal
    *ngIf="activeSmallModalComponent"
    (onClose)="deactivateModal()"
    [title]="modalTitle"
    [color]="modalColor"
  >
    <app-view-download-file
      [searchQuery]="searchQuery"
      [fromDate]="fromDate"
      [toDate]="toDate"
      (deactivateModal)="deactivateModal()"
    ></app-view-download-file>
  </zaver-details-modal>
</div>

<div
  id="cashout-container"
  *ngIf="!fetchingResponse && activePage === ProductPage.CASHOUT"
>
  <app-accounting-report
    [fromDate]="fromDate"
    [toDate]="toDate"
    [onlyShowCashout]="true"
  >
  </app-accounting-report>
</div>
