import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Market } from '@core/dto/Market';

export class FormValidators {
  /**
   * Validator for integer input fields.
   *
   */
  public static number(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const error: ValidationErrors = { integer: true };
      const value = control.value.replace(',', '.');

      if (!value) {
        return error;
      }

      let trimmedValue = value.replace(/\s/g, '');

      if (value && !/^\d+([\.\,]\d{1,2})?$/.test(trimmedValue)) {
        return error;
      }

      const intValue = parseInt(trimmedValue, 10);
      if (min > intValue || max < intValue) {
        return error;
      }

      const floatValue = parseFloat(trimmedValue);
      if (min > floatValue || max < floatValue) {
        return error;
      }

      return null;
    };
  }

  /**
   * Validator for number input fields.
   *
   */
  public static numberMultipleDecimals(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const error: ValidationErrors = { integer: true };
      let value = control.value;
      if (!value) {
        return error;
      }

      let trimmedValue = value.replace(/\s/g, '');

      if (value && !/^\d+([\.\,]\d+)?$/.test(trimmedValue)) {
        return error;
      }
      // By rounding up we catch number that exceed the max allowed value with decimals
      // Example: value = 100,10  max = 100   intValue = 101 (not allowed)
      value = trimmedValue.toString().replace(/,/g, '.');
      const floatValue = parseFloat(value);

      const intValue = Math.ceil(floatValue);
      if (min > intValue || max < intValue || floatValue < min) {
        return error;
      }

      return null;
    };
  }

  /**
   * Validators for date input fields.
   *
   */
  public static dueDateValidator(
    today: Date,
    maxDueDate: string,
    market: Market
  ): ValidatorFn {
    const maxDate = new Date(maxDueDate);
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (value === null || value === '') {
        return null;
      }

      today.setHours(0, 0, 0, 0);
      let regex;
      const isDeAtMarket = market === Market.DE || market === Market.AT;
      if (isDeAtMarket) {
        regex =
          /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/;
      } else {
        regex =
          /^\s*((?:19|20)\d{2})\-(1[012]|0?[1-9])\-(3[01]|[12][0-9]|0?[1-9])\s*$/;
      }

      if (!regex.test(value)) {
        return { invalidDate: true };
      }
      const inputDate = isDeAtMarket
        ? convertDEStringToSEDate(value)
        : new Date(value);
      if (!inputDate || inputDate < today || inputDate > maxDate) {
        return { invalidDate: true };
      }

      return null;
    };
  }

  public static dateRange(min: Date, max: Date, locale: string): ValidatorFn {
    function convertDeStringToDate(dateValue: Date) {
      const parts = dateValue.toString().split('.');
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);
      return new Date(year, month, day);
    }

    return (control: AbstractControl): ValidationErrors | null => {
      const error: ValidationErrors = { integer: true };
      let value;

      if (locale === 'de' || locale === 'at') {
        value = convertDeStringToDate(control.value);
        if (
          min.getTime() > value.getTime() ||
          max.getTime() < value.getTime()
        ) {
          return error;
        }
      } else {
        value = new Date(control.value).setHours(12, 0, 0, 0);
        if (min.getTime() > value || max.getTime() < value) {
          return error;
        }
      }

      return null;
    };
  }
}

export const convertDEStringToSEDate = (dateString: string): Date => {
  let parts = dateString.split('.');
  if (parts.length === 3) {
    let day = parseInt(parts[0], 10);
    let month = parseInt(parts[1], 10) - 1;
    let year = parseInt(parts[2], 10);
    const newDate = new Date(year, month, day);
    newDate.setHours(12, 0, 0, 0);
    return newDate;
  } else {
    return;
  }
};
