import { Industry } from './Bransch';

export class SignupPrivateCompany {
  name: string;
  personalNumber: string;
  pep: string;
  website: string;
  industry: Industry;
  industryOther: string;

  constructor(
    name: string,
    personalNumber: string,
    pep: string,
    website: string,
    industry: Industry,
    industryOther: string
  ) {
    this.name = name;
    this.personalNumber = personalNumber;
    this.pep = pep;
    this.website = website;
    this.industry = industry;
    this.industryOther = industry === Industry.OTHER ? industryOther : null;
  }
}
