<div *ngIf="isNotProduction()" class="warning">
  <div class="warning-container">
    <img
      class="icon-warning"
      src="assets/icons/Icon-Warning.svg"
    />
    <p class="text-warning">
      This is a test environment for Zaver for Business. Please go to the production site to log in to your account -
      <a href="https://business.zaver.com/">Click here</a>
    </p>
  </div>
</div>

<div class="card">
  <h1 i18n="@@login.email.title">Välkommen!</h1>
  <span class="sub-title" i18n="@@login.email.sub-title"
    >Vänligen ange din jobbmail för att logga in.</span
  >
  <form [formGroup]="form" id="login-form">
    <div class="input-container">
      <zaver-animated-form-input
        [placeholder]="emailInputPlaceholder"
        [inputMode]="'email'"
        [error]="form.controls.email.invalid && showError"
        [errorMessage]="emailInputErrorMessage"
        formControlName="email"
        [readonly]="processingLogin"
        [size]="'large'"
      ></zaver-animated-form-input>
    </div>

    <div class="switch-container">
      <app-switch
        (change)="rememberMeClicked()"
        i18n-text="@@login.email.rememberme"
        text="Kom ihåg mig"
        formControlName="rememberMe"
      ></app-switch>
    </div>

    <app-form-error *ngIf="formError" [errorText]="formError"> </app-form-error>

    <div class="button-wrapper">
      <zaver-primary-button
        [buttonType]="'submit'"
        text="Fortsätt"
        i18n-text="@@login.email.button.continue"
        [loading]="processingLogin"
        (click)="submit()"
        [color]="'black'"
      ></zaver-primary-button>
    </div>
  </form>
</div>

<div id="signup-box" *ngIf="localeService.getCurrentLocale() === 'sv'">
  <span>Vill ditt företag börja använda Zaver?</span>
  <a href="#" routerLink="/signup" routerLinkActive="active" class="link"
    >Registrera nytt företag</a
  >
</div>

<div
  id="signup-box"
  *ngIf="
    localeService.getCurrentLocale() === 'de' ||
    localeService.getCurrentLocale() === 'de-AT'
  "
>
  <span>Sie haben kein Benutzerkonto?</span>
  <a href="https://zaver.com/de/los-gehts" class="link" target="_blank"
    >Hier registrieren</a
  >
</div>
