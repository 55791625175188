import { Component, OnInit } from '@angular/core';
import { LocaleService } from '@core/service/locale.service';

@Component({
  selector: 'app-precheck',
  templateUrl: './precheck.component.html',
  styleUrls: ['./precheck.component.css', '../page-shared.scss'],
})
export class PrecheckComponent implements OnInit {
  constructor(public localeService: LocaleService) {}

  ngOnInit(): void {}
}
