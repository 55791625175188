import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@core/base.component';
import { AuthenticationService } from '@core/service/authentication.service';
import { PaymentRequestService, SurplusPayment } from '@core/service/payment-request.service';

@Component({
  selector: 'app-surplus-payment-contact-confirmation',
  templateUrl: './surplus-payment-contact-confirmation.component.html',
  styleUrls: ['./surplus-payment-contact-confirmation.component.css']
})
export class SurplusPaymentContactConfirmationComponent extends BaseComponent implements OnInit {

  @Input() selectedSurplusPayment: SurplusPayment;

  @Output() onSent = new EventEmitter();
  @Output() onCloseModal = new EventEmitter();

  submitting = false;
  serverErrorMessage = 'Meddelandet skickades inte. Vänligen försök igen eller avbryt genom att stänga. <a class="link" href="mailto:merchants@zaver.se" target="_blank">Kontakta&nbsp;oss</a> om problemet kvarstår.';

  constructor(
    private paymentRequestService: PaymentRequestService,
    auth: AuthenticationService
  ) {
    super(auth);
   }

  ngOnInit(): void {
  }

  closeModal() {
    this.onCloseModal.emit();
  }

  submit() {
    this.errorMessage = '';
    this.submitting = true;
    this.paymentRequestService.handleSurplusPayment(
      this.selectedSurplusPayment)
      .then(() => this.onSent.emit())
      .catch((error) => {
        this.handleError(error);
        this.submitting = false;
      });
  }
}
