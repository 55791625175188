<button
  [ngClass]="[size, color]"
  [class.rounded]="rounded"
  [disabled]="disabled"
>
  <ng-container *ngIf="!loading">
    <img *ngIf="iconUrl" [src]="iconUrl" alt="" />
    <span class="button-text">{{ text }}</span>
  </ng-container>
  <ng-container *ngIf="loading">
    <zaver-loading-dots [color]="getLoadingDotsColor()"></zaver-loading-dots>
  </ng-container>
</button>
