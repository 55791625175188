import {Injectable} from '@angular/core';
import {
  CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router,
  CanActivateChild
} from '@angular/router';
import {Observable} from 'rxjs';
import {AppStateService} from '@core/service/app-state.service';

@Injectable()
export class MustNotBeSignedUpCompany implements CanActivateChild {

  constructor(private router: Router, private appState: AppStateService) {
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.appState.isSignedUp()) {
      this.router.navigate(['/pendingApproval']).then(() => false);
      return false;
    }
    return true;
  }
}
