<div class="accordion-card">
    <div class="card-heading" [class.hide]="!open">
      <h2 [innerHTML]="heading"></h2>
      <div (click)="toggleOpen()">
        <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="open">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.277554 0.30049C0.64765 -0.100137 1.24772 -0.100167 1.61785 0.300422L8.5 7.74891L15.3821 0.300422C15.7523 -0.100167 16.3523 -0.100137 16.7224 0.30049C17.0925 0.701117 17.0925 1.35063 16.7224 1.75122L9.52642 9.53935C9.52625 9.53953 9.52608 9.53972 9.52591 9.5399C9.3913 9.68567 9.23148 9.80133 9.05558 9.88027C8.87945 9.95932 8.69066 10 8.5 10C8.30934 10 8.12055 9.95932 7.94442 9.88027C7.7685 9.80132 7.60867 9.68565 7.47405 9.53986C7.47389 9.53969 7.47373 9.53952 7.47358 9.53935L0.277616 1.75122C-0.0925144 1.35063 -0.0925424 0.701117 0.277554 0.30049ZM8.81432 8.0891C8.81444 8.08922 8.81455 8.08935 8.81466 8.08947L8.81432 8.0891Z" fill="#6A7381"/>
          </svg>
        <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="!open">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.300434 0.277585C0.701013 -0.0925284 1.35048 -0.0925284 1.75106 0.277585L9.53938 7.47358C9.68539 7.60829 9.80124 7.76829 9.88028 7.94442C9.95932 8.12055 10 8.30934 10 8.5C10 8.69066 9.95932 8.87945 9.88028 9.05558C9.80133 9.2315 9.68567 9.39133 9.5399 9.52595C9.53973 9.5261 9.53955 9.52626 9.53938 9.52642L1.75106 16.7224C1.35048 17.0925 0.701013 17.0925 0.300434 16.7224C-0.100145 16.3523 -0.100145 15.7522 0.300434 15.3821L7.74904 8.5L0.300434 1.61789C-0.100145 1.24777 -0.100145 0.647699 0.300434 0.277585Z" fill="#6A7381"/>
        </svg>
      </div>  
    </div>
    <div class="card-body" [class.hide]="!open">
      <ng-content>

      </ng-content>
    </div>
  </div>