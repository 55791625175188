import { CashoutTaskStatus } from '@core/params';

export class CashoutTaskRepresentation {
  friendlyStatus: string;
  textColor: string;
  backgroundColor?: string;
}

export const CashoutTaskRepresentations: Map<string, CashoutTaskRepresentation> = new Map([
  [CashoutTaskStatus.PENDING_PAYEE_APPROVAL, {
    friendlyStatus: 'Initierad',
    textColor: '#4466EE',
    backgroundColor: '#DBE7FF'
  }],
  [CashoutTaskStatus.PENDING_ADMIN_APPROVAL, {
    friendlyStatus: 'Klar för attest',
    textColor: '#DF5809',
    backgroundColor: '#FCEEE6'
  }],
  [CashoutTaskStatus.PENDING_EXECUTION, {
    friendlyStatus: 'Bekräfta utbetalning',
    textColor: '#CC7A00',
    backgroundColor: '#FEF4E5'
  }],
  [CashoutTaskStatus.EXECUTED, {
    friendlyStatus: 'Utbetald',
    textColor: '#009773',
    backgroundColor: '#E5F5F2'
  }],
  [CashoutTaskStatus.BOUNCED, {
    friendlyStatus: 'Utbetalning misslyckades',
    textColor: '#BD0F3D',
    backgroundColor: '#F8E7EC'
  }],
  [CashoutTaskStatus.FAILED, {
    friendlyStatus: 'Utbetalning misslyckades',
    textColor: '#BD0F3D',
    backgroundColor: '#F8E7EC'

  }],
  [CashoutTaskStatus.CANCELLED, {
    friendlyStatus: 'Avbruten',
    textColor: '#343B45',
    backgroundColor: '#E6E9EF'
  }],
  ['_DEFAULT_', {
    friendlyStatus: 'Processas',
    textColor: '#393939',
  }],
]);
