<div class="table-info">
  <div class="product-name">
    <input
      type="checkbox"
      (click)="onSetAllChecked(!allChecked)"
      [checked]="allChecked"
    />
    <p>Product name</p>
  </div>
  <div class="grouped-columns">
    <p class="quantity">Art.Nr/Position</p>
    <p class="quantity">Quantity</p>
    <p class="unit-price">Unit price</p>
    <p class="vat">VAT</p>
    <p class="vat-amount">VAT</p>
    <p class="total-amount">Total amount</p>
    <p class="edit"></p>
  </div>
</div>

<div class="table">
  <ng-container *ngFor="let lineItemVM of lineItemsVM">
    <form
      [formGroup]="lineItemVM.form"
      [ngClass]="lineItemVM.form.invalid ? 'invalid' : 'valid'"
    >
      <div class="row">
        <div class="product-name">
          <input
            formControlName="checked"
            type="checkbox"
            [checked]="lineItemVM.form.value.checked"
          />

          <app-line-item-input
            *ngIf="lineItemVM.editMode && lineItemVM.titleEditable"
            style="width: 100%"
            [type]="'number'"
            [textAlign]="'right'"
            [control]="$any(lineItemVM.form.controls.title)"
          ></app-line-item-input>

          <p
            *ngIf="!lineItemVM.editMode || !lineItemVM.titleEditable"
            [title]="lineItemVM.form.value.title"
            class="title"
          >
            {{ lineItemVM.form.value.title }}
          </p>
        </div>

        <div class="grouped-columns">
          <div class="quantity">
            <app-line-item-input
              *ngIf="lineItemVM.editMode"
              [type]="'number'"
              [textAlign]="'right'"
              [control]="$any(lineItemVM.form.controls.description)"
            ></app-line-item-input>

            <p
              *ngIf="!lineItemVM.editMode"
              [title]="lineItemVM.form.value.description"
              class="title"
              style="margin-left: 8px"
            >
              {{ lineItemVM.form.value.description }}
            </p>
          </div>

          <div class="quantity">
            <ng-container
              *ngIf="
                !lineItemVM.editMode || lineItemVM.form.value.quantity === 1
              "
            >
              <button
                [disabled]="isMinQuantity(lineItemVM.form.value.quantity, 1)"
                type="button"
                (click)="onChangeQuantity('REMOVE', lineItemVM)"
              >
                <img src="assets/icons/Remove.svg" />
              </button>

              <p>
                {{ lineItemVM.form.value.quantity }} /
                {{ lineItemVM.maxQuantity }}
              </p>

              <button
                [disabled]="
                  isMaxQuantity(
                    lineItemVM.form.value.quantity,
                    lineItemVM.maxQuantity
                  )
                "
                type="button"
                (click)="onChangeQuantity('ADD', lineItemVM)"
              >
                <img src="assets/icons/Add.svg" />
              </button>
            </ng-container>
            <app-line-item-input
              style="width: 50%"
              *ngIf="
                lineItemVM.editMode && lineItemVM.form.value.quantity !== 1
              "
              [type]="'number'"
              [control]="$any(lineItemVM.form.controls.quantity)"
              [textAlign]="'center'"
            ></app-line-item-input>
          </div>

          <div class="unit-price">
            <p *ngIf="!isEditable(lineItemVM.editMode)">
              {{ lineItemVM.form.value.unitPrice | amountPipe : currency }}
            </p>

            <app-line-item-input
              *ngIf="isEditable(lineItemVM.editMode)"
              [control]="$any(lineItemVM.form.controls.unitPrice)"
              [textAlign]="'right'"
            ></app-line-item-input>
          </div>

          <div class="vat">
            <p
              *ngIf="
                !isEditable(lineItemVM.editMode) ||
                !lineItemVM.taxAmountEditable
              "
            >
              {{ lineItemVM.form.value.taxRatePercent }} %
            </p>
            <zaver-vat-dropdown
              *ngIf="
                isEditable(lineItemVM.editMode) && lineItemVM.taxAmountEditable
              "
              [selectableItems]="taxRateOptions"
              [selectedItem]="
                findOption(taxRateOptions, lineItemVM.form.value.taxRatePercent)
              "
              [shorterDropdown]="true"
              (inputModelChange)="onSelectTaxRate($event, lineItemVM)"
              [errorMessage]="'errorMessage'"
              [size]="'x-small'"
            ></zaver-vat-dropdown>
          </div>

          <div class="vat-amount">
            <p>
              {{ lineItemVM.taxAmount | amountPipe : currency }}
            </p>
          </div>

          <div class="total-amount">
            {{ lineItemVM.totalAmount | amountPipe : currency }}
          </div>

          <div class="edit">
            <button type="button" (click)="onSetEdit(lineItemVM)">
              <img src="assets/icons/Icon-Edit-Widget.svg" />
            </button>
            <button
              type="button"
              *ngIf="lineItemVM.id === null"
              (click)="onRemoveLineItem(lineItemVM)"
            >
              <img src="assets/icons/Icon-Bin-Widget.svg" />
            </button>
          </div>
        </div>
      </div>
      <div
        class="error-text"
        *ngIf="
          lineItemVM.form.controls['unitPrice'].invalid ||
          lineItemVM.form.controls['quantity'].invalid
        "
      >
        <div *ngIf="lineItemVM.form.controls['unitPrice'].invalid">
          The entered amount can’t be higher than the original amount
        </div>
        <div *ngIf="lineItemVM.form.controls['quantity'].invalid">
          The number of line items can’t be more than the original amount
        </div>
      </div>
    </form>
  </ng-container>
</div>

<div class="number-of-lineItems">{{ lineItemsVM.length }} line items</div>
