import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DropdownListItemv2 } from '../dropdownv2/dropdownv2.component';

export interface Item extends DropdownListItemv2 {
  imgSrc: string;
}

@Component({
  selector: 'zaver-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
})
export class CustomSelectComponent implements OnInit, OnChanges {
  @Input() showDropdown = false;
  @Input() items: any = []; //Item[] = [];
  @Input() selectedItem: any;
  @Input() shorterDropdown: boolean = false;
  @Output() itemSelected = new EventEmitter<Item>();
  @Output() dropdownClosed = new EventEmitter();
  htmlItems: HTMLCollection;
  selectedItemIndex: number;

  constructor() {}

  ngOnInit(): void {
    this.setInitialSelectedItem();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showDropdown)
      this.setInitialFocus(changes.showDropdown.currentValue);
  }

  onKeydown(event: KeyboardEvent): void {
    if (this.showDropdown) {
      const activeElement = document.activeElement;
      const elementIndex = parseInt(activeElement.getAttribute('child_id'), 10);
      if (event.code === 'ArrowDown') {
        const nextIndex = elementIndex + 1;
        nextIndex < this.htmlItems.length &&
          (this.htmlItems[nextIndex] as HTMLElement).focus();
        event.preventDefault();
      }

      if (event.code === 'ArrowUp') {
        const prevIndex = elementIndex - 1;
        prevIndex >= 0 && (this.htmlItems[prevIndex] as HTMLElement).focus();
        event.preventDefault();
      }

      if (event.code === 'Enter' || event.code === 'Space') {
        elementIndex >= 0 && this.setSelectedItem(this.items[elementIndex]);
        event.preventDefault();
      }

      if (event.key === 'Tab') {
        event.preventDefault();
      }

      if (event.key === 'Escape') {
        this.closeDropdown();
        event.preventDefault();
      }
    }
  }

  async setInitialFocus(val: boolean): Promise<void> {
    // Wait for dom to render select items
    if (!val) return;
    this.htmlItems = await document.getElementsByClassName(
      'select-item-container'
    );
    (this.htmlItems[this.selectedItemIndex] as HTMLElement).focus();
  }

  setSelectedItem(item: Item): void {
    this.selectedItemIndex = this.items.indexOf(item);
    this.itemSelected.emit(item);
    this.closeDropdown();
  }

  setInitialSelectedItem() {
    this.selectedItemIndex = this.items.indexOf(this.selectedItem);
  }

  closeDropdown(): void {
    this.dropdownClosed.emit(false);
  }
}
