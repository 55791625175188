<div>
    <div class="presented-value">
        {{presentedValue}}
    </div>

    <zaver-input-slider *ngIf="setupReady"
    [initialValue]="initialValueIndex"
    [ariaLabel]="ariaLabel"
    [min]="min"
    [max]="max"
    [(val)]="val"
    [color]="color"
    (valChange)="onValueChange()"
    (valueChangeFinished)="onFinishedChange()"></zaver-input-slider>
</div>
