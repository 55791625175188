import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

@Component({
  selector: 'zaver-input-slider',
  templateUrl: './input-slider.component.html',
  styleUrls: ['./input-slider.component.css']
})
/**
 * Input val and output valChange makes two-way-binding possible. When using the component,
 * input value can be externaly accessed by setting [(val)]="whatever" as an html attribute.
 */
export class InputSliderComponent implements OnInit {

  @Input() min: number;
  @Input() max: number;
  
  @Input() initialValue: number;
  @Input() ariaLabel: string;

  @Input() val: number;
  @Output() valChange = new EventEmitter();

  @Input() color: string = '#DB305E';

  @ViewChild('slider') slider;
  sliderEl: any;

  @Output() valueChangeFinished = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    
  }

  //The slider element is not accessible before view init
  ngAfterViewInit() {

    //Prevent angular expression changed after it has been checked error by setting timeout
    setTimeout(() => {
      this.sliderEl = this.slider.nativeElement;

      if (!!this.initialValue) {
        this.sliderEl.value = this.initialValue;
      } else {
        this.sliderEl.value = 0;
      }
  
      this.val = this.sliderEl.value;
      this.onInputChange();
    })
  }

  onInputChange() {
    this.adjustSlider();
    this.valChange.emit(this.val);
  }

  adjustSlider() {
    const percent =
      ((this.sliderEl.value - this.sliderEl.min) /
      (this.sliderEl.max - this.sliderEl.min)) *
      100;
    this.sliderEl.style.setProperty("--webkitProgressPercent", `${percent}%`);
  }

  onValueChange() { 
    this.valChange.emit(this.val);
    this.valueChangeFinished.emit();
  }

  getColor() {
    return this.color;
  }
}
