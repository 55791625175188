<div *ngIf="!onlyShowCashout" class="intro-paragraph">
  <span i18n="@@accountingReport.introText"
    >Här skapas återredovisningsfiler över utförda transaktioner för angivet
    tidsintervall. Ladda ned rapporterna för mer detaljerad information om
    transaktionerna.</span
  >
</div>

<app-page-error
  *ngIf="errorMessage"
  [errorMessage]="errorMessage"
></app-page-error>

<ng-container *ngIf="fetchingResponse">
  <div class="page-loader-container">
    <app-page-loader></app-page-loader>
  </div>
</ng-container>
<ng-container *ngIf="!fetchingResponse">
  <hr />
  <div
    class="reports-wrapper-rowdiv"
    *ngIf="checkoutEntries > 0 && !onlyShowCashout"
  >
    <div class="reports-wrapper-coldiv">
      <div class="product-heading-div">
        <span class="product-heading" i18n="@@accountingReport.checkout.heading"
          >Checkout</span
        >&nbsp;
        <span
          class="heading-help-text"
          i18n="@@accountingReport.checkout.headingHelpText"
          >(mottagna betalningar)</span
        >
      </div>
      <div class="entries-div">
        <span
          class="product-text"
          i18n="@@accountingReport.checkout.transactions"
          >Periodens totala antal transaktioner:</span
        >&nbsp;
        <span id="checkout-entries" i18n="@@accountingReport.checkout.amount"
          >{{ checkoutEntries }}&nbsp;st</span
        >
      </div>
      <div style="display: flex">
        <span class="product-text" i18n="@@accountingReport.checkout.volume"
          >Periodens totala försäljningsvolym:</span
        >&nbsp;
        <span id="checkout-volume">{{
          checkoutVolume | value: localeService.getCurrentLocale()
        }}</span>
      </div>
    </div>

    <div class="reports-wrapper-buttons-div">
      <button
        type="button"
        class="file-button"
        style="margin-right: 8px"
        (click)="downloadCheckoutExcel()"
      >
        XLSX
      </button>

      <button
        type="button"
        class="file-button"
        *ngIf="hasCsvPreference"
        (click)="downloadCheckoutCsv()"
      >
        CSV
      </button>
    </div>
  </div>
  <div
    class="reports-wrapper-rowdiv"
    *ngIf="hasCashoutEnabled && cashoutEntries > 0"
  >
    <div class="reports-wrapper-coldiv">
      <div class="product-heading-div">
        <span class="product-heading">Cashout</span>&nbsp;<span
          class="heading-help-text"
          >(utbetalningar)</span
        >
      </div>
      <div class="entries-div">
        <span class="product-text">Periodens totala antal transaktioner:</span
        >&nbsp;
        <span id="cashout-entries">{{ cashoutEntries }}&nbsp;st</span>
      </div>
      <div style="display: flex">
        <span class="product-text">Periodens totala utbetalningsvolym:</span
        >&nbsp;
        <span id="cashout-volume" class="cerise-text">{{
          cashoutVolume | value: localeService.getCurrentLocale()
        }}</span>
      </div>
    </div>

    <div>
      <button type="button" class="file-button" (click)="downloadCashout()">
        CSV
      </button>
    </div>
  </div>

  <!-- Empty state when no reports exist for the given date-range-->
  <app-empty-state
    *ngIf="(checkoutEntries === 0 || onlyShowCashout) && cashoutEntries === 0"
    [emptyState]="emptyState"
  >
  </app-empty-state>
</ng-container>
