import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'zaver-alert-box',
  templateUrl: './alert-box.component.html',
  styleUrls: ['./alert-box.component.css']
})
export class AlertBoxComponent implements OnInit {

  @Input() text: string;
  @Input() messageList: string[];
  @Input() shouldLogoAndExitButtonBeIncluded: boolean; //TODO (check design system)
  @Input() type: string;

  constructor() { }

  ngOnInit(): void {
    if (!this.type) {
      console.error("Alert Type is required")
    }
  }

}

export enum AlertType {
  WARNING = 'WARNING',
  INFO = 'INFO'
}