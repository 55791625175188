import { CompanyRepresentativeRole } from './CompanyRepresentativeRole';
import { CurrencyCode } from './CurrencyCode';
import { Market } from './Market';

export class UserDetails {
  id: string;
  firstName: string;
  lastName: string;
  identityNumber: string;
  activeRepresentation: CompanyRepresentation | null;
  availableRepresentations: CompanyRepresentation[];
}

export interface CompanyRepresentation {
  id: string;
  role: CompanyRepresentativeRole;
  permissions: PermissionWithParameters[];
  personalNumber: string;
  email: string;
  phone: string;
  mayViewStores: boolean;
  company: Company;
}

interface Company {
  id: string;
  merchantId: string;
  name: string;
  organizationNumber: string;
  structure: CompanyStructure;
  status: CompanyStatus[];
  referralToken: string | null;
  guiCustomizations: GuiCustomizationDto;
  cashoutEnabled: boolean;
  cashoutMaxAllowedAmount: number | null;
  cashoutDetailsProviders: CashoutDetailsProvider[];
  precheckEnabled: boolean;
  storeEnabled: boolean;
  allowNonIdentifiedUsersForRoles: CompanyRepresentativeRole[];
  hasAutoRegistration: boolean;
  canHideInstallmentOptions: boolean;
  b2bPaymentsEnabled: boolean;
  defaultCurrency: CurrencyCode;
  refundsEnabled: boolean;
  defaultSignupRoleId: string;
  lineItemPaymentsEnabled: boolean;

  market: Market;
  supportedCountryCodes: number[];
  automaticRefund: boolean;

  roles: MerchantRole[];
}

// Defines the expected structure of GUI customization data that may be sent with a
// UserDetailsResponse when calling /user/whoami.
export class GuiCustomizationDto {
  // replace terms/jargon used in the GUI for this merchant
  prFormTitleAlias: string; // replaces "Title" for payment requests
  prFormDescriptionAlias: string; // replaces "Description" for payment requests
  customCashOutReferenceIconSrc: string;
  termStore: string; // replaces "butik" (singular, indefinite)
  termTheStore: string; // replaces "butiken" (singular, definite)
  termStores: string; // replaces "butiker" (plural, indefinite)
  termTheStoreGenitive: string; // replaces "butikens" (singular, definite, genitive)
  toggleInstallmentSettings?: ToggleInstallmentSettings;
}

export interface ToggleInstallmentSettings {
  termToggleInstallments?: string;
  trueMeansShowInstallments?: boolean;
}

export interface MerchantRole {
  displayOrder: number;
  role: CompanyRepresentativeRole;
}

export enum RoleColor {
  BLUE = 'BLUE',
  CERISE = 'CERISE',
  GREEN = 'GREEN',
  ORANGE = 'ORANGE',
}

export enum CompanyStructure {
  REGULAR = 'REGULAR',
  SOLE_TRADER = 'SOLE_TRADER',
}

export enum CompanyStatus {
  SIGNED_UP = 'SIGNED_UP',
  APPROVED = 'APPROVED',
  UNAPPROVED = 'UNAPPROVED',
}

export enum CashoutDetailsProvider {
  RIDE = 'RIDE',
  TINK = 'TINK',
  MANUAL = 'MANUAL',
}

export interface UserSessionUpdateMessage {
  userDetails: UserDetails;
}

export interface PermissionWithParameters {
  permission: Permission;
  parameters: AuthorizationParameters;
}

// Parameters used for access control
export interface AuthorizationParameters {
  appliesToRoles: string[];
}

export enum Permission {
  GET_PAYMENT_REQUESTS = 'GET_PAYMENT_REQUESTS',
  CREATE_PAYMENT_REQUEST = 'CREATE_PAYMENT_REQUEST',
  CANCEL_PAYMENT_REQUEST = 'CANCEL_PAYMENT_REQUEST',
  MANAGE_ALL_PAYMENT_REQUESTS = 'MANAGE_ALL_PAYMENT_REQUESTS',

  GET_EVENTS = 'GET_EVENTS',

  GET_CASHOUT_ORDERS = 'GET_CASHOUT_ORDERS',
  CREATE_CASHOUT_ORDER = 'CREATE_CASHOUT_ORDER',
  CANCEL_CASHOUT_ORDER = 'CANCEL_CASHOUT_ORDER',
  APPROVE_CASHOUT_ORDER = 'APPROVE_CASHOUT_ORDER',
  APPROVE_OWN_CASHOUT_ORDER = 'APPROVE_OWN_CASHOUT_ORDER',
  EXECUTE_CASHOUT_ORDER = 'EXECUTE_CASHOUT_ORDER',
  EXECUTE_OWN_APPROVED_CASHOUT_ORDER = 'EXECUTE_OWN_APPROVED_CASHOUT_ORDER',
  EDIT_CASHOUT_ORDER_AMOUNT = 'EDIT_CASHOUT_ORDER_AMOUNT',

  GET_USERS = 'GET_USERS',
  CREATE_USER = 'CREATE_USER',

  // CREATE_CAPTURE = 'CREATE_CAPTURE',

  // The following 4 permissions are deprecated
  CREATE_USER_REPRESENTATIVE = 'CREATE_USER_REPRESENTATIVE',
  CREATE_USER_DISBURSEMENT_INITIATOR = 'CREATE_USER_DISBURSEMENT_INITIATOR',
  CREATE_USER_ADMIN = 'CREATE_USER_ADMIN',
  CREATE_USER_SIGNATORY = 'CREATE_USER_SIGNATORY',
  //

  EDIT_USER = 'EDIT_USER',

  // The following 4 permissions are deprecated
  EDIT_USER_REPRESENTATIVE = 'EDIT_USER_REPRESENTATIVE',
  EDIT_USER_DISBURSEMENT_INITIATOR = 'EDIT_USER_DISBURSEMENT_INITIATOR',
  EDIT_USER_ADMIN = 'EDIT_USER_ADMIN',
  EDIT_USER_SIGNATORY = 'EDIT_USER_SIGNATORY',
  //

  GET_STORES = 'GET_STORES',
  CREATE_STORE = 'CREATE_STORE',
  EDIT_STORE = 'EDIT_STORE',
  REMOVE_STORE = 'REMOVE_STORE',

  GET_REFUNDS = 'GET_REFUNDS',
  CREATE_REFUND = 'CREATE_REFUND',
  APPROVE_REFUND = 'APPROVE_REFUND',
  APPROVE_OWN_REFUND = 'APPROVE_OWN_REFUND',
  CANCEL_REFUND = 'CANCEL_REFUND',

  GET_REPORTS = 'GET_REPORTS',

  CREATE_PRECHECK = 'CREATE_PRECHECK',

  CREATE_CAPTURE = 'CREATE_CAPTURE',

  GET_OWNER_INFORMATION = 'GET_OWNER_INFORMATION',
  EDIT_OWNER_INFORMATION = 'EDIT_OWNER_INFORMATION',

  GET_COMPANY_INFORMATION = 'GET_COMPANY_INFORMATION',
  EDIT_COMPANY_INFORMATION = 'EDIT_COMPANY_INFORMATION',

  GET_COMPANY_AGREEMENT = 'GET_COMPANY_AGREEMENT',

  EDIT_COMPANY_NAME = 'EDIT_COMPANY_NAME',

  GET_KYC = 'GET_KYC',
  EDIT_KYC = 'EDIT_KYC',

  GET_BANK_INFORMATION = 'GET_BANK_INFORMATION',
  EDIT_BANK_INFORMATION = 'EDIT_BANK_INFORMATION',

  GET_COMMUNICATION_SETTINGS = 'GET_COMMUNICATION_SETTINGS',
  EDIT_COMMUNICATION_SETTINGS = 'EDIT_COMMUNICATION_SETTINGS',

  GET_ZFB_MERCHANT_SETTINGS = 'GET_ZFB_MERCHANT_SETTINGS',
  EDIT_ZFB_MERCHANT_SETTINGS = 'EDIT_ZFB_MERCHANT_SETTINGS',
}
