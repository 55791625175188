import { Component, Input, OnInit } from '@angular/core';
import {
  View,
  WidgetContext,
} from '../payment-management/payment-management.interfaces';

@Component({
  selector: 'app-widget-header',
  templateUrl: './widget-header.component.html',
  styleUrls: ['./widget-header.component.scss'],
})
export class WidgetHeaderComponent implements OnInit {
  @Input() setView: (view: View) => void;
  @Input() text: string = '';
  @Input() imgSrc: string = '';
  @Input() showError: boolean = false;
  @Input() context: WidgetContext;
  @Input() urlNavigate: boolean = false;

  public exitIconImgUrl: string = '';
  public VIEW = View;
  public WIDGETCONTEXT = WidgetContext;
  constructor() {}

  ngOnInit(): void {
    if (this.context === WidgetContext.ZFB) {
      this.exitIconImgUrl = 'assets/icons/Icon-Navigation-Arrow-left.svg';
    } else {
      this.exitIconImgUrl = 'assets/icons/Icon-Navigation-Exit-Close-Black.svg';
    }
  }

  navigateToOldUrl(): void {
    history.back();
  }
}
