import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HrefService } from '@core/service/href.service';
import { Router } from '@angular/router';
import { RestBase } from '@core/rest-base';
import { UserDetails } from '@core/dto/user-details';

export interface TokenResponse {
  errors: TokenVerificationError[];
  userDetails: UserDetails;
  authenticationSessionId: string;
  success: boolean;
}

export enum ZfbTokenType {
  PASSWORD_RESET = 'PASSWORD_RESET',
  SIGNATORY_IDENTIFICATION = 'SIGNATORY_IDENTIFICATION',
  USER_CREATION = 'USER_CREATION',
  EMAIL_ACCESS_RENEWAL = 'EMAIL_ACCESS_RENEWAL',
  TWO_FACTOR_AUTHENTICATION = 'TWO_FACTOR_AUTHENTICATION',
}

export enum TokenVerificationError {
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  TOKEN_INVALID = 'TOKEN_INVALID',
}

@Injectable({
  providedIn: 'root',
})
export class VerificationTokenService extends RestBase {
  private _token: string;
  private _email: string;
  private _tokenType: ZfbTokenType;

  private TOKEN_STORAGE_KEY = 'zfb_verfication_token';
  private EMAIL_STORAGE_KEY = 'zfb_verfication_email';
  private TYPE_STORAGE_KEY = 'zfb_verificationtoken_type';

  private get token(): string {
    return this._token
      ? this._token
      : sessionStorage.getItem(this.TOKEN_STORAGE_KEY);
  }

  public setToken(token: string, type: ZfbTokenType) {
    this.token = token;
    this._tokenType = type;
  }

  set tokenType(value: ZfbTokenType) {
    sessionStorage.setItem(this.TYPE_STORAGE_KEY, value);
    this._tokenType = value;
  }

  get tokenType(): ZfbTokenType {
    return ZfbTokenType[
      this._tokenType
        ? this._tokenType
        : sessionStorage.getItem(this.TYPE_STORAGE_KEY)
    ];
  }

  private set token(value: string) {
    sessionStorage.setItem(this.TOKEN_STORAGE_KEY, value);
    this._token = value;
  }

  get email(): string {
    return this._email
      ? this._email
      : sessionStorage.getItem(this.EMAIL_STORAGE_KEY);
  }

  set email(value: string) {
    sessionStorage.setItem(this.EMAIL_STORAGE_KEY, value);
    this._email = value;
  }

  constructor(
    httpClient: HttpClient,
    hrefService: HrefService,
    protected router: Router
  ) {
    super(httpClient, hrefService.getApiUrl());
  }

  verifyToken(
    tokenType: ZfbTokenType,
    trustThisDevice: boolean
  ): Promise<TokenResponse> {
    const endpoint =
      tokenType === ZfbTokenType.EMAIL_ACCESS_RENEWAL
        ? '/auth/password/confirmEmail'
        : '/auth/password/checkToken';
    return super.post(
      endpoint,
      JSON.stringify({
        token: this.token,
        username: this.email,
        tokenType: tokenType,
        trustThisDevice: trustThisDevice,
      })
    );
  }

  resendToken(username: string, tokenType: ZfbTokenType): Promise<boolean> {
    // password reset token generation is idempotent if there's sufficient validity time remaining on the token
    return super.post(
      '/auth/password/resendToken',
      JSON.stringify({ username: username, tokenType: tokenType })
    );
  }

  resetPassword(username: string): Promise<boolean> {
    return super.post(
      '/auth/password/reset',
      JSON.stringify({ username: username })
    );
  }

  identifySignatory(email: any) {
    return super.post('/auth/signup/newCompany/signatoryUser', email);
  }

  getToken() {
    return this.token;
  }
}
