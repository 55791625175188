import { Injectable } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  LineItemDomain,
  LineItemJson,
  RefundView,
  WidgetFixedAmountRefunds,
  WidgetFixedAmountRefundsVM,
  WidgetRefundResponse,
  WidgetRefundResponseVM,
} from '@core/service/payment-capture.service';
import {
  LineItemVM,
  SimpleLineItemVM,
} from '../components/payment-management/payment-management.interfaces';

export interface RefundableLineItem {
  id: string;
  title: string;
  editMode: boolean;
  maxQuantity: number;
  unitPrice: number;
  totalAmount: number;
  taxRatePercent: number;
  taxAmount: number;
  form: FormGroup;
}

@Injectable()
export class PaymentManagementService {
  constructor() {}

  toRefundableLineItem = (
    lineItems: LineItemDomain[]
  ): RefundableLineItem[] => {
    return lineItems.map((lineItem) => {
      return {
        id: lineItem.id,
        title: lineItem.title,
        editMode: false,
        maxQuantity: lineItem.quantity,
        unitPrice: lineItem.unitPrice,
        totalAmount: lineItem.totalAmount,
        taxAmount: lineItem.taxAmount,
        taxRatePercent: lineItem.taxRatePercent,
        form: new FormGroup({
          checked: new FormControl(true),
          quantity: new FormControl(lineItem.quantity, [
            Validators.required,
            Validators.min(1),
            Validators.max(lineItem.quantity),
            this.onlyNumbersValidator,
          ]),
          toRefund: new FormControl(lineItem.totalAmount, [
            Validators.required,
            Validators.max(lineItem.totalAmount),
            this.onlyNumbersValidator,
          ]),
        }),
      };
    });
  };

  toLineItemVM = (lineItems: LineItemDomain[]): LineItemVM[] => {
    return lineItems.map((lineItem: LineItemDomain): LineItemVM => {
      const { quantity, captureState, totalAmount, unitPrice, taxRatePercent } =
        lineItem;

      const capturedQuantity: number = captureState.reduce(
        (tot: number, item: LineItemDomain) => tot + item.quantity,
        0
      );

      const capturedAmount: number = captureState.reduce(
        (tot: number, item: LineItemDomain) => tot + item.totalAmount,
        0
      );

      const quantityAvailableForCapture: number = Math.max(
        quantity - capturedQuantity,
        1
      );

      const calculatedUnitPrice: number =
        quantity !== 1 ? unitPrice : totalAmount - capturedAmount;

      const lineItemIsPartiallyCaptured: boolean = capturedQuantity > 0;

      return {
        id: lineItem.id,
        editMode: lineItem.id === null ? true : false,
        titleEditable: !lineItem.title,
        initialQuantity: quantity,
        taxAmount: lineItem.taxAmount,
        totalAmount: this.calcTotalAmount(
          calculatedUnitPrice,
          quantityAvailableForCapture
        ),
        maxQuantity: quantityAvailableForCapture,
        maxUnitPrice: unitPrice,
        taxAmountEditable: !lineItemIsPartiallyCaptured,
        form: new FormGroup({
          description: new FormControl(lineItem.description),
          title: new FormControl(lineItem.title),
          checked: new FormControl(true),
          quantity: new FormControl(quantityAvailableForCapture, [
            Validators.required,
            Validators.min(1),
            Validators.max(quantityAvailableForCapture),
            this.onlyNumbersValidator,
          ]),
          unitPrice: new FormControl(calculatedUnitPrice, [
            Validators.required,
            this.onlyNumbersValidator,
          ]),
          taxRatePercent: new FormControl(
            lineItemIsPartiallyCaptured
              ? captureState[0].taxRatePercent
              : taxRatePercent,
            [this.onlyNumbersValidator]
          ),
        }),
      };
    });
  };

  getRefundableLineItems(lineItems: LineItemDomain[]): LineItemDomain[] {
    const capturedLineItems: LineItemDomain[] = lineItems.filter((lineItem) => {
      const hasCaptureState = lineItem;
      const isDeleted = lineItem.deleted;
      const hasQuantityInCaptureState =
        hasCaptureState &&
        lineItem.captureState.some((item) => item.quantity > 0);
      const refundedAmount = lineItem.refundState.reduce(
        (acc, item) => acc + item.totalAmount,
        0
      );
      const refundableAmount = lineItem.totalAmount - refundedAmount;
      const isNotFullyRefunded = refundableAmount !== 0;
      return (
        !isDeleted &&
        hasCaptureState &&
        hasQuantityInCaptureState &&
        isNotFullyRefunded
      );
    });

    const test = capturedLineItems.reduce((acc, lineItem) => {
      return acc.concat(
        lineItem.captureState.map((capturedLineItem: LineItemDomain) => {
          const refundedQuantity = lineItem.refundState.reduce(
            (acc, item) => acc + item.quantity,
            0
          );

          const refundableQuantity = Math.max(
            capturedLineItem.quantity - refundedQuantity,
            1
          );
          const refundedAmount = lineItem.refundState.reduce(
            (acc, item) => acc + item.totalAmount,
            0
          );

          const refundableAmount =
            capturedLineItem.totalAmount - refundedAmount;
          const newLineItemDomain: LineItemDomain = {
            deleted: false,
            description: lineItem.description,
            id: lineItem.id,
            itemType: lineItem.itemType,
            merchantMetadata: lineItem.merchantMetadata,
            originalState: null,
            quantity: refundableQuantity,
            quantityUnit: lineItem.quantityUnit,
            taxAmount: capturedLineItem.taxAmount,
            taxRatePercent: capturedLineItem.taxRatePercent,
            title: lineItem.title,
            totalAmount: refundableAmount,
            unitPrice: refundableAmount / refundableQuantity,
          };

          return newLineItemDomain;
        })
      );
    }, []);

    return test;
  }

  getCapturedLineItems = (lineItems: LineItemDomain[]): SimpleLineItemVM[] => {
    const capturedLineItems = lineItems.filter((lineItem) => {
      const hasCaptureState = lineItem.captureState;
      const isDeleted = lineItem.deleted;
      const hasQuantityInCaptureState =
        hasCaptureState &&
        lineItem.captureState.some((item) => item.quantity > 0);
      return !isDeleted && hasCaptureState && hasQuantityInCaptureState;
    });

    const simpleCapturedLineItems: SimpleLineItemVM[] =
      capturedLineItems.reduce(
        (
          accumulator: SimpleLineItemVM[],
          lineItem: LineItemDomain
        ): SimpleLineItemVM[] =>
          accumulator.concat(
            lineItem.captureState.map((capturedItem: LineItemDomain) => {
              const { title, captureState, taxRatePercent } = lineItem;
              let quantity: string | number = capturedItem.quantity;
              if (captureState.length > 1 && lineItem.quantity === 1) {
                quantity = 'N/A';
              }

              return {
                title: title,
                quantity: quantity,
                unitPrice: capturedItem.unitPrice,
                taxRatePercent:
                  captureState.reduce(
                    (acc: number, item: LineItemDomain) =>
                      acc + item.totalAmount,
                    0
                  ) > 0
                    ? captureState[0].taxRatePercent
                    : taxRatePercent,
                taxAmount: capturedItem.taxAmount,
                totalAmount: capturedItem.totalAmount,
              };
            })
          ),
        []
      );

    return simpleCapturedLineItems;
  };

  getCapturableLineItems(lineItems: LineItemDomain[]): LineItemDomain[] {
    const filteredLineItems = lineItems.filter((item: LineItemDomain) => {
      const capturedQuantity = item.captureState.reduce(
        (acc, item) => acc + item.quantity,
        0
      );
      const capturedTotalAmount = item.captureState.reduce(
        (acc, item) => acc + item.totalAmount,
        0
      );
      const { deleted, quantity, totalAmount } = item;

      const quantityRemaining = quantity > capturedQuantity;
      // if initial quantity is 1 you can continue to capture until you have reached the totalAmount
      const amountRemaining =
        quantity === 1 && totalAmount > 0 && totalAmount > capturedTotalAmount;

      return !deleted && (quantityRemaining || amountRemaining);
    });

    return filteredLineItems;
  }

  getUpdatedLineItems = (lineItems: LineItemDomain[]): SimpleLineItemVM[] => {
    return lineItems
      .filter((item: LineItemDomain) => {
        const isDeleted = item.deleted;
        const isQuantityChanged = item.quantity !== item.originalState.quantity;
        const isTotalAmountChanged =
          item.totalAmount !== item.originalState.totalAmount;

        const isPartiallyDeleted =
          item.quantity === 1 &&
          item.captureState.reduce(
            (tot: number, item: LineItemDomain) => tot + item.totalAmount,
            0
          ) > 0;

        return (
          !isPartiallyDeleted &&
          (isDeleted || isQuantityChanged || isTotalAmountChanged)
        );
      })
      .map((lineItem: LineItemDomain): SimpleLineItemVM => {
        const {
          originalState,
          quantity,
          totalAmount,
          unitPrice,
          taxRatePercent,
          deleted,
        } = lineItem;

        const deletedQuantity = deleted
          ? originalState.quantity
          : Math.max(originalState.quantity - quantity, 1);
        const updatedTotalAmount = deleted
          ? originalState.totalAmount
          : originalState.totalAmount - totalAmount;
        const updatedUnitPrice =
          deleted || originalState.unitPrice === unitPrice
            ? unitPrice
            : originalState.unitPrice - unitPrice;

        const unitPriceExcludingTax = this.calcUnitPriceExcludingTax(
          updatedUnitPrice,
          taxRatePercent
        );
        const updatedTaxAmount = deleted
          ? originalState.taxAmount
          : this.calcTaxAmount(
              unitPriceExcludingTax,
              deletedQuantity,
              taxRatePercent
            );

        return {
          title: originalState.title,
          quantity: deletedQuantity,
          unitPrice: updatedUnitPrice,
          taxRatePercent,
          taxAmount: updatedTaxAmount,
          totalAmount: updatedTotalAmount,
        };
      });
  };

  toLineItemDto = (lineItemVM: LineItemVM[]): LineItemDomain[] => {
    return lineItemVM.map((item: LineItemVM) => {
      const { description, title, quantity, unitPrice, taxRatePercent } =
        item.form.value;
      return {
        description: description,
        id: item.id,
        title: title,
        totalAmount: Number(item.totalAmount),
        taxAmount: Number(item.taxAmount),
        quantity: Number(quantity),
        unitPrice: Number(unitPrice),
        taxRatePercent: Number(taxRatePercent),
        itemType: null,
        merchantMetadata: null,
        merchantReference: '',
        quantityUnit: '',
        deleted: false,
        originalState: null,
      };
    });
  };

  refundLineItemtoLineItemDto = (
    lineItemVM: RefundableLineItem[]
  ): LineItemDomain[] => {
    return lineItemVM.map((item: RefundableLineItem) => {
      const { description, title, quantity, unitPrice, taxRatePercent } =
        item.form.value;
      return {
        description: description,
        id: item.id,
        title: title,
        totalAmount: Number(item.totalAmount),
        taxAmount: Number(item.taxAmount),
        quantity: Number(quantity),
        unitPrice: Number(unitPrice),
        taxRatePercent: Number(taxRatePercent),
        itemType: null,
        merchantMetadata: null,
        merchantReference: '',
        quantityUnit: '',
        deleted: false,
        originalState: null,
      };
    });
  };

  calcTaxAmount = (
    unitPriceExcludingTax: number,
    quantity: number,
    taxRatePercent: number
  ): number => {
    return unitPriceExcludingTax * (taxRatePercent / 100) * quantity;
  };

  calcTotalAmount = (unitPrice: number, quantity: number): number => {
    return this.toTwoDecimals(unitPrice * quantity);
  };

  calcUnitPriceExcludingTax = (
    unitPrice: number,
    taxRatePercent: number
  ): number => {
    return unitPrice / ((100 + taxRatePercent) / 100);
  };

  getUpdatedLineItemsFromVMs = (
    lineItems: LineItemDomain[],
    lineItemVMs: LineItemVM[]
  ): LineItemDomain[] => {
    const updatedLineItems = lineItems
      .filter((lineItem) => !lineItem.deleted)
      .map((lineItem: LineItemDomain) => {
        const matchingLineItemVM = lineItemVMs.find(
          (lineItemVM) => lineItemVM.id === lineItem.id
        );
        return matchingLineItemVM
          ? this.getUpdatedLineItemIfChanged(matchingLineItemVM)
          : lineItem;
      });
    return updatedLineItems.filter((item) => item !== null);
  };

  getUpdatedLineItemIfChanged = (
    lineItemFormObjects: LineItemVM
  ): LineItemDomain => {
    const { maxUnitPrice, form, initialQuantity } = lineItemFormObjects;
    const { description, title, quantity, unitPrice, taxRatePercent } =
      form.value;

    if (unitPrice === maxUnitPrice && quantity === initialQuantity) {
      // This means that we won't include lineItem when sending it to backend and it will get deleted: true
      return null;
    }

    return {
      description: description,
      id: lineItemFormObjects.id,
      title: title,
      totalAmount: Number(lineItemFormObjects.totalAmount),
      taxAmount: Number(lineItemFormObjects.taxAmount),
      quantity: Number(quantity),
      unitPrice: Number(unitPrice),
      taxRatePercent: taxRatePercent,
      itemType: null,
      merchantMetadata: null,
      quantityUnit: '',
      deleted: false,
      originalState: null,
    };
  };

  onlyNumbersValidator(): ValidatorFn {
    return (control: FormControl) => {
      const value = control.value;

      if (value === null || value === undefined || value === '') {
        return null; // Allow empty value
      }

      return isNaN(+value) ? { onlyNumbers: true } : null;
    };
  }

  toTwoDecimals = (amount: number) => {
    return Math.round(amount * 100) / 100;
  };

  getRefundedLineItems(lineItems: LineItemDomain[]): SimpleLineItemVM[] {
    const lineItemsWithRefundState = lineItems.filter(
      (lineItem) => lineItem.refundState?.length > 0
    );
    const refundLineItems = lineItemsWithRefundState.reduce((acc, lineItem) => {
      lineItem.refundState.map(
        (refundLineItem) => (refundLineItem.title = lineItem.title)
      );
      return acc.concat(lineItem.refundState);
    }, [] as LineItemDomain[]);

    const refundLineItemsVM: SimpleLineItemVM[] = refundLineItems.map(
      (refundLineItem: LineItemDomain) => ({
        quantity: refundLineItem.quantity,
        taxAmount: refundLineItem.taxAmount,
        taxRatePercent: refundLineItem.taxRatePercent,
        title: refundLineItem.title,
        totalAmount: refundLineItem.totalAmount,
        unitPrice: refundLineItem.unitPrice,
      })
    );
    return refundLineItemsVM;
  }

  getRefundedAmount = (
    lineItems: LineItemDomain[],
    fixedAmountRefunds: WidgetFixedAmountRefunds[]
  ): number => {
    const refundedLineItems = this.getRefundedLineItems(lineItems);
    const lineItemsRefundAmount = refundedLineItems.reduce(
      (acc, item) => acc + item.totalAmount,
      0
    );
    const refundedFixedAmount = fixedAmountRefunds.reduce(
      (acc, item) => acc + item.amount,
      0
    );
    return lineItemsRefundAmount + refundedFixedAmount;
  };

  getWidgetLineItemRefundResponseVM = (
    refunds: WidgetRefundResponse[]
  ): WidgetRefundResponseVM[] => {
    if (!refunds) {
      return null;
    }
    return refunds.map((refund) => ({
      ...refund,
      refundView: RefundView.DEFAULT,
    }));
  };

  getWidgetFixedAMountRefundResponseVM = (
    refunds: WidgetFixedAmountRefunds[]
  ): WidgetFixedAmountRefundsVM[] => {
    if (!refunds) {
      return null;
    }
    return refunds.map((refund) => ({
      ...refund,
      refundView: RefundView.DEFAULT,
    }));
  };

  fixedAmountToSimpleLineItem(
    fixedAmountRefund: WidgetFixedAmountRefunds[]
  ): SimpleLineItemVM[] {
    return fixedAmountRefund.map((refund) => ({
      taxAmount: refund.taxAmount,
      taxRatePercent: refund.taxRatePercent,
      title: refund.description,
      // set quantity to 1 and totalamount and unitprice to refund amount
      quantity: 1,
      totalAmount: refund.amount,
      unitPrice: refund.amount,
    }));
  }

  fromLineItemJsontoSimpleLineItem(
    lineItemsJson: LineItemJson[]
  ): SimpleLineItemVM[] {
    return lineItemsJson.map((item) => {
      return {
        title: item.title,
        quantity: item.quantity,
        taxAmount: item.taxAmount,
        totalAmount: item.totalAmount,
        unitPrice: item.unitPrice,
        taxRatePercent: item.taxRatePercent,
      };
    });
  }
}
