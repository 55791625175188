import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-navigation-tab',
  templateUrl: './navigation-tab.component.html',
  styleUrls: ['./navigation-tab.component.css']
})
export class NavigationTabComponent implements OnInit {

  @Input() text: string;
  @Input() badgeNumber;
  @Input() active = false;

  @Output() clicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
