import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AccessControlService } from '@core/service/access-control.service';
import { ComponentCanDeactivate } from 'app/zfb/guard/pending-changes.guard';
import { Observable, Subject } from 'rxjs';
import { BasicQuestionsFormComponent } from '@zfb/page/signup/basic-questions-form/basic-questions-form.component';
import { DetailedQuestionsFormComponent } from '@zfb/page/signup/detailed-questions-form/detailed-questions-form.component';
import { Permission } from '@core/dto/user-details';
import { ReportRecipientComponent } from './communication/report-recipient/report-recipient.component';
import { AdminZfbMerchantSettings } from '../zfb-merchant-settings/zfb-merchant-settings';
import { ColorService } from '@core/service/color.service';
import { PageTab } from '@zfb/ui/page-tabs/page-tabs.component';

enum SettingsPage {
  COMPANY_INFORMATION = 'COMPANY_INFORMATION',
  OWNER_INFORMATION = 'OWNER_INFORMATION',
  COMMUNICATION = 'COMMUNICATION',
  GENERAL = 'GENERAL',
}
@Component({
  selector: 'app-company-adminstration',
  templateUrl: './company-adminstration.component.html',
  styleUrls: ['../page-shared.scss', './company-adminstration.component.css'],
})
export class CompanyAdminstrationComponent
  implements OnInit, ComponentCanDeactivate
{
  isLegalEntity = false;
  SettingsPage = SettingsPage;
  activePage = SettingsPage.COMPANY_INFORMATION;
  tabs: PageTab<SettingsPage>[];

  basicQuestionsDoneFetching = false;
  signupQuestionsDoneFetching = false;
  reportRecipientsDoneFetching = false;
  zfbMerchantSettingsDoneFetching = false;

  modalActive = false;
  canDeactivateSubject: Subject<boolean>;

  @ViewChild('companyInfo') companyInfo!: BasicQuestionsFormComponent;
  @ViewChild('kycAndBankInfo') kycAndBankInfo!: DetailedQuestionsFormComponent;
  @ViewChild('ownerInfo') ownerForm!: BasicQuestionsFormComponent;
  @ViewChild('reportRecipient') reportRecipient: ReportRecipientComponent;
  @ViewChild('zfbMerchantSettings')
  zfbMerchantSettings: AdminZfbMerchantSettings;

  constructor(
    private access: AccessControlService,
    public colorService: ColorService
  ) {}

  @HostListener('window:beforeunload')
  onLeave() {
    switch (this.activePage) {
      case SettingsPage.OWNER_INFORMATION:
        if (
          this.ownerForm.companyForm &&
          this.ownerForm.companyForm.formChanged
        ) {
          return false;
        }
        break;
      case SettingsPage.COMPANY_INFORMATION:
        if (this.isLegalEntity) {
          if (
            this.companyInfo.companyForm &&
            (this.companyInfo.companyForm.formChanged ||
              this.kycAndBankInfo.kycFormChanged)
          ) {
            return false;
          }
        } else {
          if (
            this.companyInfo.soloTraderForm &&
            (this.companyInfo.soloTraderForm.formChanged ||
              this.kycAndBankInfo.kycFormChanged)
          ) {
            return false;
          }
        }
        break;
      case SettingsPage.COMMUNICATION:
        if (
          this.reportRecipient.cashoutFormChanged ||
          this.reportRecipient.checkoutFormsChanged
        ) {
          return false;
        }
        break;
      case SettingsPage.GENERAL:
        if (this.zfbMerchantSettings.formChanged) {
          return false;
        }
        break;
      default:
        return true;
    }
  }

  canDeactivate(): boolean | Observable<boolean> {
    const ownerInformationFormChanged =
      this.activePage === SettingsPage.OWNER_INFORMATION &&
      this.isLegalEntity &&
      this.ownerForm.companyForm &&
      this.ownerForm.companyForm.formChanged;

    const companyInformationFormChanged =
      this.activePage === SettingsPage.COMPANY_INFORMATION &&
      ((this.isLegalEntity &&
        this.companyInfo.companyForm &&
        this.companyInfo.companyForm.formChanged) ||
        (!this.isLegalEntity &&
          this.companyInfo.soloTraderForm &&
          this.companyInfo.soloTraderForm.formChanged) ||
        this.kycAndBankInfo.kycFormChanged);

    const communicationFormChanged =
      this.activePage === SettingsPage.COMMUNICATION &&
      (this.reportRecipient.cashoutFormChanged ||
        this.reportRecipient.checkoutFormsChanged);

    const generalFormChanged =
      this.activePage === SettingsPage.GENERAL &&
      this.zfbMerchantSettings.formChanged;

    if (
      ownerInformationFormChanged ||
      companyInformationFormChanged ||
      communicationFormChanged ||
      generalFormChanged
    ) {
      this.canDeactivateSubject = new Subject();
      this.modalActive = true;
      return this.canDeactivateSubject.asObservable();
    }
    return true;
  }

  action(value: boolean) {
    this.modalActive = false;
    this.canDeactivateSubject.next(value);
    this.canDeactivateSubject.complete();
  }

  ngOnInit() {}

  setupTabs() {
    this.tabs = [
      {
        value: SettingsPage.COMPANY_INFORMATION,
        label: $localize`:@@companyAdministration.tabs.companyInformation:Företagsinformation`,
      },
    ];
    if (this.mayUpdateCompanyOwnershipInfo() && this.isLegalEntity) {
      this.tabs.push({
        value: SettingsPage.OWNER_INFORMATION,
        label: $localize`:@@companyAdministration.tabs.ownerInformation:Ägarinformation`,
      });
    }
    if (this.mayUpdateCompanyCommunicationSettings()) {
      this.tabs.push({
        value: SettingsPage.COMMUNICATION,
        label: $localize`:@@companyAdministration.tabs.communication:Kommunikation`,
      });
    }
    if (this.mayViewzfbMerchantSettings()) {
      this.tabs.push({
        value: SettingsPage.GENERAL,
        label: $localize`:@@companyAdministration.tabs.general:Övrigt`,
      });
    }
  }

  setActivePage(page: SettingsPage) {
    if (page === this.activePage) {
      return;
    }

    const mayChangeTab = this.canDeactivate();
    if (typeof mayChangeTab === 'boolean') {
      if (mayChangeTab) {
        this.navigateToPage(page);
      }
    } else {
      mayChangeTab.subscribe((val) => {
        if (val) {
          this.navigateToPage(page);
        }
      });
    }
  }

  navigateToPage(page: SettingsPage) {
    switch (page) {
      case SettingsPage.COMPANY_INFORMATION:
        this.basicQuestionsDoneFetching = false;
        this.signupQuestionsDoneFetching = false;
        break;
      case SettingsPage.OWNER_INFORMATION:
        this.basicQuestionsDoneFetching = false;
        break;
      case SettingsPage.COMMUNICATION:
        this.reportRecipientsDoneFetching = false;
        break;
      case SettingsPage.GENERAL:
        this.zfbMerchantSettingsDoneFetching = false;
        break;
      default:
    }
    this.activePage = page;
  }

  mayUpdateCompanyOwnershipInfo(): boolean {
    return this.access.userMay(Permission.EDIT_OWNER_INFORMATION);
  }

  mayUpdateCompanyCommunicationSettings(): boolean {
    return (
      this.access.userMay(Permission.EDIT_COMMUNICATION_SETTINGS) &&
      this.access.userMay(Permission.GET_COMMUNICATION_SETTINGS)
    );
  }

  mayViewzfbMerchantSettings(): boolean {
    return this.access.userMay(Permission.GET_ZFB_MERCHANT_SETTINGS);
  }

  showSettingsTabs(): boolean {
    return (
      (this.mayUpdateCompanyOwnershipInfo() && this.isLegalEntity) ||
      this.mayUpdateCompanyCommunicationSettings()
    );
  }

  basicQuestionsDone() {
    this.basicQuestionsDoneFetching = true;
    this.setupTabs();
  }
}
