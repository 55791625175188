import { Component, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@core/service/authentication.service';

@Component({
  selector: 'app-oops',
  templateUrl: './oops.component.html',
  styleUrls: [
    '../login/login-pages.scss',
    './oops.component.css',
  ]
})
export class OopsComponent {

  @HostBinding('class')
  cssClasses = 'hide-footer';

  constructor(private router: Router, private authSvc: AuthenticationService) { }

  logout() {
    this.authSvc.logout();
  }

}
