import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@core/base.component';
import { AuthenticationService } from '@core/service/authentication.service';

@Component({
  selector: 'app-report-recipient-confirmation',
  templateUrl: './report-recipient-confirmation.component.html',
})
export class ReportRecipientConfirmationComponent
  extends BaseComponent
  implements OnInit
{
  @Output() onClose = new EventEmitter();
  @Output() onSubmit = new EventEmitter();

  @Input() serverError: boolean;
  @Input() errorMessage: string;
  @Input() submitting: boolean;

  serverErrorMessage =
    'Ändringarna kunde inte sparas. Vänligen försök igen eller avbryt genom att stänga. <a class="link" href="mailto:merchants@zaver.se" target="_blank">Kontakta&nbsp;oss</a> om problemet kvarstår.';

  constructor(auth: AuthenticationService) {
    super(auth);
  }

  ngOnInit(): void {}

  closeModal() {
    this.onClose.emit();
  }

  submit() {
    this.onSubmit.emit();
  }
}
