import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Market } from '@core/dto/Market';
import { AppStateService } from '@core/service/app-state.service';
import { Observable } from 'rxjs';

@Injectable()
export class MerchantMarketGuard implements CanActivateChild {
  constructor(
    private router: Router,
    private appState: AppStateService,
    @Inject(LOCALE_ID) public locale: string
  ) {}

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    let merchantMarket = this.appState.getMarket();
    switch (merchantMarket) {
      case Market.SE:
      case Market.NO:
        if (this.locale === 'sv') {
          return true;
        }
        break;
      case Market.DE:
        if (this.locale === 'de') {
          return true;
        }
        break;
      case Market.AT:
        if (this.locale === 'de-AT') {
          return true;
        }
        break;
    }
    merchantMarket = merchantMarket === Market.NO ? Market.SE : merchantMarket;
    switch (merchantMarket) {
      case Market.SE:
      case Market.DE:
      case Market.AT:
        location.href = '/' + merchantMarket.toLowerCase() + state.url;
        return false;
      default:
        console.error('Unsupported merchant market: ' + merchantMarket);
        return false;
    }
  }
}
