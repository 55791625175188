import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@core/base.component';
import { PaymentRequestResponse } from '@core/dto/PaymentRequestResponse';
import { AuthenticationService } from '@core/service/authentication.service';
import { 
  PaymentRequestService, 
  VirtualInvoiceReminderRequest, 
  VirtualInvoiceReminderResponse } from '@core/service/payment-request.service';

@Component({
  selector: 'app-confirm-payment-request-reminder',
  templateUrl: './confirm-payment-request-reminder.component.html',
  styleUrls: ['./confirm-payment-request-reminder.component.css']
})
export class ConfirmPaymentRequestReminderComponent extends BaseComponent implements OnInit {

  @Input() reminderRequest: VirtualInvoiceReminderRequest;
  @Input() paymentRequest: PaymentRequestResponse;

  confirmationInProgress = false;
  serverErrorMessage = 'Påminnelsen skickades inte. Vänligen försök igen eller avbryt genom att stänga. <a class="link" href="mailto:merchants@zaver.se" target="_blank">Kontakta&nbsp;oss</a> om problemet kvarstår.';

  @Output() onReturnToMainModal = new EventEmitter<VirtualInvoiceReminderRequest>();
  @Output() onCloseModal = new EventEmitter();

  
  constructor(
    private paymentRequestService: PaymentRequestService,
    auth: AuthenticationService,
  ) {
    super(auth);
   }

  ngOnInit(): void {
  }

  returnToMainModal() {
    this.onReturnToMainModal.emit(this.reminderRequest);
  }

  closeModal() {
    this.onCloseModal.emit();
  }

  sendReminder() {
    this.errorMessage = '';
    this.confirmationInProgress = true;
    this.paymentRequestService.createVirtualInvoiceReminder(this.reminderRequest)
    .then((response: VirtualInvoiceReminderResponse) => {
      if (response && response.status === 'SENT') {
        this.onCloseModal.emit();
      } else {
        this.serverError = true;
        this.confirmationInProgress = false;
      }
    })
    .catch(error => this.handleError(error));
  }


}
