<div class="representation-list">
  <div
    class="representation-list-item"
    *ngFor="let representation of availableRepresentations"
    (click)="selectProfile(representation)"
  >
    <div class="company">
      <div class="company-name">{{ representation.company.name }}</div>
      <div class="organization-number">
        {{ representation.company.organizationNumber | slice: 0:6 }}-{{
          representation.company.organizationNumber | slice: 6
        }}
      </div>
    </div>
    <img src="assets/chevron_right.svg" />
  </div>
</div>
