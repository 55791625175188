import {
  Component,
  HostListener,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { Item } from '../custom-select/custom-select.component';

@Component({
  selector: 'zaver-select-country',
  templateUrl: './select-country.component.html',
  styleUrls: ['./select-country.component.scss'],
})
export class SelectCountryComponent implements OnInit {
  @Output() itemSelected = new EventEmitter<Item>();
  @Input() buttonId: string;
  @Input() items: Item[];
  @Input() selectedItem: Item;
  @Input() isMobile: boolean;
  showDropDown = false;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (document.activeElement.id === this.buttonId) {
      if (event.code === 'ArrowDown' || event.code === 'ArrowUp') {
        this.toggleDropDown();
      }
    }
  }

  constructor() {}

  ngOnInit(): void {}

  toggleDropDown() {
    this.showDropDown = !this.showDropDown;
  }

  selected(item) {
    this.selectedItem = item;
    this.itemSelected.emit(item);
  }
}
