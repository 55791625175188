import { Component, OnInit } from '@angular/core';
import { Permission } from '@core/dto/user-details';
import { AccessControlService } from '@core/service/access-control.service';
import { AppStateService } from '@core/service/app-state.service';
import { CustomizationService } from '@core/service/customization.service';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.css'],
})
export class SubHeaderComponent implements OnInit {
  storeAliasName: string;
  isDeAtMarket: boolean = false;

  constructor(
    private customizationService: CustomizationService,
    private access: AccessControlService,
    private appStateService: AppStateService
  ) {}

  ngOnInit(): void {
    this.storeAliasName = this.customizationService.getStoreAlias(
      true,
      false,
      false,
      true
    );
    this.isDeAtMarket =
      this.appStateService.getMarket() === 'DE' ||
      this.appStateService.getMarket() === 'AT';
  }

  isApproved(): boolean {
    return this.appStateService.isApproved();
  }

  mayViewUsers(): boolean {
    return this.access.userMay(Permission.GET_USERS);
  }

  mayViewCompanyGeneralInfo(): boolean {
    return (
      this.access.userMay(Permission.GET_COMPANY_INFORMATION) &&
      !this.isDeAtMarket
    );
  }

  mayViewZfbMerchantSettings(): boolean {
    return this.access.userMay(Permission.GET_ZFB_MERCHANT_SETTINGS);
  }

  mayViewReport(): boolean {
    return this.access.userMay(Permission.GET_REPORTS);
  }

  mayViewStores() {
    return (
      this.access.userMay(Permission.GET_STORES) &&
      this.appStateService.merchantHasStoreAccess()
    );
  }

  mayViewRefunds() {
    return (
      this.access.userMay(Permission.GET_REFUNDS) &&
      this.appStateService.merchantHasRefundAccess()
    );
  }
}
