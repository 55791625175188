import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompanyRepresentative } from '@core/dto/CompanyRepresentative';
import { Permission } from '@core/dto/user-details';
import { AccessControlService } from '@core/service/access-control.service';
import { CustomizationService } from '@core/service/customization.service';

@Component({
  selector: 'app-view-company-representative',
  templateUrl: './view-company-representative.component.html',
  styleUrls: ['./view-company-representative.component.css'],
})
export class ViewCompanyRepresentativeComponent implements OnInit {
  @Input() representative: CompanyRepresentative;

  @Output() editClicked = new EventEmitter<CompanyRepresentative>();

  constructor(
    public access: AccessControlService,
    private customizationService: CustomizationService
  ) {}

  ngOnInit(): void {}

  getStoreAliasCapitalized(): string {
    return this.customizationService.getStoreAlias(false, true, true, true);
  }

  getStoreAlias(): string {
    return this.customizationService.getStoreAlias(false, false);
  }

  mayEditUser() {
    return (
      this.access.userMay(Permission.EDIT_USER) &&
      this.access.mayEditUser(this.representative)
    );
  }
}
