import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, Validators } from '@angular/forms';
import { SignupService } from '@core/service/signup.service';
import { b2bOrganizationNumberValidator } from '@shared/validators/identification-number-validators';
import { FormValidators } from '@shared/validators/validators';

@Component({
  selector: 'app-company-owner-legal',
  templateUrl: './company-owner-legal.component.html',
  styleUrls: [
    './company-owner-legal.component.css']
})
export class CompanyOwnerLegalComponent implements OnInit, OnDestroy {

  @Input() showError: boolean;
  @Input() nonOwner: boolean;
  @Input() displayingInCompanyAdminPage: boolean;

  correctOrganizationNumber = false;
  startOrganizationNumberValidation = false;
  companyNamePlaceholder = 'Hämtas från org.nr.';
  displayCompanyNotFoundError = false;

  @Output() correctOrganizationNumberEmit = new EventEmitter();

  public formGroup: FormGroup;

  constructor(
      private controlContainer: ControlContainer,
      private signupService: SignupService) {
  }

  ngOnInit() {
    this.formGroup = <FormGroup>this.controlContainer.control;
    this.formGroup.addControl('name', new FormControl({value: '', disabled: true}, [Validators.required]));
    this.formGroup.addControl('organizationNumber', new FormControl('', [Validators.required, b2bOrganizationNumberValidator()]));
    this.formGroup.addControl('legalEntityId', new FormControl(''));
    if (!this.nonOwner) {
      this.formGroup.addControl('holdingShare', new FormControl('', [Validators.required, FormValidators.numberMultipleDecimals(0, 100)]));
      this.formGroup.addControl('votesShare', new FormControl('', [Validators.required, FormValidators.numberMultipleDecimals(0, 100)]));
    }
    this.onChanges();
    if (this.formGroup.controls['name'].value !== '') {
      this.correctOrganizationNumber = true;
      this.correctOrganizationNumberEmit.emit();
    }
  }

  onChanges() {
    this.formGroup.controls['organizationNumber'].valueChanges.subscribe( val => {
      let organizationNumber: string = this.formGroup.controls['organizationNumber'].value;
      organizationNumber = organizationNumber.replace(/-/g, '');
      if (organizationNumber.length >= 10) {
        this.startOrganizationNumberValidation = true;
      }
      if (this.formGroup.controls['organizationNumber'].valid) {
        let companyNumberToSend = this.formGroup.controls['organizationNumber'].value;
        companyNumberToSend = companyNumberToSend.replace(/-/g, '');
        this.signupService.getCompanyInfo(companyNumberToSend)
          .then(res => {
            if (!!res.error) {
              if (res.error === 'COMPANY_NOT_FOUND') {
                this.displayCompanyNotFoundError = true;
              } else if (res.error === 'AWAITING_LOOKUP_RESPONSE') {
                this.displayCompanyNotFoundError = false;
                return;
              }
              this.formGroup.controls['name'].setValue('', {emitEvent: false});
              this.formGroup.controls['legalEntityId'].setValue('', {emitEvent: false});
              this.formGroup.controls['name'].enable();
              this.companyNamePlaceholder = 'Ange företagsnamn';
            } else {
              this.formGroup.controls['name'].disable();
              this.displayCompanyNotFoundError = false;
              this.companyNamePlaceholder = 'Hämtas från org.nr.';
              this.formGroup.controls['name'].setValue(res.companyName, {emitEvent: false});
              this.formGroup.controls['legalEntityId'].setValue(res.legalEntityId, {emitEvent: false});
            }
            this.correctOrganizationNumber = true;
            this.correctOrganizationNumberEmit.emit();
          })
          .catch(err => {
            console.error(err);
            this.formGroup.controls['name'].enable();
            this.companyNamePlaceholder = 'Ange företagsnamn';
            this.displayCompanyNotFoundError = false;
            this.correctOrganizationNumber = true;
            this.correctOrganizationNumberEmit.emit();
          });
      } else {
        this.formGroup.controls['name'].setValue('', {emitEvent: false});
        this.formGroup.controls['legalEntityId'].setValue('', {emitEvent: false});
        this.formGroup.controls['name'].disable();
        this.displayCompanyNotFoundError = false;
        this.companyNamePlaceholder = 'Hämtas från org.nr.';

      }
    });
  }

  ngOnDestroy() {
    this.formGroup.removeControl('name');
    this.formGroup.removeControl('organizationNumber');
    this.formGroup.removeControl('legalEntityId');
    
    if (!this.nonOwner) {
      this.formGroup.removeControl('holdingShare');
      this.formGroup.removeControl('votesShare');
    }
  }
}
