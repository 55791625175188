<button
  (click)="onClick($event)"
  class="button-link"
  (click)="($event)"
  [ngClass]="{
    pressed: buttonPressedState,
    black: color === Color.Black,
    hasIcon: iconUrl
  }"
>
  {{ text }} <img *ngIf="iconUrl" [src]="iconUrl" alt="open link icon" />
</button>
