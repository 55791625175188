import localeSv from '@angular/common/locales/sv';
import localeDe from '@angular/common/locales/de';
import localeAt from '@angular/common/locales/de-AT';
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
  registerLocaleData,
  CurrencyPipe,
  CommonModule,
} from '@angular/common';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { CookieModule } from 'ngx-cookie';
import { ZfbModule } from './zfb/zfb.module';
import { Router, RouterModule } from '@angular/router';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { Angulartics2Module } from 'angulartics2';
import { WsService } from './core/service/ws.service';
import { RxStompService } from '@stomp/ng2-stompjs';
import { ComponentLibraryModule } from '../../web-component-library/projects/component-library/src/lib/component-library.module';
// import {ComponentLibraryModule} from 'web-component-library';
import { ReactiveFormsModule } from '@angular/forms';
import { SearchPipe } from '@shared/pipe/search.pipe';
import * as Sentry from '@sentry/angular';
import { AmountFormatterPipe } from '@shared/pipe/amount-formatter.pipe';
import { PendingChangesGuard } from './zfb/guard/pending-changes.guard';
import { ValuePipe } from '@shared/pipe/value.pipe';
import { ZFBPaymentCaptureService } from '../../projects/widget/src/services/zfb-payment-capture-service.service';
import { PaymentCaptureService } from '@core/service/payment-capture.service';

registerLocaleData(localeSv);
registerLocaleData(localeDe);
registerLocaleData(localeAt);

@NgModule({
  declarations: [AppComponent],
  imports: [
    CookieModule.forRoot(),
    ZfbModule,
    RouterModule,
    Angulartics2Module.forRoot([Angulartics2GoogleAnalytics]),
    CommonModule,
    ComponentLibraryModule,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    CurrencyPipe,
    RxStompService,
    PendingChangesGuard,
    WsService,
    SearchPipe,
    AmountFormatterPipe,
    ValuePipe,
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: PaymentCaptureService, useClass: ZFBPaymentCaptureService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
