<div *ngIf="form" [ngSwitch]="currentStep">
  
  <app-create-refund-reason 
    *ngSwitchCase="'reason'" 
    [formGroup]="reasonForm" 
    (changeStep)="changeStep('reason', 'forward')">
  </app-create-refund-reason>

  <app-create-refund-amount 
    *ngSwitchCase="'amount'" 
    [paymentRequest]="paymentRequest"
    [formGroup]="amountForm" 
    (changeStep)="changeStep('amount', $event)">
  </app-create-refund-amount>

  <app-create-refund-confirm 
    *ngSwitchCase="'confirmation'"
    [form]="form"
    [paymentRequest]="paymentRequest"
    [errorMessage]="errorMessage"
    [submitting]="sendingRequest"
    (changeStep)="changeStep('confirmation', 'backwards')"
    (submit)="submit()">
  </app-create-refund-confirm>

</div>

