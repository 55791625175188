import { PrecheckRepresentations } from '@assets/language/PrecheckRepresentation';
import { Currency } from '../CurrencyCode';
import { PrecheckStatus } from './PrecheckStatus';

export class PrecheckSummary {
  id: string;
  phone: string;
  created: Date;
  value: Currency;
  status: PrecheckStatus;
  statusText: string;
  statusColor: string;
  backgroundColor: string
  email: string;
  atLeastOneGranted: boolean;
  grantedInvoice: boolean;
  personName: string;
  precheckUrl: string;
  summaryEntries: Array<PrecheckSummaryEntry>;

  static setup(precheckSummary: PrecheckSummary): PrecheckSummary {
    const statusInfo = PrecheckRepresentations.get(precheckSummary.status);
    precheckSummary.statusText = statusInfo.statusText;
    precheckSummary.statusColor = statusInfo.statusColor;
    precheckSummary.backgroundColor = statusInfo.backgroundColor;
    return precheckSummary;
  }
}

export interface PrecheckSummaryEntry {
  monthlyCost: string;
  numberOfMonths: number;
  nominalInterest: string;
}
