<h1 class="page-heading">Testa köputrymme</h1>

<div class="intro-paragraph">
  Genom att testa kundens kreditvärdighet kan ni i förväg lämna ett preliminärt
  besked om hen kan välja faktura eller delbetalning via Zaver. Kunden får ett
  sms med resultatet och ska alltid ha gett sitt medgivande innan då en
  kreditupplysning kommer att inhämtas.
</div>

<div id="precheck-container">
  <div id="precheck-form-results-container">
    <form [formGroup]="form" novalidate>
      <div class="input-fields">
        <zaver-form-input
          [title]="'Kundens personnummer'"
          [placeholder]="'ååååmmddxxxx'"
          [error]="form.controls.personalNumber.invalid && showError"
          [errorMessage]="'Vänligen ange ett giltigt personnummer'"
          formControlName="personalNumber"
          [inputMode]="'numeric'"
          [hideLabelsMobile]="false"
        >
        </zaver-form-input>
      </div>
      <div class="input-fields">
        <zaver-form-input
          [title]="'Kundens mobilnummer'"
          [placeholder]="'Ange mobilnummer'"
          [error]="form.controls.phoneNumber.invalid && showError"
          [errorMessage]="'Vänligen kontrollera mobilnumret'"
          formControlName="phoneNumber"
          [inputMode]="'tel'"
          [hideLabelsMobile]="false"
        >
        </zaver-form-input>
      </div>
      <div class="input-fields" style="margin-bottom: 40px">
        <zaver-form-input
          [showContent]="true"
          [title]="'Belopp för kreditprövning'"
          [placeholder]="'Ange belopp'"
          [error]="form.controls.amount.invalid && showError"
          [errorMessage]="'Ange ett belopp mellan 2 000 kr och 200 000 kr'"
          formControlName="amount"
          [inputMode]="'numeric'"
          [hideLabelsMobile]="false"
        >
          <span style="text-align: center; font-weight: 400; font-size: 14px">
            {{ defaultCurrency }}
          </span>
        </zaver-form-input>
      </div>

      <div id="precheck-cta">
        <zaver-primary-button
          [text]="'Testa köputrymme'"
          [loading]="fetchingResponse"
          (click)="submit()"
        >
        </zaver-primary-button>
      </div>
    </form>
    <ng-container *ngIf="displayResults && !fetchingResponse">
      <hr />
      <div id="results-heading">Resultat</div>
      <!-- At least one granted (Start)-->
      <ng-container *ngIf="precheckSummary.atLeastOneGranted">
        <div id="results-info-text">
          Vi kan erbjuda {{ precheckSummary.personName }}:
        </div>
        <ng-container *ngIf="precheckSummary.grantedInvoice">
          <div class="results-product-heading">
            <img src="assets/icons/Icon-Invoice.svg" alt="" />
            <span>Fakturabelopp</span>
          </div>
          <div *ngIf="precheckSummary.grantedInvoice" class="invoice-response">
            <img
              src="assets/icons/Icon-Checkmark-Green.svg"
              alt="Invoice icon"
            />
            <span>{{
              precheckAmount | currency: 'SEK':'symbol':'.2':'sv'
            }}</span>
          </div>
          <div
            *ngIf="!precheckSummary.grantedInvoice"
            class="invoice-response invoice-not-granted"
          >
            <img src="assets/icons/Icon-Cross-Cerise.svg" alt="" />
            <span
              >Tyvärr kan vi inte bevilja
              {{ precheckSummary.personName }} “faktura” på
              {{ precheckAmount | currency: 'SEK':'symbol':'.2':'sv' }}. Testa
              med ett lägre belopp.</span
            >
          </div>
        </ng-container>
        <ng-container *ngIf="precheckSummary.summaryEntries.length > 0">
          <div class="results-product-heading">
            <img src="assets/icons/Icon-Cake.svg" alt="Installments icon" />
            <span>Delbetalningsvillkor</span>
          </div>
          <div id="installments-container">
            <div
              class="installments-row"
              *ngFor="let entry of precheckSummary.summaryEntries"
            >
              <div class="installments-icon-and-text">
                <img
                  src="assets/icons/Icon-Checkmark-Green.svg"
                  alt="Invoice icon"
                />
                <span>
                  {{
                    entry.monthlyCost | currency: 'SEK':'symbol':'.2':'sv'
                  }}/mån i {{ entry.numberOfMonths }} månader
                </span>
              </div>
              <div class="installments-interest regular">
                {{ entry.nominalInterest }}% årsränta
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <!-- At least one granted (End)-->

      <!-- Less than one granted (Start)-->
      <ng-container *ngIf="!precheckSummary.atLeastOneGranted">
        <ng-container *ngIf="!precheckSummary.grantedInvoice">
          <div class="results-product-heading">
            <img src="assets/icons/Icon-Invoice.svg" alt="" />
            <span>Fakturabelopp</span>
          </div>
          <div
            *ngIf="!precheckSummary.grantedInvoice"
            class="invoice-response invoice-not-granted"
          >
            <img src="assets/icons/Icon-Cross-Cerise.svg" alt="" />
            <span>
              Tyvärr kan vi inte bevilja
              {{ precheckSummary.personName }} “faktura” på
              {{ precheckAmount | currency: 'SEK':'symbol':'.2':'sv' }}. Testa
              med ett lägre belopp.
            </span>
          </div>
        </ng-container>
        <ng-container *ngIf="precheckSummary.summaryEntries.length == 0">
          <div class="results-product-heading">
            <img src="assets/icons/Icon-Cake.svg" alt="Installments icon" />
            <span>Delbetalningsvillkor</span>
          </div>
          <div class="invoice-response invoice-not-granted">
            <img src="assets/icons/Icon-Cross-Cerise.svg" alt="" />
            <span>
              Tyvärr kan vi inte bevilja
              {{ precheckSummary.personName }} “delbetalning” på
              {{ precheckAmount | currency: 'SEK':'symbol':'.2':'sv' }}. Testa
              med ett lägre belopp.
            </span>
          </div>
        </ng-container>
      </ng-container>
      <!-- Less than one granted (End)-->
    </ng-container>
  </div>
</div>
<div *ngIf="errorMessage" id="app-error">
  <img
    src="assets/icons/Icon-Error.svg"
    alt="Error circle"
    style="margin-right: 20px"
  />
  <span>{{ errorMessage }}</span>
</div>
