import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SignupLegalEntity} from '../dto/signup/owner/SignupLegalEntity';
import {RestBase} from '../rest-base';
import {HrefService} from './href.service';

@Injectable()
export class LegalEntityService extends RestBase {

  constructor(httpClient: HttpClient, hrefService: HrefService) {
    super(httpClient, hrefService.getApiUrl());
  }

  public get(): Promise<SignupLegalEntity> | null {
    return this.getWithPrefill().then(x => x.persisted ? x.legalEntity : null);
  }

  public getWithPrefill(): Promise<Wrapper<SignupLegalEntity>> {
    return super.get('/legalEntity');
  }

  public create(signupLegalEntity: SignupLegalEntity): Promise<SignupLegalEntity> {
    return super.post('/legalEntity', JSON.stringify(signupLegalEntity));
  }

}
class Wrapper<SignupLegalEntity> {
  persisted: boolean;
  legalEntity: SignupLegalEntity;
}
