import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NameFormatterPipe } from './pipe/name-formatter.pipe';
import { CurrencyCodeFormatter } from './pipe/currency-code-formatter.pipe';
import { DE_LanguageFormatter } from './pipe/DE_LanguageFormatter.pipe';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { DotSpinnerComponent } from './components/dot-spinner/dot-spinner.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { SwitchComponent } from './components/switch/switch.component';
import { HoverClassDirective } from './directives/hover-class.directive';
import { ZfbAddButtonComponent } from './components/zfb-add-button/zfb-add-button.component';
import { ZfbRadioButtonComponent } from './components/zfb-radio-button/zfb-radio-button.component';
import { ZfbTextareaComponent } from './components/zfb-textarea/zfb-textarea.component';
import { ValuePipe } from './pipe/value.pipe';

@NgModule({
  declarations: [
    SpinnerComponent,
    DotSpinnerComponent,
    NameFormatterPipe,
    CurrencyCodeFormatter,
    DE_LanguageFormatter,
    SwitchComponent,
    HoverClassDirective,
    ZfbAddButtonComponent,
    ZfbRadioButtonComponent,
    ZfbTextareaComponent,
    ValuePipe,
  ],
  exports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NameFormatterPipe,
    CurrencyCodeFormatter,
    SpinnerComponent,
    DotSpinnerComponent,
    SwitchComponent,
    ZfbAddButtonComponent,
    ZfbRadioButtonComponent,
    ZfbTextareaComponent,
    ValuePipe,
    DE_LanguageFormatter,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientXsrfModule.disable(),
    BrowserModule,
  ],
})
export class SharedModule {}
