import { Injectable } from '@angular/core';
import { RxStompService } from '@stomp/ng2-stompjs';
import { Message } from '@stomp/stompjs';
import { environment } from '@environments/environment';
import { CashoutBalanceResponseDto } from '@core/dto/cashout/incoming/CashoutBalanceResponseDto';
import { CashoutTaskUpdatedDto } from '@core/dto/cashout/incoming/CashoutTaskUpdatedDto';
import { LoggingService } from './logging.service';
import { UserSessionUpdateMessage } from '@core/dto/user-details';
import { UpdateBannerMessage } from 'app/zfb/ui/banner/banner.component';

@Injectable()
export class WsService {
  private subscription;
  private topicListeners: { [s: string]: WebsocketListener<any>[] } = {};
  private connected: boolean = false;

  constructor(
    private stompService: RxStompService,
    private loggingService: LoggingService
  ) {}

  public activate(): void {
    if (this.connected) {
      return;
    }
    if (!this.stompService.connected()) {
      this.stompService.configure({
        brokerURL: environment.wsUri,
        connectHeaders: { 'X-WsService': 'ZFB' },
        heartbeatIncoming: 20000,
        heartbeatOutgoing: 20000,
        reconnectDelay: 2000,
      });
      this.stompService.activate();
      this.connect();
    }
    this.connected = true;
  }

  public deactivate(): void {
    if (this.stompService.connected()) {
      this.stompService.deactivate();
      this.connected = false;
    }
  }

  public registerTopicListener(
    topic: SubTopic,
    listener: WebsocketListener<any>
  ): void {
    if (typeof this.topicListeners[topic] === 'undefined') {
      this.topicListeners[topic] = [];
    }
    this.topicListeners[topic].push(listener);
  }

  public unregisterTopicListener(
    topic: SubTopic,
    listener: WebsocketListener<any>
  ): void {
    if (typeof this.topicListeners[topic] === 'undefined') {
      return;
    }
    this.topicListeners[topic] = this.topicListeners[topic].filter(
      (l) => l !== listener
    );
  }

  private connect(): void {
    this.subscription = this.stompService
      .watch('/user/topic/zfb')
      .subscribe((message: Message) => {
        const msgBody = JSON.parse(message.body);
        switch (msgBody.subTopic) {
          case SubTopic.PaymentRequestStatus:
            this.sendToListeners(
              msgBody.subTopic,
              msgBody.payload as PaymentRequestStatusUpdate
            );
            break;
          case SubTopic.CashoutTaskStatus:
            this.sendToListeners(
              msgBody.subTopic,
              msgBody.payload as CashoutTaskUpdatedDto
            );
            break;
          case SubTopic.CashoutBalance:
            this.sendToListeners(
              msgBody.subTopic,
              msgBody.payload as CashoutBalanceResponseDto
            );
            break;
          case SubTopic.UserSwitchedProfile:
            this.sendToListeners(
              msgBody.subTopic,
              msgBody.payload as UserSessionUpdateMessage
            );
            break;
          case SubTopic.SetBanner:
            this.sendToListeners(
              msgBody.subTopic,
              msgBody.payload as UpdateBannerMessage
            );
            break;
          default:
            console.error('Unknown websocket message type: ', msgBody.subTopic);
            break;
        }
      });
  }

  private sendToListeners<T>(subTopic: SubTopic, msg: any): void {
    const typedMsg: T = msg as T;
    if (typeof this.topicListeners[subTopic] !== 'undefined') {
      this.topicListeners[subTopic].forEach((l) => l.handleMessage(typedMsg));
    }
  }
}

export enum SubTopic {
  PaymentRequestStatus = 'paymentRequest/status',
  CashoutTaskStatus = 'cashout/taskStatus',
  CashoutBalance = 'cashout/balance',
  UserSwitchedProfile = 'user/switchedProfile',
  SetBanner = 'app/messageBanner',
}

export class PaymentRequestStatusUpdate {
  id: string;
  status: string;
}

export interface WebsocketListener<T> {
  handleMessage(message: T): void;
}

export interface WsMessageListener {
  handleMessage(message: Message): void;
}
