import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SignupPrivateCompany} from '@core/dto/signup/owner/SignupPrivateCompany';
import {RestBase} from '@core/rest-base';
import {HrefService} from './href.service';

@Injectable()
export class PrivateCompanyService extends RestBase {

  constructor(httpClient: HttpClient, hrefService: HrefService) {
    super(httpClient, hrefService.getApiUrl());
  }

  public get(): Promise<SignupPrivateCompany> {
    return this.getWithPrefill().then(x => x.persisted ? x.privateCompany : null);
  }

  public getWithPrefill(): Promise<Wrapper<SignupPrivateCompany>> {
    return super.get('/privateCompany');
  }

  public create(signupPrivateCompany: SignupPrivateCompany): Promise<SignupPrivateCompany> {
    return super.post('/privateCompany', JSON.stringify(signupPrivateCompany));
  }

}

class Wrapper<SignupPrivateCompany> {
  persisted: boolean;
  privateCompany: SignupPrivateCompany;
}
