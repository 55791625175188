import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestBase } from '@core/rest-base';
import { HttpClient } from '@angular/common/http';
import { HrefService } from '@core/service/href.service';
import { LocaleService } from '@core/service/locale.service';
import { Market } from '@core/dto/Market';

export enum ApprovalError {
  ALREADY_RESOLVED = 'ALREADY_RESOLVED',
  NO_TASK = 'NO_TASK',
  INVALID_TOKEN = 'INVALID_TOKEN',
}
@Component({
  selector: 'app-user-approval',
  templateUrl: './user-approval.component.html',
  styleUrls: ['./../login-pages.scss', './user-approval.component.scss'],
})
export class UserApprovalComponent extends RestBase implements OnInit {
  verifying = true;
  accepted = false;
  error: ApprovalError;
  showError = false;
  alreadyResolved: boolean;
  unexpectedError: boolean;
  isDeAtMarket: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    private hrefService: HrefService,
    public localeService: LocaleService
  ) {
    super(httpClient, hrefService.getApiUrl());
  }

  ngOnInit() {
    const approvalToken = this.route.snapshot.paramMap.get('approvalToken');
    super
      .post(
        `/user/handleApprovalTask/`,
        JSON.stringify({ approvalToken: approvalToken })
      )
      .then(
        (res) => {
          this.accepted = res.resolution === 'APPROVE';
          this.verifying = false;
        },
        (err) => this.handleError(err.error.error)
      );
    this.isDeAtMarket =
      this.localeService.getCurrentLocaleMarket() === Market.DE ||
      this.localeService.getCurrentLocaleMarket() === Market.AT;
  }

  toLogin() {
    this.router.navigate(['/login']);
  }

  private handleError(errors) {
    this.error = errors;
    switch (this.error) {
      case ApprovalError.ALREADY_RESOLVED:
        this.alreadyResolved = true;
        break;
      case ApprovalError.NO_TASK:
      case ApprovalError.INVALID_TOKEN:
      default:
        this.unexpectedError = true;
        break;
    }
    this.showError = true;
    this.verifying = false;
  }
}
