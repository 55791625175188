<form [formGroup]="form" novalidate>
  <ng-container
    *ngIf="
      !displayingInCompanyAdminPage ||
      (displayingInCompanyAdminPage && displayingInCompanyInformationTab)
    "
  >
    <div class="card" [class.in-admin]="displayingInCompanyAdminPage">
      <div class="card-heading">
        <h2>
          <span i18n="@@companyForm.companyInformation.cardHeading"
            >Företagsinformation</span
          >
        </h2>
      </div>
      <div class="card-body">
        <div class="input-group-zfb">
          <div class="input-fields">
            <zaver-form-input
              title="Organisationsnummer"
              i18n-title="
                @@companyForm.companyInformation.organizationNumber.title"
              [placeholder]="'Organisationsnummer'"
              [error]="
                form.controls.organizationNumber.invalid &&
                (showError || startOrganizationNumberValidation)
              "
              [errorMessage]="'Vänligen ange ett korrekt organisationsnummer'"
              formControlName="organizationNumber"
              [inputMode]="'tel'"
              [size]="'large'"
            >
            </zaver-form-input>
          </div>
          <div class="input-fields" *ngIf="displayCompanyNotFoundError">
            <app-form-error
              [errorText]="
                'Vi kan inte hitta ett företag med det angivna organisationsnumret.'
              "
            >
            </app-form-error>
          </div>
          <div class="input-fields" [class.hide]="!correctOrganizationNumber">
            <zaver-form-input
              title="Företagets namn"
              i18n-title="@@companyForm.companyInformation.companyName.title"
              [placeholder]="companyNamePlaceholder"
              formControlName="name"
              [error]="form.controls.name.invalid && showError"
              [errorMessage]="'Fältet får inte lämnas tomt'"
              [maxLength]="255"
              [size]="'large'"
            >
            </zaver-form-input>
          </div>
        </div>
        <div class="input-group-zfb">
          <div class="input-fields" [class.hide]="!correctOrganizationNumber">
            <zaver-form-input
              title="Hemsida"
              i18n-title="@@companyForm.companyInformation.webiste.title"
              placeholder="Ange företagets hemsida"
              i18n-placeholder="
                @@companyForm.companyInformation.webiste.placeholder"
              formControlName="website"
              [maxLength]="255"
              [size]="'large'"
            >
            </zaver-form-input>
          </div>
          <div style="width: 100%">
            <app-agreement
              class="desktop-agreement"
              *ngIf="displayingInCompanyAdminPage && mayViewCompanyAgreement()"
              [displayingInCompanyAdminPage]="true"
            ></app-agreement>
          </div>
        </div>
        <div
          [class.industry-agreement-container]="displayingInCompanyAdminPage"
          [class.hide]="!correctOrganizationNumber"
        >
          <app-radio-select
            [value]="getIndustryValue()"
            (valueChange)="handleIndustryValueChange($event)"
            [options]="industryOptions"
            [allDisabled]="!mayEditCompanyInfo()"
            [showRequiredError]="
              showError && form.controls.industryOther.invalid
            "
            errorMessage="Vänligen ange en bransch"
            i18n-errorMessage="
              @@companyForm.companyInformation.industry.errorMessage"
            [displayingInCompanyAdminPage]="displayingInCompanyAdminPage"
            [seperateInTwoColumns]="displayingInCompanyAdminPage"
          >
          </app-radio-select>
          <app-agreement
            class="mobile-agreement"
            *ngIf="displayingInCompanyAdminPage && mayViewCompanyAgreement()"
            [displayingInCompanyAdminPage]="true"
          ></app-agreement>
        </div>
        <div id="admin-buttons" *ngIf="displayingInCompanyAdminPage">
          <zaver-secondary-button-outlined
            style="width: 110px"
            *ngIf="formChanged"
            (click)="promptCancelationConfirmation()"
            [text]="'Avbryt'"
          ></zaver-secondary-button-outlined>
          <zaver-primary-button
            style="width: 110px; margin-left: 12px"
            (click)="promptCompanyInfoSubmissionConfirmation()"
            [disabled]="!formChanged"
            [text]="'Spara'"
            [color]="'black'"
          ></zaver-primary-button>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container
    *ngIf="
      !displayingInCompanyAdminPage ||
      (mayUpdateCompanyOwnershipInfo() &&
        displayingInCompanyOwnerInformationTab)
    "
  >
    <div class="card" [class.hide]="!correctOrganizationNumber">
      <div class="card-heading">
        <h2>Ägarinformation: Ägare av {{ form.controls.name.value }}</h2>
      </div>
      <div class="card-body">
        <zaver-dropdown
          id="dropdown"
          [title]="
            'Antalet ägare (fysisk eller juridisk person) med mer än 25% av röst/ägarandelarna'
          "
          [inputModel]="selectedOwnerNumberOption"
          [selectOptions]="ownerNumberOptions"
          (inputModelChange)="nrOwnerChange($event)"
          [error]="showError && selectedOwnerNumberOption === null"
          [errorMessage]="'Vänligen ange antalet ägare'"
        >
        </zaver-dropdown>
      </div>
    </div>
    <ng-container *ngIf="!!owners">
      <div
        class="card-accordion-container"
        *ngFor="let owner of owners.controls; let i = index"
      >
        <app-card-accordion #owner [heading]="getOwnerCardHeading(i)">
          <app-signup-company-owner
            [displayingInCompanyAdminPage]="displayingInCompanyAdminPage"
            [formGroup]="getOwner(i)"
            [showError]="showError"
          ></app-signup-company-owner>
        </app-card-accordion>
      </div>
    </ng-container>

    <div class="card" [class.hide]="!correctOrganizationNumber">
      <div class="card-heading">
        <h2>Ägarinformation: Verklig huvudman</h2>
      </div>
      <div class="card-body">
        <span class="label-text"
          >Finns det någon annan person än ägarna angivna ovan som på annat sätt
          utövar kontroll över den juridiska personen, s.k. verklig
          huvudman?</span
        >
        <span class="label-help-text"
          >Exempelvis en person som har rätt att utse eller avsätta mer än
          hälften av den juridiska personens styrelseledamöter eller motsvarande
          befattningshavare, eller på grund av andra avtal kan utöva sådan
          kontroll.</span
        >
        <zaver-dropdown
          id="dropdown"
          [inputModel]="selectedNonOwnerNumberOption"
          [selectOptions]="nonOwnerNumberOptions"
          (inputModelChange)="nrNonOwnerChange($event)"
          [error]="showError && selectedNonOwnerNumberOption === null"
          [errorMessage]="'Vänligen ange antalet verkliga huvudmän'"
        >
        </zaver-dropdown>
      </div>
    </div>
    <ng-container *ngIf="!!nonOwners">
      <div
        class="card-accordion-container"
        *ngFor="let owner of nonOwners.controls; let i = index"
      >
        <app-card-accordion #nonOwner [heading]="getNonOwnerCardHeading(i)">
          <app-signup-company-owner
            [displayingInCompanyAdminPage]="displayingInCompanyAdminPage"
            [formGroup]="getNonOwner(i)"
            [nonOwner]="true"
            [showError]="showError"
          ></app-signup-company-owner>
        </app-card-accordion>
      </div>
    </ng-container>
  </ng-container>
</form>

<app-page-error
  [class.in-admin]="displayingInCompanyAdminPage"
  [class.in-owner-tab]="displayingInCompanyOwnerInformationTab"
  *ngIf="errorMessage"
  [errorMessage]="errorMessage"
></app-page-error>

<app-page-error
  [class.in-admin]="displayingInCompanyAdminPage"
  [class.in-owner-tab]="displayingInCompanyOwnerInformationTab"
  *ngIf="
    showError &&
    (form.invalid ||
      selectedNonOwnerNumberOption === null ||
      selectedOwnerNumberOption === null ||
      (!!contactInformationForm && contactInformationForm.invalid))
  "
  [errorMessage]="formErrorText"
></app-page-error>

<div class="next-button-container" *ngIf="!displayingInCompanyAdminPage">
  <button class="next-button cerise-bg" (click)="submit()">Nästa</button>
</div>

<div
  id="admin-buttons"
  *ngIf="displayingInCompanyAdminPage && displayingInCompanyOwnerInformationTab"
  [class.in-owner-tab]="displayingInCompanyOwnerInformationTab"
>
  <zaver-secondary-button-outlined
    style="width: 110px"
    *ngIf="formChanged"
    (click)="promptCancelationConfirmation()"
    [text]="'Avbryt'"
  ></zaver-secondary-button-outlined>
  <zaver-primary-button
    style="width: 110px; margin-left: 12px"
    (click)="promptOwnerInfoSubmissionConfirmation()"
    [disabled]="!formChanged"
    [text]="'Spara'"
    [color]="'black'"
  ></zaver-primary-button>
</div>

<zaver-details-modal
  *ngIf="modalActive"
  (onClose)="closeModal()"
  [title]="modalTitle"
  [color]="modalColor"
>
  <app-company-form-confirmation
    *ngIf="displaySubmissionConfirmaiton"
    [signupLegalEntityToCreate]="signupLegalEntityToCreate"
    (onAbort)="closeModal()"
    (onSubmit)="changesSaved($event)"
  >
  </app-company-form-confirmation>
  <div class="small-modal-wrapper" *ngIf="displayCancelConfirmation">
    <span i18n="@@companyForm.modal.cancelConfirm.text"
      >Är du säker på att du vill avbryta? Dina ändringar kommer inte sparas.
      😯</span
    >

    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        text="Tillbaka"
        i18n-text="@@companyForm.modal.cancelConfirm.button.back"
        (click)="closeModal()"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        text="Ja, avbryt"
        i18n-text="@@companyForm.modal.cancelConfirm.button.confirm"
        [color]="'black'"
        (click)="confirmCancelation()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </div>
</zaver-details-modal>
