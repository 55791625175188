import { Industry } from './Bransch';
import { Owner } from './Owner';

export class SignupLegalEntity {
  type: 'Aktiebolag' | 'Handelsbolag' | 'Kommanditbolag';
  name: string;
  organizationNumber: string;
  website: string;
  industry: Industry;
  industryOther: string;

  owners: Owner[];
  nonOwners: Owner[];

  constructor(type: 'Aktiebolag' | 'Handelsbolag' | 'Kommanditbolag') {
    this.type = type;
    this.owners = [];
    this.nonOwners = [];
  }
}
