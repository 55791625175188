import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup-step3',
  templateUrl: './step-3.component.html',
  styleUrls: ['../../page-shared.scss', '../signup-shared.css'],
})
export class Step3Component implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}

  signed(event) {
    this.router.navigate(['/'], { skipLocationChange: false });
  }
}
