import { Item } from '../../../web-component-library/projects/component-library/src/public-api';

export const availableCountryCodes: Item[] = [
  { imgSrc: 'assets/flags/se.svg', label: 'Sverige +46', value: '+46 ' },
  { imgSrc: 'assets/flags/no.svg', label: 'Norge +47', value: '+47 ' },
  { imgSrc: 'assets/flags/at.svg', label: 'Österreich +43', value: '+43 ' },
  { imgSrc: 'assets/flags/be.svg', label: 'Belgien +32', value: '+32 ' },
  { imgSrc: 'assets/flags/de.svg', label: 'Deutschland +49 ', value: '+49 ' },
  { imgSrc: 'assets/flags/nl.svg', label: 'Niederlande +31', value: '+31 ' },
];

export const setCountryFlag = (inputStr: string, selectedcountry: Item) => {
  inputStr = inputStr && inputStr.replace(/\s/g, '');
  if (inputStr) {

    if (inputStr.startsWith('+46') || inputStr.startsWith('0046')) {
      return availableCountryCodes[0];
    }

    if (inputStr.startsWith('+47') || inputStr.startsWith('0047')) {
      return availableCountryCodes[1];
    }

    if (inputStr.startsWith('+43') || inputStr.startsWith('0043')) {
      return availableCountryCodes[2];
    }

    if (inputStr.startsWith('+32') || inputStr.startsWith('0032')) {
      return availableCountryCodes[3];
    }

    if (inputStr.startsWith('+49') || inputStr.startsWith('0049')) {
      return availableCountryCodes[4];
    }

    if (inputStr.startsWith('+31') || inputStr.startsWith('0031')) {
      return availableCountryCodes[5];
    }
  }

  return selectedcountry;
};
