<div class="create-container spacing-container">
  <form [formGroup]="reminderForm" novalidate>
    <div
      class="input-fields"
      [class.error]="reminderForm.controls.phone.invalid && showError"
    >
      <zaver-form-input
        [showContent]="true"
        [title]="'Mobilnummer'"
        [placeholder]="'Ange mobilnummer'"
        [error]="reminderForm.controls.phone.invalid && showError"
        [errorMessage]="'Vänligen kontrollera mobilnumret'"
        formControlName="phone"
        [inputMode]="'tel'"
        [inputType]="'tel'"
        [size]="'large'"
      >
        <img src="assets/icons/Icon-Phone.svg" />
      </zaver-form-input>
    </div>
    <div
      class="input-fields"
      [class.error]="reminderForm.controls.email.invalid && showError"
    >
      <zaver-form-input
        [showContent]="true"
        [title]="'E-postadress'"
        [placeholder]="'Ange e-postadress'"
        [error]="reminderForm.controls.email.invalid && showError"
        [errorMessage]="'Vänligen ange en giltig e-postadress'"
        formControlName="email"
        [maxLength]="255"
        [inputMode]="'email'"
        [inputType]="'email'"
        [size]="'large'"
      >
        <img src="assets/icons/Icon-E-mail.svg" />
      </zaver-form-input>
    </div>
    <div class="input-fields">
      <app-date-picker
        [title]="'Förfallodatum'"
        [placeholder]="'åååå-mm-dd'"
        [formGroup]="reminderForm"
        [showError]="showError"
        [formName]="'dueDate'"
        [initialDate]="initialDate"
      >
      </app-date-picker>
    </div>
    <div class="input-fields options-container">
      <input
        type="checkbox"
        class="options"
        name="options"
        formControlName="sendAsEmail"
      />
      <label class="options-label" for="options" style="margin-right: 12px"
        >Skicka som e-post</label
      >
      <input
        type="checkbox"
        class="options"
        name="options"
        formControlName="sendAsSMS"
      />
      <label class="options-label" for="options">Skicka som SMS</label>
    </div>
    <div class="input-fields" *ngIf="formError">
      <app-form-error [errorText]="formError"> </app-form-error>
    </div>
  </form>
  <div class="continue-back-buttons-container">
    <zaver-primary-button
      [text]="'Skicka förfrågan'"
      [loading]="processing"
      (click)="submit()"
    >
    </zaver-primary-button>

    <zaver-secondary-button-subtle
      [text]="'Tillbaka'"
      [iconUrl]="'assets/icons/Icon-Bin.svg'"
      [disabled]="processing"
      (click)="returnToView.emit()"
    >
    </zaver-secondary-button-subtle>
  </div>
</div>
