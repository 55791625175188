import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyBankDetails } from '@core/dto/administration/CompanyBankDetails';
import { CompanyKyc } from '@core/dto/administration/CompanyKyc';
import { CompanyType } from '@core/dto/administration/CompanyType';
import { RestBase } from '@core/rest-base';
import { Permission } from '@core/dto/user-details';
import { HrefService } from './href.service';
import { AccessControlService } from './access-control.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyAdministrationService extends RestBase {
  constructor(
    httpClient: HttpClient,
    hrefService: HrefService,
    private access: AccessControlService
  ) {
    super(httpClient, hrefService.getApiUrl());
  }

  public getCompanyType(): Promise<CompanyType> {
    return super.get('/administration/type');
  }

  public getKyc(): Promise<CompanyKyc> {
    return super.get('/administration/kyc');
  }

  public getBankDetails(): Promise<CompanyBankDetails> {
    return super.get('/administration/bank');
  }

  public getAllZfbMerchantSettings(): Promise<any> {
    return super.get('/zfbMerchantSettings');
  }

  public updateZfbMerchantPaymentRequestSettings(
    requestBody: any
  ): Promise<any> {
    return super.post(
      '/zfbMerchantSettings/updatePaymentRequestSettings',
      JSON.stringify(requestBody)
    );
  }

  mayUpdateZfbMerchantSettings(): boolean {
    return this.access.userMay(Permission.EDIT_ZFB_MERCHANT_SETTINGS);
  }
}
