import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'DE_LanguageFormatter',
})
export class DE_LanguageFormatter implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'sv-SE':
        return 'Schweden';
      case 'de-DE':
        return 'Deutschland';
      case 'at-AT':
        return 'Österreich';
      default:
        break;
    }
  }
}
