import { RadioButtonData } from 'app/zfb/ui/radio-select/radio-select.component';

export class SignupQuestions {
  static TRANSACTION_PURPOSE_VALUES: RadioButtonData[] = [
    { value: 'Försäljning av varor', label: 'Försäljning av varor' },
    { value: 'Försäljning av tjänster', label: 'Försäljning av tjänster' },
  ];

  static BUSINESS_PURPOSE_VALUES: RadioButtonData[] = [
    { value: 'Ökad omsättning', label: 'Ökad omsättning' },
    {
      value: 'Ökade betalningsalternativ mot kund',
      label: 'Ökade betalningsalternativ mot kund',
    },
  ];

  frequency: string;
  amount: string;
  transactionPurpose: string;
  businessPurpose: string;

  bank: string;
  clearingNumber: string;
  accountNumber: string;
  iban: string;
  bic: string;

  constructor(
    amount: string,
    frequency: string,
    transactionPurpose: string,
    businessPurpose: string,
    clearingNumber: string,
    accountNumber: string,
    bank: string,
    iban: string,
    bic: string
  ) {
    this.amount = amount;
    this.frequency = frequency;
    this.transactionPurpose = transactionPurpose;
    this.businessPurpose = businessPurpose;
    this.clearingNumber = clearingNumber;
    this.accountNumber = accountNumber;
    this.bank = bank;
    this.iban = iban;
    this.bic = bic;
  }
}
