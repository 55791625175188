import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyRepresentative } from '@core/dto/CompanyRepresentative';
import { RestBase } from '@core/rest-base';
import { HrefService } from './href.service';

@Injectable()
export class SignupService extends RestBase {
  constructor(httpClient: HttpClient, hrefService: HrefService) {
    super(httpClient, hrefService.getApiUrl());
  }

  public getCompanyInfo(organizationNumber: string): Promise<CompanyInfoDto> {
    return super.get('/signup/companyInfo/?nin=' + organizationNumber);
  }

  public getNaturalPersonInfo(
    personalNumber: string
  ): Promise<NaturalPersonInfoDto> {
    return super.get('/signup/naturalPersonInfo/?nin=' + personalNumber);
  }

  public updateSignupRepresentativeUserInfo(
    representative: CompanyRepresentative
  ): Promise<CompanyRepresentative> {
    return super.post('/signup/updateUserInfo', JSON.stringify(representative));
  }
}

export interface CompanyInfoDto {
  companyName: string;
  legalEntityId: string;
  error?: 'COMPANY_NOT_FOUND' | 'UNKNOWN_ERROR' | 'AWAITING_LOOKUP_RESPONSE';
}

export interface NaturalPersonInfoDto {
  personName: string;
  legalEntityId: string;
  error?: 'ERROR_FETCHING_NAME';
}
