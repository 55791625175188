import { Component, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { AppStateService } from '@core/service/app-state.service';
import { AuthenticationService } from '@core/service/authentication.service';

@Component({
  selector: 'app-select-profile',
  templateUrl: './select-profile.component.html',
  styleUrls: ['../page-shared.scss', './select-profile.component.css'],
})
export class SelectProfileComponent {
  @HostBinding('class')
  cssClasses = 'hide-footer';

  constructor(
    private router: Router,
    private appState: AppStateService,
    private authSvc: AuthenticationService
  ) {
    if (this.appState.getActiveRepresentation()) {
      // There is already an active profile selected.
      // Make the user move further into the app because being stuck on the profile selection page would confuse some.
      // This happens when:
      // 1) there are multiple browser tabs open
      // 2) the user logs into one of the other tabs
      // 3) when this tab is later reloaded the user might end up on the profile selection screen
      this.router.navigate(['/paymentRequests'], { replaceUrl: true });
    }
  }

  logout() {
    this.authSvc.logout();
  }
}
