import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { BaseComponent } from '@core/base.component';
import { AuthenticationService } from '@core/service/authentication.service';
import { ColorService } from '@core/service/color.service';
import { CompanyAdministrationService } from '@core/service/company-administration.service';
import { ToastMessageService } from '@core/service/toast-message.service';

enum ModalView {
  SUBMIT,
  CANCEL,
}

enum SettingsType {
  PAYMENTREQUESTS = 'PAYMENTREQUESTS',
}

interface SettingsResponse {
  id?: string;
  merchantId?: string;
  showCancelledPaymentRequests: boolean;
}

interface FormObject {
  name: string;
  title: string;
  formArray: SettingsResponse;
  type: SettingsType;
}

@Component({
  selector: 'admin-zfb-merchant-settings',
  templateUrl: './zfb-merchant-settings.html',
  styleUrls: ['../page-shared.scss', './zfb-merchant-settings.component.scss'],
})
export class AdminZfbMerchantSettings extends BaseComponent implements OnInit {
  @Output() doneFetchingResponse = new EventEmitter();
  loading = true;
  settingsForm: FormGroup;
  showCancelledPaymentRequestForm: FormObject;
  modalTitle: string;
  modalActive: boolean;
  modalColor: string;
  ModalViewType = ModalView;
  modalView: ModalView;
  submitting: boolean;
  showError: boolean;
  formChanged: boolean;
  cancelledPaymentRequestSettingsValue: boolean;
  fetchedShowPaymentRequestSettings: any;
  mayUpdatePaymentRequestSettings: boolean;
  successToastMessage: string = $localize`:@@users.toast.zfbmerchantsettings.save:Inställningarna har sparats`;
  saveZfbMerchantSettingsButtonText: string = $localize`:@@zfbMerchantSettings.save-settings.button.text:Spara`;
  cancelZfbMerchantSettingsButtonText: string = $localize`:@@zfbMerchantSettings.cancel-settings.button.text:Avbryt`;
  cancelModalZfbMerchantSettingsButtonText: string = $localize`:@@zfbMerchantSettings.goback.modal-settings.button.text:Tillbaka`;
  yesCancelModalBackZfbMerchantSettingsButtonText: string = $localize`:@@zfbMerchantSettings.yes.cancel.modal-settings.button.text:Ja, avbryt`;

  constructor(
    public colorService: ColorService,
    protected auth: AuthenticationService,
    private companyAdministrationService: CompanyAdministrationService,
    private toastService: ToastMessageService
  ) {
    super(auth);
  }

  ngOnInit(): void {
    this.fetchPaymentRequestSettings().then(() => {
      this.setupForms();
      this.loading = false;
    });
    this.mayUpdatePaymentRequestSettings =
      this.companyAdministrationService.mayUpdateZfbMerchantSettings();
  }

  setupForms(): void {
    this.settingsForm = new FormGroup({});
    this.setUpSettingsForm(this.showCancelledPaymentRequestForm);
  }

  setUpSettingsForm(formObject: FormObject): void {
    const formControl = new FormControl(
      this.cancelledPaymentRequestSettingsValue
    );

    this.settingsForm.addControl(formObject.name, formControl);

    this.formChanged = false;
  }

  setUpPaymentRequestForm(): void {
    this.showCancelledPaymentRequestForm = {
      name: 'cancelledPaymentRequestSettingsValue',
      formArray: this.fetchedShowPaymentRequestSettings,
      title: $localize`:@@zfbMerchantSettings.paymentRequests.form.title:Betallänksinställningar`,
      type: SettingsType.PAYMENTREQUESTS,
    };
  }

  onPaymentRequestSettingChange(event: any): void {
    const newValue = event.target.checked;
    this.settingsForm
      .get(this.showCancelledPaymentRequestForm.name)
      ?.setValue(newValue);
    this.formChanged = this.cancelledPaymentRequestSettingsValue !== newValue;
  }

  promptCancelModal(): void {
    this.modalTitle = $localize`:@@zfbMerchantSettings.modal.cancel.title:Avbryt`;
    this.modalView = ModalView.CANCEL;
    this.modalColor = this.colorService.getCeriseColorCode();
    this.modalActive = true;
  }

  submit(): void {
    this.postSettings();
  }

  onSave(): void {
    if (this.settingsForm.invalid || !this.formChanged) {
      this.showError = true;
      return;
    }
    this.modalTitle = $localize`:@@zfbMerchantSettings.save-settings.modal.title:Uppdatera betallänksinställningen`;
    this.modalView = this.ModalViewType.SUBMIT;
    this.modalColor = '#00A880';
    this.modalActive = true;
    this.showError = false;
  }

  cancel(): void {
    this.fetchPaymentRequestSettings().then(() => {
      this.setupForms();
      this.loading = false;
    });
    this.modalActive = false;
    this.showError = false;
    this.formChanged = false;
    this.settingsForm.reset();
  }

  closeModal(): void {
    this.submitting = false;
    this.modalActive = false;
  }

  async fetchPaymentRequestSettings(): Promise<void> {
    try {
      this.fetchedShowPaymentRequestSettings =
        await this.companyAdministrationService.getAllZfbMerchantSettings();
      this.cancelledPaymentRequestSettingsValue =
        this.fetchedShowPaymentRequestSettings.showCancelledPaymentRequests;
      this.setUpPaymentRequestForm();
      this.doneFetchingResponse.emit();
    } catch (error: unknown) {
      this.handleError(error);
    }
  }

  async postSettings(): Promise<void> {
    try {
      this.submitting = true;
      if (this.formChanged) {
        const showCancelledPaymentRequests = this.settingsForm.get(
          this.showCancelledPaymentRequestForm.name
        ).value;
        await this.companyAdministrationService.updateZfbMerchantPaymentRequestSettings(
          {
            showCancelledPaymentRequests: showCancelledPaymentRequests,
          }
        );
        this.cancelledPaymentRequestSettingsValue =
          showCancelledPaymentRequests;
      }
      this.toastService.displaySuccess(this.successToastMessage);
      this.setupForms();
      this.closeModal();
    } catch (error: unknown) {
      this.submitting = false;
      this.handleError(error);
    }
  }
}
