import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-server-error',
  templateUrl: './server-error.component.html',
  styleUrls: ['./server-error.component.css'],
})
export class ServerErrorComponent implements OnInit {
  @Input() imgPath = 'assets/error-icons/Big-Eyes.png';
  @Input()
  errorHeading = $localize`:@@serverError.errorHeading:Oops! Någonting gick fel.`;
  @Input()
  errorMessage = $localize`:@@serverError.errorMessage:Vänligen försök igen. Om problemet kvarstår, <a class="link" href="mailto:merchants@zaver.se" target="_blank">kontakta&nbsp;oss.</a>`;

  constructor() {}

  ngOnInit(): void {}
}
