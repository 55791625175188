<div class="title" *ngIf="!!title">{{ title }}</div>
<div class="dropdown">
  <button
    type="button"
    (click)="toggleDropDown()"
    [ngClass]="size"
    class="dropdown-button"
    [class.isActive]="showDropDown"
    [class.focused]="showDropDown"
    [class.invalid]="error"
    [class.disabled]="disabled"
    (keydown)="onKeydown($event)"
  >
    <div class="content-wrapper">
      <img *ngIf="error" src="assets/icon/icon_error.svg" class="error-icon" />

      <img *ngIf="!!selectedItem.imgSrc" src="{{ selectedItem.imgSrc }}" />

      <div class="text-container">
        <span class="help-text" *ngIf="!!selectedItem.value && !!helpText">
          {{ helpText }}
        </span>

        <span [ngClass]="{ 'invalid-value': !selectedItem.value }">
          {{ selectedItem.label }}
        </span>
      </div>
    </div>
  </button>

  <div [class.disabled]="disabled" (click)="toggleDropDown()" class="arrow">
    <div class="img">
      <svg
        width="15"
        height="10"
        viewBox="0 0 15 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.728574 1.27151C1.03336 0.949884 1.52754 0.949859 1.83235 1.27145L7.5 7.2511L13.1676 1.27145C13.4725 0.949859 13.9666 0.949884 14.2714 1.27151C14.5762 1.59313 14.5762 2.11456 14.2714 2.43615L8.34529 8.68846C8.34515 8.68861 8.34501 8.68876 8.34487 8.6889C8.23401 8.80593 8.1024 8.89878 7.95753 8.96216C7.81249 9.02561 7.65701 9.05827 7.5 9.05827C7.34299 9.05827 7.18751 9.02561 7.04246 8.96216C6.89759 8.89877 6.76597 8.80591 6.6551 8.68887C6.65497 8.68874 6.65484 8.6886 6.65471 8.68846L0.728625 2.43615C0.423812 2.11456 0.423789 1.59313 0.728574 1.27151ZM7.75885 7.5242C7.75895 7.5243 7.75904 7.5244 7.75913 7.5245L7.75885 7.5242Z"
            fill="#0A1018"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect
              width="14"
              height="8.23529"
              fill="white"
              transform="translate(0.5 0.882812)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>

  <ng-container *ngIf="!!selectableItems && showDropDown">
    <zaver-custom-select
      style="width: 100%"
      [showDropdown]="showDropDown"
      [selectedItem]="selectedItem"
      [items]="selectableItems"
      (dropdownClosed)="toggleDropDown()"
      (itemSelected)="selectItem($event)"
      [shorterDropdown]="shorterDropdown"
    ></zaver-custom-select>
  </ng-container>
</div>
<div class="error" *ngIf="error">
  <span>{{ errorMessage }}</span>
</div>
