import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '@core/base.component';
import { Currency } from '@core/dto/CurrencyCode';
import { PaginatedList } from '@core/dto/PaginatedList';
import { Refund } from '@core/dto/refund/Refund';
import { RefundStatus } from '@core/params';
import { AppStateService } from '@core/service/app-state.service';
import { AuthenticationService } from '@core/service/authentication.service';
import { CustomizationService } from '@core/service/customization.service';
import { RefundService } from '@core/service/refund.service';
import { StoreService } from '@core/service/store.service';
import { ValueListPipe } from '@shared/pipe/value.pipe';
import { EmptyState } from '@zfb/ui/empty-state/empty-state.component';
import {
  ColumnDefinition,
  FilterOption,
  TableRow,
  TableRows,
} from '@zfb/ui/table/table.component';
import { QuickAction } from '@zfb/ui/quick-actions/quick-actions.component';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ColorService } from '@core/service/color.service';
import { PageTab } from '@zfb/ui/page-tabs/page-tabs.component';
import { LocaleService } from '@core/service/locale.service';
import { PaginationState } from '@zfb/ui/table-pagination/table-pagination.component';
import { Permission } from '@core/dto/user-details';
import { AccessControlService } from '@core/service/access-control.service';

export enum RefundPage {
  REPORTED = 'REPORTED',
  MANAGED = 'MANAGED',
}
@Component({
  selector: 'app-refunds',
  templateUrl: './refunds.component.html',
  styleUrls: ['../page-shared.scss', './refunds.component.css'],
})
export class RefundsComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  constructor(
    private refundService: RefundService,
    auth: AuthenticationService,
    private storeService: StoreService,
    private appStateService: AppStateService,
    private customizationService: CustomizationService,
    public colorService: ColorService,
    public localeService: LocaleService,
    private access: AccessControlService
  ) {
    super(auth);

    this.searchQueryChanged
      .pipe(
        map((q) => q.trim()),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((query: string) => {
        this.paginationState.page = 1;
        this.searchQuery = query;
        this.fetchRefunds();
      });
  }

  defaultManagedPageStatusFilter =
    RefundStatus.PENDING_EXECUTION +
    ',' +
    RefundStatus.EXECUTED +
    ',' +
    RefundStatus.EXECUTION_INITIATED +
    ',' +
    RefundStatus.CANCELLED +
    ',' +
    RefundStatus.CANCELLED_BY_SYSTEM +
    ',' +
    RefundStatus.ERROR;

  columns: ColumnDefinition<Refund, RefundStatus>[] = [
    {
      cssClasses: 'medium2 avoid-overflow first-column',
      columnDataTransformer: (refund) => refund.paymentRequestTitle,
      text: $localize`:@@refunds.columns.title:Titel`,
    },
    {
      columnDataTransformer: (refund) =>
        new DatePipe(this.localeService.getCurrentLocale()).transform(
          refund.created,
          'd MMM y'
        ),
      text: $localize`:@@refunds.columns.reportedDate:Rapporterad`,
    },
    {
      cssClasses: 'medium2',
      columnDataTransformer: (refund) =>
        new ValueListPipe().transform(
          refund.paymentRequestValue,
          this.localeService.getCurrentLocale()
        ),
      columnHeadingStylingTransformer: () => 'width: 170px;',
      columnStylingTransformer: () => 'width: 170px;',
      text: $localize`:@@refunds.columns.originalAmount:Urspr. belopp`,
    },
    {
      cssClasses: 'medium2 cerise-text',
      columnDataTransformer: (refund) => {
        const refundValue: Currency = {
          amount:
            refund.refundValue.amount > 0
              ? -refund.refundValue.amount
              : refund.refundValue.amount,
          currencyCode: refund.refundValue.currencyCode,
        };
        return new ValueListPipe().transform(
          refundValue,
          this.localeService.getCurrentLocale()
        );
      },
      columnHeadingStylingTransformer: () => 'width: 170px;',
      columnStylingTransformer: () => 'width: 170px;',
      text: $localize`:@@refunds.columns.refund:Retur`,
    },
    {
      filterable: false,
      type: 'status',
      text: $localize`:@@refunds.columns.status.text:Status`,
      columnDataTransformer: (refund) => refund.statusText,
      filterOptions: {
        text: $localize`:@@refunds.columns.status.filterOptions.text:Status`,
        value: 'status',
        activeFilter: null,
        options: [
          {
            headerText: $localize`:@@refunds.columns.status.filterOptions.all.headerText:alla`,
            displayText: $localize`:@@refunds.columns.status.filterOptions.all.displayText:Visa alla`,
            value: null,
          },
          {
            headerText: $localize`:@@refunds.columns.status.filterOptions.approved.headerText:godkända`,
            displayText: $localize`:@@refunds.columns.status.filterOptions.approved.displayText:Godkända`,
            value:
              RefundStatus.PENDING_EXECUTION ||
              RefundStatus.EXECUTED ||
              RefundStatus.EXECUTION_INITIATED,
          },
          {
            headerText: $localize`:@@refunds.columns.status.filterOptions.denied.headerText:nekade`,
            displayText: $localize`:@@refunds.columns.status.filterOptions.denied.displayText:Nekade`,
            value: RefundStatus.CANCELLED,
          },
          {
            headerText: $localize`:@@refunds.columns.status.filterOptions.cancelled.headerText:makulerade`,
            displayText: $localize`:@@refunds.columns.status.filterOptions.cancelled.displayText:Makulerade`,
            value: RefundStatus.CANCELLED_BY_SYSTEM || RefundStatus.ERROR,
          },
        ],
      },
    },
  ];

  // Todo: implement this when the mobile view is designed
  refunds: TableRows<Refund, RefundStatus> = {
    getPrimaryText: (refund) =>
      new ValueListPipe().transform(
        refund.refundValue,
        this.localeService.getCurrentLocale()
      ),
    getSecondaryText: (refund) => refund.paymentRequestTitle,
    getStatusBarColor: (refund) => refund.statusColor,
    getBackgroundColor: (refund) => refund.backgroundColor,
    rows: [],
    hideMobileHeader: false,
    rowClickFunction: (refund) => this.openViewRefundModal(refund),
  };

  quickActions: QuickAction<Refund>[] = [
    {
      text: $localize`:@@refunds.quickActions.approveRefund.text:Godkänn retur`,
      iconUrl: 'assets/icons/Icon-Checkmark-Circle.svg',
      textColor: '#0A1018',
      disabled: false,
      displayCondition: (refund) => {
        return (
          refund.status === RefundStatus.PENDING_MERCHANT_APPROVAL &&
          this.activePage === RefundPage.REPORTED &&
          this.access.userMay(Permission.APPROVE_REFUND) &&
          this.refundService.currentUserMayApproveRefund(refund)
        );
      },
      topBorder: false,
      function: (refund) => this.openApprovalConfirmation(refund, true),
    },
    {
      text: $localize`:@@refunds.quickActions.denyRefund.text:Neka retur`,
      iconUrl: 'assets/icons/Icon-Delete-Cerise.svg',
      textColor: this.colorService.getCeriseColorCode(),
      disabled: false,
      displayCondition: (refund) =>
        refund.status === RefundStatus.PENDING_MERCHANT_APPROVAL &&
        this.activePage === RefundPage.REPORTED &&
        this.access.userMay(Permission.CANCEL_REFUND),
      topBorder: false,
      function: (refund) => {
        this.openCancellingConfirmation(refund, true);
      },
    },
  ];

  invokedFromList = false;

  RefundPage = RefundPage;
  activePage = RefundPage.REPORTED;
  tabs: PageTab<RefundPage>[] = [
    {
      value: RefundPage.REPORTED,
      label: $localize`:@@refunds.tabs.reported:Rapporterade returer`,
    },
    {
      value: RefundPage.MANAGED,
      label: $localize`:@@refunds.tabs.managed:Hanterade`,
    },
  ];

  selectedRefund: Refund;
  merchantHasRefunds = false;

  viewRefund = false;
  confirmApproval = false;
  confirmDeclining = false;

  modalActive = false;
  smallModalActive = false;
  modalColor: string;
  modalTitle: string;

  fetchingRefunds = true;
  fetchingStores = true;
  searchQuery = '';
  searchQueryChanged: Subject<string> = new Subject<string>();
  statusFiter: string = RefundStatus.PENDING_MERCHANT_APPROVAL;

  storesFilter = '';
  storesFilterOptions: { label: string; value: string }[] = null;

  paginationState: PaginationState = {
    page: 1,
    pageSize: 10,
    total: 0,
    numOfItems: 0,
  };

  displayEmptyState = false;
  currentEmptyState: EmptyState;
  noRefundsAtAllEmptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Waving-Hand.png',
    headingText: $localize`:@@refunds.emptyState.noRefundsAtAllEmptyState.headingText:Välkommen&nbsp;till&nbsp;returer!`,
    bodyText: $localize`:@@refunds.emptyState.noRefundsAtAllEmptyState.bodyText:Så fort en retur har skapats så är det här du kommer kunna&nbsp;se&nbsp;den.`,
  };
  noReportedRefundsEmptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Girl-Laptop.png',
    headingText: $localize`:@@refunds.emptyState.noReportedRefundsEmptyState.headingText:Här&nbsp;är&nbsp;det&nbsp;lungt`,
    bodyText: $localize`:@@refunds.emptyState.noReportedRefundsEmptyState.bodyText:Det finns inga returer som behöver din uppmärksamhet&nbsp;just&nbsp;nu.`,
  };
  noManagedRefundsEmptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Female-Detective.png',
    headingText: $localize`:@@refunds.emptyState.noManagedRefundsEmptyState.headingText:Hanterade&nbsp;returer`,
    bodyText: $localize`:@@refunds.emptyState.noManagedRefundsEmptyState.bodyText:Här kommer alla hanterade returer samlas så du lätt kan hitta och gå tillbaka&nbsp;i&nbsp;historiken.`,
  };
  noRefundsSearchedEmptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Emoji-Monocle.png',
    headingText: $localize`:@@refunds.emptyState.noRefundsSearchedEmptyState.headingText:Vi hittade ingen match för&nbsp;sökningen`,
    bodyText: $localize`:@@refunds.emptyState.noRefundsSearchedEmptyState.bodyText:Ge det ett till försök. Prova&nbsp;igen.`,
  };
  noRefundsFilteredEmptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Emoji-Ghost.png',
    headingText: $localize`:@@refunds.emptyState.noRefundsFilteredEmptyState.bodyText:Här var det tomt just&nbsp;nu`,
  };

  statePushedToHistory = false;

  // Event listener for closing full screen modals on backpress
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    if (this.deviceIsMobile()) {
      if (this.statePushedToHistory) {
        this.statePushedToHistory = false;
      }
      if (this.modalActive) {
        this.closeModal();
      } else if (this.smallModalActive) {
        this.returnToViewModal(this.selectedRefund);
      }
    }
  }

  ngOnInit(): void {
    if (window.location.hash === '#modal') {
      history.back();
    }
    this.fetchRefunds();

    if (this.appStateService.repHasStoreAccess()) {
      this.setupStores();
    }
    window.onbeforeunload = () => this.ngOnDestroy();
  }

  ngOnDestroy(): void {
    if (this.deviceIsMobile()) {
      if (this.modalActive) {
        this.closeModal();
      } else if (this.smallModalActive) {
        this.closeSmallModal();
      }
    }
  }

  fetchRefunds() {
    this.fetchingRefunds = true;
    this.displayEmptyState = false;
    this.refundService
      .search(
        this.searchQuery,
        this.statusFiter,
        this.storesFilter,
        this.paginationState.page - 1, // page-numbering is zero based on the server
        this.paginationState.pageSize
      )
      .subscribe(
        (res) => {
          this.updateListWithResponse(res);
          this.fetchingRefunds = false;
        },
        (error: HttpErrorResponse) => {
          this.handleError(error);
          this.fetchingRefunds = false;
        }
      );
  }

  updateListWithResponse(res: PaginatedList<Refund>) {
    const refunds = res.elements.map(Refund.setup);
    if (this.activePage === RefundPage.REPORTED) {
      this.refunds.rows = refunds.map((refund) =>
        this.setupQuickActionsForRefund(refund)
      );
    } else {
      this.refunds.rows = refunds.map((refund) => ({
        data: refund,
        quickActions: null,
      }));
    }
    this.paginationState.numOfItems = this.refunds.rows.length;
    this.paginationState.page = res.page + 1; // page-numbering is zero based on the server
    this.paginationState.total = res.total;
    if (this.paginationState.numOfItems === 0) {
      this.setEmptyState();
    } else {
      this.merchantHasRefunds = true;
    }
    this.fetchingRefunds = false;
  }

  setupQuickActionsForRefund(refund: Refund): TableRow<Refund> {
    return {
      data: refund,
      quickActions: this.quickActions.filter((action) =>
        action.displayCondition(refund)
      ),
    };
  }

  setEmptyState() {
    if (
      (this.activePage === RefundPage.REPORTED && this.storesFilter !== '') ||
      (this.activePage === RefundPage.MANAGED &&
        (this.statusFiter !== this.defaultManagedPageStatusFilter ||
          this.storesFilter !== ''))
    ) {
      // No refunds returned after filtering
      this.currentEmptyState = this.noRefundsFilteredEmptyState;
    } else if (this.searchQuery !== '') {
      // No refunds returned after searching
      this.currentEmptyState = this.noRefundsSearchedEmptyState;
    } else {
      // No refunds retured despite no filtering and no searching
      if (this.activePage === RefundPage.MANAGED) {
        this.currentEmptyState = this.noManagedRefundsEmptyState;
      } else {
        if (this.merchantHasRefunds) {
          this.currentEmptyState = this.noReportedRefundsEmptyState;
          this.displayEmptyState = true;
        } else {
          // We need to know if there exists any refunds at all
          this.refundService.search().subscribe((res) => {
            if (res.total === 0) {
              // No refunds exists at all
              this.currentEmptyState = this.noRefundsAtAllEmptyState;
            } else {
              // There exist refunds, but none that that have the status PENDING_MERCHANT_APPROVAL
              this.currentEmptyState = this.noReportedRefundsEmptyState;
              this.merchantHasRefunds = true;
            }
            this.displayEmptyState = true;
          });
          return;
        }
      }
    }
    this.displayEmptyState = true;
  }

  setupStores() {
    this.fetchingStores = true;
    this.storeService
      .get()
      .then((res) => {
        if (res.length > 0) {
          this.storesFilterOptions = [
            {
              // prettier-ignore
              label: $localize`:@@refunds.storesFilterOptions.all.label:Visa alla ${this.getStoreAlias(true)}`,
              value: '',
            },
          ];
          res.forEach((store) =>
            this.storesFilterOptions.push({
              label: store.name,
              value: store.id,
            })
          );
        }
        this.fetchingStores = false;
      })
      .catch((error) => {
        this.handleError(error);
        this.fetchingStores = false;
      });
  }

  getStoreAlias(plural: boolean): string {
    return this.customizationService.getStoreAlias(plural);
  }

  setActivePage(page: RefundPage) {
    if (this.activePage === page) {
      return;
    }

    this.activePage = page;
    if (this.activePage === RefundPage.REPORTED) {
      this.statusFiter = RefundStatus.PENDING_MERCHANT_APPROVAL;
      this.columns[this.columns.length - 1].filterable = false;
    } else {
      this.statusFiter = this.defaultManagedPageStatusFilter;
      this.columns[this.columns.length - 1].filterable = true;
    }
    this.columns[this.columns.length - 1].filterOptions.activeFilter = null;
    this.paginationState.page = 1;
    this.fetchRefunds();
  }

  onStoresFilterChange(event: string) {
    this.storesFilter = event;
    this.paginationState.page = 1;
    this.fetchRefunds();
  }

  onFilterChange(event: {
    type: string;
    filterOption: FilterOption<RefundStatus>;
  }) {
    this.statusFiter = event.filterOption.value;
    const column = this.columns.find((col) => col.type === event.type);
    column.filterOptions.activeFilter = event.filterOption.value;
    this.paginationState.page = 1;
    this.fetchRefunds();
  }

  onPageChange(page: number) {
    this.paginationState.page = page;
    this.fetchRefunds();
  }

  onPageSizeChange(pageSize: number) {
    this.paginationState.page = 1;
    this.paginationState.pageSize = pageSize;
    this.fetchRefunds();
  }

  /*
  Modal functions
  */
  onHandled() {
    this.closeModal();
    this.closeSmallModal();
    this.fetchRefunds();
  }

  openViewRefundModal(refund: Refund) {
    this.selectedRefund = refund;
    this.openModal(refund);
  }

  returnToViewModal(refund: Refund) {
    this.closeSmallModal();
    if (this.invokedFromList) {
      this.invokedFromList = false;
      return;
    }
    this.openViewRefundModal(refund);
  }

  openApprovalConfirmation(refund: Refund, invokedFromList: boolean) {
    this.invokedFromList = invokedFromList;
    this.closeModal();
    this.selectedRefund = refund;
    this.openSmallModal('approve');
  }

  openCancellingConfirmation(refund: Refund, invokedFromList: boolean) {
    this.invokedFromList = invokedFromList;
    this.closeModal();
    this.selectedRefund = refund;
    this.openSmallModal('cancel');
  }

  openSmallModal(modalType: 'approve' | 'cancel') {
    switch (modalType) {
      case 'approve':
        this.modalColor = '#00A880';
        this.modalTitle = $localize`:@@refunds.modal.approve.modalTitle:Godkänn retur`;
        this.confirmApproval = true;
        break;
      case 'cancel':
        this.modalColor = this.colorService.getCeriseColorCode();
        this.modalTitle = $localize`:@@refunds.modal.deny.modalTitle:Neka retur`;
        this.confirmDeclining = true;
        break;
    }
    this.smallModalActive = true;
    if (!this.statePushedToHistory && this.deviceIsMobile()) {
      history.pushState(
        null,
        null,
        `${this.localeService.getCurrentLocaleBaseHref()}/admin/refunds#modal`
      );
      this.statePushedToHistory = true;
    }
  }

  closeSmallModal() {
    this.smallModalActive = false;
    this.confirmApproval = false;
    this.confirmDeclining = false;
  }

  openModal(refund: Refund) {
    if (this.activePage === RefundPage.REPORTED) {
      this.modalColor = '#4466EE';
      this.modalTitle = $localize`:@@refunds.modal.reported.modalTitle:Rapporterad retur`;
    } else {
      this.modalColor = refund.statusColor;
      this.modalTitle = $localize`:@@refunds.modal.managed.modalTitle:${refund.statusText} retur`;
    }
    this.viewRefund = true;
    this.modalActive = true;
    if (!this.statePushedToHistory && this.deviceIsMobile()) {
      history.pushState(
        null,
        null,
        `${this.localeService.getCurrentLocaleBaseHref()}/admin/refunds#modal`
      );
      this.statePushedToHistory = true;
    }
  }

  closeModal() {
    this.modalActive = false;
    this.viewRefund = false;
    if (this.statePushedToHistory && this.deviceIsMobile()) {
      history.back();
    }
  }

  deviceIsMobile() {
    return window.innerWidth <= 520;
  }
}
