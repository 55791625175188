import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface LanguageSelection {
  flagLink: string;
  text: string;
  locale: string;
  subPath: string;
}

@Component({
  selector: 'app-language-selection',
  templateUrl: './language-selection.component.html',
  styleUrls: ['./language-selection.component.scss'],
})
export class LanguageSelectionComponent implements OnInit {
  @Input() showDropdown = false;

  languages: LanguageSelection[] = [
    {
      flagLink: 'assets/flags/se.svg',
      text: 'Sweden',
      locale: 'sv',
      subPath: 'se',
    },
    {
      flagLink: 'assets/flags/de.svg',
      text: 'Germany',
      locale: 'de',
      subPath: 'de',
    },
    {
      flagLink: 'assets/flags/at.svg',
      text: 'Austria',
      locale: 'de-AT',
      subPath: 'at',
    },
  ];

  @Output() localeSelected = new EventEmitter<LanguageSelection>();
  @Output() dropdownClosed = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  setLocale(language: LanguageSelection) {
    this.localeSelected.emit(language);
    this.showDropdown = false;
  }

  closeDropdown() {
    this.showDropdown = false;
    this.dropdownClosed.emit();
  }
}
