import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AppStateService } from '@core/service/app-state.service';
import { Observable } from 'rxjs';

@Injectable()
export class MustBeLoggedIn implements CanActivate {

  constructor(private appStateService: AppStateService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    if (this.appStateService.hasCurrentUser()) {
      return true;
    }
    return this.appStateService.refreshCurrentUser()
      .then(() => {
        return true;
      })
      .catch(() => {
        this.router.navigate(['/login']);
        return false;
      });
  }

}
