import { Injectable } from '@angular/core';
import { RestBase } from '@core/rest-base';
import { HttpClient, HttpParams, HttpResponse, HttpHeaders } from '@angular/common/http';
import { HrefService } from './href.service';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { stringify } from 'querystring';

@Injectable()
export class BannerService extends RestBase {

  baseHeaders: HttpHeaders;

  private readonly httpReqOptions = { withCredentials: true };

  constructor(private httpClient: HttpClient, hrefService: HrefService) {
    super(httpClient, hrefService.getApiUrl());
  }

  public getActiveBanner() {
    const url = `${environment.apiUrl}/banner/active`;
    return this.httpClient.get<BannerDto>(url, {observe: 'response', ...this.httpReqOptions});
  }
}

export interface BannerDto {
    displayBanner: boolean;
    message: string;
}




