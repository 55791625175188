import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { LoggingService } from "./service/logging.service";
import * as StackTrace from 'stacktrace-js';
import { environment } from "@environments/environment";

@Injectable()
export class ErrorResponseInterceptor implements HttpInterceptor {

    constructor(
        private logger: LoggingService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .pipe(
                filter(event => event instanceof HttpResponse),
                tap(() => {},
                err => {
                    let response = err as HttpResponse<any>;
                    if (response.status != 403 && response.url != `${environment.apiUrl}/log/crash`) {
                        StackTrace.get().then(trace => this.logger.reportCrash("unexpected http error response in zfb:" + JSON.stringify(response), trace));
                    }
                })
            );
    }
}