import { Component, Input, OnInit } from '@angular/core';

export interface ReportTableRows<T> {
  data: T[];
  rowClickFunction?: (it: T) => void;
}

export interface ReportColumnDefinition<T> {
  cssClasses?: string;
  columnDataTransformer?: (it: T) => string; // Returns '' if not set
  columnStylingTransformer?: (it: T) => string; // Returns '' if not set
  text?: string;
}

@Component({
  selector: 'app-report-table',
  templateUrl: './report-table.component.html',
  styleUrls: ['./report-table.component.scss'],
})
export class ReportTableComponent<T> implements OnInit {
  @Input() rows: ReportTableRows<T>;
  @Input() columns: ReportColumnDefinition<T>[];

  constructor() {}

  ngOnInit(): void {}

  rowClicked(row: T) {
    if (!!this.rows.rowClickFunction) {
      this.rows.rowClickFunction(row);
    }
  }

  getColumnStyling(column: ReportColumnDefinition<T>, row: T) {
    if (column.columnStylingTransformer) {
      return column.columnStylingTransformer(row);
    }
    return '';
  }
}
