<form [formGroup]="form" id="otp-input-form">
  <div class="input-row">
    <input
      #input
      class="token-input"
      [ngClass]="{ small: this.maxLength == 4 }"
      id="input"
      type="text"
      [maxLength]="maxLength"
      inputmode="numeric"
      pattern="[0-9]"
      formControlName="token"
      [autocomplete]="IOS ? 'one-time-code' : 'off'"
      (paste)="onPaste($event)"
    />
    <div
      class="underline"
      [class.invalid]="form.controls.token.invalid && showError"
      [ngClass]="{ small: this.maxLength == 4 }"
    ></div>
  </div>
</form>
