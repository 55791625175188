import {Injectable} from '@angular/core';
import {CompanyRepresentative} from '../dto/CompanyRepresentative';
import {RestBase} from '../rest-base';
import {HttpClient} from '@angular/common/http';
import {HrefService} from './href.service';
import {environment} from '@environments/environment';

@Injectable()
export class CompanyRepresentativeService extends RestBase {

  constructor(private httpClient: HttpClient, hrefService: HrefService) {
    super(httpClient, hrefService.getApiUrl());
  }

  public get (): Promise<CompanyRepresentative[]> {
    if (environment.useFixtures) {
      return this.httpClient.get<CompanyRepresentative[]>(
          '/assets/fixtures/companyRepresentatives.json'
        ).toPromise();
    }
    return super.get('/companyRepresentative');
  }

  public getOne(id: string): Promise<CompanyRepresentative> {
    return super.get('/companyRepresentative/' + id);
  }

  public create(companyRepresentative: CompanyRepresentative): Promise<CompanyRepresentative> {
    return super.post('/companyRepresentative', JSON.stringify(companyRepresentative));
  }

  public update(companyRepresentative: CompanyRepresentative): Promise<CompanyRepresentative> {
    return super.put('/companyRepresentative', JSON.stringify(companyRepresentative));
  }

  public setInactive(id: string): Promise<CompanyRepresentative> {
    return super.put('/companyRepresentative/status/' + id, JSON.stringify('INACTIVE')); // TODO(mattias): Use constant.
  }

  public setActive(id: string): Promise<CompanyRepresentative> {
    return super.put('/companyRepresentative/status/' + id, JSON.stringify('ACTIVE')); // TODO(mattias): Use constant.
  }
}
