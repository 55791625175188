import { NgModule } from '@angular/core';
import { PaymentManagementComponent } from '../components/payment-management/payment-management.component';
import { LineItemTableComponent } from '../components/line-item-table/line-item-table.component';
import { LineItemInputComponent } from '../components/line-item-input/line-item-input.component';
import { OrderDetailsComponent } from '../components/order-details/order-details.component';
import { SimpleLineItemTableComponent } from '../components/simple-line-item-table/simple-line-item-table.component';
import { ViewOrderComponent } from '../components/view-order/view-order.component';
import { CancelOrderComponent } from '../components/cancel-order/cancel-order.component';
import { PartialCancelOrderComponent } from '../components/partial-cancel-order/partial-cancel-order.component';
import { WidgetEventTypePillComponent } from '../components/event-type-pill/event-type-pill.component';
import { ConfirmCaptureComponent } from '../components/confirm-capture/confirm-capture.component';
import { WidgetHeaderComponent } from '../components/widget-header/widget-header.component';
import { WidgetPaginationComponent } from '../components/widget-pagination/widget-pagination.component';
import { AmountPipe } from '@shared/pipe/value.pipe';
import { VatDropdownComponent } from '../components/vat-dropdown/vat-dropdown.component';
import { PaymentManagementService } from '../services/payment-management.service';
import { CurrencyPipe } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { ComponentLibraryModule } from '../../../../web-component-library/projects/component-library/src/public-api';
import { ReactiveFormsModule } from '@angular/forms';
import { HrefService } from '@core/service/href.service';
import { RefundLineItemTableComponent } from '../components/refund-line-item-table/refund-line-item-table.component';
import { ConfirmRefundComponent } from '../components/confirm-refund/confirm-refund.component';
import { FixedAmountRefundComponent } from '../components/fixed-amount-refund/fixed-amount-refund.component';
import { ConfirmFixedAmountRefundComponent } from '../components/confirm-fixed-amount-refund/confirm-fixed-amount-refund.component';

@NgModule({
  declarations: [
    PaymentManagementComponent,
    LineItemTableComponent,
    LineItemInputComponent,
    OrderDetailsComponent,
    SimpleLineItemTableComponent,
    ViewOrderComponent,
    CancelOrderComponent,
    PartialCancelOrderComponent,
    WidgetEventTypePillComponent,
    ConfirmCaptureComponent,
    WidgetHeaderComponent,
    WidgetPaginationComponent,
    AmountPipe,
    VatDropdownComponent,
    RefundLineItemTableComponent,
    ConfirmRefundComponent,
    FixedAmountRefundComponent,
    ConfirmFixedAmountRefundComponent,
  ],
  exports: [
    PaymentManagementComponent,
    LineItemTableComponent,
    LineItemInputComponent,
    OrderDetailsComponent,
    SimpleLineItemTableComponent,
    ViewOrderComponent,
    CancelOrderComponent,
    PartialCancelOrderComponent,
    WidgetEventTypePillComponent,
    ConfirmCaptureComponent,
    WidgetHeaderComponent,
    WidgetPaginationComponent,
    AmountPipe,
    VatDropdownComponent,
    RefundLineItemTableComponent,
    ConfirmRefundComponent,
    FixedAmountRefundComponent,
    ConfirmFixedAmountRefundComponent,
  ],
  providers: [CurrencyPipe, PaymentManagementService, AmountPipe, HrefService],
  imports: [SharedModule, ComponentLibraryModule, ReactiveFormsModule],
})
export class ExampleModule {}
