import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild} from '@angular/router';
import {Observable} from 'rxjs';
import {AppStateService} from '@core/service/app-state.service';

@Injectable()
export class MustHaveSelectedProfile implements CanActivateChild {

  constructor(private router: Router, private appState: AppStateService) {
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.appState.getActiveRepresentation()) {
      // There is an active profile selected.
      if (this.appState.isRejected()) {
        // the selected profile is a company that has been rejected by Zaver
        this.router.navigate(['/unApproved'], {replaceUrl: true});
        return false;
      }
      if (!this.appState.isSignedUp()) {
        // the selected profile is not fully signed up yet => redirect to signup
        this.router.navigate(['/signup/step1'], {replaceUrl: true});
        return false;
      }
      if (!this.appState.isApproved()) {
        // the selected profile is signed up and awaiting approval by Zaver
        this.router.navigate(['/pendingApproval'], {replaceUrl: true});
        return false;
      }
      // the selected profile is signed up & approved by Zaver => let the user in
      return true;
    }

    if (this.appState.getAvailableRepresentations().length >= 1) {
      // There is no selected profile, but there are some to choose from.
      // In case there is only one available profile we expect the backend to have preselected this profile when logging in.
      // Here we can expect there to be 2 or more profiles and the user should be directed to a profile selection page.
      this.router.navigate(['/selectProfile'], {skipLocationChange: false, replaceUrl: true}).then(() => false);
      return false;
    }

    // This user currently has no profiles. Send them to the Oops! page.
    this.router.navigate(['/oops'], {skipLocationChange: false, replaceUrl: true}).then(() => false);
    return false;
  }
}
