<div id="container">
  <span i18n="@@accountingReport.download.downloadAs">Hämta som:</span>

  <div class="radio-button-group">
    <div
      *ngIf="hasReportFormat(disbursementReportFormat.CSV_SEMICOLON)"
      class="radio-button-with-label"
      (click)="setFileType(disbursementReportFormat.CSV_SEMICOLON)"
    >
      <zaver-zfb-radio-button
        [color]="colorService.getCeriseColorCodeAsType()"
        [selected]="
          selectedFilePreference === disbursementReportFormat.CSV_SEMICOLON
        "
      ></zaver-zfb-radio-button>
      <span
        i18n="@@accountingReport.download.formatCsvSemicolon"
        class="radio-button-label"
      >
        CSV (.csv)
      </span>
    </div>

    <div
      *ngIf="hasReportFormat(disbursementReportFormat.CSV_COMMA)"
      class="radio-button-with-label"
      (click)="setFileType(disbursementReportFormat.CSV_COMMA)"
    >
      <zaver-zfb-radio-button
        [color]="colorService.getCeriseColorCodeAsType()"
        [selected]="
          selectedFilePreference === disbursementReportFormat.CSV_COMMA
        "
      ></zaver-zfb-radio-button>
      <span
        i18n="@@accountingReport.download.formatCsvComma"
        class="radio-button-label"
      >
        CSV (.csv) med kommatecken
      </span>
    </div>
    <div
      class="radio-button-with-label"
      (click)="setFileType(disbursementReportFormat.EXCEL_XLSX)"
    >
      <zaver-zfb-radio-button
        [color]="colorService.getCeriseColorCodeAsType()"
        [selected]="
          selectedFilePreference === disbursementReportFormat.EXCEL_XLSX
        "
      ></zaver-zfb-radio-button>
      <span
        i18n="@@accountingReport.download.formatExcelXlsx"
        class="radio-button-label"
      >
        Excel (.xlsx)
      </span>
    </div>
  </div>

  <!-- Bring this back when returns are implemented -->
  <!-- <div class="checkbox-with-label">
    <zaver-checkbox [(ngModel)]="includeRefunds"></zaver-checkbox>
    <span i18n="@@accountingReport.download.includeRefund"
      >Inkludera Avdrag/Returer</span
    >
  </div> -->

  <div id="buttons-container">
    <div class="button">
      <zaver-secondary-button-subtle
        style="width: auto"
        [text]="abortText"
        (click)="deactivateModal.emit()"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
    </div>
    <div class="button">
      <zaver-primary-button
        style="width: auto"
        [text]="exportText"
        [loading]="fetchingResponse"
        (click)="onDownload()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </div>
</div>
