import {Injectable} from '@angular/core';
import {RestBase} from '../rest-base';
import {HttpClient} from '@angular/common/http';
import {HrefService} from './href.service';

@Injectable()
export class AgreementService extends RestBase {

  constructor(httpClient: HttpClient, hrefService: HrefService) {
    super(httpClient, hrefService.getApiUrl());
  }

  getAgreement() {
    return super.getBlob('/agreement');
  }

  initializeSign() {
    // TODO(seb): remove the optional parameter after backed gets rid of it
    return super.get('/agreement/initializeSign?manual=false');
  }

  sign() {
    return super.get('/agreement/sign');
  }
}
