import { ReportRepresentations } from '@assets/language/ReportRepresentation';
import { ReportDisbursementType } from '@core/params';
import { Currency } from '../CurrencyCode';

export class ReportDisbursement {
  id: string;
  date: Date;
  reportType: string;
  count: number;
  value: Currency;
  paymentMethod: string;
  paymentReference: string;

  reportTypeColor: string;
  reportStatusText: string;

  static setup(reportDisbursement: ReportDisbursement) {
    let reportInfo = ReportRepresentations.get(reportDisbursement.reportType);
    reportDisbursement.reportTypeColor = reportInfo.reportTypeColor;
    reportDisbursement.reportStatusText = reportInfo.statusText;
  }
}

export class ReportDisbursementTotal {
  count: number;
  byCurrency: ReportDisbursementCurrencyTotal[];
}

export class ReportDisbursementCurrencyTotal {
  sum: Currency;
  count: number;
}
