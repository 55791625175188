import { CurrencyCode } from "./CurrencyCode";

export class B2bPaymentRequestRequest {
    phone: string;
    email: string;
    dueDate: Date;
    price: string;
    currencyCode: CurrencyCode;
    title: string;
    description: string;
    buyerLegalEntityId: string;

    constructor(
        phone: string,
        email: string,
        dueDate: Date,
        price: string,
        title: string,
        description: string,
        buyerLegalEntityId: string ) {
            this.phone = phone;
            this.email = email.trim();
            this.dueDate = dueDate;
            this.price = price.replace(',', '.');
            this.currencyCode = CurrencyCode.SEK;
            this.title = title.trim();
            this.description = description.trim();
            this.buyerLegalEntityId = buyerLegalEntityId;
      }
}
