import {
  B2BPaymentRequestRepresentations,
  PaymentRequestRepresentation,
  PaymentRequestRepresentations,
} from '@assets/language/PaymentRequestRepresentation';
import { Currency } from './CurrencyCode';
import { Language } from './Language';
import { Market } from './Market';
import { PaymentRequestStatus } from '@core/params';
import { LineItem } from './paymentRequest/paymentRequest';

export interface B2bPaymentData {
  buyerEmail: string;
  dueDate: Date;
  nin: string;
  status: string;
  buyerCompanyName: string;
  invoiceReference: string;
}

export class PaymentRequestResponse {
  title: string;
  description: string;
  value: Currency;

  market: Market;
  language: Language;
  phone: string;

  id: string;
  created: Date;
  dateDisplay: string;
  status: string;
  statusText: string;
  statusColor: string;
  backgroundColor: string;
  checkoutLink: string;
  completedAt: Date;
  cancelledAt: Date;
  latestCaptureTimeStamp: Date;
  buyerName: string;
  price: number;
  buyerType: 'CONSUMER' | 'COMPANY';
  b2bPaymentData: B2bPaymentData;
  paymentMethod: string;
  buyerEmail: string;
  buyerCityAddress: string;
  buyerZipAddress: string;
  buyerStreetAddress: string;
  buyerBirthDate: Date;
  capturedAmount: Currency;
  representativeName: string;
  representativeEmail: string;
  storeName: string;
  pendingRefundableValue: number;
  hideInstallmentOptions?: boolean;
  lineItems: LineItem[];
  expiryDate: Date;
  settledLoanOfferId: string;

  to;

  refundedValue: Currency;
  refundableValue: Currency;

  static setup(paymentRequestResponse: PaymentRequestResponse) {
    if (paymentRequestResponse.status === 'PENDING_BUYER_REGISTRATION') {
      paymentRequestResponse.to = paymentRequestResponse.phone;
    } else {
      paymentRequestResponse.to = paymentRequestResponse.buyerName;
    }
    let statusInfo: PaymentRequestRepresentation;
    if (
      paymentRequestResponse.status === PaymentRequestStatus.OPEN_REFUND ||
      paymentRequestResponse.status === PaymentRequestStatus.PARTLY_REFUNDED ||
      paymentRequestResponse.status === PaymentRequestStatus.FULLY_REFUNDED
    ) {
      statusInfo = PaymentRequestRepresentations.get(
        paymentRequestResponse.status
      );
    } else if (paymentRequestResponse.buyerType === 'COMPANY') {
      statusInfo = B2BPaymentRequestRepresentations.get(
        paymentRequestResponse.b2bPaymentData.status
      );
    } else {
      statusInfo = PaymentRequestRepresentations.get(
        paymentRequestResponse.status
      );
    }
    if (!statusInfo) {
      statusInfo = PaymentRequestRepresentations.get('_default_');
    }
    if (
      paymentRequestResponse.status === 'PENDING_MERCHANT_SIGN_OFF' &&
      paymentRequestResponse.capturedAmount &&
      paymentRequestResponse.capturedAmount.amount <
        paymentRequestResponse.price &&
      paymentRequestResponse.capturedAmount.amount > 0
    ) {
      statusInfo = PaymentRequestRepresentations.get('PARTIALLY_CAPTURED');
    }

    paymentRequestResponse.statusText = statusInfo.friendlyStatus;
    paymentRequestResponse.statusColor = statusInfo.textColor;
    paymentRequestResponse.backgroundColor = statusInfo.backgroundColor;
  }
}
