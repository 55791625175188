import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserAgentService {

  constructor() { }

  public getAndroidVersion(): string | undefined {
    const useragent = (navigator.userAgent).toLowerCase(); 
    const match = useragent.match(/android\s([0-9\.]*)/i);
    return match ? match[1] : undefined;
  }
}
