import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'select-locale',
  templateUrl: './select-locale.component.html',
  styleUrls: ['./select-locale.component.scss'],
})
export class SelectLocaleComponent implements OnInit {
  @Input() items: any;
  @Output() selectOption: EventEmitter<any> = new EventEmitter();

  @Input() imgSrc: string;

  constructor() {}

  ngOnInit() {}

  select($event) {
    this.selectOption.emit($event.target.value);
  }
}
