import { OnInit, Component, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Market } from '@core/dto/Market';
import { CompanyRepresentation, CompanyStatus } from '@core/dto/user-details';
import { AppStateService } from '@core/service/app-state.service';
import { AuthenticationService } from '@core/service/authentication.service';
import { LocaleService } from '@core/service/locale.service';

@Component({
  selector: 'app-profile-selection-list',
  templateUrl: './profile-selection-list.component.html',
  styleUrls: ['./profile-selection-list.component.css'],
})
export class ProfileSelectionListComponent implements OnInit {
  @Output()
  onProfileSelected = new EventEmitter();

  availableRepresentations: CompanyRepresentation[];

  constructor(
    private appStateSvc: AppStateService,
    private authSvc: AuthenticationService,
    private router: Router,
    private localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.availableRepresentations = this.appStateSvc
      .getAvailableRepresentations()
      .filter((rep) =>
        rep.company.status.find((status) => status === CompanyStatus.APPROVED)
      );
  }

  selectProfile(representation: CompanyRepresentation) {
    this.authSvc.switchProfile(representation).then((userDetails) => {
      this.onProfileSelected.emit(userDetails);

      if (
        this.localeService.getCurrentLocaleMarket() ===
        userDetails.activeRepresentation.company.market
      ) {
        // The same market om previous and new merchant, just navigate
        // do a dummy navigation first to ensure refresh
        this.dummyNavigate();
      } else {
        // The new merchant has a different market than the present one, we must change locale
        let merchantMarket = userDetails.activeRepresentation.company.market;
        merchantMarket =
          merchantMarket === Market.NO ? Market.SE : merchantMarket;
        switch (merchantMarket) {
          case Market.SE:
          case Market.DE:
          case Market.AT:
            location.href = '/' + merchantMarket.toLowerCase() + '/';
            return;
          default: // Stay on the same market
            console.error('Unsupported merchant market: ' + merchantMarket);
            this.dummyNavigate();
            return;
        }
      }
    });
  }

  dummyNavigate() {
    this.router
      .navigate(['/refresh'], {
        skipLocationChange: true,
        replaceUrl: false,
      })
      .then(() => this.router.navigate(['/'], { skipLocationChange: false }))
      .catch(() => this.router.navigate(['/'], { skipLocationChange: false }));
  }
}
