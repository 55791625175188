import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-widget-event-type-pill',
  templateUrl: './event-type-pill.component.html',
  styleUrls: ['./event-type-pill.component.css'],
})
export class WidgetEventTypePillComponent implements OnInit {
  @Input() text: string;
  @Input() textColor: string;
  @Input() backgroundColor: string;

  constructor() {}

  ngOnInit(): void {}
}
