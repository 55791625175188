import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AppStateService } from '@core/service/app-state.service';

@Injectable()
export class RepresentativeInterceptor implements HttpInterceptor {

    constructor(private route: ActivatedRoute, private appStateService: AppStateService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let representativeId = this.appStateService.getActiveRepresentation() != null ?
            this.appStateService.getActiveRepresentation().id : '';

        const cloneReq = req.clone({ headers: req.headers.set('X-Company-Representative', representativeId) });

        return next.handle(cloneReq);
    }
}
