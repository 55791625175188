import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@core/base.component';
import { AuthenticationService } from '@core/service/authentication.service';

@Component({
  selector: 'app-zfb-merchant-settings-confirmation',
  templateUrl: './zfb-merchant-settings-confirmation.component.html',
})
export class zfbMerchantSettingsConfirmationComponent
  extends BaseComponent
  implements OnInit
{
  @Output() onClose = new EventEmitter();
  @Output() onSubmit = new EventEmitter();

  @Input() serverError: boolean;
  @Input() errorMessage: string;
  @Input() submitting: boolean;

  cancelText: string = $localize`:@@zfbMerchantSettings.return.modal-settings.button.text:Tillbaka`;
  saveText: string = $localize`:@@zfbMerchantSettings.modal.confirm-save.button.text:Ja, spara`;
  closeText: string = $localize`:@@zfbMerchantSettings.close.text:Stäng`;
  tryAgainText: string = $localize`:@@zfbMerchantSettings.tryAgain.text:Försök igen`;

  serverErrorMessage = $localize`:@@zfbMerchantSettings.server-error.modal-settings.text:Ändringarna kunde inte sparas. Vänligen försök igen eller avbryt genom att stänga. <a class="link" href="mailto:merchants@zaver.se" target="_blank">Kontakta&nbsp;oss</a> om problemet kvarstår.`;
  serverErrorHeadingText = $localize`:@@zfbMerchantSettings.server-error.modal-settings.heading:Oops! Någonting gick fel.`;

  constructor(auth: AuthenticationService) {
    super(auth);
  }

  ngOnInit(): void {}

  closeModal() {
    this.onClose.emit();
  }

  submit() {
    this.onSubmit.emit();
  }
}
