<div class="spacing-container">
  <div>
    <div class="info-text-container">
      <div>
        <span i18n="@@createRefundAmount.refundedValue"
          >Tidigare godkända returer:</span
        ><span>{{
          paymentRequest.refundedValue | value: localeService.getCurrentLocale()
        }}</span>
      </div>
      <div>
        <span i18n="@@createRefundAmount.refundableValue"
          >Kvar att returnera:</span
        ><span>{{
          paymentRequest.refundableValue
            | value: localeService.getCurrentLocale()
        }}</span>
      </div>
    </div>
    <form [formGroup]="form">
      <label class="box-label">
        <div>
          <zaver-zfb-radio-button
            [selected]="form.controls.wholeAmount.value === 'true'"
            [color]="'cerise'"
          >
          </zaver-zfb-radio-button>
          <input
            style="display: none"
            type="radio"
            value="true"
            formControlName="wholeAmount"
          />
          <span i18n="@@createRefundAmount.wholeAmount">Hela beloppet</span>
        </div>
        <span>{{
          paymentRequest.refundableValue
            | value: localeService.getCurrentLocale()
        }}</span>
      </label>
      <label class="box-label">
        <div>
          <zaver-zfb-radio-button
            [selected]="form.controls.wholeAmount.value === 'false'"
            [color]="'cerise'"
          >
          </zaver-zfb-radio-button>
          <input
            style="display: none"
            type="radio"
            value="false"
            formControlName="wholeAmount"
          />
          <span i18n="@@createRefundAmount.partAmount">Del av beloppet</span>
        </div>
      </label>
      <div class="amount" *ngIf="form.controls.wholeAmount.value === 'false'">
        <zaver-form-input
          [showContent]="true"
          placeholder="Belopp"
          i18n-placeholder="@@createRefundAmount.form.amount.placeholder"
          [error]="form.controls.amount.invalid && showError"
          [errorMessage]="getAmountErrorMessage()"
          formControlName="amount"
          [inputMode]="'decimal'"
          [size]="'large'"
        >
          <span style="text-align: center; font-weight: 400; font-size: 14px">
            {{ paymentRequest.value.currencyCode }}
          </span>
        </zaver-form-input>
      </div>
      <div class="input-fields">
        <zaver-zfb-textarea
          title="Intern anteckning"
          i18n-title="@@createRefundAmount.form.note.title"
          placeholder="Lämna en anteckning om returen."
          i18n-placeholder="@@createRefundAmount.form.note.placeholder"
          formControlName="note"
          [error]="form.controls.note.invalid && showError"
          errorMessage="Fältet får inte lämnas tomt."
          i18n-errorMessage="@@createRefundAmount.form.note.errorMessage"
        >
        </zaver-zfb-textarea>
      </div>
    </form>
  </div>
  <div class="buttons-container">
    <zaver-primary-button
      text="Fortsätt"
      i18n-text="@@createRefundAmount.button.forward"
      (click)="checkFormAndAdvance()"
    >
    </zaver-primary-button>

    <zaver-secondary-button-subtle
      text="Tillbaka"
      i18n-text="@@createRefundAmount.button.back"
      (click)="changeStep.emit('backwards')"
    >
    </zaver-secondary-button-subtle>
  </div>
</div>
