import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

export interface PaginationState {
  page: number;
  pageSize: number;
  total: number;
  numOfItems: number;
}
@Component({
  selector: 'app-table-pagination',
  templateUrl: './table-pagination.component.html',
  styleUrls: ['./table-pagination.component.css'],
})
export class TablePaginationComponent implements OnInit {
  constructor(private elementRef: ElementRef) {}

  @Input() paginationState: PaginationState;

  pageSizeOptions = [10, 30, 50, 100, 200];
  firstPage: number;
  lastPage: number;

  showList = false;

  disableButtons = {
    first: false,
    prev: false,
    next: false,
    last: false,
  };

  @Output() pageChanged = new EventEmitter<number>();
  @Output() pageSizeChanged = new EventEmitter<number>();

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    if (this.showList) {
      this.showList = false;
    }
  }
  @HostListener('document:click', ['$event.target'])
  public onClick(target) {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside) {
      if (this.showList) {
        this.showList = false;
      }
    }
  }

  ngOnInit(): void {
    this.setup();
  }

  setup() {
    if (this.paginationState.total === 0) {
      // No rows in the table
      this.firstPage = 1;
      this.lastPage = 1;
    } else if (this.paginationState.total <= this.paginationState.pageSize) {
      this.firstPage = 1;
      this.lastPage = 1;
    } else {
      this.firstPage = 1;
      if (this.paginationState.total % this.paginationState.pageSize > 0) {
        this.lastPage =
          Math.floor(
            this.paginationState.total / this.paginationState.pageSize
          ) + 1;
      } else {
        this.lastPage = Math.floor(
          this.paginationState.total / this.paginationState.pageSize
        );
      }
    }
    this.setPageButtons();
  }

  setPageButtons() {
    if (this.firstPage === this.lastPage) {
      this.disableButtons.first = true;
      this.disableButtons.prev = true;
      this.disableButtons.next = true;
      this.disableButtons.last = true;
    } else if (
      this.paginationState.page > this.firstPage &&
      this.paginationState.page < this.lastPage
    ) {
      this.disableButtons.first = false;
      this.disableButtons.prev = false;
      this.disableButtons.next = false;
      this.disableButtons.last = false;
    } else if (this.paginationState.page === this.firstPage) {
      this.disableButtons.first = true;
      this.disableButtons.prev = true;
      this.disableButtons.next = false;
      this.disableButtons.last = false;
    } else if (this.paginationState.page === this.lastPage) {
      this.disableButtons.first = false;
      this.disableButtons.prev = false;
      this.disableButtons.next = true;
      this.disableButtons.last = true;
    }
  }

  toFirstPage() {
    this.disableButtons.first = true;
    this.disableButtons.prev = true;
    this.pageChanged.emit(this.firstPage);
    this.setPageButtons();
  }

  toPrevPage() {
    if (this.paginationState.page > this.firstPage) {
      this.pageChanged.emit(this.paginationState.page - 1);
    }
    this.setPageButtons();
  }

  toNextPage() {
    if (this.paginationState.page < this.lastPage) {
      this.pageChanged.emit(this.paginationState.page + 1);
    }
    this.setPageButtons();
  }

  toLastPage() {
    this.disableButtons.next = true;
    this.disableButtons.last = true;
    this.pageChanged.emit(this.lastPage);
    this.setPageButtons();
  }

  pageSizeChange(pageSize: number) {
    this.toggleList();
    if (pageSize !== this.paginationState.pageSize) {
      // New page size set
      this.pageSizeChanged.emit(pageSize);
    }
    this.setPageButtons();
  }

  toggleList() {
    this.showList = !this.showList;
  }
}
