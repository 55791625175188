import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaymentRequestEvent } from '@core/dto/PaymentRequestEvent';
import { RestBase } from '@core/rest-base';
import { environment } from '@environments/environment';
import { HrefService } from './href.service';

interface NoteRequest {
  note: string;
}
@Injectable()
export class EventsService extends RestBase {

  private readonly httpReqOptions = { withCredentials: true };

  constructor(
    private httpClient: HttpClient, 
    hrefService: HrefService
  ) {
    super(httpClient, hrefService.getApiUrl()); 
  }

  public getPaymentRequestEvents(paymentRequestId: string, eventTypeFilter: string): Promise<PaymentRequestEvent[]> {
    const url = `${environment.apiUrl}/events/paymentRequestEvents/` + paymentRequestId;

    let params = new HttpParams();
    if (eventTypeFilter) {
      params = params.append('eventTypeFilter', eventTypeFilter);
    }

    return this.httpClient.get<PaymentRequestEvent[]>(url, { ...this.httpReqOptions, params: params }).toPromise();

  }

  public createNote(paymentRequestId: string, note: string): Promise<PaymentRequestEvent> {
    const noteReqeust: NoteRequest = {
      note: note
    };
    return super.post('/events/createNote/' + paymentRequestId, JSON.stringify(noteReqeust));
  }
}
