import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RestBase} from '../rest-base';
import {HrefService} from './href.service';

@Injectable()
export class AccountService extends RestBase {

  constructor(httpClient: HttpClient, hrefService: HrefService) {
    super(httpClient, hrefService.getApiUrl());
  }

  public get(): Promise<Account[]> {
    return super.get('/account');
  }

  public create(account:Account): Promise<Account>{
    return super.post('/account', JSON.stringify(account));
  }
}
