import { Component, Input, OnInit } from '@angular/core';
import { PaymentData } from '@core/service/payment-request.service';

@Component({
  selector: 'app-b2b-payment-progressbar',
  templateUrl: './b2b-payment-progressbar.component.html',
  styleUrls: ['./b2b-payment-progressbar.component.css']
})

export class B2bPaymentProgressbarComponent implements OnInit {

  @Input() payments: PaymentData[];
  paidAmount: number;
  surplus: boolean = false;
  surplusAmount: number;
  processing: boolean = true;

  hidePaymentEvents: boolean = true;
  progressbarProcessWidth: string;

  @Input() totalAmount: number;

  constructor() { }

  ngOnInit(): void {
    if (this.payments) {
      this.calculatePaidAmount();
      this.calculateProgressBar();
    } else {
      this.paidAmount = 0;
      this.calculateProgressBar();
    }
    this.processing = false;
  }

  calculatePaidAmount() {
    let sum = 0;
    this.payments.forEach(it => sum += Number(it.amount))
    this.paidAmount = sum
  }

  calculateProgressBar() {
    if (this.paidAmount <= this.totalAmount) {
      this.progressbarProcessWidth = 'width: ' + (Math.round(this.paidAmount/this.totalAmount * 100)).toString() + '%;';
    } else {
      this.progressbarProcessWidth = 'width: ' + (Math.round(this.totalAmount/this.paidAmount * 100)).toString() + '%;';
      this.surplusAmount = this.paidAmount - this.totalAmount;
      this.surplus = true;
    }
  }

  togglePaymentEventList(){
    this.hidePaymentEvents = !this.hidePaymentEvents;
  }

}
