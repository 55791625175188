<div class="select-container">
  <div class="select-wrapper">
    <select class="select" (change)="select($event)">
      <option [value]="item.country" *ngFor="let item of items">
        {{ item.text }}
      </option>
    </select>
    <img class="flag" [src]="imgSrc" />
  </div>
  <ng-content></ng-content>
</div>
