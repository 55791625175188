import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RestBase } from '../rest-base';
import { HrefService } from './href.service';
import { FormObject } from '@zfb/page/company-adminstration/communication/report-recipient/report-recipient.component';
import { FilePreference } from '@zfb/page/report/accounting-report/accounting-report.component';

@Injectable()
export class ReportRecipientService extends RestBase {
  constructor(httpClient: HttpClient, hrefService: HrefService) {
    super(httpClient, hrefService.getApiUrl());
  }

  public updateCheckoutReportRecipients(
    emails: ReportRecipientDto[]
  ): Promise<ReportRecipientDto[]> {
    return super.post('/report/recipients/checkout', JSON.stringify(emails));
  }

  public getCheckoutReportRecipients(): Promise<ReportRecipientDto[]> {
    return super.get('/report/recipients/checkout');
  }

  public updateCashoutReportRecipients(
    emails: ReportRecipientDto
  ): Promise<ReportRecipientDto> {
    return super.post('/report/recipients/cashout', JSON.stringify(emails));
  }

  public getCashoutReportRecipients(): Promise<ReportRecipientDto> {
    return super.get('/report/recipients/cashout');
  }

  public updateRefundEmailRecipients(
    emails: ReportRecipientDto
  ): Promise<ReportRecipientDto> {
    return super.post('/report/recipients/refund', JSON.stringify(emails));
  }

  public getRefundEmailRecipients(): Promise<ReportRecipientDto> {
    return super.get('/report/recipients/refund');
  }
}

export interface ReportRecipientDto {
  reportRecipients: string[];
  id?: string;
  fileFormat?: FilePreference;
}

export const transformFormObjectToReportRecipientDto = (
  formObject: FormObject
): ReportRecipientDto => {
  return {
    reportRecipients: trimStrings(formObject.formArray.value),
    id: formObject.id,
  };
};

export const trimStrings = (emailAddresses: string[]): string[] => {
  return emailAddresses
    .filter((emailAddress) => !!emailAddress)
    .map((emailAddress) => emailAddress.trim());
};
