import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Market } from '@core/dto/Market';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  public getCurrentLocale(): string {
    return this.locale;
  }

  public getCurrentLocaleBaseHref(): string {
    switch (this.locale) {
      case 'sv':
        return '/se';
      case 'de':
        return '/de';
      case 'de-AT':
        return '/at';
      default:
        console.error(
          'Unknown locale not supported in Zaver for business: ',
          this.locale
        );
        return;
    }
  }

  public getCurrentLocaleMarket(): Market {
    switch (this.locale) {
      case 'sv':
        return Market.SE;
      case 'de':
        return Market.DE;
      case 'de-AT':
        return Market.AT;
      default:
        console.error(
          'Unknown locale not supported in Zaver for business: ',
          this.locale
        );
        return;
    }
  }
}
