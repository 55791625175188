<div class="radio-buttons-container" [class.columns]="seperateInTwoColumns">
  <div
    *ngFor="let option of options"
    class="radio-button-and-label"
    (click)="setSelected(option.value)"
    [class.disabled]="allDisabled"
  >
    <zaver-zfb-radio-button
      [selected]="selectedRadioValue === option.value"
      [color]="'cerise'"
      [disabled]="allDisabled"
    >
    </zaver-zfb-radio-button>
    <span>{{ option.label }}</span>
  </div>
</div>
<div
  class="radio-button-and-label"
  (click)="setSelected('OTHER')"
  [ngClass]="{ disabled: allDisabled }"
  style="margin-left: -8px"
  *ngIf="displayOtherOption"
>
  <zaver-zfb-radio-button
    [selected]="selectedRadioValue === 'OTHER'"
    [color]="'cerise'"
    [disabled]="allDisabled"
  >
  </zaver-zfb-radio-button>
  <input
    class="other-input"
    [attr.disabled]="allDisabled ? '' : null"
    [(ngModel)]="inputValue"
    (ngModelChange)="setSelected('OTHER')"
    placeholder="Annat"
    i18n-placeholder="@@radioSelect.other.placeholder"
  />
</div>

<app-form-error *ngIf="showRequiredError" [errorText]="errorMessage">
</app-form-error>
