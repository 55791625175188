import { PaymentRequestStatus, B2bPaymentStatus } from '@core/params';

export interface PaymentRequestRepresentation {
  friendlyStatus: string;
  textColor: string;
  backgroundColor?: string;
}

export const PaymentRequestRepresentations: Map<
  string,
  PaymentRequestRepresentation
> = new Map([
  [
    PaymentRequestStatus.BUYER_CANCELLED,
    {
      friendlyStatus: $localize`:@@paymentRequestStatus.BUYER_CANCELLED:Avbruten`,
      textColor: '#343B45',
      backgroundColor: '#E6E9EF',
    },
  ],
  [
    PaymentRequestStatus.CANCELLED,
    {
      friendlyStatus: $localize`:@@paymentRequestStatus.CANCELLED:Avbruten`,
      textColor: '#343B45',
      backgroundColor: '#E6E9EF',
    },
  ],
  [
    PaymentRequestStatus.EXPIRED,
    {
      friendlyStatus: $localize`:@@paymentRequestStatus.EXPIRED:Utgången`,
      textColor: '#858E9C',
      backgroundColor: '#F0F2F5',
    },
  ],
  [
    PaymentRequestStatus.CANCELLED_UPDATED,
    {
      friendlyStatus: $localize`:@@paymentRequestStatus.CANCELLED_UPDATED:Avbruten`,
      textColor: '#343B45',
      backgroundColor: '#E6E9EF',
    },
  ],
  [
    PaymentRequestStatus.OPEN,
    {
      friendlyStatus: $localize`:@@paymentRequestStatus.OPEN:Inväntar svar`,
      textColor: '#4466EE',
      backgroundColor: '#DBE7FF',
    },
  ],
  [
    PaymentRequestStatus.CONTROL,
    {
      friendlyStatus: $localize`:@@paymentRequestStatus.CONTROL:Under granskning`,
      textColor: '#7800fa',
    },
  ],
  [
    PaymentRequestStatus.SETTLED,
    {
      friendlyStatus: $localize`:@@paymentRequestStatus.SETTLED:Godkänd`,
      textColor: '#009773',
      backgroundColor: '#E5F5F2',
    },
  ],
  [
    PaymentRequestStatus.PENDING_MERCHANT_SIGN_OFF,
    {
      friendlyStatus: $localize`:@@paymentRequestStatus.PENDING_MERCHANT_SIGN_OFF:Redo för utbetalning`,
      textColor: '#DF5809',
      backgroundColor: '#FCEEE6',
    },
  ],
  [
    PaymentRequestStatus.PARTIALLY_CAPTURED,
    {
      friendlyStatus: $localize`:@@paymentRequestStatus.PARTIALLY_CAPTURED:Delvis godkänd`,
      textColor: '#CC7A00',
      backgroundColor: '#FEF4E5',
    },
  ],
  [
    PaymentRequestStatus.PENDING_CREATION,
    {
      friendlyStatus: $localize`:@@paymentRequestStatus.PENDING_CREATION:Skapas`,
      textColor: '#393939',
    },
  ],
  [
    PaymentRequestStatus.CREATED,
    {
      friendlyStatus: $localize`:@@paymentRequestStatus.CREATED:Behöver signeras`,
      textColor: '#2E7898',
    },
  ],
  [
    PaymentRequestStatus.PENDING_SIGN,
    {
      friendlyStatus: $localize`:@@paymentRequestStatus.PENDING_SIGN:Signeras`,
      textColor: '#4466EE',
      backgroundColor: '#DBE7FF',
    },
  ],
  [
    PaymentRequestStatus.PENDING_BUYER_REGISTRATION,
    {
      friendlyStatus: $localize`:@@paymentRequestStatus.PENDING_BUYER_REGISTRATION:Inväntar registrering`,
      textColor: '#570800',
    },
  ],
  [
    PaymentRequestStatus.OPEN_REFUND,
    {
      friendlyStatus: $localize`:@@paymentRequestStatus.OPEN_REFUND:Pågående retur`,
      textColor: '#6A45D6',
      backgroundColor: '#F0ECFB',
    },
  ],
  [
    PaymentRequestStatus.PARTLY_REFUNDED,
    {
      friendlyStatus: $localize`:@@paymentRequestStatus.PARTIALLY_REFUNDED:Delretur`,
      textColor: '#DB305E',
      backgroundColor: '#FDEBF0',
    },
  ],
  [
    PaymentRequestStatus.FULLY_REFUNDED,
    {
      friendlyStatus: $localize`:@@paymentRequestStatus.FULLY_REFUNDED:Returnerad`,
      textColor: '#DB305E',
      backgroundColor: '#FDEBF0',
    },
  ],
  [
    '_default_',
    {
      // fallback in case an unknown status is encountered
      friendlyStatus: $localize`:@@paymentRequestStatus.unknown:Processas`,
      textColor: '#393939',
    },
  ],
]);

export const B2BPaymentRequestRepresentations: Map<
  string,
  PaymentRequestRepresentation
> = new Map([
  [
    B2bPaymentStatus.INVOICE_SENT,
    {
      friendlyStatus: $localize`:@@b2bPaymentRequestStatus.INVOICE_SENT:Obetald`,
      textColor: '#4466EE',
      backgroundColor: '#DBE7FF',
    },
  ],
  [
    B2bPaymentStatus.INVOICE_PAST_DUE,
    {
      friendlyStatus: $localize`:@@b2bPaymentRequestStatus.INVOICE_PAST_DUE:Försenad`,
      textColor: '#BD0F3D',
      backgroundColor: '#F8E7EC',
    },
  ],
  [
    B2bPaymentStatus.INVOICE_PAID,
    {
      friendlyStatus: $localize`:@@b2bPaymentRequestStatus.INVOICE_PAID:Godkänd`,
      textColor: '#009773',
      backgroundColor: '#E5F5F2',
    },
  ],
  [
    B2bPaymentStatus.INVOICE_CANCELLED,
    {
      friendlyStatus: $localize`:@@b2bPaymentRequestStatus.INVOICE_CANCELLED:Avbruten`,
      textColor: '#343B45',
      backgroundColor: '#E6E9EF',
    },
  ],
  [
    '_default_',
    {
      // fallback in case an unknown status is encountered
      friendlyStatus: $localize`:@@b2bPaymentRequestStatus.unknown:Processas`,
      textColor: '#393939',
    },
  ],
]);
