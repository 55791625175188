import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface PageTab<T> {
  value: T;
  label: string;
  badgeCount?: number;
}

@Component({
  selector: 'app-page-tabs',
  templateUrl: './page-tabs.component.html',
  styleUrls: ['./page-tabs.component.scss'],
})
export class PageTabsComponent<T> implements OnInit {
  constructor() {}

  @Input() activeValue: T;
  @Input() tabs: PageTab<T>[];

  @Output() tabClicked = new EventEmitter<T>();

  ngOnInit(): void {}

  clickedTab(value: T) {
    this.tabClicked.emit(value);
    this.centerSelectedElement();
  }

  centerSelectedElement() {
    const statusPickerElement = document.getElementsByClassName('tab')[0];

    statusPickerElement.addEventListener('click', (e: Event): void => {
      if (e.target instanceof Element) {
        return e.target.scrollIntoView({
          block: 'center',
          inline: 'center',
        });
      }
    });
  }
}
