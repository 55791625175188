<div class="datepicker">
  <form [formGroup]="formGroup" novalidate>
    <zaver-form-input
      [title]="title"
      [placeholder]="placeholder"
      [errorMessage]="errorMessage"
      [error]="formGroup.controls.dueDate.invalid && showError"
      [size]="'large'"
      (input)="onDateInputChange()"
      formControlName="dueDate"
      [maxLength]="10"
      [showContent]="true"
      ><img src="assets/icons/Icon-Calendar.svg" />
    </zaver-form-input>
  </form>

  <button (click)="onClickSelect()" type="button">
    <img class="arrow-img" src="assets/icons/Icon-Navigation-Arrow-Down.svg" />
  </button>

  <div *ngIf="showCalendar">
    <app-datepicker-calendar
      #CalendarComponent
      class="calendar"
      [(date)]="dueDate"
      [closeCalendar]="closeCalendar"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [resetDueDateForm]="clearForm"
      (dateChange)="onDateChange($event)"
    >
    </app-datepicker-calendar>
    <div class="calendar-background" (click)="closeCalendar()"></div>
  </div>
</div>
