<div class="card">
  <h4>
    <span i18n="@@emailNotFound.title"
      >Den angivna mailadressen verkar inte vara ansluten till något
      företag.</span
    >
  </h4>
  <p>
    <span i18n="@@emailNotFound.firstParagraph"
      >Den mailaddress du angivit (<span class="email">{{ email }}</span
      >) verkar inte vara ansluten till något företag. Om ditt företag redan
      använder Zaver bör du kontakta företagets administratör.</span
    >
  </p>
  <ng-container *ngIf="localeService.getCurrentLocale() === 'sv'">
    <p id="last-paragraph">
      Om ditt företag inte använder Zaver, men vill bli kund hos oss, så är ni
      välkomna att registrera ett företagskonto genom att klicka
      <a href="#" routerLink="/signup" routerLinkActive="active">här</a>.
    </p>
  </ng-container>
  <ng-container
    *ngIf="
      localeService.getCurrentLocale() === 'de' ||
      localeService.getCurrentLocale() === 'de-AT'
    "
  >
    <p>
      Wenn Ihr Unternehmen Zaver nicht nutzt, aber Kunde bei uns werden möchte,
      können Sie sich gerne an uns wenden. Klicken Sie auf den untenstehenden
      Link und füllen Sie das Formular aus. Wir werden uns so schnell wie
      möglich mit Ihnen in Verbindung setzen.
    </p>
    <p>
      <a href="https://zaver.com/de/los-gehts" target="_blank" class="link"
        >Zaver starten</a
      >
    </p>
  </ng-container>

  <div class="button-wrapper">
    <zaver-primary-button
      text="Tillbaka"
      i18n-text="@@emailNotFound.button"
      (click)="goBack()"
      [color]="'cerise'"
    ></zaver-primary-button>
  </div>
</div>
<div id="signup-box" *ngIf="localeService.getCurrentLocale() === 'sv'">
  <span>Vill ditt företag börja använda Zaver?</span>
  <a href="#" routerLink="/signup" routerLinkActive="active" class="link"
    >Registrera nytt företag</a
  >
</div>
