<div class="intro-paragraph">
  <span i18n="@@salesReport.introText"
    >Här skapas försäljningsrapporter på daglig basis baserat på godkända
    transaktioner för det angivna tidsintervallet.</span
  >
</div>

<app-page-error
  *ngIf="errorMessage"
  [errorMessage]="errorMessage"
></app-page-error>

<div *ngIf="fetchingResponse" class="page-loader-container">
  <app-page-loader></app-page-loader>
</div>

<div id="summary-container" *ngIf="!fetchingResponse">
  <div id="summaries">
    <div>
      <span class="medium2" i18n="@@salesReport.summary.transactions"
        >Transaktioner:</span
      >
      <span
        id="transactions-span"
        i18n="@@salesReport.summary.transactionsAmount"
        >{{ totalTransactions }}&nbsp;st</span
      >
    </div>
    <div class="hide-on-mobile" id="spacer"></div>
    <div>
      <span class="medium2" i18n="@@salesReport.summary.volume"
        >Försäljningsvolym:</span
      >
      <span id="sum-span">{{
        totalSum | value: localeService.getCurrentLocale()
      }}</span>
    </div>
  </div>

  <button
    type="button"
    id="fetch-report-button"
    (click)="downloadFile()"
    *ngIf="dailySummaries.data.length != 0"
  >
    <span i18n="@@salesReport.fetchReport">Hämta rapport</span>
    <img class="icons" src="assets/icons/Icon-Download-Circle.svg" alt="" />
  </button>
</div>

<app-report-table
  *ngIf="!fetchingResponse && dailySummaries.data.length != 0"
  [columns]="dailySummariesColumns"
  [rows]="dailySummaries"
>
</app-report-table>

<app-table-pagination
  *ngIf="!fetchingResponse && dailySummaries.data.length != 0"
  [paginationState]="paginationState"
  (pageChanged)="onPageChange($event)"
  (pageSizeChanged)="onPageSizeChange($event)"
>
</app-table-pagination>

<!-- Empty state when no reports exist for the given date-range -->
<app-empty-state
  *ngIf="totalTransactions === 0 && !fetchingResponse"
  [emptyState]="emptyState"
>
</app-empty-state>
