import { BankInformationDto } from '@zfb/ui/cashout/create-cashout-task/create-cashout-task.component';

export class CreateCashoutTaskDto {

    constructor(public payeePhoneNumber: string,
                public amount: number,
                public payeeNationalIdentifier,
                public payeeDetailsReference,
                public bankInformation: BankInformationDto) {}


}
