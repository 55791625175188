import { RoleColor } from '@core/dto/user-details';

export interface CompanyRepresentationRoleColor {
  hexColor: string;
}

export const CompanyRepresentationRoleColorRepresentations: Map<
  string,
  CompanyRepresentationRoleColor
> = new Map([
  // REPRESENTATIVE
  [
    RoleColor.BLUE,
    {
      hexColor: '#4466EE',
    },
  ],
  // DISBURSEMENT_INITIATOR
  [
    RoleColor.CERISE,
    {
      hexColor: '#DB305E',
    },
  ],
  // ADMIN
  [
    RoleColor.GREEN,
    {
      hexColor: '#00A880',
    },
  ],
  // SIGNATORY
  [
    RoleColor.ORANGE,
    {
      hexColor: '#F87C11',
    },
  ],
  // Fallback if an unknown color is encountered
  [
    '_default_',
    {
      hexColor: '#DB305E',
    },
  ],
]);
