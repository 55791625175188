import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'zaver-status-pill',
  templateUrl: './status-pill.component.html',
  styleUrls: ['./status-pill.component.css']
})
export class StatusPillComponent implements OnInit {

  @Input() text: string;
  @Input() backgroundHexColor: string;

  constructor() { }

  ngOnInit(): void {
  }

}
