import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { RefundReason } from '@core/dto/refund/Refund';
import { LocaleService } from '@core/service/locale.service';
import { RefundService } from '@core/service/refund.service';

@Component({
  selector: 'app-create-refund-reason',
  templateUrl: './create-refund-reason.component.html',
  styleUrls: ['./create-refund-reason.component.css'],
})
export class CreateRefundReasonComponent implements OnInit {
  @Output() changeStep = new EventEmitter();

  reasons = [
    {
      label: this.refundService.getRefundReasonText(
        RefundReason.RIGHT_OF_WITHDRAWAL
      ),
      value: RefundReason.RIGHT_OF_WITHDRAWAL,
    },
    {
      label: this.refundService.getRefundReasonText(
        RefundReason.PRODUCT_DEFECT
      ),
      value: RefundReason.PRODUCT_DEFECT,
    },
    {
      label: this.refundService.getRefundReasonText(
        RefundReason.INCORRECTLY_EXECUTED_SERVICE
      ),
      value: RefundReason.INCORRECTLY_EXECUTED_SERVICE,
    },
    {
      label: this.refundService.getRefundReasonText(RefundReason.OTHER),
      value: RefundReason.OTHER,
    },
  ];

  public form: FormGroup;

  constructor(
    private controlContainer: ControlContainer,
    private refundService: RefundService,
    public localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.form = <FormGroup>this.controlContainer.control;
  }
}
