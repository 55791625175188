<div [style]="headingColorToUse(headingText)" class="heading">
  <h3>{{ headingText }}</h3>

  <ng-container *ngIf="!!helpHeadingText">
    <p>({{ helpHeadingText }})</p>
  </ng-container>
</div>

<div class="table-heading">
  <div [style]="headingColorToUse(headingText)" class="product-name">
    <p style="margin-left: 4px">{{ firstColumnText }}</p>
  </div>

  <div [style]="headingColorToUse(headingText)" class="grouped-columns">
    <p class="quantity">Quantity</p>
    <p class="unit-price">Unit price</p>
    <p class="vat">VAT</p>
    <p class="vat-amount">VAT</p>
    <p class="total-amount">Total amount</p>
  </div>
</div>

<div class="table">
  <ng-container *ngFor="let lineItemVM of simpleLineItemVM">
    <div class="row-wrapper">
      <div class="row">
        <div class="product-name">
          <p [title]="lineItemVM.title" class="title">{{ lineItemVM.title }}</p>
        </div>

        <div class="grouped-columns">
          <div class="quantity">
            <p>{{ lineItemVM.quantity }}</p>
          </div>

          <div class="unit-price">
            <p>{{ lineItemVM.unitPrice | amountPipe : currency }}</p>
          </div>
          <div class="vat">
            <p>{{ lineItemVM.taxRatePercent }} %</p>
          </div>

          <div class="vat-amount">
            <p>{{ lineItemVM.taxAmount | amountPipe : currency }}</p>
          </div>

          <div class="total-amount">
            {{ lineItemVM.totalAmount | amountPipe : currency }}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="number-of-lineItems">
  {{ sumOfLineItemsText(simpleLineItemVM.length) }}
</div>
