import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ControlContainer, FormControl, FormGroup, Validators} from '@angular/forms';
import { PEP } from '@core/dto/signup/owner/PEP';
import { SignupService } from '@core/service/signup.service';
import { swedishNationalIdentificationNumberValidator, swedishNationalIdentificationRegex } from '@shared/validators/identification-number-validators';
import { FormValidators } from '@shared/validators/validators';

@Component({
  selector: 'app-company-owner-natural',
  templateUrl: './company-owner-natural.component.html',
  styleUrls: [
    './company-owner-natural.component.css']
})
export class CompanyOwnerNaturalComponent implements OnInit, OnDestroy {

  public formGroup: FormGroup;

  @Input() nonOwner: boolean;
  @Input() showError: boolean;
  @Input() displayingInCompanyAdminPage: boolean;

  pepOptions = PEP.VALUES;
  correctPersonalNumber = false;
  startPersonalNumberValidation = false;
  namePlaceholder = 'Hämtas från personnumret';

  @Output() correctOrganizationNumberEmit = new EventEmitter();

  constructor(
    private controlContainer: ControlContainer,
    private signupService: SignupService) {
  }

  ngOnInit() {
    this.formGroup = <FormGroup>this.controlContainer.control;
    this.formGroup.addControl('name', new FormControl({value: '', disabled: true}, [Validators.required]));
    this.formGroup.addControl('personalNumber', new FormControl('', [Validators.required, swedishNationalIdentificationNumberValidator(), Validators.pattern(swedishNationalIdentificationRegex),]));
    this.formGroup.addControl('legalEntityId', new FormControl(''));
    if (!this.nonOwner) {
      this.formGroup.addControl('holdingShare', new FormControl('', [Validators.required, FormValidators.numberMultipleDecimals(0, 100)]));
      this.formGroup.addControl('votesShare', new FormControl('', [Validators.required, FormValidators.numberMultipleDecimals(0, 100)]));
    }
    this.formGroup.addControl('pep', new FormControl(this.pepOptions[0].value, [Validators.required]));
    this.onChanges();
    if (this.formGroup.controls['name'].value !== '') {
      this.correctPersonalNumber = true;
      this.correctOrganizationNumberEmit.emit();
    }
  }

  ngOnDestroy() {
    this.formGroup.removeControl('name');
    this.formGroup.removeControl('personalNumber');
    this.formGroup.removeControl('legalEntityId');
    if (!this.nonOwner) {
      this.formGroup.removeControl('holdingShare');
      this.formGroup.removeControl('votesShare');
    }
    this.formGroup.removeControl('pep');
  }

  onChanges() {
    this.formGroup.controls['personalNumber'].valueChanges.subscribe( val => {
      let personalNumber: string = this.formGroup.controls['personalNumber'].value;
      personalNumber = personalNumber.replace(/-/g, '');
      if (personalNumber.length >= 10) {
        this.startPersonalNumberValidation = true;
      }
      if (this.formGroup.controls['personalNumber'].valid) {
        let personalNumberToSend = this.formGroup.controls['personalNumber'].value;
        personalNumberToSend = personalNumberToSend.replace(/-/g, '');
        this.signupService.getNaturalPersonInfo(personalNumberToSend)
          .then(res => {
            if (!!res.error && res.error === 'ERROR_FETCHING_NAME') {
              this.formGroup.controls['name'].enable();
              this.namePlaceholder = 'Ange namn';
              this.formGroup.controls['name'].setValue('', {emitEvent: false});
            } else {
              this.formGroup.controls['name'].disable();
              this.namePlaceholder = 'Hämtas från personnumret';
              this.formGroup.controls['name'].setValue(res.personName, {emitEvent: false});
            }
            this.formGroup.controls['legalEntityId'].setValue(res.legalEntityId, {emitEvent: false});
            this.correctPersonalNumber = true;
            this.correctOrganizationNumberEmit.emit();
          })
          .catch(() => {
            this.formGroup.controls['name'].enable();
            this.namePlaceholder = 'Ange namn';
            this.correctPersonalNumber = true;
            this.correctOrganizationNumberEmit.emit();
          });
      } else {
        this.formGroup.controls['name'].setValue('', {emitEvent: false});
        this.formGroup.controls['legalEntityId'].setValue('', {emitEvent: false});
        this.formGroup.controls['name'].disable();
        this.namePlaceholder = 'Hämtas från personnumret';
      }
    });
  }
}
