<button type="button" href="#">
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.5 0C10.1066 0 10.5983 0.49171 10.5983 1.09827V17.9017C10.5983 18.5083 10.1066 19 9.5 19C8.89344 19 8.40173 18.5083 8.40173 17.9017V1.09827C8.40173 0.49171 8.89344 0 9.5 0Z"
      fill="#FFFFFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 9.5C0 8.89344 0.49171 8.40173 1.09827 8.40173H17.9017C18.5083 8.40173 19 8.89344 19 9.5C19 10.1066 18.5083 10.5983 17.9017 10.5983H1.09827C0.49171 10.5983 0 10.1066 0 9.5Z"
      fill="#FFFFFF"
    />
  </svg>
  <span>{{ text }}</span>
</button>
