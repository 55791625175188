<div class="auth-container">
  <div class="page-icon">
    <img id="top-logo" src="assets/bankid-logo.svg" alt="BID-logo"/>
  </div>
  <div *ngIf="authInProgress && (!isMobile || showQrCode)" class="open-app-text">
    Vänligen öppna din BankID-app för att fortsätta
  </div>

  <ng-container *ngIf="!showQrCode">
    <span *ngIf="authInProgress" class="status-message">{{ bankIdStatusMessage }}</span>

    <div class="dot-spinner-container" *ngIf="authInProgress && isMobile">
      <app-dot-spinner></app-dot-spinner>
    </div>

    <div class="deeplink-button-container" *ngIf="authInProgress && manualDeeplinkEnabled">
      <div class="deeplink-button" (click)="autoStartAppClick()">
        Öppna BankID appen
      </div>
    </div>

    <div class="alert-container" *ngIf="!authInProgress && errorMessageList">
      <zaver-alert-box type="WARNING" [messageList]="errorMessageList"
      ></zaver-alert-box>
    </div>

    <div class="try-again-button" *ngIf="!authInProgress">
      <zaver-primary-button
          [text]="tryAgainButtonText"
          (click)="autoStartAppClick()"
          [logo]="'assets/logo/bank_id_logo_white.svg'"
      ></zaver-primary-button>
    </div>

    <a class="second-option-link" (click)="showQrCodeClick()">
      <u>{{ useDifferentDeviceButtonText }}</u>
    </a>
  </ng-container>

  <ng-container *ngIf="showQrCode">
    <div [hidden]="!authInProgress" class="qr-container">
      <div class="qr" #canvas></div>
    </div>

    <span *ngIf="authInProgress" class="status-message">{{ bankIdStatusMessage }}</span>

    <div class="alert-container" *ngIf="!authInProgress && errorMessageList">
      <zaver-alert-box type="WARNING" [messageList]="errorMessageList"
      ></zaver-alert-box>
    </div>

    <div class="try-again-button" *ngIf="!authInProgress && errorMessageList">
      <zaver-primary-button
          [text]="tryAgainButtonText"
          (click)="showQrCodeClick()"
          [logo]="'assets/logo/bank_id_logo_white.svg'"
      ></zaver-primary-button>
    </div>

    <a class="second-option-link" (click)="autoStartAppClick()">
      <u>{{ useThisDeviceButtonText }}</u>
    </a>
  </ng-container>
</div>
