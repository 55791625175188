import { Store } from './Store';

export class StoreRelation {
    store: Store;
    paymentRequestPermission: PaymentRequestPermission;
  
    constructor(
        store: Store, 
        paymentRequestPermission: PaymentRequestPermission
    ) {
      this.store = store;
      this.paymentRequestPermission = paymentRequestPermission;
    }
}
  
export enum PaymentRequestPermission {
    MANAGE_ALL_PAYMENT_REQUESTS_FOR_STORE = 'MANAGE_ALL_PAYMENT_REQUESTS_FOR_STORE',
    MANAGE_OWN_PAYMENT_REQUESTS_FOR_STORE = 'MANAGE_OWN_PAYMENT_REQUESTS_FOR_STORE',
}
