import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@core/service/authentication.service';
import { ColorService } from '@core/service/color.service';

@Component({
  selector: 'app-pending-approval',
  templateUrl: './pending-approval.component.html',
  styleUrls: ['../page-shared.scss', './pending-approval.component.css'],
})
export class PendingApprovalComponent implements OnInit {
  constructor(
    private router: Router,
    public auth: AuthenticationService,
    public colorService: ColorService
  ) {}

  ngOnInit() {}
}
