import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { CashoutTaskDto } from '@core/dto/cashout/incoming/CashoutTaskDto';
import {
  CashoutTaskListener,
  CashoutTaskService,
} from '@core/service/cashout-task.service';
import { AccessControlService } from '@core/service/access-control.service';
import {
  CashoutBalanceListener,
  CashoutBalanceService,
} from '@core/service/cashout-balance.service';
import { CashoutBalanceResponseDto } from '@core/dto/cashout/incoming/CashoutBalanceResponseDto';
import { AppStateService } from '@core/service/app-state.service';
import { CashoutTaskStatus } from '@core/params';
import { CashoutTaskListDto } from '@core/dto/cashout/incoming/CashoutTaskListDto';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import {
  CashoutTaskRepresentation,
  CashoutTaskRepresentations,
} from 'assets/language/CashoutTaskRepresentation';
import { CashoutTaskUpdatedDto } from '@core/dto/cashout/incoming/CashoutTaskUpdatedDto';
import {
  ColumnDefinition,
  FilterOption,
  TableRows,
  TableRow,
} from '../../ui/table/table.component';
import { DatePipe } from '@angular/common';
import { BaseComponent } from '@core/base.component';
import { AuthenticationService } from '@core/service/authentication.service';
import { Permission } from '@core/dto/user-details';
import { ColorService } from '@core/service/color.service';
import { PaginationState } from '@zfb/ui/table-pagination/table-pagination.component';
import { ValueListPipe } from '@shared/pipe/value.pipe';
import { Currency, CurrencyCode } from '@core/dto/CurrencyCode';
import { EmptyState } from '@zfb/ui/empty-state/empty-state.component';
import { UserAgentService } from '@core/service/user-agent.service';
import { QuickAction } from '@zfb/ui/quick-actions/quick-actions.component';
import { ToastMessageService } from '@core/service/toast-message.service';

@Component({
  selector: 'app-cashout',
  templateUrl: './cashout.component.html',
  styleUrls: ['./cashout.component.css', '../page-shared.scss'],
})
export class CashoutComponent
  extends BaseComponent
  implements OnInit, OnDestroy, CashoutBalanceListener, CashoutTaskListener
{
  constructor(
    private taskService: CashoutTaskService,
    private appStateService: AppStateService,
    private accessControlService: AccessControlService,
    private cashoutBalanceService: CashoutBalanceService,
    auth: AuthenticationService,
    public colorService: ColorService,
    private userAgentService: UserAgentService,
    private toastService: ToastMessageService
  ) {
    super(auth);

    this.searchQueryChanged
      .pipe(
        map((q) => q.trim()),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((query) => {
        this.paginationState.page = 1;
        this.searchTasks(query);
      });
  }
  statePushedToHistory = false;

  public fetchingResponse = false;
  public fetchingBalance = false;
  public balance: number;
  public errorMessage: string;
  public cashoutTasks: TableRows<CashoutTaskDto, CashoutTaskStatus> = {
    getPrimaryText: (it: CashoutTaskDto) => {
      const value: Currency = {
        amount: it.amount,
        currencyCode: CurrencyCode.SEK,
      };
      return new ValueListPipe().transform(value, 'sv');
    },
    getSecondaryText: (it: CashoutTaskDto) => it.reference,
    getStatusBarColor: (it) => it.statusColor,
    getBackgroundColor: (it) => it.backgroundColor,
    rows: [],
    hideMobileHeader: false,
    rowClickFunction: (it: CashoutTaskDto) => this.openTaskInModal(it),
  };

  merchantHasCashoutTasks: boolean;
  public selectedTask: CashoutTaskDto;
  public submitted = false;
  public paginationState: PaginationState = {
    page: 1,
    pageSize: 10,
    total: 0,
    numOfItems: 0,
  };
  public accessGranted = false;
  public checkedAccess = false;
  public pendingExecutionSum = 0;

  public searchQuery = '';
  private searchQueryChanged: Subject<string> = new Subject<string>();

  public statusFilter = '';

  modalActive = false;
  modalSmallActive = false;
  modalTitle: string;
  modalColor: string;
  showCashoutModalContent = false;

  columns: ColumnDefinition<CashoutTaskDto, CashoutTaskStatus>[] = [
    {
      text: this.getAliasForTitleInForm(),
      cssClasses: 'medium2 avoid-overflow first-column',
      columnDataTransformer: (cashoutTask) => cashoutTask.reference,
    },
    {
      text: 'Senast ändrad',
      columnDataTransformer: (cashoutTask) =>
        new DatePipe('sv').transform(cashoutTask.lastEvent, 'mediumDate'),
      cssClasses: 'right-align',
    },
    {
      text: 'Belopp',
      columnDataTransformer: (cashoutTask) => {
        const value: Currency = {
          amount: cashoutTask.amount,
          currencyCode: CurrencyCode.SEK,
        };
        return new ValueListPipe().transform(value, 'sv');
      },
      cssClasses: 'medium2 right-align',
    },
    {
      text: 'Status',
      columnDataTransformer: (cashoutTask) => {
        if (cashoutTask.statusText === 'Redo för utbetalning') {
          return 'Redo för utb.';
        } else if (cashoutTask.statusText === 'Bekräfta utbetalning') {
          return 'Bekräfta utb.';
        } else if (cashoutTask.statusText === 'Utbetalning misslyckades') {
          return 'Utb.misslyckades';
        } else {
          return cashoutTask.statusText;
        }
      },
      type: 'status',
      filterable: true,
      filterOptions: {
        text: 'Status',
        value: 'status',
        activeFilter: null,
        options: [
          {
            headerText: 'alla',
            displayText: 'Visa alla',
            value: null,
          },
          {
            headerText: 'initierad',
            displayText: 'Initierad',
            value: CashoutTaskStatus.PENDING_PAYEE_APPROVAL,
          },
          {
            headerText: 'klar för attest',
            displayText: 'Klar för attest',
            value: CashoutTaskStatus.PENDING_ADMIN_APPROVAL,
          },
          {
            headerText: 'bekräfta utb.',
            displayText: 'Bekräfta utb.',
            value: CashoutTaskStatus.PENDING_EXECUTION,
          },
          {
            headerText: 'utbetald',
            displayText: 'Utbetald',
            value: CashoutTaskStatus.EXECUTED,
          },
          {
            headerText: 'avbruten',
            displayText: 'Avbruten',
            value: CashoutTaskStatus.CANCELLED,
          },
          {
            headerText: 'utb. misslyckades',
            displayText: 'Utb. misslyckades',
            value: CashoutTaskStatus.BOUNCED || CashoutTaskStatus.FAILED,
          },
        ],
      },
    },
  ];

  showCashoutTaskCreation = false;
  showCashoutTaskView = false;
  showCashoutCancel = false;
  showCashoutConfirm = false;
  displayCashoutTaskSentConfirmation = false;

  displayEmptyState = false;
  currentEmptyState: EmptyState;
  noCashoutsAtAllEmptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Emoji-Smile.png',
    headingText: 'Inga utbetalningar&nbsp;skapade',
    bodyText:
      'Det finns inget att visa här just nu, men så fort din första utbetalning har skapats hittar du den&nbsp;här.',

    ctaButtonText: this.shouldDisplayCreateButton() ? 'Skapa utbetalning' : '',
    ctaClickFunction: () => this.create(),
  };
  noCashoutsSearchedEmptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Emoji-Monocle.png',
    headingText: 'Vi hittade ingen match för&nbsp;sökningen',
    bodyText: 'Ge det ett till försök. Prova&nbsp;igen.',
  };
  noCashoutsFilteredEmptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Emoji-Ghost.png',
    headingText: 'Här var det tomt just&nbsp;nu',
  };

  quickActions: QuickAction<CashoutTaskDto>[] = [
    {
      text: 'Skicka om SMS',
      iconUrl: 'assets/icons/Icon-Send-Black.svg',
      textColor: '#0A1018',
      disabled: false,
      displayCondition: (cashoutTask) => {
        return cashoutTask.status === CashoutTaskStatus.PENDING_PAYEE_APPROVAL;
      },
      topBorder: false,
      function: (cashoutTask) => this.resendRequestSms(cashoutTask),
    },
    {
      text: 'Kopiera länk',
      iconUrl: 'assets/icons/Icon-Link.svg',
      textColor: '#0A1018',
      disabled: false,
      displayCondition: (cashoutTask) => {
        return cashoutTask.status !== CashoutTaskStatus.EXECUTED;
      },
      topBorder: false,
      function: (cashoutTask) => {
        navigator.clipboard.writeText(
          `https://cashout.zaver.se/${cashoutTask.taskId}`
        );
        // Android 12 has a system toast message when texts are copied to the
        // clipboard. We do not want to make our own toasts in this scenario
        if (this.userAgentService.getAndroidVersion() !== '12') {
          this.toastService.displaySuccess('Betallänken har kopierats');
        }
      },
    },
    {
      text: 'Avbryt utbetalning',
      iconUrl: 'assets/icons/Icon-Delete-Cerise.svg',
      textColor: this.colorService.getCeriseColorCode(),
      disabled: false,
      displayCondition: (cashoutTask) => {
        return cashoutTask.status === CashoutTaskStatus.PENDING_PAYEE_APPROVAL;
      },
      topBorder: true,
      function: (cashoutTask) => this.cancel(cashoutTask),
    },
  ];

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    if (this.deviceIsMobile()) {
      if (this.statePushedToHistory) {
        this.statePushedToHistory = false;
      }
      if (this.modalActive) {
        this.closeModal();
      } else if (this.modalSmallActive) {
        if (this.showCashoutCancel || this.showCashoutConfirm) {
          this.returnToViewModal();
        } else {
          this.closeSmallModal();
        }
      }
    }
  }

  ngOnInit() {
    if (window.location.hash === '#modal') {
      history.back();
    }
    this.accessGranted =
      this.accessControlService.userMay(Permission.GET_CASHOUT_ORDERS) &&
      this.appStateService.merchantHasCashoutAccess();

    this.checkedAccess = true;

    // If we end up here the viewer should be informed that he / she / the company is unauthorized
    // to view Cashout.
    if (!this.accessGranted) {
      return;
    }

    this.refreshTasks();
    this.refreshBalance();
    this.cashoutBalanceService.registerMessageListener(this);
    this.taskService.registerTaskListener(this);

    window.onbeforeunload = () => this.ngOnDestroy();
  }

  ngOnDestroy(): void {
    this.cashoutBalanceService.unregisterMessageListener(this);
    this.taskService.unregisterTaskListener(this);
    if (this.deviceIsMobile()) {
      if (this.modalActive) {
        this.closeModal();
      } else if (this.modalSmallActive) {
        this.closeSmallModal();
      }
    }
  }

  setupQuickActionsForCashoutTask(
    cashoutTask: CashoutTaskDto
  ): TableRow<CashoutTaskDto> {
    return {
      data: cashoutTask,
      quickActions: this.quickActions.filter((action) =>
        action.displayCondition(cashoutTask)
      ),
    };
  }

  resendRequestSms(cashoutTask: CashoutTaskDto) {
    this.taskService
      .resend(cashoutTask.taskId)
      .subscribe({
        next: () => {
          this.toastService.displaySuccess("Ett nytt sms har skickats");
        },
        error: () => {
          this.toastService.displayError("Ett nytt sms kunde inte skickas");
        }
    });
  }

  searchUpdatedQuery(query: string) {
    this.searchQueryChanged.next(query);
  }

  searchTasks(query: string) {
    this.searchQuery = query.trim();
    this.refreshTasks();
  }

  onStatusFilterChange() {
    this.refreshTasks();
  }

  cancel(task: CashoutTaskDto) {
    this.openTaskInModal(task);
    this.displayCancelContent();
  }

  private refreshBalance() {
    this.fetchingBalance = true;
    this.cashoutBalanceService.getBalance().subscribe(
      (res: CashoutBalanceResponseDto) => {
        this.balance = res.amount;
        this.fetchingBalance = false;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  onBalanceUpdated(balanceDto: CashoutBalanceResponseDto): void {
    // check that this balance is for the right merchant
    if (
      !!balanceDto.merchantId &&
      this.appStateService.getCurrentUser().activeRepresentation.company
        .merchantId === balanceDto.merchantId
    ) {
      this.balance = balanceDto.amount;
    }
  }

  onTaskUpdated(updatedDto: CashoutTaskUpdatedDto): void {
    const updatedTask = updatedDto.task;
    const updated = this.setStatusInfoIndividualTask(updatedTask);
    const index = this.cashoutTasks.rows.findIndex(
      (item) => item.data.taskId === updated.taskId
    );

    // update sum of cashout tasks in progress, if needed
    this.pendingExecutionSum = updatedDto.sumAmountTasksPendingExecution;

    if (index === -1) {
      // add new task to top of list
      this.cashoutTasks.rows.unshift({
        data: updated,
        quickActions: this.quickActions,
      });
      this.merchantHasCashoutTasks = true;
    } else {
      // update in place
      const oldTask = this.cashoutTasks[index];
      this.cashoutTasks[index] = updated;
    }
  }

  onPageChange($event) {
    this.paginationState.page = $event;
    this.refreshTasks();
  }

  onPageSizeChange($event) {
    this.paginationState.pageSize = $event;
    this.paginationState.page = 1;
    this.refreshTasks();
  }

  private refreshTasks() {
    this.fetchingResponse = true;
    this.displayEmptyState = false;
    this.taskService
      .getTasks(
        this.searchQuery,
        this.statusFilter,
        null,
        null,
        this.paginationState.page - 1, // page-numbering is zero based on the server
        this.paginationState.pageSize
      )
      .subscribe(
        (res: CashoutTaskListDto) => {
          this.cashoutTasks.rows = res.elements
            .map((task) => this.setStatusInfoIndividualTask(task))
            .map((task) => ({ data: task, quickActions: this.quickActions }));
          this.paginationState.numOfItems = this.cashoutTasks.rows.length;
          this.paginationState.page = res.page + 1; // page-numbering is zero based on the server
          this.paginationState.total = res.total;
          if (this.paginationState.numOfItems === 0) {
            this.setEmptyState();
          } else {
            this.merchantHasCashoutTasks = true;
          }
          this.pendingExecutionSum = res.sumAmountTasksPendingExecution;
          this.fetchingResponse = false;

          this.cashoutTasks.rows = res.elements.map((task: CashoutTaskDto) => {
            return this.setupQuickActionsForCashoutTask(task);
          });
        },
        (err) => {
          this.handleError(err);
          this.fetchingResponse = false;
        }
      );
  }

  setEmptyState(): void {
    if (!this.merchantHasCashoutTasks) {
      this.currentEmptyState = this.noCashoutsAtAllEmptyState;
    } else if (this.statusFilter !== '') {
      this.currentEmptyState = this.noCashoutsFilteredEmptyState;
    } else if (this.searchQuery !== '') {
      this.currentEmptyState = this.noCashoutsSearchedEmptyState;
    }
    this.displayEmptyState = true;
  }

  onActiveStatusChange(event: {
    type: string;
    filterOption: FilterOption<CashoutTaskStatus>;
  }) {
    if (event.type === 'status') {
      this.columns[3].filterOptions.activeFilter = event.filterOption.value;
      this.statusFilter = event.filterOption.value;
      this.paginationState.page = 1;
      this.refreshTasks();
    }
  }

  public closeModalAndRefreshData() {
    this.closeModal();
    this.refreshTasks();
    this.refreshBalance();
  }
  public closeSmallModalAndRefreshData() {
    this.closeSmallModal();
    this.refreshTasks();
    this.refreshBalance();
  }

  displayConfirmationText() {
    this.closeModal();
    this.submitted = true;
    this.showCashoutConfirm = false;
    this.showCashoutCancel = false;
    this.displayCashoutTaskSentConfirmation = true;
    this.modalColor = '#00A880';
    this.modalTitle = 'Förfrågan skickad';
    this.activateSmallModal();
  }

  public openTaskInModal(task: CashoutTaskDto) {
    this.selectedTask = task;
    this.modalColor = this.selectedTask.statusColor;
    this.modalTitle = this.selectedTask.statusText;
    this.showCashoutTaskView = true;
    this.activateModal();
  }

  public closeModal() {
    this.deactivateModal();
    this.showCashoutTaskCreation = false;
    this.showCashoutTaskView = false;
    this.selectedTask = null;
    if (this.statePushedToHistory && this.deviceIsMobile()) {
      this.statePushedToHistory = false;
      history.back();
    }
  }

  closeSmallModal() {
    if (this.submitted) {
      this.merchantHasCashoutTasks = true;
      this.refreshTasks();
      this.refreshBalance();
      this.submitted = false;
    }
    this.deactivateSmallModal();
    this.showCashoutConfirm = false;
    this.showCashoutCancel = false;
    this.displayCashoutTaskSentConfirmation = false;
    this.selectedTask = null;
    if (this.statePushedToHistory && this.deviceIsMobile()) {
      this.statePushedToHistory = false;
      history.back();
    }
  }

  public create() {
    this.selectedTask = null;
    this.modalColor = '#00A880';
    this.modalTitle = 'Ny utbetalning';
    this.showCashoutTaskCreation = true;
    this.activateModal();
  }

  activateModal() {
    if (!this.statePushedToHistory && this.deviceIsMobile()) {
      history.pushState(null, null, '/se/cashout#modal');
      this.statePushedToHistory = true;
    }
    this.modalActive = true;
  }

  activateSmallModal() {
    if (!this.statePushedToHistory && this.deviceIsMobile()) {
      history.pushState(null, null, '/se/cashout#modal');
      this.statePushedToHistory = true;
    }
    this.modalSmallActive = true;
  }

  deactivateModal() {
    this.modalActive = false;
  }

  deactivateSmallModal() {
    this.modalSmallActive = false;
  }

  public shouldDisplayCreateButton() {
    return this.accessControlService.userMay(Permission.CREATE_CASHOUT_ORDER);
  }

  updateModalTextAndColor(event: { text: string; color: string }) {
    if (event.color) {
      this.modalColor = event.color;
    }
    if (event.text) {
      this.modalTitle = event.text;
    }
  }

  displayCancelContent() {
    this.showCashoutTaskCreation = false;
    this.showCashoutTaskView = false;
    this.showCashoutConfirm = false;
    this.deactivateModal();
    this.displayCashoutTaskSentConfirmation = false;
    this.modalColor = this.colorService.getCeriseColorCode();
    this.modalTitle = 'Avbryt utbetalning';
    this.showCashoutCancel = true;
    this.activateSmallModal();
  }

  displayConfirmation() {
    this.showCashoutTaskCreation = false;
    this.showCashoutTaskView = false;
    this.showCashoutCancel = false;
    this.deactivateModal();
    this.displayCashoutTaskSentConfirmation = false;
    this.modalColor = '#FAA900';
    this.modalTitle = 'Bekräfta utbetalning';
    this.showCashoutConfirm = true;
    this.activateSmallModal();
  }

  setStatusInfo() {
    this.cashoutTasks.rows.forEach(function (cashoutTask) {
      let statusInfo: CashoutTaskRepresentation =
        CashoutTaskRepresentations.get(cashoutTask.data.status);
      if (!statusInfo) {
        statusInfo = CashoutTaskRepresentations.get('_default_');
      }
      cashoutTask.data.statusText = statusInfo.friendlyStatus;
      cashoutTask.data.statusColor = statusInfo.textColor;
      cashoutTask.data.backgroundColor = statusInfo.backgroundColor;
    });
  }

  setStatusInfoIndividualTask(task: CashoutTaskDto): CashoutTaskDto {
    let statusInfo: CashoutTaskRepresentation = CashoutTaskRepresentations.get(
      task.status
    );
    if (!statusInfo) {
      statusInfo = CashoutTaskRepresentations.get('_default_');
    }
    task.statusText = statusInfo.friendlyStatus;
    task.statusColor = statusInfo.textColor;
    task.backgroundColor = statusInfo.backgroundColor;
    return task;
  }

  getAliasForTitleInForm(): string {
    const guiCustomizations = this.appStateService.getGuiCustomizations();

    if (guiCustomizations !== null && guiCustomizations.prFormTitleAlias) {
      return guiCustomizations.prFormTitleAlias;
    }
    return 'Titel';
  }

  returnToViewModal() {
    this.deactivateSmallModal();
    this.showCashoutConfirm = false;
    this.showCashoutCancel = false;
    this.displayCashoutTaskSentConfirmation = false;
    this.openTaskInModal(this.selectedTask);
  }

  deviceIsMobile() {
    return window.innerWidth <= 520;
  }
}
