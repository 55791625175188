<div class="heading-container">
  <h1>Registrering steg 2/3</h1>
</div>
<ng-container *ngIf="!signupQuestionsDoneFetching">
  <div class="page-loader-container">
    <app-page-loader></app-page-loader>
  </div>
</ng-container>
<div style="width: 100%" [class.hide]="!signupQuestionsDoneFetching">
  <app-signup-questions
    (doneFetchingResponse)="signupQuestionsDoneFetching = true"
    (onSaved)="saved($event)"
    (back)="back()"
  >
  </app-signup-questions>
</div>
