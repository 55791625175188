<app-page-error
  [class.in-admin]="displayingInCompanyAdminPage"
  *ngIf="serverError"
  [errorMessage]="errorMessage"
></app-page-error>

<ng-container *ngIf="!fetchingResponse && !serverError">
  <form [formGroup]="kycForm" novalidate *ngIf="mayGetKyc()">
    <div class="card" [class.in-admin]="displayingInCompanyAdminPage">
      <div class="card-heading">
        <h2>Kundkännedom</h2>
      </div>
      <div class="card-body">
        <div class="input-fields">
          <zaver-dropdown
            id="dropdown"
            [title]="
              'Uppskattat belopp som företaget avser att ta emot via Bolagets tjänster per år'
            "
            [inputModel]="kycForm.controls.amount.value"
            (inputModelChange)="kycForm.controls.amount.setValue($event)"
            [selectOptions]="amountOptions"
            [error]="showError && kycForm.controls.amount.invalid"
            [errorMessage]="'Vänligen välj ett alternativ'"
            [disabled]="!mayChangeKYC()"
          >
          </zaver-dropdown>
        </div>
        <div class="input-fields">
          <zaver-dropdown
            id="dropdown"
            [title]="
              'Uppskattning på antal betalningar som företaget avser att ta emot via Bolagets tjänster per år'
            "
            [inputModel]="kycForm.controls.frequency.value"
            (inputModelChange)="kycForm.controls.frequency.setValue($event)"
            [selectOptions]="frequencyOptions"
            [error]="showError && kycForm.controls.frequency.invalid"
            [errorMessage]="'Vänligen välj ett alternativ'"
            [disabled]="!mayChangeKYC()"
          >
          </zaver-dropdown>
        </div>
        <div [class.radio-select-container]="displayingInCompanyAdminPage">
          <div>
            <div class="label">
              Vad är syftet med överföringarna som sker till företaget?
            </div>
            <app-radio-select
              [value]="kycForm.controls.transactionPurpose.value"
              (valueChange)="
                kycForm.controls.transactionPurpose.setValue($event)
              "
              [options]="transactionPurposeValues"
              [allDisabled]="!mayChangeKYC()"
              [showRequiredError]="
                showError && kycForm.controls.transactionPurpose.invalid
              "
              [errorMessage]="'Vänligen välj ett alternativ'"
            >
            </app-radio-select>
          </div>
          <div>
            <div class="label">Vad är syftet med affärsförbindelsen?</div>
            <app-radio-select
              [value]="kycForm.controls.businessPurpose.value"
              (valueChange)="kycForm.controls.businessPurpose.setValue($event)"
              [options]="businessPurposeValues"
              [allDisabled]="!mayChangeKYC()"
              [showRequiredError]="
                showError && kycForm.controls.businessPurpose.invalid
              "
              [errorMessage]="'Vänligen välj ett alternativ'"
            >
            </app-radio-select>
          </div>
        </div>
        <div
          id="admin-buttons"
          *ngIf="displayingInCompanyAdminPage && mayChangeKYC()"
        >
          <zaver-secondary-button-outlined
            style="width: 110px"
            *ngIf="kycFormChanged"
            (click)="promptKycCancelationConfirmation()"
            [text]="'Avbryt'"
          ></zaver-secondary-button-outlined>
          <zaver-primary-button
            style="width: 110px; margin-left: 12px"
            (click)="promptKycSubmissionConfirmation()"
            [disabled]="!kycFormChanged"
            [text]="'Spara'"
            [color]="'black'"
          ></zaver-primary-button>
        </div>
      </div>
    </div>
    <ng-container *ngIf="displayingInCompanyAdminPage">
      <app-page-error [errorMessage]="errorMessage"></app-page-error>
    </ng-container>
  </form>
  <form [formGroup]="bankForm" novalidate *ngIf="mayGetBankDetails()">
    <div class="card" [class.in-admin]="displayingInCompanyAdminPage">
      <div class="card-heading">
        <h2>
          <span i18n="@@detailedQuestionsForm.bankInformation.cardHeading"
            >Bankkontouppgifter</span
          >
        </h2>
      </div>
      <div class="card-body">
        <div class="input-group-zfb">
          <ng-container *ngIf="localeService.getCurrentLocale() === 'sv'">
            <div class="input-fields">
              <zaver-form-input
                [title]="'Clearingnummer'"
                [placeholder]="'Clearingnummer'"
                [error]="bankForm.controls.clearingNumber.invalid && showError"
                [errorMessage]="
                  'Vänligen ange ett giltigt clearingnummer, 4 eller 5 siffror långt.'
                "
                formControlName="clearingNumber"
                [inputMode]="'numeric'"
                [size]="'large'"
              >
              </zaver-form-input>
            </div>
            <div class="input-fields">
              <zaver-form-input
                [title]="'Kontonummer'"
                [placeholder]="'Kontonummer'"
                [error]="bankForm.controls.accountNumber.invalid && showError"
                [errorMessage]="
                  'Vänligen ange ett giltigt kontonummer, mellan 7 och 10 siffror långt.'
                "
                formControlName="accountNumber"
                [inputMode]="'numeric'"
                [size]="'large'"
              >
              </zaver-form-input>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              localeService.getCurrentLocale() === 'de' ||
              localeService.getCurrentLocale() === 'de-AT'
            "
          >
            <div class="input-fields">
              <zaver-form-input
                [title]="'IBAN'"
                [placeholder]="'IBAN'"
                [error]="bankForm.controls.iban.invalid && showError"
                formControlName="iban"
                [inputMode]="'numeric'"
                [size]="'large'"
              >
              </zaver-form-input>
            </div>
            <div class="input-fields">
              <zaver-form-input
                [title]="'BIC'"
                [placeholder]="'BIC'"
                [error]="bankForm.controls.bic.invalid && showError"
                formControlName="bic"
                [inputMode]="'numeric'"
                [size]="'large'"
              >
              </zaver-form-input>
            </div>
          </ng-container>
        </div>
        <div class="input-fields">
          <zaver-form-input
            title="Bank"
            i18n-title="@@detailedQuestionsForm.bankInformation.bankName.title"
            [placeholder]="'Hämtas från clearing-nr'"
            formControlName="bank"
            [error]="bankForm.controls.bank.invalid && showError"
            [errorMessage]="
              'Banknamnet kan inte vara längre än 50 tecken långt.'
            "
            [size]="'large'"
          >
          </zaver-form-input>
        </div>

        <div id="bank-info-text" *ngIf="displayingInCompanyAdminPage">
          <span i18n="@@detailedQuestionsForm.bankInformation.infoText"
            >För att ändra bankkontouppgifter behöver vi erhålla ett kontobevis
            för det nya kontot. Vänligen kontakta oss för mer information
            <a href="mailto:merchants@zaver.se" class="link"
              >merchants@zaver.se</a
            ></span
          >
        </div>
      </div>
    </div>
  </form>
  <ng-container *ngIf="!displayingInCompanyAdminPage">
    <app-page-error
      *ngIf="errorMessage"
      [errorMessage]="errorMessage"
    ></app-page-error>
    <app-page-error
      *ngIf="showError && (kycForm.invalid || bankForm.invalid)"
      [errorMessage]="formErrorText"
    ></app-page-error>
  </ng-container>

  <div class="next-button-container" *ngIf="!displayingInCompanyAdminPage">
    <button class="back-button" (click)="back.emit()">Tillbaka</button>
    <button class="next-button cerise-bg" (click)="submit()">Nästa</button>
  </div>
</ng-container>

<zaver-details-modal
  *ngIf="modalActive"
  (onClose)="closeModal()"
  [title]="modalTitle"
  [color]="modalColor"
>
  <app-detailed-questions-form-confirmation
    *ngIf="displaySubmissionConfirmaiton"
    [signupQuestionsToCreate]="signupQuestionsToCreate"
    (onAbort)="closeModal()"
    (onSubmit)="changesSaved($event)"
  >
  </app-detailed-questions-form-confirmation>
  <div class="small-modal-wrapper" *ngIf="displayCancelConfirmation">
    <span i18n="@@detailedQuestionsForm.modal.cancelConfirmation.text"
      >Är du säker på att du vill avbryta? Dina ändringar kommer inte sparas.
      😯</span
    >

    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        text="Tillbaka"
        i18n-text="@@detailedQuestionsForm.modal.cancelConfirmation.button.back"
        (click)="closeModal()"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        text="Ja, avbryt"
        i18n-text="
          @@detailedQuestionsForm.modal.cancelConfirmation.button.confirm"
        [color]="'black'"
        (click)="cancelKycForm()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </div>
</zaver-details-modal>
