import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'zaver-button-link',
  templateUrl: './button-link.component.html',
  styleUrls: ['./button-link.component.scss'],
})
export class ButtonLinkComponent implements OnInit {
  @Input() text: string;
  @Input() disabled = false;
  @Input() interactive = false;
  @Input() color = Color.Blue;
  @Input() iconUrl;

  constructor() {}

  buttonPressedState = false;

  Color = Color;

  ngOnInit(): void {}

  onClick(event: MouseEvent) {
    if (this.disabled) {
      event.stopPropagation();
    }

    if (this.interactive) {
      this.buttonPressedState = true;
      setTimeout(() => {
        this.buttonPressedState = false;
      }, 500);
    }
  }
}

export enum Color {
  Blue = 'Blue',
  Black = 'Black',
}
