<div class="table-info">
  <div class="product-name">
    <input
      type="checkbox"
      (click)="onSetAllChecked(!allChecked)"
      [checked]="allChecked"
    />
    <p>Product name</p>
  </div>
  <div class="grouped-columns">
    <p class="quantity">Quantity</p>
    <p class="unit-price">Unit price</p>
    <p class="vat-amount">To refund</p>
    <p class="total-amount">Refundable</p>
    <p class="edit"></p>
  </div>
</div>

<div class="table">
  <ng-container *ngFor="let lineItemVM of lineItemsVM">
    <form
      [formGroup]="lineItemVM.form"
      [ngClass]="lineItemVM.form.invalid ? 'invalid' : 'valid'"
    >
      <div class="row">
        <div class="product-name">
          <input
            formControlName="checked"
            type="checkbox"
            [checked]="lineItemVM.form.value.checked"
          />

          <p [title]="lineItemVM.title" class="title">
            {{ lineItemVM.title }}
          </p>
        </div>

        <div class="grouped-columns">
          <div
            class="quantity"
            *ngIf="lineItemVM.form.controls['quantity'].disabled"
          >
            N/A
          </div>
          <div
            class="quantity"
            *ngIf="!lineItemVM.form.controls['quantity'].disabled"
          >
            <ng-container
              *ngIf="
                !lineItemVM.editMode || lineItemVM.form.value.quantity === 1
              "
            >
              <button
                [disabled]="isMinQuantity(lineItemVM.form.value.quantity, 1)"
                type="button"
                (click)="onChangeQuantity('REMOVE', lineItemVM)"
              >
                <img src="assets/icons/Remove.svg" />
              </button>

              <p>
                {{ lineItemVM.form.value.quantity }} /
                {{ lineItemVM.maxQuantity }}
              </p>

              <button
                [disabled]="
                  isMaxQuantity(
                    lineItemVM.form.value.quantity,
                    lineItemVM.maxQuantity
                  )
                "
                type="button"
                (click)="onChangeQuantity('ADD', lineItemVM)"
              >
                <img src="assets/icons/Add.svg" />
              </button>
            </ng-container>
            <app-line-item-input
              style="width: 50%"
              *ngIf="
                lineItemVM.editMode && lineItemVM.form.value.quantity !== 1
              "
              [type]="'number'"
              [control]="$any(lineItemVM.form.controls.quantity)"
              [textAlign]="'center'"
            ></app-line-item-input>
          </div>

          <div class="unit-price">
            <p>
              {{ lineItemVM.unitPrice | amountPipe : currency }}
            </p>
          </div>

          <div class="unit-price">
            <p *ngIf="!isEditable(lineItemVM.editMode)">
              {{ lineItemVM.form.value.toRefund | amountPipe : currency }}
            </p>

            <app-line-item-input
              *ngIf="isEditable(lineItemVM.editMode)"
              [control]="$any(lineItemVM.form.controls.toRefund)"
              [textAlign]="'right'"
            ></app-line-item-input>
          </div>

          <div class="total-amount">
            {{ lineItemVM.totalAmount | amountPipe : currency }}
          </div>

          <div class="edit">
            <button type="button" (click)="onSetEdit(lineItemVM)">
              <img src="assets/icons/Icon-Edit-Widget.svg" />
            </button>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
</div>

<div class="number-of-lineItems">{{ lineItemsVM.length }} line items</div>
