import {
  EnvIdentifier,
  EnvironmentSettings,
  LogLevel,
} from './environment-types';

export const environment: EnvironmentSettings = {
  production: false,
  apiUrl: 'https://server.staging.zaver.com/business',
  wsUri: 'wss://server.staging.zaver.com/business/socket/websocket',
  useFixtures: false,
  environment: EnvIdentifier.STAGING,
  logSettings: {
    minLevels: {
      telemetry: LogLevel.NONE,
      console: LogLevel.ALL,
      stash: LogLevel.ALL,
    },
    routerLog: false,
  },
};
