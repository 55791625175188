import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QuickAction } from '../quick-actions/quick-actions.component';

export interface TableRow<RowType> {
  data: RowType;
  quickActions: QuickAction<RowType>[];
}
export interface TableRows<RowType, CellType> {
  rows: TableRow<RowType>[];
  getPrimaryText: (it: RowType) => string;
  getSecondaryText: (it: RowType) => string;
  getMobileTextColumnStyle?: (it: RowType) => string;
  getStatusBarColor: (it: RowType) => string;
  getBackgroundColor?: (it: RowType) => string;
  rowClickFunction?: (it: RowType) => void;
  hideMobileHeader: boolean;
}

export interface FilterOption<T> {
  headerText: string;
  displayText: string;
  value: T;
}
export interface FilterOptions<T> {
  text: string;
  value: string;
  activeFilter: T;
  options: FilterOption<T>[];
}

export interface ColumnDefinition<RowType, CellType> {
  cssClasses?: string;
  columnStylingTransformer?: (it: RowType) => string; // Returns '' if not set
  columnHeadingStylingTransformer?: () => string; // Returns '' if not set
  columnDataTransformer?: (it: RowType) => string; // Returns '' if not set
  actionClicked?: (it: RowType) => void;
  columnColor?: (it: RowType) => string; // Used to set a custom color to be used somewhere in a column
  backgroundColor?: (it: RowType) => string;
  filterOptions?: FilterOptions<CellType>;
  filterable?: boolean;
  sortable?: boolean;
  sortedRising?: boolean;
  value?: (it: RowType) => string;
  title?: string;
  text?: string;
  type?: string;
  sortOptions?: any;
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<RowType, CellType> implements OnInit {
  @Input() values: TableRows<RowType, CellType>;
  @Input() columns: ColumnDefinition<RowType, CellType>[];

  @Output() filterChange = new EventEmitter<{
    type: string;
    filterOption: FilterOption<CellType>;
  }>();
  @Output() sortChange = new EventEmitter<{ type: string; value: boolean }>();

  showQuickActions = false;

  constructor() {}

  ngOnInit() {
    this.showQuickActions = this.values.rows.some(
      (row) => row.quickActions !== null
    );
  }

  isStoreRow(row) {
    if (!!row.type) {
      return true;
    } else {
      return false;
    }
  }

  isUserRow(row) {
    if (!!row.role) {
      return true;
    } else {
      return false;
    }
  }

  isUserOrStoreRow(row) {
    if (!!row.role || !!row.type) {
      return true;
    } else {
      return false;
    }
  }

  actionClicked(data: RowType, column: ColumnDefinition<RowType, CellType>) {
    event.stopPropagation();
    column.actionClicked(data);
  }

  rowClicked(row: RowType) {
    if (!!this.values.rowClickFunction) {
      this.values.rowClickFunction(row);
    }
  }

  backgroundColor(row: RowType) {
    if (!!this.values.getBackgroundColor) {
      return this.values.getBackgroundColor(row);
    }
  }

  getColumnStyling(column: ColumnDefinition<RowType, CellType>, row: RowType) {
    if (column.columnStylingTransformer) {
      return column.columnStylingTransformer(row);
    }
    return '';
  }

  getColumnHeadingStyling(column: ColumnDefinition<RowType, CellType>) {
    if (column.columnHeadingStylingTransformer) {
      return column.columnHeadingStylingTransformer();
    }
    return '';
  }

  getMobileTextStyling(row: RowType) {
    if (this.values.getMobileTextColumnStyle) {
      return this.values.getMobileTextColumnStyle(row);
    }
    return '';
  }
}
