<div class="progressbar-text-button-container" *ngIf="!processing">
  <div class="progressbar-and-text">
    <div class="progress-bar" [class.cerise-bg]="surplus">
      <div
        class="progress-bar-progress"
        [attr.style]="progressbarProcessWidth"
      ></div>
    </div>
    <div class="amount-text">
      <span>{{ paidAmount | currency: 'SEK ':'code':'1.2-2':'sv' }}</span
      >&nbsp;/&nbsp;<span>{{
        totalAmount | currency: 'SEK ':'code':'1.2-2':'sv'
      }}</span>
    </div>
  </div>
  <div
    *ngIf="payments.length > 0"
    class="show-button"
    (click)="payments.length > 0 && togglePaymentEventList()"
  >
    <ng-container *ngIf="hidePaymentEvents">
      <svg
        width="14"
        height="9"
        viewBox="0 0 14 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.228574 0.387718C0.533359 0.0660944 1.02754 0.0660702 1.33235 0.387663L7 6.36731L12.6676 0.387663C12.9725 0.0660702 13.4666 0.0660944 13.7714 0.387718C14.0762 0.709341 14.0762 1.23077 13.7714 1.55236L7.84529 7.80467C7.84515 7.80482 7.84501 7.80497 7.84487 7.80511C7.73401 7.92214 7.6024 8.01499 7.45753 8.07837C7.31249 8.14182 7.15701 8.17448 7 8.17448C6.84299 8.17448 6.68751 8.14182 6.54246 8.07837C6.39759 8.01498 6.26597 7.92212 6.1551 7.80508C6.15497 7.80495 6.15484 7.80481 6.15471 7.80467L0.228625 1.55236C-0.0761884 1.23077 -0.0762113 0.709341 0.228574 0.387718ZM7.25885 6.64041C7.25895 6.64051 7.25904 6.64061 7.25913 6.64071L7.25885 6.64041Z"
            fill="#6A7381"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="14" height="8.23529" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </ng-container>
    <ng-container *ngIf="!hidePaymentEvents">
      <svg
        width="14"
        height="9"
        viewBox="0 0 14 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_4190:31674)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7 1.85384L1.33235 7.98789C1.02754 8.31779 0.533359 8.31776 0.228574 7.98783C-0.0762114 7.6579 -0.0761883 7.12301 0.228625 6.79311L6.15471 0.37936C6.15478 0.379283 6.15485 0.379205 6.15493 0.379127C6.15499 0.379054 6.15506 0.37898 6.15513 0.378907C6.26599 0.25886 6.3976 0.163612 6.54246 0.0985996C6.68751 0.0335056 6.84298 0 7 0C7.15702 0 7.31249 0.0335056 7.45753 0.0985996C7.6024 0.163612 7.73401 0.25886 7.84487 0.378907C7.84501 0.379058 7.84515 0.379209 7.84529 0.37936L13.7714 6.79311C14.0762 7.12301 14.0762 7.6579 13.7714 7.98783C13.4666 8.31776 12.9725 8.31779 12.6676 7.98789L7 1.85384ZM7.25927 1.57323C7.25913 1.57338 7.25899 1.57353 7.25885 1.57368L7.25927 1.57323Z"
            fill="#6A7381"
          />
        </g>
        <defs>
          <clipPath id="clip0_4190:31674">
            <rect width="14" height="8.23529" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </ng-container>
  </div>
</div>
<div class="surplus-text" class="cerise-text" *ngIf="surplus">
  Överinbet. {{ surplusAmount | currency: 'SEK ':'code':'1.2-2':'sv' }}
</div>
<div
  class="payment-events"
  *ngIf="payments.length > 0"
  [class.hide]="hidePaymentEvents"
>
  <span class="payments-heading">Registrerade inbetalningar</span>
  <div class="payment-event" *ngFor="let payment of payments">
    <span class="amount-number">{{
      payment.amount | currency: 'SEK ':'':'1.2-2':'sv'
    }}</span>
    SEK - Inbet. {{ payment.paymentDate | date: 'mediumDate':'':'sv' }}
  </div>
</div>
