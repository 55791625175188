import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@core/base.component';
import { CashoutTaskDto } from '@core/dto/cashout/incoming/CashoutTaskDto';
import { AuthenticationService } from '@core/service/authentication.service';
import { CashoutTaskService } from '@core/service/cashout-task.service';

@Component({
  selector: 'app-cancel-cashout-task',
  templateUrl: './cancel-cashout-task.component.html',
  styleUrls: ['./cancel-cashout-task.component.css']
})
export class CancelCashoutTaskComponent extends BaseComponent implements OnInit {

  @Input() cashoutTask: CashoutTaskDto;

  @Output() onReturnToMainModal = new EventEmitter();
  @Output() onCloseAfterCancel = new EventEmitter();
  @Output() onCloseModal = new EventEmitter();

  cancellationInProgress = false;
  serverErrorMessage = 'Åtgärden kunde inte genomföras. Vänligen försök igen. <a class="link" href="mailto:merchants@zaver.se" target="_blank">Kontakta&nbsp;oss</a> om problemet kvarstår.';

  constructor(
    private cashoutTaskService: CashoutTaskService,
    auth: AuthenticationService,
  ) {
    super(auth);
   }

  ngOnInit(): void {
  }

  returnToMainModal() {
    this.onReturnToMainModal.emit();
  }

  closeModal() {
    this.onCloseModal.emit();
  }

  cancel() {
    this.errorMessage = '';
    this.cancellationInProgress = true;
    this.cashoutTaskService.cancel(this.cashoutTask.taskId).subscribe(() => {
        this.onCloseAfterCancel.emit();
    }, error => {
      this.handleError(error);
      this.cancellationInProgress = false;
    });
  }
}
