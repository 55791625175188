<button
  (click)="stopIfDisabled($event)"
  [ngClass]="[size, color]"
  [class.rounded]="rounded"
  [class.disabled]="disabled"
  [type]="buttonType"
  [attr.form]="!!formId ? formId : null"
>
  <ng-container *ngIf="!loading">
    <div class="icon-and-text-wrapper">
      <img class="icon" *ngIf="iconUrl" [src]="iconUrl" alt="" />
      <span class="button-text">{{ text }}</span>
    </div>
  </ng-container>

  <ng-container *ngIf="loading">
    <zaver-loading-dots [color]="'#ffffff'"></zaver-loading-dots>
  </ng-container>

  <div *ngIf="logo" class="logo-wrapper">
    <img class="logo" [src]="logo" alt="" />
  </div>
</button>
