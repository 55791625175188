import { Component, Input, OnInit } from '@angular/core';
import { View } from '../payment-management/payment-management.interfaces';
import { DropdownListItemv2 } from '../vat-dropdown/vat-dropdown.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CurrencyCode } from '@core/dto/CurrencyCode';

@Component({
  selector: 'app-confirm-refund',
  templateUrl: './confirm-refund.component.html',
  styleUrls: ['./confirm-refund.component.scss'],
})
export class ConfirmRefundComponent implements OnInit {
  @Input() uncapturedAmount: number;
  @Input() totalOrderAmount: number;
  @Input() selectedRefundTaxAmount: number;
  @Input() selectedAmountToRefund: number;
  @Input() inProgress: boolean;
  @Input() setView: (view: View) => void;
  @Input() onCreateRefund: (selectedReason, note, refund) => void;
  @Input() currency: CurrencyCode;
  public VIEW = View;
  confirmRefundForm: FormGroup;

  refundReasons: DropdownListItemv2[] = [
    {
      label: 'Faulty service',
      value: 'INCORRECTLY_EXECUTED_SERVICE',
    },
    {
      label: 'Other reason',
      value: 'OTHER',
    },
    {
      label: 'Defective item',
      value: 'PRODUCT_DEFECT',
    },
    {
      label: 'Right of withdrawal',
      value: 'RIGHT_OF_WITHDRAWAL',
    },
  ];
  selectedReason = this.refundReasons[1];
  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.confirmRefundForm = this.formBuilder.group({
      reason: [this.selectedReason],
      note: [''],
    });
  }

  findOption(option: DropdownListItemv2[], value: any) {
    return option.find((item) => item.value === value);
  }

  setReason(value): void {
    this.confirmRefundForm.controls.reason.setValue(value);
    this.selectedReason = this.refundReasons.find(
      (reason) => reason.value === value
    );
  }
}
