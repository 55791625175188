import {Component} from '@angular/core';
import {BidProgress} from '@core/dto/Progress';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseComponent} from '@core/base.component';
import {AuthenticationService, UserIdentificationBody} from '@core/service/authentication.service';
import {AppStateService} from '@core/service/app-state.service';
import {CompanyStatus, UserDetails} from '@core/dto/user-details';
import {ColorService} from '@core/service/color.service';
import {VerificationTokenService} from '@core/service/verification-token.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-signup-auth',
  templateUrl: './signup-auth.component.html',
  styleUrls: ['../../login/login-pages.scss', './signup-auth.component.css'],
})
export class SignupAuthComponent
    extends BaseComponent {

  constructor(
      private authenticationService: AuthenticationService,
      private router: Router,
      private route: ActivatedRoute,
      private tokenService: VerificationTokenService,
      protected auth: AuthenticationService,
      private appState: AppStateService,
      public colorService: ColorService
  ) {
    super(auth);
  }

  signupId: string;

  shouldShowModal: boolean = false;
  modalColor: string = '#EF3466';

  handleSuccess(progress: BidProgress<any>): void {
    this.appState.updateCurrentUser(progress.payload);
    this.signupId = this.route.snapshot.queryParams['signupId'];
    // find ongoing registration, i.e. company registrations where the user is company signatory
    // and it is not signed up, approved or rejected

    // if we have a signupId then we try and find a matching ongoing company registration (referralToken),
    // otherwise we find the latest that does not have any referralToken
    const ongoing = this.appState
    .getAvailableRepresentations()
    .filter(
        (rep) =>
            JSON.stringify(rep.role.id) ===
            JSON.stringify(rep.company.defaultSignupRoleId)
    )
    .filter(
        (rep) =>
            !rep.company.status.find(
                (status) => status === CompanyStatus.APPROVED
            )
    )
    .filter((rep) =>
        this.signupId
            ? rep.company.referralToken === this.signupId
            : !rep.company.referralToken
    );

    let result: Promise<UserDetails>;
    if (ongoing.length > 0) {
      // switch to the last ongoing registration we found
      // (expected to be sorted in the backend; on creation date, ascending order),
      // there should only be at most one ongoing registration
      result = this.authenticationService.switchProfile(
          ongoing[ongoing.length - 1]
      );
    } else {
      // start new registration, with the referral signupId (if available)
      result = this.authenticationService.signupNewCompany(this.signupId);
    }
    result
    .then(() => {
        this.router.navigate(['/signup/step1'], {
          skipLocationChange: false,
        });
      }
    )
    .catch((err: HttpErrorResponse) => {
      this.shouldShowModal = true;
      console.error(err);
      this.appState.clear(); // Clears current user on error
    })
  }

  getUserBody = (): UserIdentificationBody => {
    return {
      token: this.tokenService.getToken(),
      email: this.tokenService.email,
    };
  }

  back(): void {
    history.back();
  }

  handleCloseModal(): void {
    this.shouldShowModal = false;

    this.router.navigate([`/signup/partner/${this.signupId}`]);
  }
}
