import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocaleService } from '@core/service/locale.service';
import { Observable } from 'rxjs';

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {
  constructor(private localeService: LocaleService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add the locale so we can get correctly translated error messages from backend
    const cloneReq = req.clone({
      headers: req.headers.set(
        'X-FRONTEND-LOCALE',
        this.localeService.getCurrentLocale()
      ),
    });

    return next.handle(cloneReq);
  }
}
