<div>
    <div id="server-error-image">
        <img src="{{imgPath}}" alt="Server error image">
    </div>
    <div id="server-error-heading">
        <span [innerHTML]="errorHeading"></span>
    </div>
    <div id="server-error-text">
        <span [innerHTML]="errorMessage"></span>
    </div>
</div>
