import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class DateValidators {
  static DATE_FORMAT_YYYMMDD_SE =
    /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
  static DATE_FORMAT_DDMMYYY_DE =
  /^(0[1-9]|[12]\d|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/;

  public static dateFormat(locale: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const input: string = control.value;

      if (!input) {
        return;
      }

      switch (locale) {
        case 'sv':
          return this.DATE_FORMAT_YYYMMDD_SE.test(input)
            ? null
            : { wrongFormat: true };
        case 'de':
        case 'de-AT':
          return this.DATE_FORMAT_DDMMYYY_DE.test(input)
            ? null
            : { wrongFormat: true };
        default:
          break;
      }
    };
  }
}
