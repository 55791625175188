import { RoleColor } from './user-details';

export class CompanyRepresentativeRole {
  displayDescription: string;
  displayName: string;
  identified: boolean;
  mayHaveStoreRelation: boolean;
  id: string;
  color: RoleColor;

  constructor(role: CompanyRepresentativeRole) {
    this.displayDescription = role.displayDescription;
    this.displayName = role.displayName;
    this.identified = role.identified;
    this.mayHaveStoreRelation = role.mayHaveStoreRelation;
    this.id = role.id;
    this.color = role.color;
  }
}
