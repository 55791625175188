<ng-container *ngIf="localeService.getCurrentLocale() === 'sv'">
  <app-precheck-se></app-precheck-se>
</ng-container>
<ng-container
  *ngIf="
    localeService.getCurrentLocale() === 'de' ||
    localeService.getCurrentLocale() === 'de-AT'
  "
>
  <app-precheck-de></app-precheck-de>
</ng-container>
