import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@core/base.component';
import { ReportType } from '@core/dto/ReportInfo';
import { AuthenticationService } from '@core/service/authentication.service';
import { ColorService } from '@core/service/color.service';
import {
  DisbursementReportFormat,
  ReportService,
} from '@core/service/report.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-view-download-file',
  templateUrl: './view-download-file.component.html',
  styleUrls: ['./view-download-file.component.css'],
})
export class ViewDownloadFileComponent extends BaseComponent implements OnInit {
  @Input() searchQuery: string;
  @Input() fromDate: Date;
  @Input() toDate: Date;
  @Output() deactivateModal = new EventEmitter();

  reportFormats: DisbursementReportFormat[];
  selectedFilePreference: DisbursementReportFormat =
    DisbursementReportFormat.EXCEL_XLSX;
  includeRefunds = true;
  fetchingResponse = false;

  exportText = $localize`:@@accountingReport.download.export:Exportera`;
  abortText = $localize`:@@accountingReport.download.abort:Avbryt`;

  // for use in the HTML
  disbursementReportFormat = DisbursementReportFormat;

  constructor(
    protected auth: AuthenticationService,
    private reportService: ReportService,
    public colorService: ColorService
  ) {
    super(auth);
  }

  ngOnInit(): void {
    this.getReportFormats();
  }

  setFileType(fileType: DisbursementReportFormat) {
    this.selectedFilePreference = fileType;
  }

  setIncludeRefund(includeRefund: boolean) {
    this.includeRefunds = includeRefund;
  }

  hasReportFormat(format: DisbursementReportFormat): boolean {
    return this.reportFormats?.includes(format) ?? false;
  }

  async getReportFormats() {
    let that = this;
    this.reportService.getMerchantReportFormats().subscribe((res) => {
      that.reportFormats = res.body?.formats ?? [];

      // Pick the preselected report format.
      if ((this.reportFormats?.length ?? 0) == 0) {
        // This is default if no other format is configured for this merchant. It is always available.
        this.selectedFilePreference = DisbursementReportFormat.EXCEL_XLSX;
      } else if (
        this.reportFormats.includes(DisbursementReportFormat.EXCEL_XLSX)
      ) {
        // If the merchant has an Excel-format configured then that is preferred.
        this.selectedFilePreference = DisbursementReportFormat.EXCEL_XLSX;
      } else if (
        this.reportFormats.includes(DisbursementReportFormat.CSV_SEMICOLON)
      ) {
        // Otherwise if the merchant has this format configured then it should be preselected.
        this.selectedFilePreference = DisbursementReportFormat.CSV_SEMICOLON;
      } else if (
        this.reportFormats.includes(DisbursementReportFormat.CSV_COMMA)
      ) {
        // This is the least preferred format but if the merchant has it configured then we select it for them.
        this.selectedFilePreference = DisbursementReportFormat.CSV_COMMA;
      } else {
        // Fallback to the good ol' Excel format in case nothing else fits above.
        this.selectedFilePreference = DisbursementReportFormat.EXCEL_XLSX;
      }
    });
  }

  onDownload() {
    let filetype: string;
    switch (this.selectedFilePreference) {
      case DisbursementReportFormat.EXCEL_XLSX:
        filetype =
          'applications/vnc.openxmlformats-officedument.spreadsheetxml.sheet';
        this.downloadFile(ReportType.CHECKOUT, filetype);
        break;
      case DisbursementReportFormat.CSV_SEMICOLON:
        filetype = 'text/csv;charset=UTF-8';
        this.downloadFile(ReportType.CHECKOUT, filetype);
        break;
      case DisbursementReportFormat.CSV_COMMA:
        filetype = 'text/csv;charset=UTF-8';
        this.downloadFile(ReportType.CHECKOUT, filetype);
        break;
    }
  }

  async downloadFile(reportType: ReportType, filetype: string) {
    this.clearError();
    if (this.fromDate && this.toDate) {
      this.fetchingResponse = true;
      return this.reportService
        .getMerchantReportV2(
          this.searchQuery,
          this.fromDate,
          this.toDate,
          this.selectedFilePreference,
          reportType,
          this.includeRefunds
        )
        .subscribe(
          (res) => {
            this.fetchingResponse = false;
            FileSaver.saveAs(
              new Blob([res.body], { type: filetype }),
              res.headers.get('content-disposition').split('"')[1]
            ); // Filename encased in quotes, get middle object of 3
          },
          (err) => {
            super.handleError(err);
            this.fetchingResponse = false;
            const httpError: HttpErrorResponse = JSON.parse(
              JSON.stringify(err)
            );
            if (httpError.status === 400) {
              this.errorMessage = $localize`:@@salesReport.getFile.error.tooLarge:Filen du försöker ladda ned innehåller för många rader. Vänligen välj ett tidsintervall med färre än 5000 transaktioner.`;
            } else {
              this.errorMessage = $localize`:@@salesReport.getFile.error.other:Något gick fel när rapporten skulle laddas ned`;
            }
          }
        );
    }
  }
}
