<div *ngIf="values && columns" id="table-container">
  <div class="div-table">
    <div class="div-table-row">
      <div class="div-table-col" *ngFor="let column of columns">
        <span>{{ column.text }}</span>
      </div>
    </div>
    <div class="div-table-row" *ngFor="let row of values">
      <div class="div-table-col" *ngFor="let column of columns">
        <div
          class="right-align user-store-name-icon"
          *ngIf="column.value === 'userEmail'"
        >
          <img src="assets/icons/Icon-User-Representative.svg" />
          <span class="user-name-pending medium2 avoid-overflow">{{
            row.userEmail
          }}</span>
        </div>

        <div
          class="right-align medium"
          *ngIf="column.value === 'registrationDate'"
        >
          {{ row['registrationDate'] | date: 'mediumDate':'' }}
        </div>

        <div *ngIf="column.value === 'actionCol'" class="pending-user-action">
          <div
            class="action-button deny-action gray-background black-text"
            (click)="actionClicked.emit({ object: row, action: 'DENY' })"
          >
            <span i18n="@@userApprovalTable.table.deny">Neka</span>
          </div>
          <div
            class="action-button approve-action"
            (click)="actionClicked.emit({ object: row, action: 'APPROVE' })"
          >
            <span i18n="@@userApprovalTable.table.approve">Godkänn</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="div-table-mobile">
    <div class="div-table-row-mobile" *ngFor="let row of values">
      <div class="mobile-status-bar" [style.background-color]="'#4466EE'"></div>

      <div class="div-table-row-mobile-content">
        <div class="div-table-col-mobile-pending-users">
          <div class="primary-text">{{ row.userEmail }}</div>

          <div class="pending-user-action-mobile">
            <div
              class="action-button deny-action-mobile cerise-color cerise-border"
              (click)="actionClicked.emit({ object: row, action: 'DENY' })"
            >
              <span i18n="@@userApprovalTable.mobileTable.deny">Neka</span>
            </div>
            <div
              class="action-button approve-action"
              (click)="actionClicked.emit({ object: row, action: 'APPROVE' })"
            >
              <span i18n="@@userApprovalTable.mobileTable.approve"
                >Godkänn</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
