import { Component, Input, OnInit } from '@angular/core';
import {
  SimpleLineItemVM,
  View,
} from '../payment-management/payment-management.interfaces';
import {
  RefundView,
  WidgetFixedAmountRefundsVM,
  WidgetRefundResponseVM,
} from '@core/service/payment-capture.service';
import { CurrencyCode } from '@core/dto/CurrencyCode';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit {
  @Input() capturedLineItems: SimpleLineItemVM[];
  @Input() unCapturedLineItems: SimpleLineItemVM[];
  @Input() availableForCapture: number;
  @Input() initialPaymentRequestAmount: number;
  @Input() capturedAmount: number;
  @Input() deletedAmount: number;
  @Input() refundedAmount: number;
  @Input() amountLeftToCapture: number;
  @Input() paymentMethod: string;
  @Input() latestCaptureTimeStamp: Date;
  @Input() slask: boolean;
  @Input() setView: (view: View) => void;
  @Input() paymentRequestStatus;
  @Input() showPaymentUpdate: () => void;
  @Input() lineItemRefunds: WidgetRefundResponseVM[];
  @Input() fixedAmountRefunds: WidgetFixedAmountRefundsVM[];
  @Input() onApproveRefund: (refundId: string) => void;
  @Input() onCancelRefund: (refundId: string) => void;
  @Input() totalRefundableAmount: number;
  @Input() fixedAmountRefundLineItems: SimpleLineItemVM[];
  @Input() currency: CurrencyCode;

  @Input() inProgress: boolean;
  public VIEW = View;
  public REFUNDVIEW = RefundView;

  constructor() {}

  ngOnInit(): void {}

  handleLineItemRefund(refund: WidgetRefundResponseVM, action: RefundView) {
    refund.refundView = action;
  }

  handleFixedAmountRefund(
    refund: WidgetFixedAmountRefundsVM,
    action: RefundView
  ) {
    refund.refundView = action;
  }
}
