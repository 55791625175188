<div class="spacing-container">
  <div>
    <div id="confirmation-heading" class="medium">
      {{ cashoutTask.reference }}
    </div>

    <div class="list-container">
      <div class="list-row" *ngIf="cashoutTask.payeeName">
        <div class="icon-and-text">
          <img
            class="icons"
            src="assets/icons/Icon-User-Single-Black-No-Circle.svg"
            alt=""
          />
          <span class="medium2">Säljare</span>
        </div>
        <div>
          {{ cashoutTask.payeeName }}
        </div>
      </div>
      <div class="list-row">
        <div class="icon-and-text">
          <img class="icons" src="assets/icons/Icon-Phone.svg" alt="" />
          <span class="medium2">Mobilnummer</span>
        </div>
        <div>
          <a href="tel:{{ cashoutTask.payeePhone }}" class="link">{{
            cashoutTask.payeePhone
          }}</a>
        </div>
      </div>
      <div
        class="list-row"
        [class.editingPadding]="editingAmount"
        [class.edit-amount-row]="
          cashoutTask.status === 'PENDING_EXECUTION' && !editingAmount
        "
        id="edit-amount-row"
      >
        <div class="icon-and-text">
          <img class="icons" src="assets/icons/Icon-Money-Wallet.svg" alt="" />
          <span class="medium2">Belopp</span>
        </div>

        <div *ngIf="cashoutTask.status !== 'PENDING_EXECUTION'">
          <span>{{
            cashoutTask.amount | currency: 'SEK ':'code':'1.2-2':'sv'
          }}</span>
        </div>

        <div *ngIf="cashoutTask.status === 'PENDING_EXECUTION'">
          <div *ngIf="!shouldDisplayEditAmountButton()">
            <span>{{
              cashoutTask.amount | currency: 'SEK ':'code':'1.2-2':'sv'
            }}</span>
          </div>

          <div
            id="amount-edit"
            *ngIf="!editingAmount && shouldDisplayEditAmountButton()"
          >
            <span>{{
              cashoutTask.amount | currency: 'SEK ':'code':'1.2-2':'sv'
            }}</span>
            <div
              class="hide-mobile-s"
              *ngIf="shouldDisplayEditAmountButton()"
              (click)="editAmount()"
            >
              <div id="edit-icon-div">
                <div id="edit-icon"></div>
              </div>
            </div>
          </div>
          <div id="amount-edit" *ngIf="editingAmount">
            <form [formGroup]="editAmountForm" novalidate>
              <input
                #amountInput
                type="text"
                inputmode="numeric"
                id="edit-amount-input"
                formControlName="amount"
              />
            </form>
          </div>
        </div>
      </div>
      <div class="list-row">
        <div class="icon-and-text">
          <img class="icons" src="assets/icons/Icon-Bank.svg" alt="" />
          <span class="medium2">Bankkontonummer</span>
        </div>
        <div>
          <span
            *ngIf="
              cashoutTask.bankAccount && cashoutTask.status !== 'CANCELLED'
            "
            >{{ cashoutTask.bankAccount }}</span
          >
          <span
            *ngIf="
              !cashoutTask.bankAccount || cashoutTask.status === 'CANCELLED'
            "
            >N/A</span
          >
        </div>
      </div>
      <div class="list-row" *ngIf="!cashoutTask.approvingRepresentativeName">
        <div class="icon-and-text">
          <img
            class="icons"
            src="assets/icons/Icon-User-Single-Black.svg"
            alt=""
          />
          <span class="medium2">Initierad av</span>
        </div>
        <div>
          {{ cashoutTask.initializingRepresentativeName }}
        </div>
      </div>
      <div class="list-row" *ngIf="cashoutTask.approvingRepresentativeName">
        <div class="icon-and-text">
          <img
            class="icons"
            src="assets/icons/Icon-User-Single-Black.svg"
            alt=""
          />
          <span class="medium2">Attesterad av</span>
        </div>
        <div>
          {{ cashoutTask.approvingRepresentativeName }}
        </div>
      </div>
      <div class="list-row" *ngIf="cashoutTask.executingRepresentativeName">
        <div class="icon-and-text">
          <img
            class="icons"
            src="assets/icons/Icon-User-Single-Black.svg"
            alt=""
          />
          <span class="medium2">Utbetalad av</span>
        </div>
        <div>
          {{ cashoutTask.executingRepresentativeName }}
        </div>
      </div>
    </div>

    <div
      class="errors-container"
      *ngIf="
        editingAmount &&
        editAmountForm.controls.amount.invalid &&
        showEditAmountError
      "
    >
      <app-form-error [errorText]="getEditingAmountError()"> </app-form-error>
    </div>
    <app-form-error *ngIf="errorMessage" [errorText]="errorMessage">
    </app-form-error>
  </div>

  <div
    class="continue-button-container"
    *ngIf="cashoutTask.status == 'PENDING_PAYEE_APPROVAL'"
  >
    <zaver-secondary-button-subtle
      [text]="'Avbryt utbetalning'"
      [iconUrl]="'assets/icons/Icon-Bin.svg'"
      (click)="cancelCashout()"
    ></zaver-secondary-button-subtle>
  </div>

  <div *ngIf="cashoutTask.status === 'PENDING_ADMIN_APPROVAL'">
    <div
      *ngIf="shouldDisplayAdminApprovalButton() && shouldDisplayCancelButton()"
      class="continue-back-buttons-container"
    >
      <zaver-primary-button
        [text]="'Attestera'"
        [loading]="sendingApproval"
        (click)="submitAdminAppproval()"
      >
      </zaver-primary-button>
      <zaver-secondary-button-subtle
        [text]="'Avbryt utbetalning'"
        [iconUrl]="'assets/icons/Icon-Bin.svg'"
        (click)="cancelCashout()"
        [disabled]="sendingApproval"
      >
      </zaver-secondary-button-subtle>
    </div>
    <div
      *ngIf="!shouldDisplayCancelButton() && shouldDisplayAdminApprovalButton()"
      class="continue-button-container"
    >
      <zaver-primary-button
        [text]="'Attestera'"
        [loading]="sendingApproval"
        (click)="submitAdminAppproval()"
      >
      </zaver-primary-button>
    </div>
    <div
      *ngIf="!shouldDisplayAdminApprovalButton() && shouldDisplayCancelButton()"
      class="continue-button-container"
    >
      <zaver-secondary-button-subtle
        *ngIf="shouldDisplayCancelButton()"
        [text]="'Avbryt utbetalning'"
        [iconUrl]="'assets/icons/Icon-Bin.svg'"
        (click)="cancelCashout()"
      >
      </zaver-secondary-button-subtle>
    </div>
  </div>

  <div *ngIf="cashoutTask.status === 'PENDING_EXECUTION'">
    <div id="editing-button-group">
      <ng-container *ngIf="!editingAmount">
        <zaver-primary-button
          *ngIf="shouldDisplayExecutionButton()"
          [text]="'Betala'"
          (click)="confirmDisbursement()"
        >
        </zaver-primary-button>

        <zaver-secondary-button-subtle
          *ngIf="shouldDisplayEditAmountButton()"
          [text]="'Redigera belopp'"
          (click)="editAmount()"
        >
        </zaver-secondary-button-subtle>
      </ng-container>
      <ng-container *ngIf="editingAmount">
        <zaver-primary-button
          [text]="'Uppdatera belopp'"
          [loading]="savingUpdatedAmount"
          (click)="saveEditedAmount()"
        >
        </zaver-primary-button>
        <zaver-secondary-button-subtle
          [text]="'Ångra'"
          [disabled]="savingUpdatedAmount"
          (click)="undoEditAmount()"
        >
        </zaver-secondary-button-subtle>
      </ng-container>

      <div id="cancel-text-with-icon" *ngIf="shouldDisplayCancelButton()">
        <div id="cancel-click-area" (click)="cancelCashout()">
          <img
            src="assets/icons/Icon-Bin.svg"
            alt="Trashcan icon"
            style="margin-right: 8px"
          />
          <span>Avbryt utbetalning</span>
        </div>
      </div>
    </div>
  </div>
</div>
