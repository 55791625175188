<div id="sub-header">
  <div id="menu-div">
    <a
      class="menu-item"
      [routerLink]="['users']"
      appHoverClass="cerise-text"
      routerLinkActive="cerise-text"
      [skipLocationChange]="false"
      *ngIf="mayViewUsers()"
    >
      <span i18n="@@subHeader.users">Användare</span>
    </a>
    <a
      class="menu-item"
      [routerLink]="['stores']"
      appHoverClass="cerise-text"
      routerLinkActive="cerise-text"
      [skipLocationChange]="false"
      *ngIf="mayViewStores()"
    >
      {{ storeAliasName }}
    </a>
    <a
      class="menu-item"
      [routerLink]="['refunds']"
      appHoverClass="cerise-text"
      routerLinkActive="cerise-text"
      [skipLocationChange]="false"
      *ngIf="mayViewRefunds()"
    >
      <span i18n="@@subHeader.refunds">Returer</span>
    </a>
    <a
      class="menu-item"
      [routerLink]="['report']"
      routerLinkActive="cerise-text"
      [skipLocationChange]="false"
      *ngIf="mayViewReport()"
    >
      <span i18n="@@subHeader.reports">Rapporter</span>
    </a>
    <a
      class="menu-item"
      [routerLink]="['company']"
      appHoverClass="cerise-text"
      routerLinkActive="cerise-text"
      [skipLocationChange]="false"
      *ngIf="mayViewCompanyGeneralInfo()"
    >
      <span i18n="@@subHeader.companySettings">Företagsinställningar</span>
    </a>
    <a
      class="menu-item"
      [routerLink]="['zfbMerchantSettings']"
      appHoverClass="cerise-text"
      routerLinkActive="cerise-text"
      [skipLocationChange]="false"
      *ngIf="mayViewZfbMerchantSettings()"
    >
      <span i18n="@@companyAdministration.tabs.general">Övrigt</span>
    </a>
  </div>
</div>
