import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LoggingService } from './service/logging.service';
import * as StackTrace from 'stacktrace-js'
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class LoggingErrorHandler implements ErrorHandler {
    constructor(
        private injector: Injector
    ) { }
    handleError(error: Error) {
        let logger = this.injector.get(LoggingService);
        if(!(error instanceof HttpErrorResponse)){
            StackTrace.fromError(error)
                .then(
                    trace => logger.reportCrash("uncaught exception in checkout, message=" + error.message, trace))
                .catch(
                    () => logger.reportCrash("StackTrace.fromError threw exception on error=" + JSON.stringify(error) + "with message=" + error.message, null)
                );
        }
    }
}

