<div class="card">
  <h1><span i18n="@@passwordEntry.title">Ange ditt lösenord</span></h1>
  <form [formGroup]="form" id="login-form">
    <div class="input-container">
      <zaver-animated-form-input
        [size]="'large'"
        placeholder="Ange jobbmail"
        i18n-placeholder="@@passwordEntry.form.email.placeholder"
        [inputMode]="'email'"
        [error]="form.controls.email.invalid && showError"
        errorMessage="Vänligen ange en giltig e-postadress"
        i18n-errorMessage="@@passwordEntry.form.email.errorMessage"
        formControlName="email"
        [readonly]="processingLogin"
      ></zaver-animated-form-input>
    </div>

    <div class="input-container">
      <zaver-animated-form-input
        [size]="'large'"
        placeholder="Ange ditt lösenord"
        i18n-placeholder="@@passwordEntry.form.password.placeholder"
        [inputType]="'password'"
        [error]="form.controls.password.invalid && showError"
        errorMessage="Vänligen ange ditt lösenord"
        i18n-errorMessage="@@passwordEntry.form.password.errorMessage"
        formControlName="password"
        [readonly]="processingLogin"
      ></zaver-animated-form-input>
    </div>

    <div class="switch-container">
      <app-switch
        (change)="rememberMeClicked()"
        text="Kom ihåg mig"
        i18n-text="@@passwordEntry.rememberMe.text"
        formControlName="rememberMe"
      ></app-switch>
    </div>
    <app-form-error *ngIf="formError" [errorText]="formError"> </app-form-error>

    <div class="button-wrapper">
      <zaver-primary-button
        [buttonType]="'submit'"
        text="Logga in"
        i18n-text="@@passwordEntry.button.submit"
        [loading]="processingLogin"
        [color]="'cerise'"
        (click)="submit()"
      ></zaver-primary-button>
    </div>
  </form>
</div>
<div id="signup-box">
  <span
    (click)="resetPassword()"
    class="link"
    i18n="@@passwordEntry.forgotPassword.text"
    >Glömt lösenordet?</span
  >
</div>
