import {
  Component,
  EventEmitter,
  ElementRef,
  Input,
  OnInit,
  HostListener,
  Output,
} from '@angular/core';
@Component({
  selector: 'zaver-details-modal',
  templateUrl: './details-modal.component.html',
  styleUrls: ['./details-modal.component.css'],
})
export class DetailsModalComponent implements OnInit {
  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent): void {
    if (this.modalActive) {
      this.removeModal();
    }
  }

  @Output() onClose = new EventEmitter<any>();
  @Input() title: string;
  @Input() color: string = '#4466EE';
  @Input() titleColor: string;
  private element: any;
  private modalActive: boolean = false;

  constructor(private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    this.setupModal();
  }

  private removeModalListener = (event) => {
    if (event.target.className === 'modal') {
      this.removeModal();
    }
  };

  setupModal() {
    this.modalActive = true;
    this.element.addEventListener('click', this.removeModalListener);
  }

  removeModal() {
    this.modalActive = false;
    this.onClose.emit();
  }
}
