import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Permission } from '@core/dto/user-details';
import { AccessControlService } from '@core/service/access-control.service';
import { AppStateService } from '@core/service/app-state.service';
import { Observable } from 'rxjs';

@Injectable()
export class CanViewAdminPages implements CanActivate {
  constructor(
    private router: Router,
    private access: AccessControlService,
    private appState: AppStateService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    if (
      this.access.userMay(Permission.GET_USERS) ||
      (this.access.userMay(Permission.GET_STORES) &&
        this.appState.merchantHasStoreAccess()) ||
      (this.access.userMay(Permission.GET_REFUNDS) &&
        this.appState.merchantHasRefundAccess()) ||
      this.access.userMay(Permission.GET_REPORTS) ||
      this.access.userMay(Permission.GET_COMPANY_INFORMATION)
    ) {
      return true;
    } else {
      this.router.navigate(['']);
      return false;
    }
  }
}

@Injectable()
export class CanViewStoresPage implements CanActivate {
  constructor(
    private router: Router,
    private access: AccessControlService,
    private appState: AppStateService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    if (
      this.access.userMay(Permission.GET_STORES) &&
      this.appState.merchantHasStoreAccess()
    ) {
      return true;
    } else {
      this.router.navigate(['']);
      return false;
    }
  }
}

@Injectable()
export class CanViewRefundsPage implements CanActivate {
  constructor(
    private router: Router,
    private access: AccessControlService,
    private appState: AppStateService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    if (
      this.access.userMay(Permission.GET_REFUNDS) &&
      this.appState.merchantHasRefundAccess()
    ) {
      return true;
    } else {
      this.router.navigate(['']);
      return false;
    }
  }
}

@Injectable()
export class CanViewReportsPage implements CanActivate {
  constructor(private router: Router, private access: AccessControlService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    if (this.access.userMay(Permission.GET_REPORTS)) {
      return true;
    } else {
      this.router.navigate(['']);
      return false;
    }
  }
}

@Injectable()
export class CanViewSettingsPage implements CanActivate {
  constructor(private router: Router, private access: AccessControlService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    if (this.access.userMay(Permission.GET_COMPANY_INFORMATION)) {
      return true;
    } else {
      this.router.navigate(['']);
      return false;
    }
  }
}

@Injectable()
export class CanViewCashoutPage implements CanActivate {
  constructor(
    private router: Router,
    private access: AccessControlService,
    private appState: AppStateService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    if (
      this.access.userMay(Permission.GET_CASHOUT_ORDERS) &&
      this.appState.merchantHasCashoutAccess()
    ) {
      return true;
    } else {
      this.router.navigate(['']);
      return false;
    }
  }
}

@Injectable()
export class CanViewPrecheckPage implements CanActivate {
  constructor(
    private router: Router,
    private access: AccessControlService,
    private appState: AppStateService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    if (
      this.access.userMay(Permission.CREATE_PRECHECK) &&
      this.appState.merchantHasPrecheckAccess()
    ) {
      return true;
    } else {
      this.router.navigate(['']);
      return false;
    }
  }
}

@Injectable()
export class CanViewZfbMerchantsSettings implements CanActivate {
  constructor(private router: Router, private access: AccessControlService) {}

  canActivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.access.userMay(Permission.GET_ZFB_MERCHANT_SETTINGS)) {
      return true;
    } else {
      this.router.navigate(['']);
      return false;
    }
  }
}
