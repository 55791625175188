<div class="event-container">
  <div class="event-header">
    <div class="time-container">
      <svg
        width="18"
        height="18"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 23.25C5.797 23.25 0.75 18.203 0.75 12C0.75 5.797 5.797 0.75 12 0.75C18.203 0.75 23.25 5.797 23.25 12C23.25 18.203 18.203 23.25 12 23.25ZM12 2.25C6.624 2.25 2.25 6.624 2.25 12C2.25 17.376 6.624 21.75 12 21.75C17.376 21.75 21.75 17.376 21.75 12C21.75 6.624 17.376 2.25 12 2.25Z"
          fill="#0A1018"
        />
        <path
          d="M16.687 17.438C16.487 17.438 16.298 17.36 16.157 17.218L11.47 12.53C11.4 12.46 11.345 12.378 11.307 12.285C11.304 12.277 11.301 12.268 11.298 12.26C11.266 12.174 11.251 12.088 11.251 12V8.25C11.251 7.836 11.587 7.5 12.001 7.5C12.415 7.5 12.751 7.836 12.751 8.25V11.689L17.218 16.157C17.36 16.299 17.438 16.487 17.438 16.687C17.438 16.887 17.36 17.076 17.218 17.217C17.076 17.358 16.887 17.438 16.687 17.438Z"
          fill="#0A1018"
        />
      </svg>
      <span class="event-time">{{ event.created | date: 'HH.mm':'' }}</span>
    </div>
    <div class="types-container">
      <app-event-type-pill
        *ngIf="showEventPill()"
        [textColor]="'#4466EE'"
        [backgroundColor]="'#DBE7FF'"
        text="Händelse"
        i18n-text="@@paymentRequestEvent.type.event"
      >
      </app-event-type-pill>
      <app-event-type-pill
        *ngIf="showNotePill()"
        [textColor]="'#00A880'"
        [backgroundColor]="'#E5F5F2'"
        text="Anteckning"
        i18n-text="@@paymentRequestEvent.type.note"
      >
      </app-event-type-pill>
      <app-event-type-pill
        *ngIf="showRefundPill()"
        text="Retur"
        i18n-text="@@paymentRequestEvent.type.refund"
        [textColor]="colorService.getCeriseColorCode()"
        [backgroundColor]="'#FCE8F2'"
      >
      </app-event-type-pill>
    </div>
  </div>
  <div>
    <ng-container [ngSwitch]="event.type">
      <ng-container *ngSwitchCase="'INITIALIZED'">
        <span class="event-text">
          <ng-container *ngIf="!event.messageArguments.representativeEmail">
            <span i18n="@@paymentRequestEvent.INITIALIZED.noArguments"
              >Betalningen initierades</span
            >
          </ng-container>
          <ng-container *ngIf="!!event.messageArguments.representativeEmail">
            <span i18n="@@paymentRequestEvent.INITIALIZED.withArguments"
              >Betalning initierad av</span
            >&nbsp;
            <ng-container *ngIf="!!event.messageArguments.representativeName">
              <a
                class="link"
                target="_blank"
                href="mailto:{{ event.messageArguments.representativeEmail }}"
                >{{ event.messageArguments.representativeName }}</a
              >
            </ng-container>
            <ng-container *ngIf="!event.messageArguments.representativeName">
              <a
                class="link"
                target="_blank"
                href="mailto:{{ event.messageArguments.representativeEmail }}"
                >{{ event.messageArguments.representativeEmail }}</a
              >
            </ng-container>
            <ng-container *ngIf="!!event.messageArguments.storeName">
              <span>, {{ event.messageArguments.storeName }}</span>
            </ng-container>
          </ng-container>
          <span>.</span></span
        >
      </ng-container>

      <ng-container *ngSwitchCase="'INITIALIZED_API'">
        <span class="event-text" i18n="@@paymentRequestEvent.INITIALIZED_API"
          >Betalningen initierades via API.</span
        >
      </ng-container>

      <ng-container *ngSwitchCase="'CANCELLED'">
        <span class="event-text">
          <ng-container *ngIf="!event.messageArguments.representativeEmail">
            <span i18n="@@paymentRequestEvent.CANCELLED.noArguments"
              >Betalningen avbröts</span
            >
          </ng-container>
          <ng-container *ngIf="!!event.messageArguments.representativeEmail">
            <span i18n="@@paymentRequestEvent.CANCELLED.withArguments"
              >Betalningen avbröts av&nbsp;
              <ng-container *ngIf="!!event.messageArguments.representativeName">
                <a
                  class="link"
                  target="_blank"
                  href="mailto:{{ event.messageArguments.representativeEmail }}"
                  >{{ event.messageArguments.representativeName }}</a
                >
              </ng-container>
              <ng-container *ngIf="!event.messageArguments.representativeName">
                <a
                  class="link"
                  target="_blank"
                  href="mailto:{{ event.messageArguments.representativeEmail }}"
                  >{{ event.messageArguments.representativeEmail }}</a
                >
              </ng-container>
              <ng-container *ngIf="!!event.messageArguments.storeName"
                ><span
                  >, {{ event.messageArguments.storeName }}</span
                ></ng-container
              >
            </span>
          </ng-container>
          <span>.</span></span
        >
      </ng-container>

      <ng-container *ngSwitchCase="'SETTLED'">
        <span class="event-text" i18n="@@paymentRequestEvent.SETTLED"
          >Betalningen genomfördes.</span
        >
      </ng-container>

      <ng-container *ngSwitchCase="'CANCELLED_SWISH_DEPOSIT'">
        <span class="event-text">Betalaren avbröt Swish-betalningen.</span>
      </ng-container>

      <ng-container *ngSwitchCase="'SMS_RESENT'">
        <span class="event-text" i18n="@@paymentRequestEvent.SMS_RESENT"
          >Ett nytt sms med betallänk har skickats.</span
        >
      </ng-container>

      <ng-container *ngSwitchCase="'REFUND_INITIALIZED'">
        <span
          class="event-text"
          i18n="@@paymentRequestEvent.REFUND_INITIALIZED"
        >
          <span>En retur på </span>
          <span class="cerise-text">
            {{
              event.messageArguments.refundValue
                | value: localeService.getCurrentLocale()
            }}
          </span>
          <ng-container *ngIf="!event.messageArguments.representativeEmail">
            <span>initierades</span>
          </ng-container>
          <ng-container *ngIf="!!event.messageArguments.representativeEmail">
            <span>initierades av </span>
            <ng-container *ngIf="!!event.messageArguments.representativeName">
              <a
                class="link"
                target="_blank"
                href="mailto:{{ event.messageArguments.representativeEmail }}"
                >{{ event.messageArguments.representativeName }}</a
              >
            </ng-container>
            <ng-container *ngIf="!event.messageArguments.representativeName">
              <a
                class="link"
                target="_blank"
                href="mailto:{{ event.messageArguments.representativeEmail }}"
                >{{ event.messageArguments.representativeEmail }}</a
              >
            </ng-container>
            <ng-container *ngIf="!!event.messageArguments.storeName">
              <span>, {{ event.messageArguments.storeName }}</span>
            </ng-container>
          </ng-container>
          <span>.</span></span
        >
      </ng-container>

      <ng-container *ngSwitchCase="'REFUND_CANCELLED'">
        <span class="event-text" i18n="@@paymentRequestEvent.REFUND_CANCELLED">
          <span>En retur på </span>
          <span class="cerise-text">
            {{
              event.messageArguments.refundValue
                | value: localeService.getCurrentLocale()
            }}
          </span>
          <ng-container *ngIf="!event.messageArguments.representativeEmail">
            <span>har nekats</span>
          </ng-container>
          <ng-container *ngIf="!!event.messageArguments.representativeEmail">
            <span>har nekats av </span>
            <ng-container *ngIf="!!event.messageArguments.representativeName">
              <a
                class="link"
                target="_blank"
                href="mailto:{{ event.messageArguments.representativeEmail }}"
                >{{ event.messageArguments.representativeName }}</a
              >
            </ng-container>
            <ng-container *ngIf="!event.messageArguments.representativeName">
              <a
                class="link"
                target="_blank"
                href="mailto:{{ event.messageArguments.representativeEmail }}"
                >{{ event.messageArguments.representativeEmail }}</a
              >
            </ng-container>
            <ng-container *ngIf="!!event.messageArguments.storeName">
              <span>, {{ event.messageArguments.storeName }}</span>
            </ng-container>
          </ng-container>
          <span>.</span></span
        >
      </ng-container>

      <ng-container *ngSwitchCase="'REFUND_APPROVED'">
        <span class="event-text" i18n="@@paymentRequestEvent.REFUND_APPROVED">
          <span>En retur på </span>
          <span class="cerise-text">
            {{
              event.messageArguments.refundValue
                | value: localeService.getCurrentLocale()
            }}
          </span>
          <ng-container *ngIf="!event.messageArguments.representativeEmail">
            <span> har attesterats</span>
          </ng-container>
          <ng-container *ngIf="!!event.messageArguments.representativeEmail">
            <span> har attesterats och godkänts av </span>
            <ng-container *ngIf="!!event.messageArguments.representativeName">
              <a
                class="link"
                target="_blank"
                href="mailto:{{ event.messageArguments.representativeEmail }}"
                >{{ event.messageArguments.representativeName }}</a
              >
            </ng-container>
            <ng-container *ngIf="!event.messageArguments.representativeName">
              <a
                class="link"
                target="_blank"
                href="mailto:{{ event.messageArguments.representativeEmail }}"
                >{{ event.messageArguments.representativeEmail }}</a
              >
            </ng-container>
            <ng-container *ngIf="!!event.messageArguments.storeName">
              <span>, {{ event.messageArguments.storeName }}</span>
            </ng-container>
          </ng-container>
          <span>.</span></span
        >
      </ng-container>

      <ng-container *ngSwitchCase="'REFUND_CANCELLED_BY_SYSTEM'">
        <span
          class="event-text"
          i18n="@@paymentRequestEvent.REFUND_CANCELLED_BY_SYSTEM"
          >En retur på
          <span class="cerise-text">
            {{
              event.messageArguments.refundValue
                | value: localeService.getCurrentLocale()
            }}
          </span>
          <span
            >har automatiskt makulerats då tidigare redan attesterad(e)
            retur(er), tillsammans uppgår till ett belopp som överstiger det
            högst tillåtna returbeloppet för detta köp.</span
          >
        </span>
      </ng-container>

      <ng-container *ngSwitchCase="'MERCHANT_NOTE'">
        <span class="event-text" [innerHTML]="event.note"></span>
      </ng-container>

      <ng-container
        *ngSwitchCase="'REFUND_SWISH_AMOUNT_EXCEEDS_REFUNDABLE_AMOUNT'"
      >
        <span class="event-text"
          >En Swish-retur på
          <span class="cerise-text">
            {{
              event.messageArguments.refundValue
                | value: localeService.getCurrentLocale()
            }}
          </span>
          <span
            >har makulerats då tidigare redan attesterad(e) retur(er),
            tillsammans uppgår till ett belopp som överstiger det högst tillåtna
            returbeloppet för detta köp.</span
          >
        </span>
      </ng-container>

      <ng-container *ngSwitchCase="'REFUND_SWISH_PAYMENT_TOO_OLD'">
        <span class="event-text"
          >En Swish-retur på
          <span class="cerise-text">
            {{
              event.messageArguments.refundValue
                | value: localeService.getCurrentLocale()
            }}
          </span>
          <span
            >avbröts då det har gått mer är 12 månader sedan den ursprungliga
            betalningen&nbsp;gjordes.</span
          >
        </span>
      </ng-container>

      <ng-container *ngSwitchCase="'REFUND_SWISH_OTHER_ERROR'">
        <span class="event-text"
          >En Swish-retur på
          <span class="cerise-text">
            {{
              event.messageArguments.refundValue
                | value: localeService.getCurrentLocale()
            }}
          </span>
          <span>avbröts, återbetalningen kunde inte genomföras.</span>
        </span>
      </ng-container>

      <ng-container *ngSwitchCase="'REFUND_SWISH_MERCHANT_NUMBER_CHANGED'">
        <span class="event-text"
          >En Swish-retur på
          <span class="cerise-text">
            {{
              event.messageArguments.refundValue
                | value: localeService.getCurrentLocale()
            }}
          </span>
          <span
            >avbröts då erat företags Swish-nummret har ändrats efter att
            betalningen genomfördes.</span
          >
        </span>
      </ng-container>

      <ng-container *ngSwitchCase="'REFUND_SWISH_MERCHANT_ORG_NUMBER_CHANGED'">
        <span class="event-text"
          >En Swish-retur på
          <span class="cerise-text">
            {{
              event.messageArguments.refundValue
                | value: localeService.getCurrentLocale()
            }}
          </span>
          <span
            >avbröts då erat företags Org-nummert har ändrats efter att
            betalningen genomfördes.</span
          >
        </span>
      </ng-container>

      <ng-container *ngSwitchCase="'REFUND_SWISH_CUSTOMER_NUMBER_CHANGED'">
        <span class="event-text"
          >En Swish-retur på
          <span class="cerise-text">
            {{
              event.messageArguments.refundValue
                | value: localeService.getCurrentLocale()
            }}
          </span>
          <span
            >avbröts då mottagaren av returen inte längre har samma mobilnummer
            anslutet till Swish.</span
          >
        </span>
      </ng-container>

      <ng-container *ngSwitchCase="'REFUND_SWISH_MERCHANT_INSUFFICIENT_FUNDS'">
        <span class="event-text"
          >En Swish-retur på
          <span class="cerise-text">
            {{
              event.messageArguments.refundValue
                | value: localeService.getCurrentLocale()
            }}
          </span>
          <span
            >kunde inte genomföras då saldot på Swish-kontot var för lågt.</span
          >
        </span>
      </ng-container>
    </ng-container>
  </div>

  <div class="event-note" *ngIf="event.note">
    <hr class="note-divider" />
    <ng-container *ngIf="event.type === 'MERCHANT_NOTE'">
      <ng-container *ngIf="!!event.messageArguments.representativeEmail">
        <span i18n="@@paymentRequestEvent.MERCHANT_NOTE">Skapad av:</span>&nbsp;
        <ng-container *ngIf="!!event.messageArguments.representativeName">
          <a
            class="link"
            target="_blank"
            href="mailto:{{ event.messageArguments.representativeEmail }}"
            >{{ event.messageArguments.representativeName }}</a
          >
        </ng-container>

        <ng-container *ngIf="!event.messageArguments.representativeName">
          <a
            class="link"
            target="_blank"
            href="mailto:{{ event.messageArguments.representativeEmail }}"
            >{{ event.messageArguments.representativeEmail }}</a
          >
        </ng-container>

        <br />

        <ng-container *ngIf="!!event.messageArguments.storeName">
          <span
            >{{ getStoreAlias() }}: {{ event.messageArguments.storeName }}</span
          >
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="event.type !== 'MERCHANT_NOTE'">
      <span [innerHTML]="event.note"></span>
    </ng-container>
  </div>
</div>
