<div class="order-details-wrapper">
  <div class="order-details-container">
    <div class="heading-wrapper">
      <p class="heading">Order details</p>
      <app-widget-event-type-pill
        *ngIf="!!paymentRequestStatus"
        [text]="paymentRequestStatus.text"
        [backgroundColor]="paymentRequestStatus.color"
        [textColor]="paymentRequestStatus.textColor"
      ></app-widget-event-type-pill>
    </div>

    <div class="button-link-wrapper">
      <zaver-button-link
        (click)="setView(VIEW.VIEW_ORDER)"
        [text]="'Order history'"
      ></zaver-button-link>
    </div>

    <div class="row" *ngIf="latestCaptureTimeStamp">
      <p>Latest capture timestamp</p>
      <p>{{ latestCaptureTimeStamp | date : 'dd.MM.yyyy - HH:mm' }}</p>
    </div>

    <div class="row" *ngIf="paymentMethod">
      <p>Payment method</p>
      <p>{{ paymentMethod }}</p>
    </div>

    <div class="row">
      <p>Initial order amount</p>
      <p>{{ initialPaymentRequestAmount | amountPipe : currency }}</p>
    </div>

    <div class="row">
      <p>Captured amount</p>
      <p>{{ capturedAmount | amountPipe : currency }}</p>
    </div>

    <div class="row">
      <p>Refunded amount</p>
      <p>{{ refundedAmount | amountPipe : currency }}</p>
    </div>

    <div class="row" *ngIf="deletedAmount">
      <p>Cancelled amount</p>
      <p>{{ deletedAmount | amountPipe : currency }}</p>
    </div>

    <div class="row">
      <p>Amount left to capture</p>
      <p>{{ amountLeftToCapture | amountPipe : currency }}</p>
    </div>

    <ng-container *ngFor="let refund of lineItemRefunds">
      <div class="refund-card" *ngIf="refund.refundView === 'DEFAULT'">
        <h3>Attestation required</h3>
        <p>
          A refund request was created by at
          {{ refund.created | date : 'dd.MM.yyyy - HH:mm' }} for
          {{ refund.refundValue.amount | amountPipe : currency }}.
        </p>
        <div style="display: flex">
          <zaver-secondary-button-outlined
            style="margin-right: 16px"
            [size]="'x-small'"
            [text]="'Deny refund'"
            (click)="handleLineItemRefund(refund, REFUNDVIEW.DENY)"
            [disabled]="inProgress"
          ></zaver-secondary-button-outlined>
          <zaver-secondary-button-outlined
            [size]="'x-small'"
            [text]="'Approve refund'"
            (click)="handleLineItemRefund(refund, REFUNDVIEW.APPROVE)"
            [disabled]="inProgress"
          ></zaver-secondary-button-outlined>
        </div>
        <p>
          The refund needs to be approved by an authorized user before it can be
          processed by Zaver.
        </p>
      </div>

      <div class="refund-card" *ngIf="refund.refundView === 'APPROVE'">
        <h3>Approve refund?</h3>
        <p>When the refund is approved, Zaver will start processing it.</p>
        <div style="display: flex">
          <zaver-secondary-button-outlined
            style="width: calc(50% - 8px); margin-right: 16px"
            [size]="'x-small'"
            [text]="'Yes, approve'"
            [loading]="inProgress"
            (click)="onApproveRefund(refund.id)"
            [disabled]="inProgress"
          ></zaver-secondary-button-outlined>
          <zaver-button-link
            style="display: flex; align-items: center"
            (click)="handleLineItemRefund(refund, REFUNDVIEW.DEFAULT)"
            [text]="'No, go back'"
          ></zaver-button-link>
        </div>
      </div>

      <div class="refund-card" *ngIf="refund.refundView === 'DENY'">
        <h3>Deny refund?</h3>
        <p>
          Denying the refund will cancel the return request and the order will
          revert back to its previous status.
        </p>
        <div style="display: flex">
          <zaver-secondary-button-outlined
            [color]="'cerise'"
            style="width: calc(50% - 8px); margin-right: 16px"
            [size]="'x-small'"
            [text]="'Deny and cancel refund'"
            [loading]="inProgress"
            (click)="onCancelRefund(refund.id)"
            [disabled]="inProgress"
          ></zaver-secondary-button-outlined>
          <zaver-button-link
            style="display: flex; align-items: center"
            (click)="handleLineItemRefund(refund, REFUNDVIEW.DEFAULT)"
            [text]="'No, go back'"
          ></zaver-button-link>
        </div>
      </div>
    </ng-container>

    <ng-container *ngFor="let refund of fixedAmountRefunds">
      <div class="refund-card" *ngIf="refund.refundView === 'DEFAULT'">
        <h3>Attestation required</h3>
        <p>
          A refund request was created by at
          {{ refund.created | date : 'dd.MM.yyyy - HH:mm' }} for
          {{ refund.amount | amountPipe : currency }}.
        </p>
        <div style="display: flex">
          <zaver-secondary-button-outlined
            style="margin-right: 16px"
            [size]="'x-small'"
            [text]="'Deny refund'"
            (click)="handleFixedAmountRefund(refund, REFUNDVIEW.DENY)"
            [disabled]="inProgress"
          ></zaver-secondary-button-outlined>
          <zaver-secondary-button-outlined
            [size]="'x-small'"
            [text]="'Approve refund'"
            (click)="handleFixedAmountRefund(refund, REFUNDVIEW.APPROVE)"
            [disabled]="inProgress"
          ></zaver-secondary-button-outlined>
        </div>
        <p>
          The refund needs to be approved by an authorized user before it can be
          processed by Zaver.
        </p>
      </div>

      <div class="refund-card" *ngIf="refund.refundView === 'APPROVE'">
        <h3>Approve refund?</h3>
        <p>When the refund is approved, Zaver will start processing it.</p>
        <div style="display: flex">
          <zaver-secondary-button-outlined
            style="width: calc(50% - 8px); margin-right: 16px"
            [size]="'x-small'"
            [text]="'Yes, approve'"
            [loading]="inProgress"
            (click)="onApproveRefund(refund.id)"
            [disabled]="inProgress"
          ></zaver-secondary-button-outlined>
          <zaver-button-link
            style="display: flex; align-items: center"
            (click)="handleFixedAmountRefund(refund, REFUNDVIEW.DEFAULT)"
            [text]="'No, go back'"
          ></zaver-button-link>
        </div>
      </div>

      <div class="refund-card" *ngIf="refund.refundView === 'DENY'">
        <h3>Deny refund?</h3>
        <p>
          Denying the refund will cancel the return request and the order will
          revert back to its previous status.
        </p>
        <div style="display: flex">
          <zaver-secondary-button-outlined
            [color]="'cerise'"
            style="width: calc(50% - 8px); margin-right: 16px"
            [size]="'x-small'"
            [text]="'Deny and cancel refund'"
            [loading]="inProgress"
            (click)="onCancelRefund(refund.id)"
            [disabled]="inProgress"
          ></zaver-secondary-button-outlined>
          <zaver-button-link
            style="display: flex; align-items: center"
            (click)="handleFixedAmountRefund(refund, REFUNDVIEW.DEFAULT)"
            [text]="'No, go back'"
          ></zaver-button-link>
        </div>
      </div>
    </ng-container>

    <div class="buttons-wrapper">
      <zaver-secondary-button-subtle
        *ngIf="availableForCapture > 0"
        [text]="'Edit order'"
        [size]="'x-small'"
        (click)="showPaymentUpdate()"
      ></zaver-secondary-button-subtle>

      <div class="capture-buttons-container">
        <zaver-primary-button
          *ngIf="totalRefundableAmount > 0"
          [text]="'Create refund'"
          [size]="'x-small'"
          (click)="
            setView(
              fixedAmountRefundLineItems.length > 0
                ? VIEW.REFUND_FIXED_AMOUNT
                : VIEW.REFUND_LINE_ITEM
            )
          "
        ></zaver-primary-button>

        <zaver-primary-button
          *ngIf="availableForCapture > 0"
          [text]="'Capture payment'"
          [size]="'x-small'"
          (click)="setView(VIEW.PARTIAL_CAPTURE)"
        ></zaver-primary-button>
      </div>
    </div>
  </div>
</div>
