import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'zaver-secondary-button-subtle',
  templateUrl: './secondary-button-subtle.component.html',
  styleUrls: ['./secondary-button-subtle.component.scss'],
})
export class SecondaryButtonSubtleComponent implements OnInit {
  @Input() text: string;
  @Input() iconUrl: string;
  @Input() disabled: boolean = false;
  @Input() loading: boolean;
  @Input() size: 'x-small' | 'small' | 'medium' | 'large' = 'medium';
  @Input() rounded = false;

  constructor() {}

  ngOnInit(): void {}

  stopIfDisabled(event: MouseEvent) {
    if (this.disabled || this.loading) {
      event.stopPropagation();
    }
  }
}
