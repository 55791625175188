import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-widget-pagination',
  templateUrl: './widget-pagination.component.html',
  styleUrls: ['./widget-pagination.component.scss'],
})
export class WidgetPaginationComponent implements OnInit {
  @Input() lineItemsVM = [];
  @Output() visibleLineItemsChange = new EventEmitter<any>();
  @Input() visibleLineItems: Array<any> = [];

  @Input() itemsPerPage = 10;
  lastItemOnPage: number = 0;
  numberOfPages: number = 0;
  constructor() {}

  ngOnInit(): void {
    this.numberOfPages = Math.ceil(this.lineItemsVM.length / this.itemsPerPage);
    this.lastItemOnPage = this.itemsPerPage;
  }

  private setVisibleItems(): void {
    const firstItemOnPage = this.lastItemOnPage - this.itemsPerPage;
    this.visibleLineItems = this.lineItemsVM.slice(firstItemOnPage, this.lastItemOnPage);
    this.visibleLineItemsChange.emit(this.visibleLineItems);
  }

  first(): void {
    this.lastItemOnPage = this.itemsPerPage;
    this.visibleLineItems = this.lineItemsVM.slice(0, this.itemsPerPage);
    this.visibleLineItemsChange.emit(this.visibleLineItems);
  }

  next(): void {
    if (!this.isLastPage()) {
      this.lastItemOnPage += this.itemsPerPage;
      this.setVisibleItems();
    }
  }

  prev(): void {
    if (this.lastItemOnPage > this.itemsPerPage) {
      this.lastItemOnPage -= this.itemsPerPage;
      this.setVisibleItems();
    }
  }

  last(): void {
    this.lastItemOnPage = Math.ceil(this.lineItemsVM.length / this.itemsPerPage) * this.itemsPerPage;

    const numberOfItemsOnLastPage = this.lineItemsVM.length % this.itemsPerPage || this.itemsPerPage;
    this.visibleLineItems = this.lineItemsVM.slice(-numberOfItemsOnLastPage);
    this.visibleLineItemsChange.emit(this.visibleLineItems);
  }

  isLastPage(): boolean {
    return this.lineItemsVM.length <= this.lastItemOnPage;
  }
}
