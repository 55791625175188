<div
  class="empty-state-container"
  [class.less-margin-top]="emptyState.lessMarginTop"
>
  <div class="empty-state-image">
    <img src="{{ emptyState.imgSrc }}" alt="Empty state image" />
  </div>
  <div
    class="empty-state-heading"
    [class.smaller]="emptyState.placedInModal"
    *ngIf="emptyState.headingText"
  >
    <span [innerHTML]="emptyState.headingText"></span>
  </div>
  <div
    class="empty-state-text"
    [class.smaller]="emptyState.placedInModal"
    *ngIf="emptyState.bodyText"
  >
    <span [innerHTML]="emptyState.bodyText"></span>
  </div>
  <button
    *ngIf="emptyState.ctaButtonText"
    class="empty-state-action-button"
    (click)="ctaPressed()"
  >
    <span [innerHTML]="emptyState.ctaButtonText"></span>
  </button>
</div>
