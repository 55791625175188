import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompanyRepresentative } from '@core/dto/CompanyRepresentative';
import { Store } from '@core/dto/Store';
import { Permission } from '@core/dto/user-details';
import { AccessControlService } from '@core/service/access-control.service';
import { ColorService } from '@core/service/color.service';
import { StoreService } from '@core/service/store.service';
import { EmptyState } from '@zfb/ui/empty-state/empty-state.component';
import { QuickAction } from '@zfb/ui/quick-actions/quick-actions.component';

@Component({
  selector: 'app-view-store',
  templateUrl: './view-store.component.html',
  styleUrls: ['./view-store.component.scss'],
})
export class ViewStoreComponent implements OnInit {
  @Input() store: Store;

  quickActions: QuickAction<Store>[] = [
    {
      text: $localize`:@@viewStore.quickActions.edit.text:Redigera`,
      iconUrl: 'assets/icons/Icon-Edit-Box.svg',
      textColor: '#0A1018',
      disabled: false,
      displayCondition: () => this.access.userMay(Permission.EDIT_STORE),
      topBorder: false,
      function: (store) => this.openEditStoreModal.emit(store),
    },
    {
      text: $localize`:@@viewStore.quickActions.remove.text:Ta bort`,
      iconUrl: 'assets/icons/Icon-Bin-Cerise.svg',
      textColor: this.colorService.getCeriseColorCode(),
      disabled: false,
      displayCondition: () => this.access.userMay(Permission.REMOVE_STORE),
      topBorder: false,
      function: (store) => this.openRemoveStoreModal.emit(store),
    },
  ];

  activeQuickActions: QuickAction<Store>[];

  fetchingResponse = true;
  storeRepresentatives: CompanyRepresentative[];
  numOfStoreRepresentatives: number;

  noUsersEmptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Emoji-Ghost.png',
    headingText: $localize`:@@viewStore.emptyState.noUsersEmptyState.headingText:Inga anslutna användare`,
    bodyText: $localize`:@@viewStore.emptyState.noUsersEmptyState.bodyText:Här finns det inga anslutna användare ännu. Gå till <a href="/se/admin/users" class="link">Användare</a> för att lägga till&nbsp;några.`,
    lessMarginTop: true,
    placedInModal: true,
  };

  @Output() openEditStoreModal = new EventEmitter<Store>();
  @Output() openRemoveStoreModal = new EventEmitter<Store>();

  constructor(
    private storeService: StoreService,
    private access: AccessControlService,
    private colorService: ColorService
  ) {}

  ngOnInit(): void {
    this.setup();
  }

  setup() {
    this.fetchingResponse = true;
    this.storeService.getRepresentatives(this.store.id).then((response) => {
      this.storeRepresentatives = response;
      this.numOfStoreRepresentatives = this.storeRepresentatives.length;
      if (this.storeRepresentatives) {
        this.setupRepresentatives();
      }
      this.fetchingResponse = false;
    });
    this.activeQuickActions = this.quickActions.filter((action) =>
      action.displayCondition(this.store)
    );
  }

  setupRepresentatives() {
    this.storeRepresentatives.map((rep) => CompanyRepresentative.setup(rep));
  }
}
