<div class="card">
  <h1>
    <span i18n="@@userPendingAdminApproval.title"
      >Ditt konto är påväg att skapas!&nbsp;🎉</span
    >
  </h1>

  <p>
    <span i18n="@@userPendingAdminApproval.firstParagraph"
      >Så fort en administratör på <strong>{{ company }}</strong> bekräftar ditt
      konto i Zaver kommer du kunna logga in med
      <a href="mailto:{{ email }}" class="link">{{ email }}</a> för att börja
      använda våra tjänster.</span
    >
  </p>

  <p id="last-paragraph">
    <span i18n="@@userPendingAdminApproval.lastParagraph"
      >Vi skickar ett mail till dig när en admin på ert företag har godkänt ditt
      användarkonto.</span
    >
  </p>

  <div class="button-wrapper">
    <zaver-primary-button
      text="Tillbaka"
      i18n-text="@@userPendingAdminApproval.button"
      (click)="backToLogin()"
      [color]="colorService.getCeriseColorCodeAsType()"
    ></zaver-primary-button>
  </div>
</div>
