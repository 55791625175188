import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppStateService } from '@core/service/app-state.service';

@Component({
  selector: 'app-user-approval-table',
  templateUrl: './user-approval-table.component.html',
  styleUrls: ['./user-approval-table.component.css'],
})
export class UserApprovalTableComponent implements OnInit {
  @Input() values: any[];
  @Input() columns: any[];

  @Output() actionClicked = new EventEmitter<{ object: any; action: string }>();

  constructor(public appState: AppStateService) {}

  ngOnInit() {}
}
