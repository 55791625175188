export class Store {
  id: string;
  company: any; // TODO needed?
  name: string;
  type: StoreType;

  constructor(name, type) {
    this.name = name;
    this.type = type;
  }

}

export enum StoreType {
  STORE = 'STORE',
  E_COMMERCE = 'E_COMMERCE',
}
