import { Currency } from '../CurrencyCode';

export class PrecheckRequest {
  personalNumber: string;
  name: string;
  phone: string;
  value: Currency;

  constructor(
    personalNumber: string,
    name: string,
    phone: string,
    value: Currency
  ) {
    this.personalNumber = personalNumber;
    this.name = name;
    this.phone = phone;
    this.value = value;
  }
}
