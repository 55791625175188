<div class="back-button-container" (click)="back()">
  <img
    class="arrow-img"
    src="assets/icons/Icon-Navigation-Arrow Left.svg"
    alt="icon-left"
  />
  <span>Tillbaka</span>
</div>

<div class="card">
  <h1>Registrera nytt företagskonto</h1>
  <span class="sub-title">{{
    processingSignup
      ? ''
      : 'Vad kul att ni vill registrera ett företagskonto hos oss! Ange din e-postadress nedan för gå vidare i registreringen.'
  }}</span>
  <form [formGroup]="form" id="login-form">
    <div class="input-container">
      <zaver-animated-form-input
        [size]="'large'"
        [placeholder]="'Ange jobbmail'"
        [inputMode]="'email'"
        [error]="form.controls.email.invalid && showError"
        [errorMessage]="'Vänligen ange en giltigt e-postadress'"
        formControlName="email"
        [readonly]="processingSignup"
      ></zaver-animated-form-input>
    </div>

    <div class="switch-container">
      <app-switch
        (change)="rememberMeClicked()"
        text="Kom ihåg mig"
        formControlName="rememberMe"
      ></app-switch>
    </div>
    <app-form-error *ngIf="formError" [errorText]="formError"> </app-form-error>

    <zaver-primary-button
      [buttonType]="'submit'"
      [text]="'Fortsätt'"
      [loading]="processingSignup"
      [color]="'black'"
      (click)="continueSignup()"
    ></zaver-primary-button>
  </form>
</div>
<!-- <div id="signup-box">
  <a (click)="back()" routerLinkActive="active" class="link">Gå tillbaka</a>
</div> -->
