<div class="search-container" [ngClass]="size">
  <div class="icon-container">
    <svg
      class="svg-magnification"
      width="18"
      height="18"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.7 19.9L15.3 13.5C16.3 12.1 16.9 10.4 16.9 8.5C17 3.8 13.2 0 8.5 0C3.8 0 0 3.8 0 8.5C0 13.2 3.8 17 8.5 17C10.6 17 12.5 16.2 14 15L20.3 21.3C20.7 21.7 21.3 21.7 21.7 21.3C22.1 20.9 22.1 20.3 21.7 19.9ZM8.5 15C4.9 15 2 12.1 2 8.5C2 4.9 4.9 2 8.5 2C12.1 2 15 4.9 15 8.5C15 12.1 12.1 15 8.5 15Z"
        fill="#6A7381"
      />
    </svg>
  </div>
  <input
    type="search"
    placeholder="{{ placeholder }}"
    [(ngModel)]="inputModel"
    (ngModelChange)="inputModelChange.emit(inputModel)"
  />
  <div class="remove-search-container" (click)="clearText()">
    <div class="remove-search" (click)="clearText()" *ngIf="inputModel !== ''">
      <svg
        class="svg-cross"
        width="9.43"
        height="9.43"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip754)">
          <path
            d="M0.311048 15.1869C0.111821 15.3862 -6.63714e-05 15.6565 5.19422e-08 15.9383C6.64753e-05 16.2202 0.112081 16.4904 0.311403 16.6896C0.510724 16.8889 0.781024 17.0007 1.06284 17.0007C1.34466 17.0006 1.6149 16.8886 1.81413 16.6893L8.37471 10.1294C8.39116 10.1129 8.41071 10.0998 8.43222 10.0909C8.45373 10.082 8.4768 10.0774 8.50009 10.0774C8.52338 10.0774 8.54645 10.082 8.56796 10.0909C8.58947 10.0998 8.60901 10.1129 8.62546 10.1294L15.186 16.6907C15.2847 16.7894 15.4018 16.8677 15.5307 16.9211C15.6596 16.9745 15.7978 17.0021 15.9373 17.0021C16.0769 17.0021 16.2151 16.9747 16.344 16.9213C16.4729 16.8679 16.5901 16.7897 16.6888 16.691C16.7875 16.5924 16.8658 16.4753 16.9192 16.3464C16.9726 16.2175 17.0001 16.0793 17.0002 15.9398C17.0002 15.8002 16.9728 15.662 16.9194 15.5331C16.866 15.4042 16.7878 15.287 16.6891 15.1883L10.1293 8.62561C10.1128 8.60916 10.0997 8.58962 10.0908 8.56811C10.0818 8.54659 10.0772 8.52353 10.0772 8.50024C10.0772 8.47694 10.0818 8.45388 10.0908 8.43237C10.0997 8.41085 10.1128 8.39131 10.1293 8.37486L16.6905 1.81428C16.8898 1.61477 17.0016 1.34429 17.0014 1.06234C17.0012 0.780391 16.889 0.510068 16.6895 0.310841C16.49 0.111614 16.2195 -0.000198988 15.9375 2.65851e-07C15.6556 0.00019952 15.3853 0.112395 15.186 0.311904L8.62546 6.87107C8.60901 6.88756 8.58947 6.90065 8.56796 6.90957C8.54645 6.9185 8.52338 6.9231 8.50009 6.9231C8.4768 6.9231 8.45373 6.9185 8.43222 6.90957C8.41071 6.90065 8.39116 6.88756 8.37471 6.87107L1.81413 0.311904C1.71548 0.21321 1.59836 0.134912 1.46946 0.0814818C1.34055 0.0280513 1.20238 0.000534001 1.06284 0.000501114C0.781024 0.000434695 0.510724 0.112322 0.311402 0.311549C0.112081 0.510777 6.64487e-05 0.781024 2.95541e-08 1.06284C-6.63896e-05 1.34466 0.111821 1.61496 0.311048 1.81428L6.87092 8.37486C6.88741 8.39131 6.9005 8.41085 6.90943 8.43237C6.91835 8.45388 6.92295 8.47694 6.92295 8.50024C6.92295 8.52353 6.91835 8.54659 6.90943 8.56811C6.9005 8.58962 6.88741 8.60916 6.87092 8.62561L0.311048 15.1869Z"
            fill="#6A7381"
          />
        </g>
        <defs>
          <clipPath id="clip754">
            <rect width="17" height="17" fill="none" />
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>
</div>
