<div class="card">
  <h1 style="margin-bottom: 52px">
    <span i18n="@@passwordReset.title">Välj ett lösenord</span>
  </h1>
  <form [formGroup]="form" (ngSubmit)="submit()" id="login-form">
    <div class="input-container">
      <zaver-animated-form-input
        [size]="'large'"
        placeholder="Välj lösenord"
        i18n-placeholder="@@passwordReset.form.password.placeholder"
        [inputType]="'password'"
        [error]="form.controls.password.invalid && showError"
        [errorMessage]="passwordError"
        formControlName="password"
        [readonly]="processingLogin"
      >
      </zaver-animated-form-input>
    </div>
    <div class="input-container">
      <zaver-animated-form-input
        [size]="'large'"
        placeholder="Bekräfta lösenord"
        i18n-placeholder="@@passwordReset.form.passwordConfirm.placeholder"
        [inputType]="'password'"
        [error]="form.controls.passwordConfirm.invalid && showError"
        [errorMessage]="confirmPasswordError"
        formControlName="passwordConfirm"
        [readonly]="processingLogin"
      >
      </zaver-animated-form-input>
    </div>
    <div class="password-help-text">
      <span i18n="@@passwordReset.passwordHelpText"
        >Lösenordet måste vara mellan 8 och 30 tecken, och innehålla minst 1
        stor bokstav</span
      >
    </div>
    <div id="errors-container" *ngIf="validationErrors">
      <app-form-error
        *ngFor="let error of validationErrors"
        [errorText]="error"
      >
      </app-form-error>
      <app-form-error *ngIf="formError" [errorText]="formError">
      </app-form-error>
    </div>

    <div class="button-wrapper">
      <zaver-primary-button
        [buttonType]="'submit'"
        text="Fortsätt"
        i18n-text="@@passwordReset.button.submit"
        [loading]="processingLogin"
        [color]="'black'"
        (click)="submit()"
      ></zaver-primary-button>
    </div>
  </form>
</div>
