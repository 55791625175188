<h1 class="page-heading-with-subheader">
  <span i18n="@@refunds.title">Hantera returer</span>
</h1>

<div id="search-and-filter">
  <div id="input-fields">
    <zaver-search
      id="search-bar"
      placeholder="Sök ..."
      i18n-placeholder="@@refunds.search.placeholder"
      [inputModel]="searchQuery"
      (inputModelChange)="searchQueryChanged.next($event)"
    >
    </zaver-search>
    <zaver-dropdownv2
      id="dropdown"
      *ngIf="!fetchingStores && storesFilterOptions"
      [selectableItems]="storesFilterOptions"
      (inputModelChange)="onStoresFilterChange($event)"
    >
    </zaver-dropdownv2>
  </div>
</div>

<app-page-error *ngIf="errorMessage" [errorMessage]="errorMessage">
</app-page-error>

<app-page-tabs
  [activeValue]="activePage"
  [tabs]="tabs"
  (tabClicked)="setActivePage($event)"
></app-page-tabs>

<app-table
  [columns]="columns"
  [values]="refunds"
  (filterChange)="onFilterChange($event)"
  *ngIf="!fetchingRefunds"
>
</app-table>

<app-empty-state *ngIf="displayEmptyState" [emptyState]="currentEmptyState">
</app-empty-state>

<app-table-pagination
  *ngIf="!fetchingRefunds && !displayEmptyState"
  [paginationState]="paginationState"
  (pageChanged)="onPageChange($event)"
  (pageSizeChanged)="onPageSizeChange($event)"
>
</app-table-pagination>

<div *ngIf="fetchingRefunds" class="page-loader-container">
  <app-page-loader></app-page-loader>
</div>

<zaver-form-modal
  *ngIf="modalActive"
  (onClose)="closeModal()"
  [title]="modalTitle"
  [color]="modalColor"
>
  <app-view-refund
    *ngIf="viewRefund"
    [refund]="selectedRefund"
    (approve)="openApprovalConfirmation($event, false)"
    (decline)="openCancellingConfirmation($event, false)"
  >
  </app-view-refund>
</zaver-form-modal>

<zaver-details-modal
  *ngIf="smallModalActive"
  (onClose)="closeSmallModal()"
  [title]="modalTitle"
  [color]="modalColor"
>
  <app-approve-refund
    *ngIf="confirmApproval"
    [refund]="selectedRefund"
    (closeModal)="closeSmallModal()"
    (return)="returnToViewModal($event)"
    (approved)="onHandled()"
  >
  </app-approve-refund>
  <app-decline-refund
    *ngIf="confirmDeclining"
    [refund]="selectedRefund"
    (closeModal)="closeSmallModal()"
    (return)="returnToViewModal($event)"
    (cancelled)="onHandled()"
  >
  </app-decline-refund>
</zaver-details-modal>
