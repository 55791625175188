<div class="back-button-container" (click)="back()">
  <img
    class="arrow-img"
    src="assets/icons/Icon-Navigation-Arrow Left.svg"
    alt="icon-left"
  />
  <span>Tillbaka</span>
</div>

<div class="card">
  <app-bank-id [provideUserBody]="getUserBody" (onSuccess)="handleSuccess($event)"></app-bank-id>
</div>

<zaver-details-modal
  *ngIf="shouldShowModal"
  (onClose)="handleCloseModal()"
  [color]="modalColor"
  title="Fel"
>
  <div class="small-modal-wrapper">
    <span class="modal-text-heading"
      >Aj då, det ser ut som att ett fel har uppstått. 🙈
    </span>
    <span
      >Vänligen kontrollera hänvisningslänken eller begär en ny.
    </span>

    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        text="Tillbaka till registrering"
        (click)="handleCloseModal()"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
    </div>
  </div>
</zaver-details-modal>
