<label class="cb-container" [class.disabled]="disabled">
  <input type="checkbox"
         [checked]="isChecked"
         (change)="onChanged($event)"
         (blur)="onBlur($event)"
         [disabled]="disabled">
  <svg [class.checked]="isChecked" [class.disabled]="disabled" class="checkmark" viewBox="-2.5 -2.5 15 12" fill=""
       xmlns="http://www.w3.org/2000/svg">
    <path d="M1 4L3.5 6.5L9 1" stroke="white" stroke-width="2" stroke-linecap="round" fill="none"
          stroke-linejoin="round"/>
  </svg>
  <p class="label">{{text}}</p>
</label>
