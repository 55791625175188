import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

enum ToastMessageType {
    success = 'success',
    info = 'info',
    warning = 'warning',
    error = 'error'
}

export interface ToastMessage {
    id: string;
    message: string;
    type: ToastMessageType;
}

@Injectable()
export class ToastMessageService {

    private randomIdCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    public toastMessageSubject = new Subject<ToastMessage>();

    constructor() {}

    displaySuccess(message: string) {
        this.toastMessageSubject.next({id: this.randomId(), message: message, type: ToastMessageType.success});
    }

    displayInfo(message: string) {
        this.toastMessageSubject.next({id: this.randomId(), message: message, type: ToastMessageType.info});
    }

    displayWarning(message: string) {
        this.toastMessageSubject.next({id: this.randomId(), message: message, type: ToastMessageType.warning});
    }

    displayError(message: string) {
        this.toastMessageSubject.next({id: this.randomId(), message: message, type: ToastMessageType.error});
    }

    randomId() {
        let result = '';
        const charactersLength = this.randomIdCharacters.length;
        for (let i = 0; i < 10; i++) {
          result += this.randomIdCharacters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      }

}
