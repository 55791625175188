import { Component, Input, OnInit } from '@angular/core';
import { PaymentRequestEventType } from '@core/constants/PaymentRequestEventType';
import { Currency } from '@core/dto/CurrencyCode';
import { PaymentRequestEvent } from '@core/dto/PaymentRequestEvent';
import { AppStateService } from '@core/service/app-state.service';
import { ColorService } from '@core/service/color.service';
import { CustomizationService } from '@core/service/customization.service';
import { LocaleService } from '@core/service/locale.service';

@Component({
  selector: 'app-payment-request-event',
  templateUrl: './payment-request-event.component.html',
  styleUrls: ['./payment-request-event.component.scss'],
})
export class PaymentRequestEventComponent implements OnInit {
  @Input() event: PaymentRequestEvent;

  constructor(
    private customizationService: CustomizationService,
    public appState: AppStateService,
    public colorService: ColorService,
    public localeService: LocaleService
  ) {}

  ngOnInit(): void {}

  showNotePill() {
    return (
      this.event.type === PaymentRequestEventType.MERCHANT_NOTE ||
      !!this.event.note
    );
  }

  showRefundPill() {
    return this.event.type.startsWith('REFUND_');
  }

  showEventPill() {
    return (
      !this.showRefundPill() &&
      this.event.type !== PaymentRequestEventType.MERCHANT_NOTE
    );
  }

  getStoreAlias(): string {
    return this.customizationService.getStoreAlias(false, false, false, true);
  }
}
