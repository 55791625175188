import { Component, OnInit } from '@angular/core';
import { ColorService } from '@core/service/color.service';
import { LocaleService } from '@core/service/locale.service';

@Component({
  selector: 'app-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss'],
})
export class PageLoaderComponent implements OnInit {
  locale: string;
  constructor(private localeService: LocaleService, public colorService: ColorService) {}

  ngOnInit(): void {
    this.locale = this.localeService.getCurrentLocale();
  }
}
