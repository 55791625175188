import { Component, Input, OnInit } from '@angular/core';
import {
  SimpleLineItemVM,
  View,
} from '../payment-management/payment-management.interfaces';
import { LineItemJson } from '@core/service/payment-capture.service';
import { CurrencyCode } from '@core/dto/CurrencyCode';
import { Market } from '@core/dto/Market';

@Component({
  selector: 'app-view-order',
  templateUrl: './view-order.component.html',
  styleUrls: ['./view-order.component.scss'],
})
export class ViewOrderComponent implements OnInit {
  @Input() uncapturedLineItems: SimpleLineItemVM[];
  @Input() capturedLineItems: SimpleLineItemVM[];
  @Input() removedLineItems: SimpleLineItemVM[];
  @Input() refundedLineItems: SimpleLineItemVM[];
  @Input() fixedAmountRefundLineItems: SimpleLineItemVM[];
  @Input() pendingLineItemRefundLineItems: SimpleLineItemVM[];
  @Input() pendingFixedAmountRefundLineItems: SimpleLineItemVM[];
  @Input() availableForCapture: number;
  @Input() slask: boolean;
  @Input() initialPaymentRequestAmount: number;
  @Input() capturedAmount: number;
  @Input() deletedAmount: number;
  @Input() inProgress: boolean;
  @Input() setView: (view: View) => void;
  @Input() context;
  @Input() totalRefundableAmount: number = 0;
  @Input() currency: CurrencyCode;
  @Input() market: Market;
  public MARKET = Market;
  public VIEW = View;

  constructor() {}

  ngOnInit(): void {
    if (this.slask) {
      this.availableForCapture = 0;
    }
  }
  fromLineItemJsontoSimpleLineItem(
    lineItemsJson: LineItemJson[]
  ): SimpleLineItemVM[] {
    return lineItemsJson.map((item) => {
      return {
        title: item.title,
        quantity: item.quantity,
        taxAmount: item.taxAmount,
        totalAmount: item.totalAmount,
        unitPrice: item.unitPrice,
        taxRatePercent: item.taxRatePercent,
      };
    });
  }
}
