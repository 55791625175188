import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';

export interface QuickAction<T> {
  text: string;
  iconUrl: string;
  textColor: string;
  disabled: boolean;
  displayCondition: ((it: T) => boolean);
  function: ((it: T) => void);
  topBorder: boolean;
  buttonId?: string; // Used for Tag Manager
}

@Component({
  selector: 'app-quick-actions',
  templateUrl: './quick-actions.component.html',
  styleUrls: ['./quick-actions.component.css']
})
export class QuickActionsComponent<T> implements OnInit, OnDestroy {

  constructor(private elementRef: ElementRef) {}

  @Input() actions: QuickAction<T>[];
  @Input() data: T; // The object that the quickactions are invoked on
  @Input() verticalButton = false;

  // These inputs are used to define the static position of the list against the button that opens the list
  // If these are not set, the position of the list will vary depending on where in the viewport the component
  // is positioned, to ensure that the list is opened into the viewport
  //
  // The quick actions list is by default positioned under and to the right of the button
  @Input() listHorizontalPosition: 'left'|'right';
  @Input() listVerticalPosition: 'over'|'under';

  _showList = false;
  set showList(showList: boolean) {
    this._showList = showList;
    if (showList) {
      document.addEventListener('click', this.clickFunction, true);
      this.positionListInViewport();
    } else {
      document.removeEventListener('click', this.clickFunction, true);
      this.removeDynamicallyAddedClasses();
    }
  }
  get showList() {
    return this._showList;
  }

  disabled = false;
  listCssClasses = '';

  // This function is in genreal used to detect if a click is made outside 
  // of an opened list of quickactions, and in particular when a list of 
  // quickactions is open on top of an open modal, and clicking outside the 
  // opened list of quickactions should close the list of quickactions 
  // but not close the modal.
  private clickFunction = (event) => {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
      if (!clickedInside) {
        event.stopPropagation();
        this.showList = false;
      }
  }

  // Closes the quickactions list when the Escape key is pressed
  @HostListener('document:keydown.escape', ['$event']) 
  onEscapeClicked() {
    this.showList = false;
  }

  ngOnInit() {
    this.disabled = this.actions.length === 0; 
    if (this.verticalButton) {
      this.listCssClasses += 'vertical ';
    } else {
      this.listCssClasses += 'horizontal ';
    }
    if (this.listHorizontalPosition) {
      this.listCssClasses += this.listHorizontalPosition + ' ';
    }
    if (this.listVerticalPosition) {
      this.listCssClasses += this.listVerticalPosition + ' ';
    }
    
  }

  ngOnDestroy(): void {
    document.removeEventListener('click', this.clickFunction, true);
  }

  executeQuickAction(action: QuickAction<T>) {
    action.function(this.data);
    this.showList = false;
  }

  toggleList() {
    if (!this.disabled) {
      this.showList = !this.showList;
    }
  }

  positionListInViewport() {
    const componentPositions = this.elementRef.nativeElement.getBoundingClientRect();
    const innerHeight = window.innerHeight;
    const innerWidth = window.innerWidth;

    if (!this.listVerticalPosition && (innerHeight - componentPositions.bottom) < 200) {
      // The bottom side of the component is too close to the bottom of the screen => Open list upwards
      this.listCssClasses += 'over ';
    }
    if (!this.listHorizontalPosition && (innerWidth - componentPositions.right) < 200) {
      // The right side of the component is too close to the right of the screen => Open list to the left
      this.listCssClasses += 'left ';
    }
  }

  removeDynamicallyAddedClasses() {
    if (!this.listVerticalPosition) {
      this.listCssClasses = this.listCssClasses.replace('over ', '');
    }
    if (!this.listHorizontalPosition) {
      this.listCssClasses = this.listCssClasses.replace('left ', '');
    }
    
  }

}

