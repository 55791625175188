import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  OnDestroy,
  OnChanges,
} from '@angular/core';
import { formatDate } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Month } from '../calendar/calendar.component';
import { FormValidators } from '@shared/validators/validators';
import { LANG_SV } from '../calendar/lang-sv';
import { Subscription } from 'rxjs';
import { ColorService } from '@core/service/color.service';
import { LANG_DE } from '../calendar/lang-de';
import { LocaleService } from '@core/service/locale.service';
import { DateValidators } from '@shared/validators/date.validators';
import { AppStateService } from '@core/service/app-state.service';

interface LegendButton {
  text: string;
  func: (it: string) => void;
  focus: boolean;
}

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.css'],
})
export class DateRangePickerComponent implements OnInit, OnDestroy, OnChanges {
  @Output() setFromDate = new EventEmitter<Date>();
  @Output() setToDate = new EventEmitter<Date>();

  @Input() limitDate: Date;
  @Input() initFromDate: Date;
  @Input() initToDate: Date;

  form: FormGroup;
  formChangeSubscription: Subscription;
  dateFormatPlaceholder: string;

  startDate: Date;
  endDate: Date;
  minDate: Date;

  prevStartDate: Date;
  prevEndDate: Date;

  dateFormat: string;

  prevSelectLabel: string;
  showCalendar: boolean;
  selectLabel: string;
  errorMessage: string;
  modalActive: boolean;
  showError = false;

  mobilelabel: string;
  prevMobileLabel: string;
  startDateString: string;
  endDateString: string;
  startMonthDateString: string;
  endMonthDateString: string;
  limitDateString: string;

  monthNames: Array<Month>;

  buttonOptions: Array<LegendButton> = [
    {
      text: $localize`:@@dateRangePicker.buttonOptions.yesterday:Igår`,
      func: (it) => this.yesterday(it),
      focus: false,
    },
    {
      text: $localize`:@@dateRangePicker.buttonOptions.lastSevenDays:Senaste 7 dagarna`,
      func: (it) => this.lastSevenDays(it),
      focus: false,
    },
    {
      text: $localize`:@@dateRangePicker.buttonOptions.lastWeek:Förra veckan`,
      func: (it) => this.lastWeek(it),
      focus: false,
    },
    {
      text: $localize`:@@dateRangePicker.buttonOptions.lastMonth:Förra månaden`,
      func: (it) => this.lastMonth(it),
      focus: false,
    },
    {
      text: $localize`:@@dateRangePicker.buttonOptions.custom:Anpassat`,
      func: null,
      focus: false,
    },
  ];

  constructor(
    public colorService: ColorService,
    private localeService: LocaleService
  ) {
    switch (localeService.getCurrentLocale()) {
      case 'sv':
        this.monthNames = LANG_SV.months;
        this.dateFormat = 'yyyy-MM-dd';
        this.dateFormatPlaceholder = 'åååå-mm-dd';
        break;
      case 'de':
      case 'de-AT':
        this.monthNames = LANG_DE.months;
        this.dateFormat = 'dd.MM.yyyy';
        this.dateFormatPlaceholder = 'dd.mm.yyyy';
        break;
      default:
        console.error(
          'Unknown local not supported in calendar: ',
          localeService.getCurrentLocale()
        );
        return;
    }
  }

  ngOnInit(): void {
    this.showCalendar = false;
    this.updateCalendar();
  }

  ngOnChanges(changes: any) {
    if (!!changes.limitDate) {
      this.updateCalendar();
    }
  }

  ngOnDestroy() {
    if (this.formChangeSubscription) {
      this.formChangeSubscription.unsubscribe();
    }
  }

  updateCalendar = (): void => {
    this.startDate = this.startDate ?? this.initFromDate;
    this.endDate = this.endDate ?? this.initToDate;

    const endDateIsBiggerThanMaxDate =
      this.endDate.getTime() > this.limitDate.getTime();
    if (endDateIsBiggerThanMaxDate) {
      this.endDate = this.limitDate;
    }
    this.minDate = new Date('2000-01-01');
    this.startMonthDateString = `${
      this.monthNames[this.startDate.getMonth()].short
    } ${this.startDate.getDate()}`;
    this.endMonthDateString = `${
      this.monthNames[this.endDate.getMonth()].short
    } ${this.endDate.getDate()}`;

    this.mobilelabel = `${this.startMonthDateString} - ${this.endMonthDateString}`;
    this.selectLabel = `${
      this.startMonthDateString
    }, ${this.startDate.getFullYear()} - ${
      this.endMonthDateString
    }, ${this.endDate.getFullYear()}`;
    this.update();
  };

  yesterday(text: string) {
    const yesterDay = new Date();
    yesterDay.setDate(yesterDay.getDate() - 1);
    yesterDay.setHours(12, 0, 0, 0);
    this.startDate = yesterDay;
    this.endDate = yesterDay;
    this.selectLabel = text;
    this.update();
  }

  lastSevenDays(text: string) {
    const lastSevenDays = new Date(this.limitDate);
    lastSevenDays.setHours(12, 0, 0, 0);
    lastSevenDays.setDate(lastSevenDays.getDate() - 6);
    this.startDate = lastSevenDays;
    this.endDate = new Date(this.limitDate);

    this.selectLabel = text;
    this.update();
  }

  lastWeek(text: string) {
    this.startDate = this.getLastMonday();
    const date = this.getLastMonday();
    const lastSunday = date.setDate(this.getLastMonday().getDate() + 6);
    this.endDate = new Date(lastSunday);
    this.selectLabel = text;
    this.update();
  }

  getLastMonday() {
    const date = new Date();
    const today = date.getDate();
    const dayOfTheWeek = date.getDay();
    const newDate = date.setDate(today - dayOfTheWeek - 6);
    return new Date(newDate);
  }

  lastMonth(text: string) {
    const today = new Date();
    const lastDay = new Date(today.getFullYear(), today.getMonth(), 0);
    const firstDay = new Date(lastDay.getFullYear(), lastDay.getMonth(), 1);
    this.startDate = firstDay;
    this.endDate = lastDay;
    this.selectLabel = text;
    this.update();
  }

  update() {
    this.limitDateString = formatDate(
      this.limitDate,
      this.dateFormat,
      this.localeService.getCurrentLocale()
    );

    this.startDate.setHours(12, 0, 0, 0);
    this.endDate.setHours(12, 0, 0, 0);
    this.limitDate.setHours(12, 0, 0, 0);

    this.startDateString = formatDate(
      this.startDate,
      this.dateFormat,
      this.localeService.getCurrentLocale()
    );
    this.endDateString = formatDate(
      this.endDate,
      this.dateFormat,
      this.localeService.getCurrentLocale()
    );

    this.form = new FormGroup({
      startDate: new FormControl(
        formatDate(
          this.startDate,
          this.dateFormat,
          this.localeService.getCurrentLocale()
        ),
        [
          Validators.required,
          FormValidators.dateRange(
            this.minDate,
            this.limitDate,
            this.localeService.getCurrentLocale()
          ),
          Validators.maxLength(10),
          DateValidators.dateFormat(this.localeService.getCurrentLocale()),
        ]
      ),

      endDate: new FormControl(
        formatDate(
          this.endDate,
          this.dateFormat,
          this.localeService.getCurrentLocale()
        ),
        [
          Validators.required,
          FormValidators.dateRange(
            this.startDate,
            this.limitDate,
            this.localeService.getCurrentLocale()
          ),
          Validators.maxLength(10),
          DateValidators.dateFormat(this.localeService.getCurrentLocale()),
        ]
      ),
    });

    if (this.formChangeSubscription) {
      this.formChangeSubscription.unsubscribe();
    }
    this.formChangeSubscription = this.form.valueChanges.subscribe(() => {
      if (this.form.controls.startDate.value !== this.startDateString) {
        this.onStartDateInputChange();
      } else if (this.form.controls.endDate.value !== this.endDateString) {
        this.onEndDateInputChange();
      }
    });
  }

  onClickSelect() {
    this.prevStartDate = this.startDate;
    this.prevEndDate = this.endDate;
    this.prevSelectLabel = this.selectLabel;
    this.prevMobileLabel = this.mobilelabel;
    this.update();
    this.showCalendar = !this.showCalendar;
  }

  onClose() {
    this.showError = false;
    this.startDate = this.prevStartDate;
    this.endDate = this.prevEndDate;
    this.modalActive = false;
    this.showCalendar = false;
    this.selectLabel = this.prevSelectLabel;
    this.mobilelabel = this.prevMobileLabel;
  }

  onCloseModal() {
    this.showError = false;
    this.modalActive = false;
    this.showCalendar = true;
  }

  onStartDateInputChange(): void {
    this.getErrorMessage();
    if (
      !!new Date(this.form.controls.startDate.value).getDate() &&
      this.form.controls.startDate.valid
    ) {
      this.startDate = new Date(
        new Date(this.form.controls.startDate.value).setHours(12, 0, 0, 0)
      );
      this.legendButtonClick(this.buttonOptions[4]);
      this.updateCalendar();
    }
  }

  onEndDateInputChange(): void {
    this.getErrorMessage();
    if (
      !!new Date(this.form.controls.endDate.value).getDate() &&
      this.form.controls.endDate.valid
    ) {
      this.endDate = new Date(
        new Date(this.form.controls.endDate.value).setHours(12, 0, 0, 0)
      );
      this.legendButtonClick(this.buttonOptions[4]);
      this.updateCalendar();
    }
  }

  getErrorMessage() {
    const minDateString = formatDate(
      this.minDate,
      this.dateFormat,
      this.localeService.getCurrentLocale()
    );
    if (
      this.form.controls.startDate.invalid ||
      this.form.controls.endDate.invalid
    ) {
      this.errorMessage = $localize`:@@dateRangePicker.errorMessage:Vänligen ange ett datumintervall mellan ${minDateString} och ${this.limitDateString}`;
    }
  }

  openModal() {
    this.modalActive = true;
    this.showCalendar = false;
  }

  onSave() {
    if (
      this.form.controls.endDate.valid &&
      this.form.controls.startDate.valid
    ) {
      this.showError = false;
      this.startDate.setHours(12, 0, 0, 0);
      this.endDate.setHours(12, 0, 0, 0);
      this.setFromDate.emit(this.startDate);
      this.setToDate.emit(this.endDate);
      this.modalActive = false;
      this.showCalendar = false;
    } else {
      this.showError = true;
    }
  }

  deviceIsMobile() {
    return window.innerWidth <= 768;
  }

  legendButtonClick(button: LegendButton) {
    this.buttonOptions.map(
      (legendButton: LegendButton) => (legendButton.focus = false)
    );
    button.focus = true;
    if (!!button.func) {
      button.func(button.text);
    }
  }
}
