<div class="partial-cancel-order-wrapper">
  <div class="partial-cancel-order-container">
    <p class="heading">
      Remove {{ selectedLineItemsVM.length }} items from order
    </p>

    <p class="text">
      This action cannot be undone. Are you sure you want to continue and remove
      following items from this order?
    </p>

    <ng-container *ngFor="let lineItemVM of visibleLineItems">
      <div class="row">
        <p>
          {{ lineItemVM.form.value.quantity }} x
          {{ lineItemVM.form.value.title }}
        </p>
        <p>{{ getCurrency(lineItemVM.totalAmount) | value: 'DE' }}</p>
      </div>
    </ng-container>

    <app-widget-pagination
      [lineItemsVM]="selectedLineItemsVM"
      [(visibleLineItems)]="visibleLineItems"
      [itemsPerPage]="itemsPerPage"
    ></app-widget-pagination>

    <div class="buttons-wrapper">
      <zaver-secondary-button-subtle
        [text]="'No, cancel'"
        [size]="'x-small'"
        (click)="setView(VIEW.PAYMENT_UPDATE)"
      ></zaver-secondary-button-subtle>

      <zaver-primary-button
        [text]="'Yes, continue'"
        [size]="'x-small'"
        (click)="onUpdate()"
        [loading]="inProgress"
      ></zaver-primary-button>
    </div>
  </div>
</div>
