import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-table-sort-dropdown',
  templateUrl: './table-sort-dropdown.component.html',
  styleUrls: ['./table-sort-dropdown.component.css']
})
export class TableSortDropdownComponent implements OnInit {
  @HostListener('document:click', ['$event.target'])
  public onClick(target) {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside) {
      if (this.showList) {
        this.showList = false;
      }
    }
  }

  @Input() options: any;

  @Output() sortClicked  = new EventEmitter<boolean>();

  showList: boolean = false;

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
  }

  toggleList() {
    this.showList = !this.showList;

  }

  onClickSort(ascending: boolean) {
    this.toggleList()
    this.sortClicked.emit(ascending)
  }

}
