import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BaseComponent } from '@core/base.component';
import { SignupLegalEntity } from '@core/dto/signup/owner/SignupLegalEntity';
import { SignupPrivateCompany } from '@core/dto/signup/owner/SignupPrivateCompany';
import { LegalEntityService } from '@core/service/legal-entity.service';
import { PrivateCompanyService } from '@core/service/private-company.service';
import { AuthenticationService } from '@core/service/authentication.service';
import { forkJoin } from 'rxjs';
import { CompanyFormComponent } from '@zfb/page/signup/company-form/company-form.component';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppStateService } from '@core/service/app-state.service';
import { CompanyAdministrationService } from '@core/service/company-administration.service';
import { phoneNumberValidator } from '../../../../../../web-component-library/projects/component-library/src/common/phone-number-validator.directive';

@Component({
  selector: 'app-basic-questions',
  templateUrl: './basic-questions-form.component.html',
  styleUrls: ['../signup-shared.css', './basic-questions-form.component.css'],
})
export class BasicQuestionsFormComponent
  extends BaseComponent
  implements OnInit
{
  selectedCompanyType:
    | 'Aktiebolag'
    | 'Handelsbolag'
    | 'Kommanditbolag'
    | 'private' = null;
  @Output()
  onSaved = new EventEmitter();

  companyTypeOptions = [
    {
      label: 'Aktiebolag',
      value: 'Aktiebolag',
    },
    {
      label: 'Handelsbolag',
      value: 'Handelsbolag',
    },
    {
      label: 'Kommanditbolag',
      value: 'Kommanditbolag',
    },
    {
      label: 'Enskild firma',
      value: 'private',
    },
  ];

  legalEntity: SignupLegalEntity;
  privateCompany: SignupPrivateCompany;
  companyRegistered = false;

  @Input() displayingInCompanyAdminPage = false;
  @Input() displayingInCompanyInformationTab = false;
  @Input() displayingInCompanyOwnerInformationTab = false;

  fetchingResponse = true;
  @Output() doneFetchingResponse = new EventEmitter();

  @ViewChild('companyForm') companyForm!: CompanyFormComponent;
  @ViewChild('soloTraderForm') soloTraderForm!: CompanyFormComponent;

  contactInformationForm: FormGroup;
  showContactFormError: boolean;

  @Output() isLegalEntity = new EventEmitter<boolean>();

  constructor(
    private legalEntityService: LegalEntityService,
    private privateCompanyService: PrivateCompanyService,
    protected auth: AuthenticationService,
    private appStateService: AppStateService,
    private formBuilder: FormBuilder,
    private companyAdministrationService: CompanyAdministrationService
  ) {
    super(auth);
  }

  ngOnInit() {
    const activeRep = this.appStateService.getCurrentUser().activeRepresentation;
    const repPhone = activeRep ? activeRep.phone : '';

    if (!this.displayingInCompanyAdminPage) {
      // this is during signup
      this.contactInformationForm = this.formBuilder.group({
        phone: [
          repPhone || '',
          [
            Validators.required,
            phoneNumberValidator([46])
          ],
        ]
      });
      this.fetchCompanyInfoOld();
    } else {
      // This is the company administration page
      this.fetchCompanyInfo();
    }
  }

  async fetchCompanyInfo() {
    const companyType = await (
      await this.companyAdministrationService.getCompanyType()
    ).type;

    if (companyType === 'private') {
      try {
        const wrapper = await this.privateCompanyService.getWithPrefill();
        this.privateCompany = wrapper.privateCompany;
        this.selectedCompanyType = 'private';
        this.companyRegistered = true;
        this.isLegalEntity.emit(false);
      } catch (error: unknown) {
        this.handleError(error);
        this.privateCompany = null;
      }
      this.fetchingResponse = false;
      this.doneFetchingResponse.emit();
    } else {
      try {
        const wrapper = await this.legalEntityService.getWithPrefill();
        this.legalEntity = wrapper.legalEntity;
        this.selectedCompanyType = this.legalEntity.type;
        this.companyRegistered = true;
        this.isLegalEntity.emit(true);
      } catch (error: unknown) {
        this.handleError(error);
        this.legalEntity = null;
      }
      this.fetchingResponse = false;
      this.doneFetchingResponse.emit();
    }
  }

  // The old way of doing this. This should be replaced by something that
  // does not rely on there being a 404 every time we fetch the company!!!
  fetchCompanyInfoOld() {
    const fetchLegalEntity = this.legalEntityService
      .getWithPrefill()
      .then((wrapper) => {
        this.legalEntity = wrapper.legalEntity;
        return wrapper.persisted;
      })
      .catch((error) => {
        this.handleError(error);
        this.legalEntity = null;
      });
    const fetchPrivateCompany = this.privateCompanyService
      .getWithPrefill()
      .then((wrapper) => {
        this.privateCompany = wrapper.privateCompany;
        return wrapper.persisted;
      })
      .catch((error2) => {
        this.handleError(error2);
        this.privateCompany = null;
      });

    forkJoin(fetchLegalEntity, fetchPrivateCompany).subscribe((all) => {
      const isLegalEntity: void | boolean = all[0];
      const isPrivateCompany: void | boolean = all[1];
      if (isLegalEntity) {
        this.selectedCompanyType = this.legalEntity.type;
        this.companyRegistered = true;
        this.isLegalEntity.emit(true);
      } else if (isPrivateCompany) {
        this.selectedCompanyType = 'private';
        this.companyRegistered = true;
        this.isLegalEntity.emit(false);
      } else {
        this.companyRegistered = false;
      }
      this.fetchingResponse = false;
      this.doneFetchingResponse.emit();
    });
  }

  savedLegalEntity() {
    this.onSaved.emit();
  }

  savedPrivateCompany() {
    this.onSaved.emit();
  }

  onCompanyTypeChange(event: string) {
    if (
      event === 'Aktiebolag' ||
      event === 'Handelsbolag' ||
      event === 'Kommanditbolag' ||
      event === 'private'
    ) {
      this.selectedCompanyType = event;
    }
  }

  handleError(error) {
    try {
      const httpError: HttpErrorResponse = JSON.parse(JSON.stringify(error));
      if (httpError.status === 404) {
        // The implementation of signup/company admin pages rely
        // on there always being one 404 response from IDAU
        return;
      }
      super.handleError(error);
    } catch {
      super.handleError(error);
    }
  }

  getPhoneErrorMessage(): string {
    const errors = this.contactInformationForm.controls['phone'].errors;
    if (!errors) {
      return null;
    }
    if (!!errors['required']) {
      return 'Vänligen ange ett mobilnummer';
    } else if (!!errors['invalidFormat']) {
      return 'Vänligen kontrollera mobilnumret';
    } else if (!!errors['invalidNumber']) {
      return 'Vänligen kontrollera mobilnumret';
    } else if (!!errors['unsupportedCountryCode']) {
      return 'Den angivna landskoden stöds inte';
    }
    return null;
  }
}
