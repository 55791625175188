<div class="header">
  <div>
    <h1 *ngIf="paymentRequest.buyerType === 'COMPANY'">
      {{ paymentRequest.b2bPaymentData.buyerCompanyName }}
    </h1>
    <section class="status-picker-wrapper" *ngIf="mayViewEvents()">
      <div class="status-picker">
        <div
          class="status-picker-container"
          (click)="activePageChange('overview')"
          [class.activeStatus]="activePage === 'overview'"
        >
          <span i18n="@@viewPaymentRequest.tabs.overview.text">Översikt</span>
        </div>
        <!-- <div
        *ngIf="isMarketDEAT()" -->
        <div
          *ngIf="paymentRequest.lineItems?.length > 0"
          class="status-picker-container"
          (click)="activePageChange('purchase')"
          [class.activeStatus]="activePage === 'purchase'"
        >
          <span *ngIf="isMarketDEAT()">Bestellung</span>
          <span *ngIf="!isMarketDEAT()">Order</span>
        </div>
        <div
          class="status-picker-container"
          (click)="activePageChange('events')"
          [class.activeStatus]="activePage === 'events'"
        >
          <span i18n="@@viewPaymentRequest.tabs.events.text">Händelser</span>
        </div>
      </div>
    </section>
  </div>
  <app-quick-actions
    *ngIf="activeQuickActions.length > 0 && !showGoBackButton"
    [data]="paymentRequest"
    [actions]="activeQuickActions"
    [listHorizontalPosition]="'left'"
    [listVerticalPosition]="'under'"
  >
  </app-quick-actions>
</div>

<ng-container *ngIf="activePage === 'overview'">
  <div class="spacing-container" *ngIf="!isMarketDEAT()">
    <div>
      <div class="list-container">
        <div
          class="list-row"
          *ngIf="
            paymentRequest.buyerType === 'COMPANY' &&
            !!paymentRequest.b2bPaymentData.nin
          "
        >
          <div class="icon-and-text">
            <img
              class="icons"
              src="assets/icons/Icon-Office-Business-Card.svg"
              alt=""
            />
            <span class="medium2">Org.nr</span>
          </div>
          <div class="medium">
            {{ paymentRequest.b2bPaymentData.nin }}
          </div>
        </div>
        <div
          class="list-row"
          *ngIf="paymentRequest.buyerType === 'CONSUMER' && !!paymentRequest.to"
        >
          <div class="icon-and-text">
            <img
              class="icons"
              src="assets/icons/Icon-User-Single-Black-No-Circle.svg"
              alt=""
            />
            <span class="medium2" i18n="@@viewPaymentRequest.customer"
              >Kund</span
            >
          </div>

          <div class="medium">{{ paymentRequest.to | nameofperson }}</div>
        </div>
        <div class="list-row">
          <div class="icon-and-text">
            <img class="icons" src="assets/icons/Icon-Phone.svg" alt="" />
            <span class="medium2" i18n="@@viewPaymentRequest.phoneNumber"
              >Mobilnummer</span
            >
          </div>
          <div class="medium">
            <a
              href="tel:{{ paymentRequest.phone }}"
              class="link hide-long-texts"
              >{{ paymentRequest.phone }}</a
            >
          </div>
        </div>
        <div
          class="list-row"
          *ngIf="
            paymentRequest.buyerType === 'COMPANY' &&
            !!paymentRequest.b2bPaymentData.buyerEmail
          "
        >
          <div class="icon-and-text">
            <img class="icons" src="assets/icons/Icon-E-mail.svg" alt="" />
            <span class="medium2">E-postadress</span>
          </div>
          <div class="medium">
            <a
              href="mailto:{{ paymentRequest.b2bPaymentData.buyerEmail }}"
              class="hide-long-texts link"
              >{{ paymentRequest.b2bPaymentData.buyerEmail }}</a
            >
          </div>
        </div>
        <div class="list-row">
          <div class="icon-and-text">
            <img
              class="icons"
              src="assets/icons/Icon-Money-Wallet.svg"
              alt=""
            />
            <span class="medium2" i18n="@@viewPaymentRequest.amount">
              Belopp
            </span>
          </div>
          <div class="medium">
            {{
              paymentRequest.value | value : localeService.getCurrentLocale()
            }}
          </div>
        </div>
        <div
          class="list-row"
          *ngIf="
            paymentRequest.buyerType === 'COMPANY' &&
            !!paymentRequest.b2bPaymentData.invoiceReference
          "
        >
          <div class="icon-and-text">
            <img class="icons" src="assets/icons/Icon-E-mail.svg" alt="" />
            <span class="medium2">OCR/Meddelande</span>
          </div>
          <div class="medium">
            {{ paymentRequest.b2bPaymentData.invoiceReference }}
          </div>
        </div>
        <div
          class="list-row"
          *ngIf="
            paymentRequest.buyerType === 'COMPANY' &&
            !!paymentRequest.b2bPaymentData.dueDate
          "
        >
          <div class="icon-and-text">
            <img class="icons" src="assets/icons/Icon-E-mail.svg" alt="" />
            <span class="medium2">Förfallodatum</span>
          </div>
          <div class="medium">
            {{ paymentRequest.b2bPaymentData.dueDate }}
          </div>
        </div>
      </div>
      <div
        class="b2b-data-wrapper"
        *ngIf="paymentRequest.buyerType === 'COMPANY'"
      >
        <app-page-loader *ngIf="fetchingPartialMatches"></app-page-loader>
        <app-b2b-payment-progressbar
          *ngIf="!fetchingPartialMatches && partialMatch"
          [payments]="partialMatch.paymentData"
          [totalAmount]="paymentRequest.value.amount"
        >
        </app-b2b-payment-progressbar>
        <app-form-error *ngIf="errorMessage" [errorText]="errorMessage">
        </app-form-error>
      </div>

      <div id="payment-request-texts">
        <span id="view-title" *ngIf="paymentRequest.title">{{
          paymentRequest.title
        }}</span>
        <span id="view-description" *ngIf="paymentRequest.description"
          >{{ displayDescription }}&nbsp;
          <span
            class="description-links"
            *ngIf="displayDescriptionLengthToggle && descriptionShorted"
            (click)="showMoreDescription()"
            i18n="@@viewPaymentRequest.description.showMore"
            >Visa mer</span
          >
          <span
            class="description-links"
            *ngIf="displayDescriptionLengthToggle && !descriptionShorted"
            (click)="showLessDescription()"
            i18n="@@viewPaymentRequest.description.showLess"
            >Visa mindre</span
          >
        </span>
      </div>
    </div>

    <div id="button-div">
      <!-- The id on this button is used for Tag Manager-->
      <zaver-secondary-button-subtle
        *ngIf="showCancelButton()"
        text="Avbryt förfrågan"
        [iconUrl]="'assets/icons/Icon-Bin.svg'"
        (click)="cancel()"
        id="click_cancel_paymentrequest_modal_button"
        i18n-text="@@viewPaymentRequest.button.cancel.text"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        *ngIf="showCreateRefundButton() && !showGoBackButton"
        text="Skapa retur"
        i18n-text="@@viewPaymentRequest.button.createRefund.text"
        (click)="onOpenCreateRefundModal()"
      ></zaver-primary-button>

      <zaver-primary-button
        *ngIf="showGoBackButton"
        text="Tillbaka"
        i18n-text="@@viewPaymentRequest.button.goBack.text"
        (click)="onGoBack.emit()"
      ></zaver-primary-button>
    </div>
  </div>

  <div
    class="spacing-container"
    [ngClass]="{ de: isMarketDEAT() }"
    *ngIf="isMarketDEAT()"
  >
    <div class="list-container">
      <div
        class="list-row"
        *ngIf="
          paymentRequest.buyerType === 'COMPANY' &&
          !!paymentRequest.b2bPaymentData.nin
        "
      >
        <div class="icon-and-text">
          <img
            class="icons"
            src="assets/icons/Icon-Office-Business-Card.svg"
            alt=""
          />
          <span class="medium2">Org.nr</span>
        </div>
        <div class="medium">
          {{ paymentRequest.b2bPaymentData.nin }}
        </div>
      </div>

      <div
        class="list-row"
        *ngIf="!isMarketDEAT() && paymentRequest.buyerType === 'CONSUMER'"
      >
        <div class="icon-and-text">
          <img class="icons" src="assets/icons/Icon-Phone.svg" alt="" />
          <span class="medium2" i18n="@@viewPaymentRequest.phoneNumber"
            >Mobilnummer</span
          >
        </div>
        <div class="medium">
          <a
            href="tel:{{ paymentRequest.phone }}"
            class="link hide-long-texts"
            >{{ paymentRequest.phone }}</a
          >
        </div>
      </div>
      <div
        class="list-row"
        *ngIf="
          paymentRequest.buyerType === 'COMPANY' &&
          !!paymentRequest.b2bPaymentData.buyerEmail
        "
      >
        <div class="icon-and-text">
          <img class="icons" src="assets/icons/Icon-E-mail.svg" alt="" />
          <span class="medium2">E-postadress</span>
        </div>
        <div class="medium">
          <a
            href="mailto:{{ paymentRequest.b2bPaymentData.buyerEmail }}"
            class="hide-long-texts link"
            >{{ paymentRequest.b2bPaymentData.buyerEmail }}</a
          >
        </div>
      </div>
      <div
        class="list-row"
        *ngIf="!isMarketDEAT() && paymentRequest.buyerType === 'CONSUMER'"
      >
        <div class="icon-and-text">
          <img class="icons" src="assets/icons/Icon-Money-Wallet.svg" alt="" />
          <span class="medium2" i18n="@@viewPaymentRequest.amount">
            Belopp
          </span>
        </div>
        <div class="medium">
          {{ paymentRequest.value | value : localeService.getCurrentLocale() }}
        </div>
      </div>
      <div
        class="list-row"
        *ngIf="
          paymentRequest.buyerType === 'COMPANY' &&
          !!paymentRequest.b2bPaymentData.invoiceReference
        "
      >
        <div class="icon-and-text">
          <img class="icons" src="assets/icons/Icon-E-mail.svg" alt="" />
          <span class="medium2">OCR/Meddelande</span>
        </div>
        <div class="medium">
          {{ paymentRequest.b2bPaymentData.invoiceReference }}
        </div>
      </div>
      <div
        class="list-row"
        *ngIf="
          paymentRequest.buyerType === 'COMPANY' &&
          !!paymentRequest.b2bPaymentData.dueDate
        "
      >
        <div class="icon-and-text">
          <img class="icons" src="assets/icons/Icon-E-mail.svg" alt="" />
          <span class="medium2">Förfallodatum</span>
        </div>
        <div class="medium">
          {{ paymentRequest.b2bPaymentData.dueDate }}
        </div>
      </div>

      <ng-container
        *ngIf="isMarketDEAT() && paymentRequest.buyerType === 'CONSUMER'"
        class="list-container"
      >
        <div
          class="event-container"
          [ngClass]="{ 'customer-information': isMarketDEAT() }"
        >
          <h2 *ngIf="paymentRequest.to">
            {{ paymentRequest.to | nameofperson }}
          </h2>
          <p *ngIf="paymentRequest.buyerBirthDate">
            Geburtsdatum:
            {{ paymentRequest.buyerBirthDate | date : 'dd.MM.yyyy' }}
          </p>
          <p *ngIf="paymentRequest.buyerStreetAddress">
            {{ paymentRequest.buyerStreetAddress }}
          </p>
          <p
            *ngIf="
              paymentRequest.buyerZipAddress && paymentRequest.buyerCityAddress
            "
          >
            {{ paymentRequest.buyerZipAddress }}
            {{ paymentRequest.buyerCityAddress }}
          </p>
          <p *ngIf="paymentRequest.language">
            {{ paymentRequest.language | DE_LanguageFormatter }}
          </p>
          <div class="customer-contact-information">
            <span *ngIf="paymentRequest.buyerEmail">{{
              paymentRequest.buyerEmail
            }}</span>
            <span *ngIf="paymentRequest.phone">{{ paymentRequest.phone }}</span>
          </div>
        </div>
      </ng-container>
      <div
        class="b2b-data-wrapper"
        *ngIf="paymentRequest.buyerType === 'COMPANY'"
      >
        <app-page-loader *ngIf="fetchingPartialMatches"></app-page-loader>
        <app-b2b-payment-progressbar
          *ngIf="!fetchingPartialMatches && partialMatch"
          [payments]="partialMatch.paymentData"
          [totalAmount]="paymentRequest.value.amount"
        >
        </app-b2b-payment-progressbar>
        <app-form-error *ngIf="errorMessage" [errorText]="errorMessage">
        </app-form-error>
      </div>

      <div
        id="payment-request-texts"
        *ngIf="!isMarketDEAT() && paymentRequest.buyerType === 'CONSUMER'"
      >
        <span id="view-title" *ngIf="paymentRequest.title">{{
          paymentRequest.title
        }}</span>
        <span id="view-description" *ngIf="paymentRequest.description"
          >{{ displayDescription }}&nbsp;
          <span
            class="description-links"
            *ngIf="displayDescriptionLengthToggle && descriptionShorted"
            (click)="showMoreDescription()"
            i18n="@@viewPaymentRequest.description.showMore"
            >Visa mer</span
          >
          <span
            class="description-links"
            *ngIf="displayDescriptionLengthToggle && !descriptionShorted"
            (click)="showLessDescription()"
            i18n="@@viewPaymentRequest.description.showLess"
            >Visa mindre</span
          >
        </span>
      </div>
    </div>

    <div id="button-div">
      <!-- The id on this button is used for Tag Manager-->
      <zaver-secondary-button-subtle
        *ngIf="showCancelButton()"
        text="Avbryt förfrågan"
        [iconUrl]="'assets/icons/Icon-Bin.svg'"
        (click)="cancel()"
        id="click_cancel_paymentrequest_modal_button"
        i18n-text="@@viewPaymentRequest.button.cancel.text"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        *ngIf="showCreateRefundButton() && !showGoBackButton"
        text="Skapa retur"
        i18n-text="@@viewPaymentRequest.button.createRefund.text"
        (click)="onOpenCreateRefundModal()"
      ></zaver-primary-button>

      <zaver-primary-button
        *ngIf="showGoBackButton"
        text="Tillbaka"
        i18n-text="@@viewPaymentRequest.button.goBack.text"
        (click)="onGoBack.emit()"
      ></zaver-primary-button>
    </div>
  </div>
</ng-container>

<app-payment-request-events
  *ngIf="activePage === 'events' && mayViewEvents()"
  [paymentRequest]="paymentRequest"
></app-payment-request-events>

<ng-container *ngIf="activePage === 'purchase'">
  <ng-container>
    <!-- ngIf="isMarketDEAT()"> -->
    <div style="margin: 28px 24px" class="purchase-information-container">
      <h2 *ngIf="isMarketDEAT()">Details zur Bestellung</h2>
      <h2 *ngIf="!isMarketDEAT()">Order details</h2>
      <h3 *ngIf="paymentRequest.title">{{ paymentRequest.title }}</h3>
      <ng-container *ngIf="displayDescription">
        <p class="purchase-description">
          {{ displayDescription }}
          <ng-container *ngIf="displayDescriptionLengthToggle">
            <button
              (click)="
                descriptionShorted
                  ? showMoreDescription()
                  : showLessDescription()
              "
            >
              {{ descriptionShorted ? 'Show more' : 'Show less' }}
            </button>
          </ng-container>
        </p>
      </ng-container>

      <div *ngIf="viban" class="viban-container">
        <span class="viban-title">Zaver re-payment information</span>
        <div class="viban-items">
          <span>Purchase specific VIBAN:</span>
          <span class="purchase-description">{{ viban }}</span>
        </div>
      </div>

      <div
        [ngStyle]="!viban && !displayDescription && { 'padding-top': '30px' }"
      >
        <ng-container *ngIf="!!paymentRequest.paymentMethod">
          <div class="list-row" *ngIf="!!paymentRequest.latestCaptureTimeStamp">
            <div class="icon-and-text">
              <img
                class="icons"
                src="assets/icons/Icon-Money-Wallet.svg"
                alt="Icon clock"
              />
              <span class="medium2">Latest capture timestamp</span>
            </div>
            <span>{{
              paymentRequest.latestCaptureTimeStamp
                | date : 'dd.MM.yyyy - HH:mm'
            }}</span>
          </div>

          <div class="list-row">
            <div class="icon-and-text">
              <img class="icons" src="assets/icons/Icon-Invoice.svg" alt="" />
              <span class="medium2">Payment method</span>
            </div>
            <span>{{ paymentRequest.paymentMethod | titlecase }}</span>
          </div>
        </ng-container>

        <ng-container
          *ngIf="
            !!paymentRequest.expiryDate &&
            (paymentRequest.status === 'OPEN' ||
              paymentRequest.status === 'EXPIRED')
          "
        >
          <div class="list-row">
            <div class="icon-and-text">
              <img
                class="icons"
                src="assets/icons/Icon-Clock.svg"
                alt="Icon clock"
              />
              <span class="medium2">Expiry date</span>
            </div>
            <span>
              {{
                paymentRequest.expiryDate | date : 'dd.MM.yyyy - '
              }}23:59</span
            >
          </div>
        </ng-container>

        <ng-container *ngIf="!!paymentRequest.cancelledAt">
          <div class="list-row">
            <div class="icon-and-text">
              <img
                class="icons"
                src="assets/icons/Icon-Clock.svg"
                alt="Icon clock"
              />
              <span class="medium2">Cancellation timestamp</span>
            </div>
            <span>
              {{
                paymentRequest.cancelledAt | date : 'dd.MM.yyyy - HH:mm'
              }}</span
            >
          </div>
        </ng-container>

        <div class="list-row">
          <div class="icon-and-text">
            <img class="icons" src="assets/icons/Icon-Bullets.svg" alt="" />
            <span class="medium2">Line items</span>
          </div>
          <span>{{
            paymentRequest.lineItems?.length > 0
              ? paymentRequest.lineItems.length
              : 0
          }}</span>
        </div>

        <div class="list-row">
          <div class="icon-and-text">
            <img
              class="icons"
              src="assets/icons/Icon-Money-Wallet.svg"
              alt=""
            />
            <span class="medium2">Initial order amount</span>
          </div>
          <span>{{
            paymentRequest.value['amount'] | amountPipe : currency
          }}</span>
        </div>

        <div class="list-row" *ngIf="paymentRequest.status == 'CANCELLED'">
          <div class="icon-and-text">
            <img
              class="icons"
              src="assets/icons/Icon-Money-Wallet.svg"
              alt=""
            />
            <span class="medium2">Cancelled amount</span>
          </div>
          <span>{{ paymentRequest.price | amountPipe : currency }}</span>
        </div>

        <div class="list-row">
          <div class="icon-and-text">
            <img
              class="icons"
              src="assets/icons/Icon-Money-Wallet.svg"
              alt=""
            />
            <span class="medium2">Captured amount</span>
          </div>
          <span
            >{{
              paymentRequest.capturedAmount['amount'] | amountPipe : currency
            }}
          </span>
        </div>
        <div class="list-row" *ngIf="!!amountLeftToCapture">
          <div class="icon-and-text">
            <img
              class="icons"
              src="assets/icons/Icon-Money-Wallet.svg"
              alt=""
            />
            <span class="medium2">Amount left to capture</span>
          </div>
          <span>{{ amountLeftToCapture | amountPipe : currency }}</span>
        </div>
        <div
          class="list-row"
          *ngIf="
            !!paymentRequest.refundedValue &&
            paymentRequest.status !== 'SETTLED' &&
            paymentRequest.status !== 'OPEN_REFUND'
          "
        >
          <div class="icon-and-text">
            <img
              class="icons"
              src="assets/icons/Icon-Money-Wallet.svg"
              alt=""
            />
            <span class="medium2">Refunded amount</span>
          </div>
          <span
            >{{
              paymentRequest.refundedValue['amount'] | amountPipe : currency
            }}
          </span>
        </div>

        <div class="list-row" *ngIf="paymentRequest.status === 'OPEN_REFUND'">
          <div class="icon-and-text">
            <img
              class="icons"
              src="assets/icons/Icon-Money-Wallet.svg"
              alt=""
            />
            <span class="medium2">Pending refund</span>
          </div>
          <span>{{
            paymentRequest.pendingRefundableValue | amountPipe : currency
          }}</span>
        </div>

        <div id="button-div">
          <zaver-primary-button
            *ngIf="showViewAndManagePaymentRequestButton()"
            (click)="viewOrderDetails()"
            [text]="viewOrderButtonText"
          ></zaver-primary-button>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
