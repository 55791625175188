import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-line-item-input',
  templateUrl: './line-item-input.component.html',
  styleUrls: ['./line-item-input.component.scss'],
})
export class LineItemInputComponent implements OnInit {
  @Input() value: string = '';
  @Input() disabled: boolean = false;
  @Input() textAlign: 'center' | 'left' | 'right' = 'center';
  @Input() type: 'amount' | 'number' | 'text' = 'amount';
  @Input() control: FormControl;
  constructor() {}

  ngOnInit(): void {}
}
