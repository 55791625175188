<div class="small-modal-wrapper">
  <ng-container *ngIf="!serverError">
    <span i18n="@@declineRefund.text"
      >Är du säker på att du vill neka returen? 🙂</span
    >
    <app-form-error *ngIf="errorMessage" [errorText]="errorMessage">
    </app-form-error>

    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        text="Tillbaka"
        i18n-text="@@declineRefund.backButton"
        [disabled]="cancelling"
        (click)="return.emit(refund)"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        text="Ja, neka"
        i18n-text="@@declineRefund.confirmButton"
        [loading]="cancelling"
        [color]="'black'"
        (click)="cancel()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </ng-container>
  <ng-container *ngIf="serverError">
    <app-server-error [errorMessage]="serverErrorMessage"></app-server-error>

    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        text="Stäng"
        i18n-text="@@declineRefund.serverError.backButton"
        [disabled]="cancelling"
        (click)="closeModal.emit()"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        text="Försök igen"
        i18n-text="@@declineRefund.serverError.confirmButton"
        [loading]="cancelling"
        [color]="'black'"
        (click)="cancel()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </ng-container>
</div>
