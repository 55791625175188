import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PrecheckStatus } from '@core/dto/precheck/PrecheckStatus';
import { PrecheckSummary } from '@core/dto/precheck/PrecheckSummary';
import { LocaleService } from '@core/service/locale.service';

@Component({
  selector: 'app-view-precheck',
  templateUrl: './view-precheck.component.html',
  styleUrls: ['./view-precheck.component.scss'],
})
export class ViewPrecheckComponent implements OnInit {
  @Input() precheck: PrecheckSummary;

  @Output() resendSms = new EventEmitter<PrecheckSummary>();

  PrecheckStatus = PrecheckStatus;

  constructor(public localeService: LocaleService) {}

  ngOnInit(): void {}

  resend() {
    this.resendSms.emit(this.precheck);
  }

  showAmount(): boolean {
    return (
      this.precheck.status === PrecheckStatus.PENDING ||
      this.precheck.status === PrecheckStatus.CANCELLED
    );
  }

  iconColor(): string {
    if (this.precheck.status === PrecheckStatus.CANCELLED) {
      return '#6a7381';
    } else {
      return '#0A1018';
    }
  }
}
