import {
  CompanyRepresentativeStatusRepresentation,
  CompanyRepresentativeStatusRepresentations,
} from '@assets/language/CompanyRepresentativeStatusRepresentation';
import {
  CompanyRepresentationRoleColor,
  CompanyRepresentationRoleColorRepresentations,
} from '@assets/language/RoleColorRepresentation';
import { RepresentativeStatus } from '@core/params';
import { CompanyRepresentativeRole } from './CompanyRepresentativeRole';
import { StoreRelation } from './StoreRelation';

export class CompanyRepresentative {
  id: string;
  user: UserInformation;
  personalNumber: string;
  phone: string;
  email: string;
  status: RepresentativeStatus;
  statusText: string;
  statusColor: string;
  backgroundColor: string;
  role: CompanyRepresentativeRole;
  created: string;
  dateDisplay: string;
  storeRelation: StoreRelation;
  roleColor: string;

  constructor(
    id: string,
    personalNumber: string,
    phone: string,
    email: string,
    role: CompanyRepresentativeRole
  ) {
    (this.id = id), (this.personalNumber = personalNumber);
    this.phone = phone;
    this.email = email;
    this.role = role;
  }

  static setup(rep: CompanyRepresentative) {
    let statusInfo: CompanyRepresentativeStatusRepresentation =
      CompanyRepresentativeStatusRepresentations.get(rep.status);
    if (!statusInfo) {
      statusInfo = CompanyRepresentativeStatusRepresentations.get('_default_');
    }
    rep.statusText = statusInfo.friendlyName;
    rep.statusColor = statusInfo.textColor;
    rep.backgroundColor = statusInfo.backgroundColor;

    let colorInfo: CompanyRepresentationRoleColor =
      CompanyRepresentationRoleColorRepresentations.get(rep.role.color);
    if (!colorInfo) {
      colorInfo =
        CompanyRepresentationRoleColorRepresentations.get('_default_');
    }
    rep.roleColor = colorInfo.hexColor;
  }
}

export interface UserInformation {
  userId: string;
  displayName: string;
  creationDate: string;
  lastLogin: string;
}
