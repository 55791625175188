<ng-container *ngIf="fetchingResponse">
  <div id="representative-spinner-container">
    <app-page-loader></app-page-loader>
  </div>
</ng-container>
<ng-container *ngIf="!fetchingResponse">
  <app-user-approval-table
    [columns]="columns"
    [values]="pendingApprovals"
    (actionClicked)="actionClicked($event)"
    *ngIf="pendingApprovals && pendingApprovals.length > 0"
  >
  </app-user-approval-table>

  <app-empty-state *ngIf="displayEmptyState" [emptyState]="emptyState">
  </app-empty-state>
</ng-container>
