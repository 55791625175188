<div id="view-store-header">
  <span id="display-name">
    {{ store.name }}
  </span>
  <app-quick-actions
    *ngIf="activeQuickActions.length > 0"
    [data]="store"
    [actions]="activeQuickActions"
    [listHorizontalPosition]="'left'"
    [listVerticalPosition]="'under'"
  >
  </app-quick-actions>
</div>
<hr class="divider" />
<div id="store-representatives-container">
  <ng-container *ngIf="!fetchingResponse && storeRepresentatives">
    <div id="store-representatives-container-heading">
      <span i18n="@@viewStore.connectedUsers"
        >Anslutna användare ({{ numOfStoreRepresentatives }} st)</span
      >
    </div>
    <div
      class="store-representative-row-list"
      *ngIf="storeRepresentatives.length > 0"
    >
      <div
        *ngFor="let representative of storeRepresentatives"
        class="store-representative-row"
      >
        <div class="avatar-and-name">
          <app-avatar [color]="representative.roleColor"> </app-avatar>
          <div class="representative-display-name">
            {{ representative.user.displayName }}
          </div>
        </div>
        <div class="representative-status">
          <app-widget-event-type-pill
          [text]="representative.statusText"
          [backgroundColor]="representative.backgroundColor"
          [textColor]="representative.statusColor"
          ></app-widget-event-type-pill>
        </div>
      </div>
    </div>
    <app-empty-state
      *ngIf="storeRepresentatives.length === 0"
      [emptyState]="noUsersEmptyState"
    >
    </app-empty-state>
  </ng-container>
  <div class="loader-wrapper" *ngIf="fetchingResponse">
    <app-page-loader></app-page-loader>
  </div>
</div>
