import {Injectable} from '@angular/core';
import {HrefService} from './href.service';
import {BROWSERS, DeviceDetectorService, OS} from 'ngx-device-detector';

@Injectable()
export class BankIdService {

  private readonly hashParamAuthId = 'authRefId';

  iphone: boolean;

  constructor(private deviceService: DeviceDetectorService, private hrefService: HrefService) {
    this.iphone = this.deviceService.getDeviceInfo().device === 'iphone';
  }

  startBankIdApp(autoStartToken?: string, authRefId?: string) {
    let redirect: string;

    const deviceInfo = this.deviceService.getDeviceInfo();
    if (deviceInfo.os === OS.IOS) {
      if (deviceInfo.browser === BROWSERS.CHROME) {
        redirect = 'googlechrome://';
      } else if (deviceInfo.browser === BROWSERS.FIREFOX) {
        redirect = 'firefox://';
      } else {
        // Apparently Safari shouldn't reload the page if the redirect url is the same and contains
        // any hash parameters. This behaviour is not documented anywhere! This also makes
        // the app work on any other random browser which opens a new tab or reloads the old one.
        redirect = `${window.location.href}#${this.hashParamAuthId}=${authRefId}`;
      }
    } else {
      redirect = 'null';
    }

    const encodedRedirect = encodeURIComponent(redirect);

    const url = `bankid:///?autostarttoken=${autoStartToken}&redirect=${encodedRedirect}`;
    window.location.replace(url);
  }

  getCurrentAuthRefId(): string {
    const hashParamsString = window.location.hash.substring(1);
    const params = new URLSearchParams(hashParamsString);

    return params.get(this.hashParamAuthId);
  }

}
