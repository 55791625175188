export class ReportInfo {
  id: String;
  reportType: ReportType;
  startDate: Date;
  endDate: Date;
  numberOfEntries: number;
}

export enum ReportType {
  CHECKOUT = 'CHECKOUT',
  CASHOUT = 'CASHOUT',
}

export class ReportInfoSummary {
  startYear: number;
  startMonthOfStartYear: number;
  endYear: number;
  endMonthOfEndYear: number;

  selectedYear: number;
  selectedMonth: number;
  selectedReports: ReportInfo[];
}
