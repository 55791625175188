<div class="table-filter" *ngIf="filterOptions">
  <div class="header-text" (click)="toggleList()">
    {{ filterOptions.text }} ({{ selectedOption.headerText }})
  </div>
  <div class="table-filter-options" [ngClass]="{ isActive: showList }">
    <div
      *ngFor="let option of filterOptions.options"
      class="table-filter-option"
      [class.isActive]="option === selectedOption"
      (click)="onClickFilter(option)"
    >
      {{ option.displayText }}
    </div>
  </div>
</div>
