import { Component, Input, OnInit } from '@angular/core';
import { View } from '../payment-management/payment-management.interfaces';

@Component({
  selector: 'app-cancel-order',
  templateUrl: './cancel-order.component.html',
  styleUrls: ['./cancel-order.component.scss'],
})
export class CancelOrderComponent implements OnInit {
  @Input() setView: (view: View) => void;
  @Input() inProgress: boolean;
  @Input() removeAllItems: () => void;
  public VIEW = View;
  constructor() {}

  ngOnInit(): void {}
}
