import {Pipe, PipeTransform} from '@angular/core';

/**
 * This is a custom pipe for inserting spaces in amounts. Its intended use is to format
 * monetary amounts into 'Swedish-style' currency representation.
 *
 * It will look att the length of the string representation of the provided amount and
 * insert spaces where it needs to in order to make the amount look pretty.
 *
 * Samples:
 *
 * Input            Output
 * ____________________________________
 *
 * 10               '10'
 * 100              '100'
 * 1000             '1 000'
 * 5659577          '5 659 577'
 * 994421217        '994 421 217'
 */
@Pipe({ name: 'spacedAmount' })
export class AmountFormatterPipe implements PipeTransform {
    transform(value: number, ...args: any[]): string {
        const val = value.toString();
        let result = '';

        // The length of the first part of the amount which has the length
        // of the modulus of the length of the value divided by 3 - which is
        // the desired length of the 'sections'
        // E.g. '99595' would have an introLen of 2 as 5 % 3 = 2
        const introLen = val.length % 3;

        // Append the substring from 0 to introLen to the result E.g. the '99'
        // in '99595'
        result = result.concat(val.substr(0, introLen));

        // Extract the portion of val which has a length evenly divisible by 3
        // and return if the no such portion can be extracted (meaning val has
        // a length of >= 3
        const divisible = val.substr(introLen, val.length - introLen);
        if (divisible.length === 0) {
            return result;
        }

        // Calculate the number of spaces to insert between the chars in the
        // divisible portion
        let spaceCount = Math.floor(val.length / 3);

        // Keep track of the numbers of characters skipped since the last space
        // was inserted, if the count is 3 a space should be inserted
        let skipCount = 0;

        // Start of with a space before appending the three character slices of
        // divisible
        result = result.concat(' ');

        // Iterate through the characters in divisible and insert them into
        // result with an extra space between each three characters
        for (let i = 0; i < divisible.length; i ++) {
            if (skipCount === 3 && spaceCount > 0) {
              result = result.concat(' ', divisible.charAt(i));
              skipCount = 0;
              spaceCount --;
            } else {
               result = result.concat(divisible.charAt(i));
            }
            skipCount ++;
        }

        return result;
    }
}
