<div class="card" [class.in-admin]="displayingInCompanyAdminPage">
  <div class="card-heading">
    <h2>Företagsinformation</h2>
  </div>
  <div class="card-body">
    <form [formGroup]="form" novalidate>
      <div class="input-group-zfb">
        <div class="input-fields">
          <zaver-form-input
            [title]="'Personnummer'"
            [placeholder]="'Personnummer'"
            [error]="
              form.controls.personalNumber.invalid &&
              (showError || startOrganizationNumberValidation)
            "
            [errorMessage]="
              'Vänligen ange ett korrekt personnummer (yyyymmddxxxx)'
            "
            formControlName="personalNumber"
            [inputMode]="'tel'"
            [size]="'large'"
          >
          </zaver-form-input>
        </div>
        <div class="input-fields" *ngIf="displayCompanyNotFoundError">
          <app-form-error
            [errorText]="
              'Vi kan inte hitta en enskild firma med det angivna personnummer.'
            "
          >
          </app-form-error>
        </div>
        <div class="input-fields" [class.hide]="!correctOrganizationNumber">
          <zaver-form-input
            [title]="'Företagets namn'"
            [placeholder]="companyNamePlaceholder"
            formControlName="name"
            [error]="form.controls.name.invalid && showError"
            [errorMessage]="'Fältet får inte lämnas tomt'"
            [maxLength]="255"
            [size]="'large'"
          >
          </zaver-form-input>
        </div>
      </div>
      <div class="input-group-zfb" [class.hide]="!correctOrganizationNumber">
        <div class="input-fields">
          <zaver-form-input
            [title]="'Hemsida'"
            [placeholder]="'Ange företagets hemsida'"
            formControlName="website"
            [inputMode]="'url'"
            [inputType]="'url'"
            [size]="'large'"
          >
          </zaver-form-input>
        </div>
        <div style="width: 100%">
          <app-agreement
            class="desktop-agreement"
            *ngIf="displayingInCompanyAdminPage"
            [displayingInCompanyAdminPage]="true"
          ></app-agreement>
        </div>
      </div>
      <div
        [class.industry-agreement-container]="displayingInCompanyAdminPage"
        [class.hide]="!correctOrganizationNumber"
      >
        <app-radio-select
          [value]="getIndustryValue()"
          (valueChange)="handleIndustryValueChange($event)"
          [options]="industryOptions"
          [showRequiredError]="showError && form.controls.industryOther.invalid"
          [errorMessage]="'Vänligen ange en bransch'"
          [displayingInCompanyAdminPage]="displayingInCompanyAdminPage"
          [seperateInTwoColumns]="displayingInCompanyAdminPage"
        >
        </app-radio-select>
        <app-agreement
          class="mobile-agreement"
          *ngIf="displayingInCompanyAdminPage"
          [displayingInCompanyAdminPage]="true"
        ></app-agreement>
      </div>

      <hr class="divider" [class.hide]="!correctOrganizationNumber" />
      <div class="label" [class.hide]="!correctOrganizationNumber">
        Är du, eller har du inom de senaste månaderna varit, en person i
        politisk utsatt ställning, nära anhörig eller känd medarbetare till en
        sådan?
      </div>
      <app-radio-select
        [class.hide]="!correctOrganizationNumber"
        [value]="form.controls.pep.value"
        (valueChange)="form.controls.pep.setValue($event)"
        [options]="pepOptions"
        [errorMessage]="'Vänligen ange ett alternativ'"
        [showRequiredError]="showError && form.controls.pep.invalid"
        [displayOtherOption]="false"
      >
      </app-radio-select>
      <div id="admin-buttons" *ngIf="displayingInCompanyAdminPage">
        <zaver-secondary-button-outlined
          style="width: 110px"
          *ngIf="formChanged"
          (click)="promptCancelationConfirmation()"
          [text]="'Avbryt'"
        ></zaver-secondary-button-outlined>
        <zaver-primary-button
          style="width: 110px; margin-left: 12px"
          (click)="promptCompanyInfoSubmissionConfirmation()"
          [disabled]="!formChanged"
          [text]="'Spara'"
          [color]="'cerise'"
        ></zaver-primary-button>
      </div>
    </form>
  </div>
</div>
<app-page-error
  *ngIf="errorMessage"
  [errorMessage]="errorMessage"
></app-page-error>
<app-page-error
  *ngIf="
    showError &&
    (form.invalid ||
      (!!contactInformationForm && contactInformationForm.invalid))
  "
  [errorMessage]="formErrorText"
></app-page-error>

<div class="next-button-container" *ngIf="!displayingInCompanyAdminPage">
  <button class="next-button cerise-bg" (click)="submit()">Nästa</button>
</div>

<zaver-details-modal
  *ngIf="modalActive"
  (onClose)="action(false)"
  [title]="modalTitle"
  [color]="modalColor"
>
  <div class="small-modal-wrapper" *ngIf="displaySubmissionConfirmaiton">
    <div>Är du säker på att du vill spara dina ändringar? 👋</div>
    <app-form-error *ngIf="errorMessage" [errorText]="errorMessage">
    </app-form-error>

    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        [text]="'Tillbaka'"
        [disabled]="submitting"
        (click)="action(false)"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        [text]="'Ja, spara'"
        [color]="'black'"
        [loading]="submitting"
        (click)="action(true)"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </div>
  <div class="small-modal-wrapper" *ngIf="displayCancelConfirmation">
    <div>
      Är du säker på att du vill avbryta? Dina ändringar kommer inte sparas. 😯
    </div>

    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        [text]="'Tillbaka'"
        (click)="action(false)"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        [text]="'Ja, avbryt'"
        [color]="'black'"
        (click)="action(true)"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </div>
</zaver-details-modal>
