import { Injectable } from '@angular/core';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { AppStateService } from './app-state.service';

@Injectable()
export class PhoneNumberService {
  constructor(private appStateService: AppStateService) {}

  // Format a phone number to its international form and remove any spaces
  toInternationalFormat(phone: string): string {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();

    try {
      const phoneNumber = phoneNumberUtil.parse(
        phone,
        this.appStateService.getMarket().toString()
      );
      return phoneNumberUtil
        .format(phoneNumber, PhoneNumberFormat.INTERNATIONAL)
        .split(' ')
        .join('');
    } catch (error) {
      return null;
    }
  }
}
