import { Component, OnInit } from '@angular/core';
import { LocaleService } from '@core/service/locale.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  constructor(private localeService: LocaleService) {}
  currentLoale: string;
  currentYear: string;

  ngOnInit() {
    this.currentLoale = this.localeService.getCurrentLocale();
    this.currentYear = new Date().getFullYear().toString();
  }

  contactLink(): string {
    switch (this.localeService.getCurrentLocale()) {
      case 'de':
      case 'de-AT':
        return 'https://zaver.com/de/kontakt';
      case 'sv':
        return 'https://zaver.com/se/kontakt';
      default:
        return 'https://zaver.com/en/contact';
    }
  }

  termsLink(): string {
    switch (this.localeService.getCurrentLocale()) {
      case 'de':
      case 'de-AT':
        return 'https://zaver.com/de/bedingungen';
      case 'sv':
        return 'https://zaver.com/se/anvandarvillkor';
      default:
        return 'https://zaver.com/en/terms-of-use';
    }
  }
}
