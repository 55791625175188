import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-un-approved',
  templateUrl: './un-approved.component.html',
  styleUrls: ['../page-shared.scss', './un-approved.component.css'],
})
export class UnApprovedComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
