<div>
  <div
    class="header cerise-bg"
    *ngIf="deviceIsMobile() && !(isRangeEnd || isRangeStart)"
  >
    <div class="header-text">
      <span i18n="@@calendar.header">Välj datum</span>
    </div>
    <button class="exit-button" (click)="onCancel()" type="button">
      <img
        class="exit-close-icon"
        src="assets/icons/Icon-Navigation-Exit-Close.svg"
        alt="icon-exit-close"
      />
    </button>
  </div>

  <div class="title" *ngIf="disableLegend">{{ calendarLegend }}</div>
  <div class="title" *ngIf="!disableLegend">
    <div class="calendar-button-wrapper" (click)="onYearView()">
      <div>{{ calendarLegend }}</div>
      <img
        class="icon-select-down"
        src="assets/icons/Icon-Select-Down2.svg"
        alt="icon-down"
      />
    </div>

    <div class="navigation-wrapper">
      <button
        class="left"
        (click)="onPrev()"
        [ngClass]="isMinMonth ? 'button-disabled' : ''"
        type="button"
      >
        <img
          class="arrow-img"
          src="assets/icons/Icon-Navigation-Arrow Left.svg"
          alt="icon-left"
        />
      </button>
      <button
        class="right"
        (click)="onNext()"
        [ngClass]="isMaxMonth ? 'button-disabled' : ''"
        type="button"
      >
        <img
          class="arrow-img"
          src="assets/icons/Icon-Navigation-Arrow Right.svg"
          alt="icon-right"
        />
      </button>
    </div>
  </div>

  <div>
    <div class="content" *ngIf="calendarView === 'DAYS'">
      <div class="labels">
        <div class="label" *ngFor="let day of dayNames">{{ day.short }}</div>
      </div>

      <div class="month">
        <div
          class="day-wrapper"
          *ngFor="let day of displayDays"
          [ngClass]="dateWithinRange(day)"
        >
          <button
            class="day"
            (click)="onSelectDate(day)"
            [ngClass]="getDayBackgroundColor(day)"
            [disabled]="!getSelectableDays(day)"
            type="button"
          >
            <span *ngIf="!!day"> {{ day.getDate() }} </span>
          </button>
        </div>
      </div>

      <div
        class="mobile-buttons"
        *ngIf="deviceIsMobile() && !(isRangeEnd || isRangeStart)"
      >
        <button class="back-button" (click)="onCancel()" type="button">
          <span i18n="@@calendar.button.cancel">Avbryt</span>
        </button>

        <div class="save-button" style="margin: 0 20px">
          <zaver-primary-button
            text="'Spara'"
            i18n-text="@@calendar.button.save"
            (click)="onSave()"
          ></zaver-primary-button>
        </div>
      </div>
    </div>
  </div>

  <div class="months" *ngIf="calendarView === 'YEARS'">
    <button
      *ngFor="let year of displayYears"
      class="monthView"
      [ngClass]="getYearBackgroundColor(year)"
      (click)="onSelectYear(year)"
      type="button"
    >
      <span>{{ year }}</span>
    </button>
  </div>

  <div class="months" *ngIf="calendarView === 'MONTHS'">
    <button
      *ngFor="let month of monthNames"
      class="monthView"
      (click)="onSelectMonth(month.index)"
      [ngClass]="getMonthBackgroundColor(month.index)"
      [disabled]="!getSelectableMonths(month.index)"
      type="button"
    >
      <span> {{ month.short }} </span>
    </button>
  </div>

  <div class="unsetDate-container" *ngIf="!!resetDueDateForm">
    <button
      class="unsetDate-button"
      (click)="resetCalendar()"
      i18n="@@calendar.unset-button"
    >
      Inget utgångsdatum
    </button>
  </div>
</div>
