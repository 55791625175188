import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  HostListener,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'zaver-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.css'],
})
export class FormModalComponent implements OnInit {
  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent): void {
    if (this.modalActive) {
      this.removeModal();
    }
  }

  @Output() onClose = new EventEmitter<any>();
  @Input() title: string;
  @Input() color = '#4466EE';
  @Input() modalBodyVisible = false;
  @Input() preventClosingmodalByAccident: boolean = false;
  private element: any;
  private modalActive: boolean = false;

  constructor(private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    this.setupModal();
  }

  private removeModalListener = (event) => {
    if (event.target.className === 'modal') {
      this.removeModal();
    }
  };

  setupModal(): void {
    this.modalActive = true;
    !this.preventClosingmodalByAccident &&
      this.element.addEventListener('click', this.removeModalListener);
  }

  removeModal(): void {
    this.modalActive = false;
    this.onClose.emit();
  }
}
