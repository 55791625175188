<div class="title" *ngIf="title">
  {{ title }}
</div>
<div
  class="selectCustom js-selectCustom"
  [class.isActive]="showDropDown"
  [class.invalid]="error"
  [class.disabled]="disabled"
  [ngClass]="size"
>
  <button
    class="selectCustom-trigger"
    (click)="toggleDropDownList()"
    type="button"
  >
    {{ selectedOption.label }}
    <svg
      width="14"
      height="9"
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.228574 0.387718C0.533359 0.0660944 1.02754 0.0660702 1.33235 0.387663L7 6.36731L12.6676 0.387663C12.9725 0.0660702 13.4666 0.0660944 13.7714 0.387718C14.0762 0.709341 14.0762 1.23077 13.7714 1.55236L7.84529 7.80467C7.84515 7.80482 7.84501 7.80497 7.84487 7.80511C7.73401 7.92214 7.6024 8.01499 7.45753 8.07837C7.31249 8.14182 7.15701 8.17448 7 8.17448C6.84299 8.17448 6.68751 8.14182 6.54246 8.07837C6.39759 8.01498 6.26597 7.92212 6.1551 7.80508C6.15497 7.80495 6.15484 7.80481 6.15471 7.80467L0.228625 1.55236C-0.0761884 1.23077 -0.0762113 0.709341 0.228574 0.387718ZM7.25885 6.64041C7.25895 6.64051 7.25904 6.64061 7.25913 6.64071L7.25885 6.64041Z"
          fill="#0A1018"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="14" height="8.23529" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </button>
  <div class="selectCustom-options" [class.shorter-dropdown]="shorterDropdown">
    <div
      *ngFor="let option of selectOptions"
      class="selectCustom-option"
      [class.isActive]="option === selectedOption"
      (click)="onSelect(option)"
    >
      {{ option.label }}
    </div>
  </div>
</div>
<div class="error" *ngIf="error">
  <span>{{ errorMessage }}</span>
</div>
