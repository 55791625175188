import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'zaver-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.css']
})
export class BadgeComponent implements OnInit {

  @Input() imgSrc: string;
  @Input() badgeText: string;
  @Input() color: string;

  constructor() { }

  ngOnInit() {

  }

}
