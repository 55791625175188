import { Component, Input, OnInit } from '@angular/core';
import { Currency, CurrencyCode } from '@core/dto/CurrencyCode';
import { View } from '../payment-management/payment-management.interfaces';

@Component({
  selector: 'app-confirm-capture',
  templateUrl: './confirm-capture.component.html',
  styleUrls: ['./confirm-capture.component.scss'],
})
export class ConfirmCaptureComponent implements OnInit {
  @Input() uncapturedAmount: number;
  @Input() selectedPreTaxAmount: number;
  @Input() selectedTaxAmount: number;
  @Input() selectedAmountToCapture: number;
  @Input() inProgress: boolean;
  @Input() setView: (view: View) => void;
  @Input() onCapture: () => void;
  @Input() currency: CurrencyCode;
  public VIEW = View;

  constructor() {}

  ngOnInit(): void {}
}
