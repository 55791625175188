<button
  [id]="buttonId"
  (click)="toggleDropDown()"
  class="select-button"
  type="button"
>
  <img src="{{ selectedItem.imgSrc }}" style="border-radius: 4px" />
</button>
<zaver-custom-select
  style="width: 220px"
  [showDropdown]="showDropDown"
  (dropdownClosed)="showDropDown = false"
  (itemSelected)="selected($event)"
  [items]="items"
  [selectedItem]="selectedItem"
></zaver-custom-select>
