import { Injectable } from '@angular/core';
import { AppStateService } from './app-state.service';
import { CompanyRepresentative } from '@core/dto/CompanyRepresentative';
import {
  AuthorizationParameters,
  MerchantRole,
  Permission,
} from '@core/dto/user-details';
import { CompanyRepresentativeRole } from '@core/dto/CompanyRepresentativeRole';

@Injectable()
export class AccessControlService {
  constructor(private appStateService: AppStateService) {}

  public mayEditUser(representative: CompanyRepresentative): boolean {
    const me = this.appStateService.getCurrentUser();
    // A user may not edit themselves
    if (me.id === representative.user.userId) {
      return false;
    }
    return this.mayEditUserWithRole(representative.role);
  }

  private mayEditUserWithRole(role: CompanyRepresentativeRole): boolean {
    if (!this.userMay(Permission.EDIT_USER)) {
      return false;
    }

    const parameters: AuthorizationParameters = this.getParameters(
      Permission.EDIT_USER
    );

    if (
      !!parameters &&
      !!parameters.appliesToRoles &&
      parameters.appliesToRoles.length > 0
    ) {
      return parameters.appliesToRoles.includes(role.id);
    }
    return false;
  }

  public getAvailableRolesForEditing(
    editedRepresentative: CompanyRepresentative
  ): MerchantRole[] {
    // Used for creating/editing existing users
    // Only admin/signatory can create/change users

    const merchantRoles = this.appStateService
      .getMerchantRoles()
      .filter((m) => this.mayCreateUserWithRole(m.role));

    const merchantRole = this.appStateService
      .getMerchantRoles()
      .find(
        (m) =>
          JSON.stringify(m.role.id) ===
          JSON.stringify(editedRepresentative.role.id)
      );

    if (!merchantRoles.includes(merchantRole)) {
      // actor is not allowed to create users with the editedRepresentative's role
      // Check if the actor is allowed to edit the role, and then add it to the merchantRoles list to be returned
      // This so the role appears in the list of the roles available
      if (this.mayEditUserWithRole(editedRepresentative.role)) {
        merchantRoles.push(merchantRole);
      }
    }

    return merchantRoles;
  }

  public getAvailableRolesForCreation(): MerchantRole[] {
    // Signatories can not be created through the GUI
    return this.appStateService
      .getMerchantRoles()
      .filter((merchantRole) => this.mayCreateUserWithRole(merchantRole.role));

    // return this.userRoles.filter((roleObj) => this.mayCreateUserWithRole(roleObj.value));
  }

  public mayCreateUserWithRole(role: CompanyRepresentativeRole): boolean {
    if (!this.userMay(Permission.CREATE_USER)) {
      return false;
    }

    const parameters: AuthorizationParameters = this.getParameters(
      Permission.CREATE_USER
    );

    if (
      !!parameters &&
      !!parameters.appliesToRoles &&
      parameters.appliesToRoles.length > 0
    ) {
      return parameters.appliesToRoles.includes(role.id);
    }
    return false;
  }

  private getParameters(permission: Permission): AuthorizationParameters {
    const myUser = this.appStateService.getCurrentUser();
    const permissionWithParameters =
      myUser.activeRepresentation.permissions.find(
        (perm) => perm.permission === permission
      );
    if (!!permissionWithParameters) {
      return permissionWithParameters.parameters;
    }
    return null;
  }

  userMay(permission: Permission) {
    if (!this.appStateService.hasCurrentUser()) {
      return false;
    }
    const myUser = this.appStateService.getCurrentUser();

    const permissions: Permission[] =
      myUser.activeRepresentation.permissions.map((perm) => perm.permission);

    return this.permissionsArrayIncludesForIE11(permissions, permission);
  }

  private permissionsArrayIncludesForIE11(
    haystack: Permission[],
    needle: Permission
  ) {
    if (this == null) {
      throw new TypeError('arrayIncludesForIE11 called on null or undefined');
    }

    if (haystack.indexOf === undefined) {
      throw new TypeError(
        'arrayIncludesForIE11 called on something that is not an array'
      );
    }

    return haystack.indexOf(needle) !== -1;
  }
}
