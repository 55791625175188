<label class="switch">
  <span class="text">{{ text }}</span>
  <input
    type="checkbox"
    [checked]="isChecked"
    (change)="onChanged($event)"
    (blur)="onBlur($event)"
    [disabled]="disabled"
  /><i [ngClass]="disabled && 'disabled'"></i>
</label>
