<div class="modal">
  <div class="modal-wrapper">
    <div
      class="modal-header"
      [class.bordered-header]="!!title"
      [style.background]="color"
    >
      <div class="title">
        {{ title }}
      </div>
      <div (click)="removeModal()" class="close-icon">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip10)">
            <path
              d="M0.256157 12.5069C0.0920879 12.671 -5.46588e-05 12.8936 4.2776e-08 13.1257C5.47443e-05 13.3578 0.0923022 13.5803 0.256449 13.7444C0.420596 13.9085 0.643196 14.0006 0.87528 14.0006C1.10736 14.0005 1.32992 13.9083 1.49399 13.7441L6.89682 8.34186C6.91037 8.32828 6.92646 8.31751 6.94418 8.31015C6.9619 8.3028 6.98089 8.29902 7.00007 8.29902C7.01926 8.29902 7.03825 8.3028 7.05597 8.31015C7.07368 8.31751 7.08978 8.32828 7.10332 8.34186L12.5062 13.7453C12.5874 13.8266 12.6838 13.891 12.79 13.935C12.8962 13.979 13.01 14.0017 13.1249 14.0017C13.2398 14.0018 13.3536 13.9791 13.4598 13.9352C13.5659 13.8912 13.6624 13.8268 13.7437 13.7456C13.825 13.6643 13.8895 13.5679 13.9335 13.4617C13.9775 13.3556 14.0001 13.2418 14.0001 13.1269C14.0002 13.0119 13.9776 12.8981 13.9336 12.792C13.8897 12.6858 13.8252 12.5893 13.744 12.508L8.34174 7.10345C8.32816 7.0899 8.31738 7.07381 8.31003 7.05609C8.30268 7.03837 8.2989 7.01938 8.2989 7.0002C8.2989 6.98101 8.30268 6.96202 8.31003 6.9443C8.31738 6.92659 8.32816 6.91049 8.34174 6.89695L13.7452 1.49411C13.9092 1.32981 14.0013 1.10706 14.0011 0.874868C14.001 0.642675 13.9086 0.420056 13.7443 0.255987C13.58 0.0919173 13.3572 -0.000163872 13.125 2.18936e-07C12.8928 0.00016431 12.6702 0.0925602 12.5062 0.256862L7.10332 5.65853C7.08978 5.67211 7.07368 5.68288 7.05597 5.69024C7.03825 5.69759 7.01926 5.70137 7.00007 5.70137C6.98089 5.70137 6.9619 5.69759 6.94418 5.69024C6.92646 5.68288 6.91037 5.67211 6.89682 5.65853L1.49399 0.256862C1.41275 0.175585 1.3163 0.111104 1.21014 0.0671027C1.10398 0.023101 0.990197 0.000439766 0.87528 0.000412682C0.643196 0.000357984 0.420596 0.0925006 0.256449 0.25657C0.0923022 0.42064 5.47224e-05 0.643196 2.43387e-08 0.875281C-5.46738e-05 1.10736 0.0920879 1.32996 0.256157 1.49411L5.65841 6.89695C5.67199 6.91049 5.68276 6.92659 5.69011 6.9443C5.69747 6.96202 5.70125 6.98101 5.70125 7.0002C5.70125 7.01938 5.69747 7.03837 5.69011 7.05609C5.68276 7.07381 5.67199 7.0899 5.65841 7.10345L0.256157 12.5069Z"
              fill="#ffffff"
            />
          </g>
          <defs>
            <clipPath id="clip10">
              <rect width="14" height="14" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
    <div class="modal-body" [class.visible-modal-body]="modalBodyVisible">
      <ng-content></ng-content>
    </div>
  </div>
</div>

<div class="modal-background"></div>
