<div class="dropdown">
  <button
    type="button"
    (click)="toggleDropDown()"
    [ngClass]="size"
    class="dropdown-button"
    [class.isActive]="showDropDown"
    [class.focused]="showDropDown"
    [class.invalid]="error"
    [class.disabled]="disabled"
  >
    <div>
      {{ selectedItem.label }}
    </div>
    <div [class.disabled]="disabled" class="arrow">
      <img
        class="img"
        src="assets/icons/Icon-Navigation-Arrow-Down-Small.svg"
      />
    </div>
  </button>
  <ng-container *ngIf="!!selectableItems">
    <zaver-custom-select
      style="width: 100%"
      [showDropdown]="showDropDown"
      [selectedItem]="selectedItem"
      [items]="selectableItems"
      (dropdownClosed)="toggleDropDown()"
      (itemSelected)="selectItem($event)"
      [shorterDropdown]="shorterDropdown"
    ></zaver-custom-select>
  </ng-container>
</div>
