import { RadioButtonData } from "app/zfb/ui/radio-select/radio-select.component";

export class PEP {
    static VALUES: RadioButtonData[] = [
      {value: 'NON_PEP', label: 'Nej, personen är inte en PEP, nära anhörig eller känd medarbetare till en sådan.'},
      {value: 'IS_PEP', label: 'Ja, personen är själv en person i politiskt utsatt ställning'},
      {value: 'PEP_FAMILY', label: 'Ja, personen är familjemedlem till en PEP'},
      {value: 'PEP_ASSOCIATE', label: 'Ja, personen är känd medarbetare till en PEP'},
    ];
};

export class PEP_SOLE_TRADER {
  static VALUES: RadioButtonData[] = [
    {value: 'NON_PEP', label: 'Nej, jag är inte en PEP, nära anhörig eller känd medarbetare till en sådan.'},
    {value: 'IS_PEP', label: 'Ja, jag är själv en person i politiskt utsatt ställning'},
    {value: 'PEP_FAMILY', label: 'Ja, jag är familjemedlem till en PEP'},
    {value: 'PEP_ASSOCIATE', label: 'Ja, jag är känd medarbetare till en PEP'},
  ];
};
