<div class="card">
  <ng-container *ngIf="!showError">
    <ng-container *ngIf="verifying">
      <h1>
        <span i18n="@@userApproval.verifying.title">Behandlar verifiering</span>
      </h1>
      <app-page-loader></app-page-loader>
    </ng-container>
    <ng-container *ngIf="!verifying">
      <ng-container *ngIf="accepted">
        <h1>
          <span i18n="@@userApproval.accepted.title">Ditt svar:</span
          >&nbsp;<span
            class="color-green"
            i18n="@@userApproval.accepted.subTitle"
            >Ja, bekräftar.</span
          >
        </h1>
        <span class="text" i18n="@@userApproval.accepted.text"
          >Du har svarat att du ska ha ett konto på företaget i fråga.</span
        >
      </ng-container>
      <ng-container *ngIf="!accepted">
        <h1>
          <span i18n="@@userApproval.declined.title">Ditt svar:</span
          >&nbsp;<span
            class="cerise-text"
            i18n="@@userApproval.declined.subTitle"
            >Nej, avböjer.</span
          >
        </h1>
        <span class="text" i18n="@@userApproval.declined.text"
          >Du har svarat att du inte ska ha ett konto på företaget i
          fråga.</span
        >
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="showError">
    <ng-container *ngIf="alreadyResolved">
      <h1>
        <span i18n="@@userApproval.error.alreadyResolved.title"
          >Hej igen! 👋</span
        >
      </h1>
      <span
        class="last-paragraph text"
        i18n="@@userApproval.error.alreadyResolved.text"
        >Det verkar som att du redan har besökt denna sida.</span
      >
    </ng-container>
    <ng-container *ngIf="unexpectedError">
      <h1>
        <span i18n="@@userApproval.error.unexpectedError.title"
          >Ajdå, nu gick det snett.</span
        >
      </h1>
      <span
        class="last-paragraph text"
        i18n="@@userApproval.error.unexpectedError.text"
        >Något oväntat gick fel. Vänligen försök igen, eller kontakta vår
        kundservice.</span
      >
    </ng-container>
  </ng-container>
  <div class="button-wrapper">
    <zaver-primary-button
      *ngIf="!verifying"
      text="Logga in"
      i18n-text="@@userApproval.button.login"
      (click)="toLogin()"
      [color]="'cerise'"
    ></zaver-primary-button>
  </div>
</div>
