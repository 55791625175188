import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BidProgress } from '../dto/Progress';
import { RestBase } from '../rest-base';
import { HrefService } from './href.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppStateService } from './app-state.service';
import { LoggingService } from './logging.service';
import { CompanyRepresentation, UserDetails } from '@core/dto/user-details';
import { Market } from '@core/dto/Market';

@Injectable()
export class AuthenticationService extends RestBase {
  sessionTimeout: number;
  timeoutTimer: ReturnType<typeof setInterval>;
  activityTimer: ReturnType<typeof setInterval>;
  activeUserChecker = this.markUserActive.bind(this);
  userActive = true;

  constructor(
    httpClient: HttpClient,
    hrefService: HrefService,
    protected router: Router,
    private appStateService: AppStateService,
    private loggingService: LoggingService,
    private route: ActivatedRoute
  ) {
    super(httpClient, hrefService.getApiUrl());

    this.timeoutTimer = setInterval(this.checkSession.bind(this), 5000);
    this.activityTimer = setInterval(this.heartBeat.bind(this), 30000);

    // these events will reset the inactivity timer
    window.addEventListener('keydown', this.activeUserChecker);
    window.addEventListener('mouseover', this.activeUserChecker);
    window.addEventListener('touchstart', this.activeUserChecker);
  }

  markUserActive() {
    this.userActive = true;
  }

  getLoginTypeForEmail(email: string): Promise<LoginTypeResponse> {
    return super.get(`/auth/type/?email=${encodeURIComponent(email)}`);
  }

  initializePasswordLogin(
    email: string,
    password: string,
    authenticationSessionId: string
  ): Promise<PasswordLoginResponse> {
    return super.post(
      '/auth/password',
      JSON.stringify({
        username: email,
        password: password,
        authenticationSessionId: authenticationSessionId,
      })
    );
  }

  initializeBankIdLogin(): Promise<BidProgress<any>> {
    const email = this.route.snapshot.queryParamMap.get('email');
    if (email === null) {
      // we need email in order to sign in to correct zfb-user, if email is null we need to route back to login-component
      // where users can input email
      this.router.navigate(['/login'], { skipLocationChange: false });
    } else {
      return super.post(`/auth?email=${encodeURIComponent(email)}`, null);
    }
  }

  checkLoginProgress(
    reference: string,
    userIdentificationToken?: UserIdentificationBody
  ): Promise<BidProgress<any>> {
    return super.post(
      '/auth/' + reference,
      userIdentificationToken ? JSON.stringify(userIdentificationToken) : ''
    );
  }

  setSessionTimeout(timeout: number) {
    // this.loggingService.debug("setting session timeout:" + new Date(Date.now() + timeout).toLocaleString());
    this.sessionTimeout = Date.now() + timeout;
  }

  checkSession() {
    if (!this.appStateService.hasCurrentUser()) {
      return;
    }
    if (this.sessionTimeout + 2000 < Date.now()) {
      this.appStateService.refreshCurrentUser().catch(() => {
        this.logout();
      });
    }
  }

  heartBeat() {
    if (this.userActive && this.appStateService.hasCurrentUser()) {
      super.get('/user/heartbeat').catch(() => {
        this.logout();
      });
      this.userActive = false;
    }
  }

  switchProfile(representative: CompanyRepresentation): Promise<UserDetails> {
    const currentUser = this.appStateService.getCurrentUser();
    currentUser.activeRepresentation = representative;
    this.appStateService.updateCurrentUserTentative(currentUser);

    return super
      .put('/auth/switchProfile/' + representative.id, '')
      .then((userDetails: UserDetails) => {
        this.appStateService.updateCurrentUser(userDetails);
        return userDetails;
      });
  }

  signupNewCompany(signupId?: string): Promise<UserDetails> {
    const body = JSON.stringify({ signupId: signupId });
    return super
      .post('/auth/signup/newCompany', body)
      .then((userDetails: UserDetails) => {
        this.appStateService.updateCurrentUser(userDetails);
        return userDetails;
      });
  }

  logout() {
    this.loggingService.debug('logging out from zfb');
    this.router
      .navigate(['/login'], { skipLocationChange: false })
      .then((res) => {
        if (res) {
          super
            .get('/auth/logout')
            .then(() => {
              this.appStateService.clear();
            })
            .catch(() => {
              this.appStateService.clear();
            });
        }
      });
  }

  updatePassword(
    token: string,
    username: string,
    newPassword: string
  ): Promise<LoginType> {
    return super.post(
      '/auth/password/finalizeReset',
      JSON.stringify({
        token: token,
        username: username,
        newPassword: newPassword,
      })
    );
  }

  createUser(
    token: string,
    username: string,
    newPassword: string
  ): Promise<LoginType> {
    return super.post(
      '/auth/autoCreateUser',
      JSON.stringify({
        signupToken: token,
        email: username,
        password: newPassword,
      })
    );
  }
}

export enum LoginType {
  PASSWORD = 'PASSWORD',
  BANKID = 'BANKID',
  TWO_FACTOR = 'TWO_FACTOR',
  NO_USER = 'NO_USER',
  CREATE_USER = 'CREATE_USER',
  SET_PASSWORD = 'SET_PASSWORD',
  PENDING_ADMIN_APPROVAL = 'PENDING_ADMIN_APPROVAL',
  PENDING_USER_APPROVAL = 'PENDING_USER_APPROVAL',
  PENDING_IDENTIFICATION = 'PENDING_IDENTIFICATION',
  VERIFY_EMAIL_ACCESS = 'VERIFY_EMAIL_ACCESS',
}

export interface LoginTypeResponse {
  loginType: LoginType;
  marketRepresentations: Market[];
}

export interface PasswordLoginResponse {
  success: boolean;
  userDetails: UserDetails;
  error: PasswordLoginError;
}

export interface UserIdentificationBody {
  email: string;
  token: string;
}

export enum PasswordLoginError {
  NO_ERROR = 'NO_ERROR',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  IDENTIFIED_USER = 'IDENTIFIED_USER',
  REFRESH_EMAIL_SENT = 'REFRESH_EMAIL_SENT',
  TWO_FACTOR_AUTHENTICATION = 'TWO_FACTOR_AUTHENTICATION',
}
