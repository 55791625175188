import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocaleService } from '@core/service/locale.service';

export interface RadioButtonData {
  value: string;
  label: string;
}
@Component({
  selector: 'app-radio-select',
  templateUrl: './radio-select.component.html',
  styleUrls: ['./radio-select.component.css'],
})
export class RadioSelectComponent implements OnInit {
  @Input() value: string;
  @Input() options: RadioButtonData[];
  @Input() showRequiredError = false;
  @Input() errorMessage: string;
  @Input() allDisabled = false;
  @Input() displayOtherOption = true;
  @Input() seperateInTwoColumns = false;

  @Input() displayingInCompanyAdminPage = false;

  @Output() valueChange = new EventEmitter<string>();

  selectedRadioValue: string;
  inputValue: string;

  constructor(public localeService: LocaleService) {}

  ngOnInit() {
    if (this.value && this.value !== '') {
      let found = false;
      for (const option of this.options) {
        if (this.value === option.value) {
          this.selectedRadioValue = this.value;
          found = true;
          break;
        }
      }
      if (!found) {
        this.selectedRadioValue = 'OTHER';
        this.inputValue = this.value;
      }
    }
  }

  setSelected(value: string) {
    if (this.allDisabled) {
      return;
    }
    this.selectedRadioValue = value;
    if (this.selectedRadioValue === 'OTHER') {
      this.valueChange.emit(this.inputValue);
    } else {
      this.valueChange.emit(this.selectedRadioValue);
    }
  }
}
