import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '@core/base.component';
import { Currency, CurrencyCode } from '@core/dto/CurrencyCode';
import { PaymentRequestResponse } from '@core/dto/PaymentRequestResponse';
import { RefundReason } from '@core/dto/refund/Refund';
import { AppStateService } from '@core/service/app-state.service';
import { AuthenticationService } from '@core/service/authentication.service';
import { PaymentRequestService } from '@core/service/payment-request.service';
import { RefundCreationRequest, RefundService } from '@core/service/refund.service';
import { ToastMessageService } from '@core/service/toast-message.service';
import { FormValidators } from '@shared/validators/validators';

type Step = 'reason' | 'amount' | 'confirmation';

@Component({
  selector: 'app-create-refund',
  templateUrl: './create-refund.component.html',
  styleUrls: ['./create-refund.component.css'],
})
export class CreateRefundComponent extends BaseComponent implements OnInit {
  public currentStep: Step = 'reason';

  @Input() paymentRequest: PaymentRequestResponse;

  form: FormGroup;
  showError = false;

  sendingRequest = false;
  merchantDefaultCurrency: CurrencyCode;

  @Output() refundCreated = new EventEmitter();
  @Output() closeModal = new EventEmitter();

  constructor(
    private refundService: RefundService,
    auth: AuthenticationService,
    private toastService: ToastMessageService,
    private paymentRequestService: PaymentRequestService,
    private appStateService: AppStateService
  ) {
    super(auth);
  }

  ngOnInit() {
    // Refetch the choosen payment request to ensure we have the refundableAmount and the refundedAmount values
    this.paymentRequestService.getOne(this.paymentRequest.id).then((pr) => {
      this.paymentRequest = pr;
      this.setupForm();
    });
    this.merchantDefaultCurrency = this.appStateService.getDefaultCurrency();
  }

  setupForm() {
    this.form = new FormGroup({
      reasonForm: new FormGroup({
        reason: new FormControl(RefundReason.RIGHT_OF_WITHDRAWAL),
      }),
      amountForm: new FormGroup({
        wholeAmount: new FormControl('true', Validators.required),
        amount: new FormControl(this.paymentRequest.refundableValue.amount.toString(), [
          Validators.required,
          FormValidators.number(1, this.paymentRequest.refundableValue.amount),
        ]),
        note: new FormControl('', Validators.required),
      }),
    });
  }

  get amountForm() {
    return this.form.controls.amountForm as FormGroup;
  }

  get reasonForm() {
    return this.form.controls.reasonForm as FormGroup;
  }

  changeStep(currentStep: Step, direction: 'forward' | 'backwards') {
    switch (currentStep) {
      case 'reason':
        if (direction === 'forward') {
          this.currentStep = 'amount';
        }
        break;
      case 'amount':
        if (direction === 'forward') {
          this.currentStep = 'confirmation';
        } else {
          this.currentStep = 'reason';
        }
        break;
      case 'confirmation':
        if (direction === 'backwards') {
          this.currentStep = 'amount';
        }
        break;
      default:
        break;
    }
  }

  submit() {
    this.errorMessage = '';
    if (this.form.valid) {
      this.sendingRequest = true;
      const refundValue: Currency = {
        amount: this.form.controls.amountForm.value.amount.replace(/\s/g, '').replace(',', '.'),
        currencyCode: this.paymentRequest.value.currencyCode,
      };

      const refundCreationRequest: any = {
        paymentRequestId: this.paymentRequest.id,
        value: refundValue,
        reason: this.form.controls.reasonForm.value.reason,
        notes: this.form.controls.amountForm.value.note.trim(),
      };

      this.refundService
        .create(refundCreationRequest)
        .then(() => {
          this.toastService.displaySuccess($localize`:@@createRefund.toast.refundCreated:Returen har skapats`);
          this.refundCreated.emit();
          this.sendingRequest = false;
        })
        .catch((error: HttpErrorResponse) => {
          this.handleError(error);
          this.sendingRequest = false;
        });
    } else {
      this.showError = true;
    }
  }
}
