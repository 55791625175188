import { Component, Input, OnInit } from '@angular/core';
import { CurrencyCode } from '@core/dto/CurrencyCode';
import { SimpleLineItemVM } from '../payment-management/payment-management.interfaces';

@Component({
  selector: 'app-simple-line-item-table',
  templateUrl: './simple-line-item-table.component.html',
  styleUrls: ['./simple-line-item-table.component.scss'],
})
export class SimpleLineItemTableComponent implements OnInit {
  @Input() simpleLineItemVM: SimpleLineItemVM[];
  @Input() headingText: string = '';
  @Input() helpHeadingText: string = '';
  @Input() firstColumnText: string = 'Product name';
  @Input() currency: CurrencyCode;
  constructor() {}

  ngOnInit(): void {}

  headingColorToUse(text: string): string {
    const greyTextConditions = [
      'Captured',
      'Refunded',
      'Refunded (fixed amount)',
      'Removed / Cancelled items',
      'Refund in progress',
      'Previously captured items',
    ];

    return greyTextConditions.includes(text)
      ? 'color: #6A7381'
      : 'color: #20252D';
  }

  sumOfLineItemsText(numberOfItems: number): string {
    if (numberOfItems > 1) {
      return `${numberOfItems} line items`;
    } else {
      return `${numberOfItems} line item`;
    }
  }
}
