import { Injectable } from '@angular/core';

@Injectable()
export class CalendarService {
  firstWeekDay: number;
  constructor() {
    this.firstWeekDay = 1; // 0 = sunday, 1 = monday etc
  }

  weekStartDate(date: any) {
    const startDate = new Date(date.getTime());
    while (startDate.getDay() !== this.firstWeekDay) {
      startDate.setDate(startDate.getDate() - 1);
    }
    return startDate;
  }

  monthDates(year: any, month: any, dayFormatter: any = null, weekFormatter: any = null) {
    if (typeof year !== 'number' || year < 1970) {
      throw 'year must be a number >= 1970';
    }
    if (typeof month !== 'number' || month < 0 || month > 11) {
      throw 'month must be a number (Jan is 0)';
    }
    var weeks: Array<any> = [],
      week: Array<any> = [],
      i = 0,
      date = this.weekStartDate(new Date(year, month, 1));
    do {
      for (i = 0; i < 7; i++) {
        week.push(dayFormatter ? dayFormatter(date) : date);
        date = new Date(date.getTime());
        date.setDate(date.getDate() + 1);
      }
      weeks.push(weekFormatter ? weekFormatter(week) : week);
      week = [];
    } while (date.getMonth() <= month && date.getFullYear() === year);
    return weeks;
  }

  getYears(minDate?: number, maxDate?: number) {
    const arr = [];
    const startYear = !minDate ? 1970 : minDate;
    const endYear = !maxDate ? 2050 : maxDate;
    for (let i = startYear; i <= endYear; i++) {
      arr.push(i);
    }
    return arr;
  }

  monthDays(year: any, month: any): Date[] {
    const getDay = function getDayOrNull(date: any) {
      date.setHours(12, 0, 0, 0);
      return date.getMonth() === month ? date : null;
    };
    return this.monthDates(year, month, getDay);
  }
}
