import { Component, Input, OnInit } from '@angular/core';
import { Currency, CurrencyCode } from '@core/dto/CurrencyCode';
import {
  LineItemVM,
  View,
} from '../payment-management/payment-management.interfaces';

@Component({
  selector: 'app-partial-cancel-order',
  templateUrl: './partial-cancel-order.component.html',
  styleUrls: ['./partial-cancel-order.component.scss'],
})
export class PartialCancelOrderComponent implements OnInit {
  @Input() setView: (view: View) => void;
  @Input() inProgress: boolean;
  @Input() selectedLineItemsVM: LineItemVM[];
  @Input() onUpdate: () => void;
  @Input() currency: CurrencyCode;
  visibleLineItems: LineItemVM[];
  public VIEW = View;
  itemsPerPage: number = 10;
  constructor() {}

  ngOnInit(): void {
    this.visibleLineItems = this.selectedLineItemsVM.slice(
      0,
      this.itemsPerPage
    );
  }

  getCurrency(amount: number): Currency {
    return {
      amount,
      currencyCode: this.currency,
    };
  }
}
