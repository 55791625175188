import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chevron-right',
  templateUrl: './chevron-right.component.html',
  styleUrls: ['./chevron-right.component.css']
})
export class ChevronRightComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
