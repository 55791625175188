import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'zaver-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
})
export class SearchComponent implements OnInit {
  @Input() inputModel: string;
  @Input() placeholder: string = 'Sök ...';
  @Output() inputModelChange = new EventEmitter<string>();
  @Input() size: 'small' | 'medium' | 'large' = 'medium';

  constructor() {}

  ngOnInit(): void {}

  clearText() {
    this.inputModel = '';
    this.inputModelChange.emit(this.inputModel);
  }
}
