import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedList } from '@core/dto/PaginatedList';
import { Refund, RefundApprovalResponse, RefundReason } from '@core/dto/refund/Refund';
import { Permission } from '@core/dto/user-details';
import { RestBase } from '@core/rest-base';
import { environment } from '@environments/environment';
import { captureMessage } from '@sentry/angular';
import { Observable } from 'rxjs';
import { AccessControlService } from './access-control.service';
import { AppStateService } from './app-state.service';
import { HrefService } from './href.service';
import { Currency } from '@core/dto/CurrencyCode';
import { LineItem } from '@core/dto/paymentRequest/paymentRequest';

@Injectable()
export class RefundService extends RestBase {
  private readonly httpReqOptions = { withCredentials: true };

  constructor(
    private httpClient: HttpClient,
    hrefService: HrefService,
    private appStateService: AppStateService,
    private access: AccessControlService
  ) {
    super(httpClient, hrefService.getApiUrl());
  }

  /*
  IDAU endpoint methods
  */
  public create(refund: RefundCreationRequest): Promise<Refund> {
    return super.post('/refund', JSON.stringify(refund));
  }

  public search(
    search?: string,
    status?: string,
    storeId?: string,
    page: number = 0,
    pageSize: number = 10
  ): Observable<PaginatedList<Refund>> {
    if (environment.useFixtures) {
      return this.httpClient.get<PaginatedList<Refund>>('/assets/fixtures/refunds.json'); // TODO map into something compatible
    }
    const url = `${environment.apiUrl}/refund`;

    let params = new HttpParams();
    if (search) {
      params = params.append('search', search);
    }
    if (status) {
      params = params.append('status', status);
    }
    if (storeId) {
      params = params.append('storeFilter', storeId);
    }
    if (page || page === 0) {
      params = params.append('page', page.toString());
    }
    if (pageSize) {
      params = params.append('pageSize', pageSize.toString());
    }

    return this.httpClient.get<PaginatedList<Refund>>(url, {
      ...this.httpReqOptions,
      params: params,
    });
  }

  public approve(refundId: string): Promise<RefundApprovalResponse> {
    return super.post('/refund/' + refundId + '/approve', JSON.stringify({}));
  }

  public cancel(refundId: string): Promise<Refund> {
    return super.post('/refund/' + refundId + '/cancel', JSON.stringify({}));
  }

  /*
  Internal helper methods
  */
  public getRefundReasonText(reason: RefundReason): string {
    switch (reason) {
      case RefundReason.RIGHT_OF_WITHDRAWAL:
        return $localize`:@@refundService.refundReason.RIGHT_OF_WITHDRAWAL.text:Ångerrätt`;
      case RefundReason.PRODUCT_DEFECT:
        return $localize`:@@refundService.refundReason.PRODUCT_DEFECT.text:Defekt vara`;
      case RefundReason.INCORRECTLY_EXECUTED_SERVICE:
        return $localize`:@@refundService.refundReason.INCORRECTLY_EXECUTED_SERVICE.text:Felaktigt utförd tjänst`;
      case RefundReason.OTHER:
        return $localize`:@@refundService.refundReason.OTHER.text:Annan orsak`;
      case RefundReason.NO_REASON:
        return 'N/A';
      default:
        captureMessage('Missing mapping for RefundReason: ' + reason);
        return 'N/A';
    }
  }

  public currentUserMayApproveRefund(refund: Refund): boolean {
    if (this.access.userMay(Permission.APPROVE_REFUND)) {
      if (this.access.userMay(Permission.APPROVE_OWN_REFUND)) {
        return true;
      }
      return !this.initiatedByCurrentUser(refund);
    }
    return false;
  }

  private initiatedByCurrentUser(refund: Refund) {
    if (refund) {
      return this.appStateService.getCurrentUser().activeRepresentation.id === refund.initializingRepresentativeId;
    }
    return false;
  }
}

export interface RefundCreationRequest {
  paymentRequestId: string;
  value: Currency;
  reason: RefundReason;
  notes: string;
  lineItems?: RefundableLineItemDto[];
}

export interface RefundableLineItemDto {
  lineItemId: string;
  refundAmount: number;
  refundTaxAmount: number;
  refundTaxRatePercentage: number;
  refundQuantity: number;
  unitAmount: number;
  description: string;
}
