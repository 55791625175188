<div class="cancel-order-wrapper">
  <div class="cancel-order-container">
    <p class="heading">Cancel order</p>

    <p class="text">This action cannot be undone. Are you sure you want to continue and fully cancel this order?</p>

    <div class="buttons-wrapper">
      <zaver-secondary-button-subtle
        [text]="'Go back'"
        [size]="'x-small'"
        (click)="setView(VIEW.PAYMENT_UPDATE)"
      ></zaver-secondary-button-subtle>

      <zaver-primary-button
        [text]="'Yes, continue'"
        [size]="'x-small'"
        (click)="removeAllItems()"
        [loading]="inProgress"
      ></zaver-primary-button>
    </div>
  </div>
</div>
