import { RefundStatus } from '@core/params';

export interface RefundRepresentation {
  statusText: string;
  statusColor: string;
  backgroundColor?: string;
}

export const RefundRepresentations: Map<string, RefundRepresentation> = new Map(
  [
    [
      RefundStatus.PENDING_MERCHANT_APPROVAL,
      {
        statusText: $localize`:@@refundStatus.PENDING_MERCHANT_APPROVAL:Inväntar attest`,
        statusColor: '#4466EE',
        backgroundColor: '#DBE7FF'
      },
    ],
    [
      RefundStatus.CANCELLED,
      {
        statusText: $localize`:@@refundStatus.CANCELLED:Nekad`,
        statusColor: $localize`:@@refundStatus.CANCELLED.color:#DB305E`, // DE: #DB305E
        backgroundColor: '#FDEBF0'
      },
    ],
    [
      RefundStatus.PENDING_EXECUTION,
      {
        statusText: $localize`:@@refundStatus.PENDING_EXECUTION:Godkänd`,
        statusColor: '#009773',
        backgroundColor: '#E5F5F2'
      },
    ],
    [
      RefundStatus.EXECUTED,
      {
        statusText: $localize`:@@refundStatus.EXECUTED:Godkänd`,
        statusColor: '#009773',
        backgroundColor: '#E5F5F2'
      },
    ],
    [
      RefundStatus.CANCELLED_BY_SYSTEM,
      {
        statusText: $localize`:@@refundStatus.CANCELLED_BY_SYSTEM:Makulerad`,
        statusColor: '#6A45D6',
        backgroundColor: '#F0ECFB'
      },
    ],
    [
      '_default_',
      {
        // fallback in case an unknown status is encountered
        statusText: $localize`:@@refundStatus.DEFAULT:Processas`,
        statusColor: '#393939',
      },
    ],
  ]
);
