<form [formGroup]="formGroup" novalidate>
  <div class="input-fields">
    <zaver-form-input
      [title]="'Organisationsnummer'"
      [placeholder]="'Organisationsnummer'"
      [error]="
        formGroup.controls.organizationNumber.invalid &&
        (showError || startOrganizationNumberValidation)
      "
      [errorMessage]="'Vänligen ange ett korrekt organisationsnummer'"
      formControlName="organizationNumber"
      [inputMode]="'tel'"
      [size]="'large'"
    >
    </zaver-form-input>
  </div>
  <div class="input-fields">
    <app-form-error
      *ngIf="displayCompanyNotFoundError"
      [errorText]="
        'Vi kan inte hitta ett företag med det angivna organisationsnumret.'
      "
    >
    </app-form-error>
  </div>
  <div class="input-fields" [class.hide]="!correctOrganizationNumber">
    <zaver-form-input
      [title]="'Företagets namn'"
      [placeholder]="companyNamePlaceholder"
      formControlName="name"
      [error]="formGroup.controls.name.invalid && showError"
      [errorMessage]="'Fältet får inte lämnas tomt'"
      [maxLength]="255"
      [size]="'large'"
    >
    </zaver-form-input>
  </div>
  <ng-container *ngIf="!nonOwner">
    <div class="input-fields" [class.hide]="!correctOrganizationNumber">
      <zaver-form-input
        [showContent]="true"
        [title]="'Aktieinnehav'"
        [placeholder]="'Aktieinnehav'"
        [error]="formGroup.controls.holdingShare.invalid && showError"
        [errorMessage]="'Ange aktieinnehavet'"
        formControlName="holdingShare"
        [inputMode]="'decimal'"
        [size]="'large'"
      >
        <span style="text-align: center; font-weight: 400; font-size: 14px">
          %
        </span>
      </zaver-form-input>
    </div>
    <div class="input-fields" [class.hide]="!correctOrganizationNumber">
      <zaver-form-input
        [showContent]="true"
        [title]="'Innehav av röster'"
        [placeholder]="'Innehav av röster'"
        [error]="formGroup.controls.votesShare.invalid && showError"
        [errorMessage]="'Ange innehavet av röster'"
        formControlName="votesShare"
        [inputMode]="'decimal'"
        [size]="'large'"
      >
        <span style="text-align: center; font-weight: 400; font-size: 14px">
          %
        </span>
      </zaver-form-input>
    </div>
  </ng-container>
</form>
