<div class="general-error-container" *ngIf="showError">
  <img src="assets/icons/icon_error.svg" />
  <div>
    Oops! It looks like the new amount is higher than the original amount. Let's
    make some adjustments to proceed.
  </div>
</div>

<div class="widget-header-wrapper">
  <div class="zfb-navigate-icon-wrapper">
    <ng-container *ngIf="context === WIDGETCONTEXT.ZFB">
      <button
        class="navigate-icon"
        (click)="urlNavigate ? navigateToOldUrl() : setView(VIEW.ORDER_DETAILS)"
      >
        <img src="{{ exitIconImgUrl }}" />
      </button>
    </ng-container>
  </div>

  <div style="display: flex; justify-content: space-between">
    <div class="widget-header-container">
      <h1 class="widget-header">{{ text }}</h1>
      <img *ngIf="!!imgSrc" [src]="imgSrc" />
    </div>
    <ng-container *ngIf="context === WIDGETCONTEXT.STANDALONE">
      <button class="navigate-icon" (click)="setView(VIEW.ORDER_DETAILS)">
        <img src="{{ exitIconImgUrl }}" />
      </button>
    </ng-container>
  </div>
</div>
