import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'zaver-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormInputComponent),
      multi: true,
    },
  ],
})
export class FormInputComponent implements OnInit, ControlValueAccessor {
  @Input() title: string;
  @Input() helpTitle: string;
  @Input() placeholder: string;
  @Input() error: boolean;
  @Input() errorMessage: string;
  @Input() disabled: boolean;
  @Input() value: string;
  @Input() lengthOfValue: number;
  @Input() maxLength: number;
  @Input() showCounter: boolean;
  @Input() inputMode = 'text';
  @Input() inputType = 'text';
  @Input() hideLabelsMobile = true;
  @Input() readonly: boolean;
  @Input() showContent = false;
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  showPassword = false;

  ngOnInit(): void {
    if (this.showCounter && !this.maxLength) {
      console.error(
        'If showCounter is true there must be a maxLength specified'
      );
      return;
    }
  }

  /**
   * ControlValueAccessor methods (This is boiler plate)
   */
  onChange: any = () => {}; // Populated by Angular
  onTouch: any = () => {}; // Populated by Angular
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  /**
   * Method called when the FormControl is updated from outside this component
   */
  writeValue(value: string): void {
    this.value = value;
  }

  /**
   * Write form disabled state to the DOM element (model => view)
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  toggleVisibility(): void {
    this.showPassword = !this.showPassword;
  }
}
