import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Currency, CurrencyCode } from '@core/dto/CurrencyCode';

@Pipe({ name: 'value' })
export class ValuePipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(value: Currency, locale: string): string {
    if (!value) {
      return 'null';
    }

    // For SE/NO we want to show SEK/NOK, but otherwise the symbol (€,$)
    const codeOrSymbol = (locale === 'se' || locale === 'no') ? 'code' : 'symbol';

    return this.currencyPipe.transform(
      value.amount,
      value.currencyCode,
      codeOrSymbol,
      '1.2-2',
      locale
    );
  }
}

@Pipe({ name: 'valueList' })
export class ValueListPipe implements PipeTransform {
  transform(value: Currency, locale: string): string {
    if (!value) {
      return 'null';
    }

    return `${new CurrencyPipe(locale).transform(
      value.amount,
      value.currencyCode,
      '',
      '1.2-2'
    )} <span class="list-currency">(${value.currencyCode})</span>`;
  }
}

@Pipe({ name: 'amountPipe' })
export class AmountPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(value: number, currency: CurrencyCode): string {
    const codeOrSymbol = currency === CurrencyCode.SEK ? 'code' : 'symbol';
    return this.currencyPipe.transform(
      value,
      currency,
      codeOrSymbol,
      '1.2-2',
      'de'
    );
  }
}
