<div class="language-dropdown" *ngIf="!!showDropdown">
  <!-- Language selection dropdown -->
  <div
    class="language-container"
    *ngFor="let language of languages"
    (click)="setLocale(language)"
  >
    <img src="{{ language.flagLink }}" />
    <span>{{ language.text }}</span>
  </div>
</div>
<div
  class="dropdown-background"
  (click)="closeDropdown()"
  *ngIf="!!showDropdown"
></div>
