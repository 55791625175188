import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BankIdLoginComponent } from './zfb/page/login/bankid-login/bank-id-login.component';
import { StartSignupComponent } from './zfb/page/signup/start/start-signup.component';
import { Step1Component } from './zfb/page/signup/step-1/step-1.component';
import { Step2Component } from './zfb/page/signup/step-2/step-2.component';
import { Step3Component } from './zfb/page/signup/step-3/step-3.component';
import { PendingApprovalComponent } from './zfb/page/pending-approval/pending-approval.component';
import { PaymentRequestsComponent } from './zfb/page/payment-requests/payment-requests.component';
import { UsersComponent } from './zfb/page/users/users.component';
import { CompanyAdminstrationComponent } from './zfb/page/company-adminstration/company-adminstration.component';
import { AdminZfbMerchantSettings } from './zfb/page/zfb-merchant-settings/zfb-merchant-settings';
import { UnApprovedComponent } from './zfb/page/un-approved/un-approved.component';
import { CashoutComponent } from './zfb/page/cashout/cashout.component';
import { ReportComponent } from './zfb/page/report/report.component';
import { PrecheckComponent } from './zfb/page/precheck/precheck.component';
import { SelectProfileComponent } from './zfb/page/select-profile/select-profile.component';
import { OopsComponent } from './zfb/page/oops/oops.component';
import { SignupAuthComponent } from './zfb/page/signup/auth/signup-auth.component';
import { MustBeLoggedIn } from './zfb/guard/must-be-logged-in.guard';
import { MustNotBeLoggedIn } from './zfb/guard/must-not-be-logged-in.guard';
import { MustBeSignedUpCompany } from './zfb/guard/must-be-signed-up-company.guard';
import { MustNotBeSignedUpCompany } from './zfb/guard/must-not-be-signed-up.guard';
import { MustBeApprovedCompany } from './zfb/guard/must-be-approved-company.guard';
import { MustNotBeApprovedCompany } from './zfb/guard/must-not-be-approved-company.guard';
import { MustHaveSelectedProfile } from './zfb/guard/must-have-selected-profile.guard';
import { MustNotBeUnapprovedCompany } from './zfb/guard/must-not-be-unapproved-company.guard';
import { PartnerSignupComponent } from './zfb/page/signup/partner/partner-signup.component';
import { EmailComponent } from './zfb/page/login/email/email.component';
import { PasswordEntryComponent } from './zfb/page/login/password-entry/password-entry.component';
import { PasswordResetComponent } from './zfb/page/login/password-reset/password-reset.component';
import { EmailNotFoundComponent } from './zfb/page/login/email-not-found/email-not-found.component';
import { TokenEntryComponent } from './zfb/page/login/token-entry/token-entry.component';
import { UserPendingAdminApprovalComponent } from './zfb/page/login/user-pending-admin-approval/user-pending-admin-approval.component';
import { UserPendingUserApprovalComponent } from './zfb/page/login/user-pending-user-approval/user-pending-user-approval.component';
import { UserApprovalComponent } from './zfb/page/login/user-approval/user-approval.component';
import { ZfbTokenType } from '@core/service/verification-token.service';
import { CreateUserComponent } from './zfb/page/login/create-user/create-user.component';
import { AdminComponent } from './zfb/page/admin/admin.component';
import { StoresComponent } from './zfb/page/stores/stores.component';
import { PendingChangesGuard } from './zfb/guard/pending-changes.guard';
import {
  CanViewAdminPages,
  CanViewCashoutPage,
  CanViewPrecheckPage,
  CanViewRefundsPage,
  CanViewReportsPage,
  CanViewSettingsPage,
  CanViewStoresPage,
  CanViewZfbMerchantsSettings,
} from './zfb/guard/page-guards.guard';
import { RefundsComponent } from '@zfb/page/refunds/refunds.component';
import { MerchantMarketGuard } from '@zfb/guard/merchant-market.guard';
import { PaymentManagementComponent } from '../../projects/widget/src/components/payment-management/payment-management.component';

const routes: Routes = [
  { path: '', redirectTo: '/paymentRequests', pathMatch: 'full' },
  { path: 'login', component: EmailComponent },
  { path: 'email-not-found', component: EmailNotFoundComponent },
  { path: 'password-entry', component: PasswordEntryComponent },
  { path: 'password-reset', component: PasswordResetComponent },
  {
    path: 'password-reset-token',
    component: TokenEntryComponent,
    data: {
      header: $localize`:@@router.passwordResetToken.data.header:Glömt ditt lösenord? Ingen&nbsp;fara.`,
      nextStep: ZfbTokenType.PASSWORD_RESET,
    },
  },
  {
    path: 'token-entry',
    component: TokenEntryComponent,
    data: { nextStep: ZfbTokenType.PASSWORD_RESET },
  },
  {
    path: 'token-entry-two-factor',
    component: TokenEntryComponent,
    data: {
      header: $localize`:@@router.tokenEntryTwoFactor.data.header:Tvåfaktorsautentisering`,
      nextStep: ZfbTokenType.TWO_FACTOR_AUTHENTICATION,
    },
  },
  {
    path: 'widget',
    component: PaymentManagementComponent,
  },
  {
    path: 'signup-token',
    component: TokenEntryComponent,
    data: { nextStep: ZfbTokenType.USER_CREATION },
  },
  {
    path: 'email-renew',
    component: TokenEntryComponent,
    data: { nextStep: ZfbTokenType.EMAIL_ACCESS_RENEWAL },
  },
  { path: 'create-user', component: CreateUserComponent },
  {
    path: 'identify-signatory-token',
    component: TokenEntryComponent,
    data: { nextStep: ZfbTokenType.SIGNATORY_IDENTIFICATION },
  },
  {
    path: 'pending-admin-approval',
    component: UserPendingAdminApprovalComponent,
  },
  {
    path: 'pending-user-approval',
    component: UserPendingUserApprovalComponent,
  },
  {
    path: 'handle-user-approval/:approvalToken',
    component: UserApprovalComponent,
  },
  {
    path: 'bankid-login',
    canActivateChild: [MustNotBeLoggedIn],
    children: [
      { path: '', component: BankIdLoginComponent },
      { path: ':reference', component: BankIdLoginComponent },
    ],
  },
  { path: 'bankid-login/:reference', component: BankIdLoginComponent },
  { path: 'oops', canActivate: [MustBeLoggedIn], component: OopsComponent },
  { path: 'refresh', component: OopsComponent },
  {
    path: 'selectProfile',
    canActivate: [MustBeLoggedIn],
    component: SelectProfileComponent,
  },
  {
    path: 'signup',
    children: [
      { path: '', component: StartSignupComponent },
      { path: 'partner/:signupId', component: PartnerSignupComponent },
      { path: 'auth', component: SignupAuthComponent },
      { path: 'auth/:reference', component: SignupAuthComponent },
    ],
  },
  {
    path: 'signup',
    canActivate: [MustBeLoggedIn],
    canActivateChild: [MustNotBeSignedUpCompany, MustNotBeUnapprovedCompany],
    children: [
      { path: 'step1', component: Step1Component },
      { path: 'step2', component: Step2Component },
      { path: 'step3', component: Step3Component },
      { path: 'step3/:sign', component: Step3Component },
    ],
  },
  {
    path: 'pendingApproval',
    canActivate: [MustBeLoggedIn],
    canActivateChild: [
      MustBeSignedUpCompany,
      MustNotBeApprovedCompany,
      MustNotBeUnapprovedCompany,
    ],
    children: [{ path: '', component: PendingApprovalComponent }],
  },
  {
    path: 'unApproved',
    component: UnApprovedComponent,
  },
  {
    path: '',
    canActivate: [MustBeLoggedIn],
    canActivateChild: [MustHaveSelectedProfile, MerchantMarketGuard],
    children: [
      { path: 'paymentRequests', component: PaymentRequestsComponent },
      {
        path: 'cashout',
        component: CashoutComponent,
        canActivate: [CanViewCashoutPage],
      },
      { path: 'report', redirectTo: '/admin/report', pathMatch: 'full' },
      {
        path: 'precheck',
        component: PrecheckComponent,
        canActivate: [CanViewPrecheckPage],
      },
      {
        path: 'admin',
        canActivate: [CanViewAdminPages],
        component: AdminComponent,
        children: [
          { path: 'users', component: UsersComponent },
          {
            path: 'stores',
            component: StoresComponent,
            canActivate: [CanViewStoresPage],
          },
          {
            path: 'refunds',
            component: RefundsComponent,
            canActivate: [CanViewRefundsPage],
          },
          {
            path: 'report',
            component: ReportComponent,
            canActivate: [CanViewReportsPage],
          },
          {
            path: 'company',
            component: CompanyAdminstrationComponent,
            canActivate: [CanViewSettingsPage],
            canDeactivate: [PendingChangesGuard],
          },
          {
            path: 'zfbMerchantSettings',
            component: AdminZfbMerchantSettings,
            canActivate: [CanViewZfbMerchantsSettings],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    CanViewAdminPages,
    CanViewStoresPage,
    CanViewRefundsPage,
    CanViewReportsPage,
    CanViewSettingsPage,
    CanViewCashoutPage,
    CanViewPrecheckPage,
    CanViewZfbMerchantsSettings,
    MustBeLoggedIn,
    MustNotBeLoggedIn,
    MustBeSignedUpCompany,
    MustNotBeSignedUpCompany,
    MustBeApprovedCompany,
    MustNotBeApprovedCompany,
    MustHaveSelectedProfile,
    MustNotBeUnapprovedCompany,
    MerchantMarketGuard,
  ],
})
export class CustomRouterModule {}
