import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'zaver-list-slider',
  templateUrl: './list-slider.component.html',
  styleUrls: ['./list-slider.component.css']
})
export class ListSliderComponent implements OnInit {

  @Input() listOptions : ListOption[];
  @Input() initialValue: ListOption;
  @Input() color: string = '#DB305E';
  @Input() ariaLabel: string;

  min: number;
  max: number;

  initialValueIndex;
  presentedValue;
  setupReady;

  val;
  
  @Input() value : any;
  @Output() valueChange = new EventEmitter();

  @Output() completedChange = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    if (!this.listOptions) {
      console.error('List options required as component input');
      return;
    }

    this.min = 0;
    this.max = this.listOptions.length - 1;
    if (!!this.value || this.value === 0) {
      this.initialValueIndex = this.listOptions.indexOf(this.listOptions.find(el => el.value === this.value));
    } else if (!!this.initialValue) {
      this.initialValueIndex = this.listOptions.indexOf(this.initialValue);
      if (this.initialValueIndex === -1) {
        this.initialValueIndex = 0;
      }
    }

    this.setupReady = true;
    
  }

  onValueChange() {
    this.presentedValue = this.listOptions[this.val].label;
    //console.log('Emitting changed value: ', this.listOptions[this.val].value);
    this.valueChange.emit(this.listOptions[this.val].value);
  }

  onFinishedChange() {
    this.completedChange.emit()
  }

}

export class ListOption {
  label: string;
  value: any;
}
