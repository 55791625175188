import { Component, OnInit, Input } from '@angular/core';
import { WsService, SubTopic, WebsocketListener } from '@core/service/ws.service';
import { CompanyStatus, UserDetails } from '@core/dto/user-details';
import { BannerService } from '@core/service/banner.service'

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit, WebsocketListener<UpdateBannerMessage> {

    @Input() set user (value : UserDetails) {
      this.userDetails = value;
      this.updateMayViewBanner();
      this.checkIfActiveBanner();
    }

    mayViewBanner : boolean;

    userDetails: UserDetails;
    activeBanner = false;
    bannerMessage: string = '';

  constructor(
    private wsService: WsService,
    private bannerService: BannerService
  ) {
    this.wsService.registerTopicListener(SubTopic.SetBanner, this);
  }

  handleMessage(wsMessage: UpdateBannerMessage): void {
    if (this.mayViewBanner) {
      this.activeBanner = wsMessage.displayBanner;
      this.bannerMessage = wsMessage.message;
    }

  }

  ngOnInit() {
      this.updateMayViewBanner()
      this.checkIfActiveBanner()
  }

  checkIfActiveBanner() {
    if (this.mayViewBanner) {
      this.bannerService.getActiveBanner().subscribe(res =>
        {
          this.activeBanner = res.body.displayBanner;
          this.bannerMessage = res.body.message;
        });
    }
  }

  updateMayViewBanner() {
    if (!!this.userDetails) {
      if (!!this.userDetails.activeRepresentation) {
        if (this.userDetails.activeRepresentation.company.status.indexOf(CompanyStatus.APPROVED) !== -1) {
          this.mayViewBanner = true;
          return true;
        }
      }
    }
    this.mayViewBanner = false;
    return false;
  }
}

export interface UpdateBannerMessage {
    displayBanner: boolean;
    message: string;
}
