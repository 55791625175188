import { PaymentRequestEventType } from '@core/constants/PaymentRequestEventType';
import { ValuePipe } from '@shared/pipe/value.pipe';
import { Currency } from './CurrencyCode';
import { RefundReason } from './refund/Refund';

export class PaymentRequestEvent {
    dateDisplay: number; // Used to group the events under the same day in list
    created: number;
    type: PaymentRequestEventType;
    messageArguments: EventMessageArguments;
    message: string;
    note: string;

    static setup(event: PaymentRequestEvent) {
        event.dateDisplay  = new Date(event.created).setHours(0, 0, 0, 0);
        if (!!event.messageArguments && !!event.messageArguments.refundValue) {
            const newRefundValue: Currency = {
                amount: -event.messageArguments.refundValue.amount,
                currencyCode: event.messageArguments.refundValue.currencyCode
            };
            event.messageArguments.refundValue = newRefundValue;
        }
        return event;
    }
}

export interface EventMessageArguments {
    representativeName: string;
    representativeEmail: string;
    storeName: string;
    refundValue: Currency;
    refundReason: RefundReason;
}



