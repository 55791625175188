import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'zaver-zfb-radio-button',
  templateUrl: './zfb-radio-button.component.html',
  styleUrls: ['./zfb-radio-button.component.scss'],
})
export class ZfbRadioButtonComponent implements OnInit {
  @Input() selected: boolean;
  @Input() color: string;
  @Input() disabled = false;
  @Input() text: string;

  constructor() {}

  ngOnInit(): void {}
}
