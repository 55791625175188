import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { BaseComponent } from '@core/base.component';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '@core/service/authentication.service';

import {
  ReportRecipientService,
  ReportRecipientDto,
  transformFormObjectToReportRecipientDto,
} from '@core/service/report-recipient.service';
import { FilePreference } from '@zfb/page/report/accounting-report/accounting-report.component';
import { EMAIL_PATTERN } from '@core/patterns';
import { AppStateService } from '@core/service/app-state.service';
import { ColorService } from '@core/service/color.service';
import { Market } from '@core/dto/Market';
import { LocaleService } from '@core/service/locale.service';

enum ModalView {
  SUBMIT = 'SUBMIT',
  CANCEL = 'CANCEL',
  REMOVE_EMAIL_ADDRESSES = 'REMOVE_EMAIL_ADDRESSES',
}

enum EmailType {
  CASHOUT = 'CASHOUT',
  CHECKOUT = 'CHECKOUT',
  REFUND = 'REFUND',
}

export interface FormObject {
  name: string;
  title: string;
  formArray: FormArray;
  type: EmailType;
  id?: string;
  fileFormatText?: string;
  showFileFormatText?: boolean;
}

export const emailValidators: ValidatorFn[] = [
  Validators.required,
  Validators.pattern(EMAIL_PATTERN),
];

@Component({
  selector: 'app-report-recipient',
  templateUrl: './report-recipient.component.html',
  styleUrls: [
    '../../../signup/signup-shared.css',
    './report-recipient.component.scss',
  ],
})
export class ReportRecipientComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Output() doneFetchingResponse = new EventEmitter();

  emailForm: FormGroup;
  allEmailForms: FormObject[];

  hasCashoutEnabled: boolean;
  hasRefundEnabled: boolean;

  fetchedRefundEmailAddresses: ReportRecipientDto;
  refundForm: FormObject;
  refundFormChanged = false;

  fetchedCheckoutEmailRecipients: ReportRecipientDto[];
  checkoutForms: FormObject[];
  checkoutFormsChanged = false;

  fetchedCashoutEmailAddresses: ReportRecipientDto;
  cashoutForm: FormObject;
  cashoutFormChanged = false;

  formChangedSubscription: Subscription[];

  modalColor = '';
  ModalView = ModalView;
  modalView: ModalView;
  modalTitle = '';
  formErrorText = 'Vänligen kontrollera dina svar.';
  currentForm: FormObject = null;
  isDeAtMarket: boolean = false;

  loading = true;
  modalActive = false;
  submitting = false;
  showError = false;

  constructor(
    private appState: AppStateService,
    protected auth: AuthenticationService,
    private reportRecipientService: ReportRecipientService,
    public colorService: ColorService,
    public localeService: LocaleService
  ) {
    super(auth);
  }

  ngOnInit(): void {
    this.hasCashoutEnabled = this.appState.merchantHasCashoutAccess();
    this.hasRefundEnabled = this.appState.merchantHasRefundAccess();
    this.fetchEmailAddresses();
    this.isDeAtMarket =
      this.localeService.getCurrentLocaleMarket() === Market.DE ||
      this.localeService.getCurrentLocaleMarket() === Market.AT;
  }

  ngOnDestroy(): void {
    if (
      this.formChangedSubscription &&
      this.formChangedSubscription.length > 0
    ) {
      this.formChangedSubscription.map((form) => form.unsubscribe);
    }
  }

  async fetchEmailAddresses(): Promise<void> {
    try {
      if (this.hasRefundEnabled) {
        this.fetchedRefundEmailAddresses =
          await this.reportRecipientService.getRefundEmailRecipients();
      }

      this.fetchedCheckoutEmailRecipients =
        await this.reportRecipientService.getCheckoutReportRecipients();

      if (this.hasCashoutEnabled) {
        this.fetchedCashoutEmailAddresses =
          await this.reportRecipientService.getCashoutReportRecipients();
      }
      this.setupForms();

      this.doneFetchingResponse.emit();
    } catch (error: unknown) {
      this.handleError(error);
    }
  }

  async postEmailAddresses(): Promise<void> {
    try {
      this.submitting = true;
      if (this.refundFormChanged && this.hasRefundEnabled) {
        this.fetchedRefundEmailAddresses =
          await this.reportRecipientService.updateRefundEmailRecipients(
            transformFormObjectToReportRecipientDto(this.refundForm)
          );
      }
      if (this.checkoutFormsChanged) {
        const checkoutReportRecipients: ReportRecipientDto[] =
          this.checkoutForms.map((checkoutForm: FormObject) =>
            transformFormObjectToReportRecipientDto(checkoutForm)
          );

        this.fetchedCheckoutEmailRecipients =
          await this.reportRecipientService.updateCheckoutReportRecipients(
            checkoutReportRecipients
          );
      }
      if (this.cashoutFormChanged && this.hasCashoutEnabled) {
        this.fetchedCashoutEmailAddresses =
          await this.reportRecipientService.updateCashoutReportRecipients(
            transformFormObjectToReportRecipientDto(this.cashoutForm)
          );
      }
      this.setupForms();
      this.closeModal();
    } catch (error: unknown) {
      this.submitting = false;
      this.handleError(error);
    }
  }

  equals = (item1: any, item2: any): boolean =>
    JSON.stringify(item1) === JSON.stringify(item2);

  handleFormSubscription(): void {
    if (
      this.formChangedSubscription &&
      this.formChangedSubscription.length > 0
    ) {
      this.formChangedSubscription.map((form) => form.unsubscribe);
    }

    // dont include if falsy
    this.allEmailForms = [
      this.refundForm,
      ...this.checkoutForms,
      this.cashoutForm,
    ].filter((item) => !!item);

    this.formChangedSubscription = this.allEmailForms.map((form: FormObject) =>
      form.formArray.valueChanges.subscribe(() => {
        form.formArray.controls.map((formControl) => {
          !formControl.value
            ? formControl.setValidators(null)
            : formControl.setValidators(emailValidators);
        });
        const fetchedCheckoutEmailAdresses =
          this.fetchedCheckoutEmailRecipients.map(
            (checkoutRecipient: ReportRecipientDto) =>
              checkoutRecipient.reportRecipients
          );

        const currentCheckoutEmailAddresses: string[] = this.checkoutForms.map(
          (formObject: FormObject) => formObject.formArray.value
        );

        if (this.hasRefundEnabled) {
          this.refundFormChanged = !this.equals(
            this.fetchedRefundEmailAddresses.reportRecipients,
            this.refundForm.formArray.value
          );
        }

        this.checkoutFormsChanged = !this.equals(
          fetchedCheckoutEmailAdresses,
          currentCheckoutEmailAddresses
        );

        if (this.hasCashoutEnabled) {
          this.cashoutFormChanged = !this.equals(
            this.fetchedCashoutEmailAddresses.reportRecipients,
            this.cashoutForm.formArray.value
          );
        }
      })
    );
  }

  createEmailFormArray = (emailAddresses: string[]): FormArray => {
    if (emailAddresses.length > 0) {
      return new FormArray(
        emailAddresses.map((emailAddress) => new FormControl(emailAddress))
      );
    } else {
      return new FormArray([new FormControl('')]);
    }
  };

  fileFormatText = (fileFormat: FilePreference): string =>
    fileFormat === FilePreference.EXCEL_XLSX ? 'XLSX' : 'CSV';

  setUpRefundForm(): void {
    if (this.hasRefundEnabled) {
      this.refundForm = {
        name: 'refundEmailFormArray',
        formArray: this.createEmailFormArray(
          this.fetchedRefundEmailAddresses.reportRecipients
        ),
        type: EmailType.REFUND,
        title: 'hantering av returer',
      };

      this.emailForm.addControl(
        this.refundForm.name,
        this.refundForm.formArray
      );
      this.refundFormChanged = false;
    }
  }

  setUpCheckoutForm(): void {
    this.checkoutForms = this.fetchedCheckoutEmailRecipients.map(
      (checkoutRecipient, i) => ({
        name: `checkoutEmailFormArray${i + 1}`,
        formArray: this.createEmailFormArray(
          checkoutRecipient.reportRecipients
        ),
        title: 'återredovisningsrapporter (checkout)',
        type: EmailType.CHECKOUT,
        fileFormatText: this.fileFormatText(checkoutRecipient.fileFormat),
        id: checkoutRecipient.id,
        showFileFormatText: this.fetchedCheckoutEmailRecipients.length > 1,
      })
    );

    if (this.fetchedCheckoutEmailRecipients.length === 0) {
      this.checkoutForms = [
        {
          name: `checkoutEmailFormArray`,
          formArray: this.createEmailFormArray([]),
          title: 'återredovisningsrapporter (checkout)',
          type: EmailType.CHECKOUT,
          fileFormatText: this.fileFormatText(FilePreference.EXCEL_XLSX),
        },
      ];
    }

    this.checkoutForms.map((item) =>
      this.emailForm.addControl(item.name, item.formArray)
    );

    this.checkoutFormsChanged = false;
  }

  setUpCashoutForm(): void {
    if (this.hasCashoutEnabled) {
      this.cashoutForm = {
        name: 'cashoutEmailFormArray',
        formArray: this.createEmailFormArray(
          this.fetchedCashoutEmailAddresses.reportRecipients
        ),
        type: EmailType.CASHOUT,
        title: 'återredovisningsrapporter (cashout)',
      };

      this.emailForm.addControl(
        this.cashoutForm.name,
        this.cashoutForm.formArray
      );
      this.cashoutFormChanged = false;
    }
  }

  setupForms(): void {
    this.emailForm = new FormGroup({});
    this.setUpRefundForm();
    this.setUpCheckoutForm();
    this.setUpCashoutForm();
    this.handleFormSubscription();
    this.loading = false;
  }

  onSave(): void {
    this.clearError();
    if (this.emailForm.valid) {
      this.modalTitle = 'Uppdatera e-postinställningar';
      this.modalView = ModalView.SUBMIT;
      this.modalColor = '#00A880';
      this.modalActive = true;
      this.showError = false;
    } else {
      this.showError = true;
    }
  }

  submit(): void {
    this.postEmailAddresses();
  }

  promptCancelModal(): void {
    this.modalTitle = 'Avbryt';
    this.modalView = ModalView.CANCEL;
    this.modalColor = this.colorService.getCeriseColorCode();
    this.modalActive = true;
  }

  cancel(): void {
    this.showError = false;
    this.closeModal();
    this.setupForms();
  }

  closeModal(): void {
    this.submitting = false;
    this.modalActive = false;
  }

  removeEmailForm(form: FormObject, index: number): void {
    form.formArray.removeAt(index);
  }

  addEmailForm(form: FormObject): void {
    form.formArray.push(new FormControl(''));
  }

  removeAllEmailAdressesPrompt(form: FormObject): void {
    this.currentForm = form;
    this.modalTitle = 'Ta bort';
    this.modalView = ModalView.REMOVE_EMAIL_ADDRESSES;
    this.modalColor = this.colorService.getCeriseColorCode();
    this.modalActive = true;
  }

  removeAllEmailAdresses(): void {
    this.currentForm.formArray.clear();
    this.currentForm.formArray.push(new FormControl(''));
    this.modalActive = false;
  }
}
