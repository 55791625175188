import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CurrencyCode } from '@core/dto/CurrencyCode';
import { DropdownListItemv2 } from '../../../../../web-component-library/projects/component-library/src/lib/dropdownv2/dropdownv2.component';
import { View } from '../payment-management/payment-management.interfaces';
import { RefundableLineItem } from '../../services/payment-management.service';

@Component({
  selector: 'app-refund-line-item-table',
  templateUrl: './refund-line-item-table.component.html',
  styleUrls: ['./refund-line-item-table.component.scss'],
})
export class RefundLineItemTableComponent implements OnInit {
  constructor() {}
  @Output() setTaxRatePrecent = new EventEmitter<any>();
  @Output() setAllChecked = new EventEmitter<boolean>();
  @Output() changeQuantity = new EventEmitter<any>();
  @Output() setEditMode = new EventEmitter<RefundableLineItem>();
  @Output() removeLineItem = new EventEmitter<RefundableLineItem>();

  @Input() lineItemsVM: RefundableLineItem[];
  @Input() allChecked: boolean;
  @Input() currentView: View;
  @Input() currency: CurrencyCode;

  public VIEW = View;

  ngOnInit(): void {}

  isMinQuantity(val: number, min: number): boolean {
    return Number(min) >= Number(val);
  }

  isMaxQuantity(val: number, max: number): boolean {
    return Number(max) <= Number(val);
  }

  onChangeQuantity(value: string, lineItemVM: RefundableLineItem): void {
    this.changeQuantity.emit({ value, lineItemVM });
  }

  onSetAllChecked(checked: boolean): void {
    this.allChecked = checked;
    this.setAllChecked.emit(checked);
  }

  onSelectTaxRate(value: string, lineItemVM: RefundableLineItem): void {
    this.setTaxRatePrecent.emit({ value, lineItemVM });
  }

  isEditable(editMode: boolean): boolean {
    return editMode && this.currentView !== View.PAYMENT_UPDATE;
  }

  onSetEdit(lineItemVM: RefundableLineItem): void {
    this.setEditMode.emit(lineItemVM);
  }

  onRemoveLineItem(lineItemVM: RefundableLineItem): void {
    this.removeLineItem.emit(lineItemVM);
  }

  taxRateOptions: DropdownListItemv2[] = [
    {
      label: '19',
      value: 19,
    },
    {
      label: '7',
      value: 7,
    },
    {
      label: '0',
      value: 0,
    },
  ];

  findOption(option: DropdownListItemv2[], value: any): DropdownListItemv2 {
    return option.find((item) => item.value === value);
  }
}
