import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ColorService } from '@core/service/color.service';
import { VerificationTokenService } from '@core/service/verification-token.service';

@Component({
  selector: 'app-user-pending-user-approval',
  templateUrl: './user-pending-user-approval.component.html',
  styleUrls: [
    '../login-pages.scss',
    './user-pending-user-approval.component.css',
  ],
})
export class UserPendingUserApprovalComponent implements OnInit {
  email: string;

  constructor(
    private router: Router,
    private tokenService: VerificationTokenService,
    public colorService: ColorService
  ) {}

  ngOnInit() {
    this.email = this.tokenService.email;
  }

  backToLogin() {
    this.router.navigate(['/login']);
  }
}
