<div class="comp-container">
  <div
    class="list-container"
    [class.grey-text]="precheck.status === PrecheckStatus.CANCELLED"
  >
    <div class="list-row" *ngIf="precheck.personName">
      <div class="icon-and-text">
        <!-- User icon -->
        <!-- prettier-ignore -->
        <svg class="icons" [attr.fill]="iconColor()" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.57085 4.83464C8.74555 3.65994 10.3388 3 12.0001 3C13.6614 3 15.2546 3.65994 16.4293 4.83464C17.604 6.00935 18.2639 7.60259 18.2639 9.26387C18.2639 10.9251 17.604 12.5184 16.4293 13.6931C15.2546 14.8678 13.6614 15.5277 12.0001 15.5277C10.3388 15.5277 8.74555 14.8678 7.57085 13.6931C6.39615 12.5184 5.73621 10.9251 5.73621 9.26387C5.73621 7.60259 6.39615 6.00935 7.57085 4.83464ZM12.0001 4.56597C10.7541 4.56597 9.55918 5.06092 8.67815 5.94195C7.79713 6.82298 7.30217 8.0179 7.30217 9.26387C7.30217 10.5098 7.79713 11.7048 8.67815 12.5858C9.55918 13.4668 10.7541 13.9618 12.0001 13.9618C13.246 13.9618 14.441 13.4668 15.322 12.5858C16.203 11.7048 16.698 10.5098 16.698 9.26387C16.698 8.0179 16.203 6.82298 15.322 5.94195C14.441 5.06092 13.246 4.56597 12.0001 4.56597Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.79087 17.9342C9.12478 17.3792 10.5553 17.0935 12 17.0935C13.4447 17.0935 14.8752 17.3792 16.2091 17.9342C17.543 18.4891 18.754 19.3024 19.7724 20.3272C20.0772 20.6339 20.0757 21.1297 19.7689 21.4345C19.4622 21.7393 18.9664 21.7378 18.6616 21.431C17.7888 20.5527 16.7509 19.8557 15.6076 19.38C14.4643 18.9043 13.2383 18.6595 12 18.6595C10.7617 18.6595 9.53568 18.9043 8.39241 19.38C7.24914 19.8557 6.21121 20.5527 5.33837 21.431C5.03355 21.7378 4.5378 21.7393 4.23107 21.4345C3.92434 21.1297 3.92278 20.6339 4.2276 20.3272C5.24599 19.3024 6.45697 18.4891 7.79087 17.9342Z"/>
          </svg>
        <span class="medium2">Kunde</span>
      </div>
      <div>
        {{ precheck.personName }}
      </div>
    </div>
    <div class="list-row">
      <div class="icon-and-text">
        <!-- Phone icon -->
        <!-- prettier-ignore -->
        <svg class="icons" [attr.fill]="iconColor()" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4.50098 3.75C4.50098 1.67893 6.17991 0 8.25098 0H15.751C17.822 0 19.501 1.67893 19.501 3.75V20.25C19.501 22.3211 17.822 24 15.751 24H8.25098C6.17991 24 4.50098 22.3211 4.50098 20.25V3.75ZM8.25098 1.5C7.00834 1.5 6.00098 2.50736 6.00098 3.75V20.25C6.00098 21.4926 7.00834 22.5 8.25098 22.5H15.751C16.9936 22.5 18.001 21.4926 18.001 20.25V3.75C18.001 2.50736 16.9936 1.5 15.751 1.5H8.25098Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4.50098 18.75C4.50098 18.3358 4.83676 18 5.25098 18H18.751C19.1652 18 19.501 18.3358 19.501 18.75C19.501 19.1642 19.1652 19.5 18.751 19.5H5.25098C4.83676 19.5 4.50098 19.1642 4.50098 18.75Z"/>
        </svg>
        <span class="medium2">Handynummer</span>
      </div>
      <div>
        <a href="tel:{{ precheck.phone }}" class="link">{{ precheck.phone }}</a>
      </div>
    </div>
    <div class="list-row">
      <div class="icon-and-text">
        <!-- Calendar icon -->
        <!-- prettier-ignore -->
        <svg class="icons" [attr.fill]="iconColor()" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.25 24C1.009 24 0 22.991 0 21.75V5.25C0 4.009 1.009 3 2.25 3H6V0.75C6 0.336 6.336 0 6.75 0C7.164 0 7.5 0.336 7.5 0.75V3H16.5V0.75C16.5 0.336 16.836 0 17.25 0C17.664 0 18 0.336 18 0.75V3H21.75C22.991 3 24 4.009 24 5.25V21.75C24 22.991 22.991 24 21.75 24H2.25ZM1.5 21.75C1.5 22.164 1.836 22.5 2.25 22.5H21.75C22.164 22.5 22.5 22.164 22.5 21.75V10.5H1.5V21.75ZM22.5 9V5.25C22.5 4.836 22.164 4.5 21.75 4.5H18V6C18 6.414 17.664 6.75 17.25 6.75C16.836 6.75 16.5 6.414 16.5 6V4.5H7.5V6C7.5 6.414 7.164 6.75 6.75 6.75C6.336 6.75 6 6.414 6 6V4.5H2.25C1.836 4.5 1.5 4.836 1.5 5.25V9H22.5Z"/>
          <path d="M5.625 15C6.24632 15 6.75 14.4963 6.75 13.875C6.75 13.2537 6.24632 12.75 5.625 12.75C5.00368 12.75 4.5 13.2537 4.5 13.875C4.5 14.4963 5.00368 15 5.625 15Z"/>
          <path d="M5.625 20.25C6.24632 20.25 6.75 19.7463 6.75 19.125C6.75 18.5037 6.24632 18 5.625 18C5.00368 18 4.5 18.5037 4.5 19.125C4.5 19.7463 5.00368 20.25 5.625 20.25Z"/>
          <path d="M12 15C12.6213 15 13.125 14.4963 13.125 13.875C13.125 13.2537 12.6213 12.75 12 12.75C11.3787 12.75 10.875 13.2537 10.875 13.875C10.875 14.4963 11.3787 15 12 15Z"/>
          <path d="M12 20.25C12.6213 20.25 13.125 19.7463 13.125 19.125C13.125 18.5037 12.6213 18 12 18C11.3787 18 10.875 18.5037 10.875 19.125C10.875 19.7463 11.3787 20.25 12 20.25Z"/>
          <path d="M18.375 15C18.9963 15 19.5 14.4963 19.5 13.875C19.5 13.2537 18.9963 12.75 18.375 12.75C17.7537 12.75 17.25 13.2537 17.25 13.875C17.25 14.4963 17.7537 15 18.375 15Z"/>
          <path d="M18.375 20.25C17.755 20.25 17.25 19.745 17.25 19.125C17.25 18.505 17.755 18 18.375 18C18.389 18 18.405 18.001 18.421 18.003C18.431 18.004 18.44 18.005 18.448 18.006C19.031 18.035 19.5 18.527 19.5 19.126C19.5 19.745 18.995 20.25 18.375 20.25Z"/>
          </svg>
        <span class="medium2">Erstellt</span>
      </div>
      <div>
        {{ precheck.created | date }}
      </div>
    </div>
    <div class="list-row" *ngIf="precheck.email">
      <div class="icon-and-text">
        <!-- Calendar icon -->
        <!-- prettier-ignore -->
        <svg class="icons" xmlns="http://www.w3.org/2000/svg" width="24" height="17" viewBox="0 0 24 17" fill="none">
          <path d="M3 16.5C1.759 16.5 0.75 15.491 0.75 14.25V2.25C0.75 1.746 0.914 1.269 1.226 0.87C1.233 0.859 1.241 0.848 1.248 0.839C1.254 0.831 1.263 0.821 1.271 0.812C1.696 0.298 2.327 0 3 0H21C21.671 0 22.301 0.297 22.731 0.814C22.741 0.824 22.75 0.835 22.757 0.845C22.763 0.852 22.769 0.862 22.775 0.871C23.086 1.27 23.25 1.746 23.25 2.25V14.25C23.25 15.491 22.241 16.5 21 16.5H3ZM2.25 14.25C2.25 14.664 2.586 15 3 15H21C21.414 15 21.75 14.664 21.75 14.25V2.562L14.474 8.158C13.77 8.7 12.891 8.999 12 8.999C11.109 8.999 10.23 8.7 9.526 8.158L2.25 2.562V14.25ZM10.44 6.969C10.884 7.311 11.438 7.499 12 7.499C12.562 7.499 13.115 7.311 13.559 6.969L20.67 1.499H3.329L10.44 6.969Z" fill="#0A1018"/>
          </svg>
        <span class="medium2">E-mail Adresse</span>
      </div>
      <div>
        {{ precheck.email }}
      </div>
    </div>

    <div class="list-row" *ngIf="showAmount()">
      <div class="icon-and-text">
        <!-- Money-wallet icon -->
        <!-- prettier-ignore -->
        <svg class="icons" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" [attr.fill]="iconColor()">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75003 7.22002C3.14534 7.22002 2.56541 7.46023 2.13783 7.88782C1.71024 8.3154 1.47003 8.89533 1.47003 9.50002V20C1.47003 20.6047 1.71024 21.1846 2.13783 21.6122C2.56541 22.0398 3.14534 22.28 3.75003 22.28H18.75C19.3547 22.28 19.9346 22.0398 20.3622 21.6122C20.7898 21.1846 21.03 20.6047 21.03 20V18.5C21.03 18.1024 21.3524 17.78 21.75 17.78C22.1477 17.78 22.47 18.1024 22.47 18.5V20C22.47 20.9866 22.0781 21.9328 21.3805 22.6305C20.6828 23.3281 19.7366 23.72 18.75 23.72H3.75003C2.76342 23.72 1.81723 23.3281 1.11959 22.6305C0.421957 21.9328 0.0300293 20.9866 0.0300293 20V9.50002C0.0300293 8.51341 0.421957 7.56722 1.11959 6.86958C1.81723 6.17195 2.76342 5.78002 3.75003 5.78002H18.75C19.7366 5.78002 20.6828 6.17195 21.3805 6.86958C22.0781 7.56722 22.47 8.51342 22.47 9.50002V11C22.47 11.3977 22.1477 11.72 21.75 11.72C21.3524 11.72 21.03 11.3977 21.03 11V9.50002C21.03 8.89533 20.7898 8.3154 20.3622 7.88782C19.9346 7.46023 19.3547 7.22002 18.75 7.22002H3.75003Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9215 0.298087C15.2124 0.277374 15.5046 0.314181 15.7812 0.406404C16.0579 0.498627 16.3137 0.644459 16.534 0.835568C16.7542 1.02663 16.9347 1.25922 17.065 1.52003C17.065 1.52008 17.065 1.51997 17.065 1.52003L19.394 6.17803C19.5718 6.53369 19.4277 6.96617 19.072 7.14401C18.7164 7.32184 18.2839 7.17768 18.106 6.82201L15.777 2.16401C15.7312 2.07235 15.6677 1.99044 15.5903 1.9233C15.5129 1.85615 15.4231 1.80491 15.3259 1.77251C15.2287 1.74011 15.126 1.72717 15.0238 1.73445C14.9216 1.74173 14.8218 1.76907 14.7302 1.81492L4.07202 7.14401C3.71636 7.32184 3.28387 7.17768 3.10604 6.82201C2.92821 6.46635 3.07237 6.03386 3.42804 5.85603L14.0859 0.527118C14.3467 0.396625 14.6306 0.318799 14.9215 0.298087Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8393 11.5893C15.6775 10.751 16.8145 10.28 18 10.28H21.75C22.3388 10.28 22.9035 10.5139 23.3198 10.9302C23.7361 11.3466 23.97 11.9112 23.97 12.5V17C23.97 17.5888 23.7361 18.1535 23.3198 18.5698C22.9035 18.9861 22.3388 19.22 21.75 19.22H18C16.8145 19.22 15.6775 18.7491 14.8393 17.9108C14.001 17.0725 13.53 15.9355 13.53 14.75C13.53 13.5645 14.001 12.4275 14.8393 11.5893ZM18 11.72C17.1964 11.72 16.4257 12.0393 15.8575 12.6075C15.2893 13.1757 14.97 13.9464 14.97 14.75C14.97 15.5536 15.2893 16.3243 15.8575 16.8926C16.4257 17.4608 17.1964 17.78 18 17.78H21.75C21.9569 17.78 22.1553 17.6978 22.3016 17.5516C22.4478 17.4053 22.53 17.2069 22.53 17V12.5C22.53 12.2932 22.4478 12.0948 22.3016 11.9485C22.1553 11.8022 21.9569 11.72 21.75 11.72L18 11.72Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18 13.655C17.7096 13.655 17.4311 13.7704 17.2257 13.9757C17.0204 14.1811 16.905 14.4596 16.905 14.75C16.905 14.9666 16.9692 15.1783 17.0896 15.3584C17.2099 15.5384 17.3809 15.6788 17.581 15.7617C17.7811 15.8445 18.0012 15.8662 18.2137 15.824C18.4261 15.7817 18.6212 15.6774 18.7743 15.5243C18.9275 15.3712 19.0317 15.1761 19.074 14.9636C19.1162 14.7512 19.0946 14.5311 19.0117 14.331C18.9288 14.1309 18.7885 13.9599 18.6084 13.8396C18.4283 13.7192 18.2166 13.655 18 13.655Z"/>
          </svg>
        <span class="medium2">Betrag</span>
      </div>
      <div>
        {{ precheck.value | value: localeService.getCurrentLocale() }}
      </div>
    </div>
  </div>
  <ng-container *ngIf="precheck.status === PrecheckStatus.PENDING">
    <div class="info-container">
      <img src="assets/icons/Icon-Info.svg" alt="Info circle" />
      <span
        >Sobald der Empfänger des Links den Kreditprüfungsprozess abgeschlossen
        hat, wird das Ergebnis hier angezeigt.</span
      >
    </div>
    <div class="button-wrapper">
      <zaver-primary-button
        style="max-width: 324px"
        [text]="'SMS erneut senden'"
        (click)="resend()"
      ></zaver-primary-button>
    </div>
  </ng-container>
  <ng-container *ngIf="precheck.status === PrecheckStatus.GRANTED">
    <div class="approved-heading">Genehmigte Zahlungsmethoden:</div>
    <div class="result-container">
      <div class="list-container">
        <div class="list-row">
          <div class="icon-and-text">
            <img class="icons" src="assets/icons/Icon-Invoice.svg" alt="" />
            <span class="medium2">Rechnung 14 Tage</span>
          </div>
          <img src="assets/icons/Icon-Checkmark-Green.svg" alt="" />
        </div>
        <div class="approved-amount">
          <span>{{
            precheck.value | value: localeService.getCurrentLocale()
          }}</span>
        </div>
      </div>
    </div>
    <div class="result-container">
      <div class="list-container">
        <div class="list-row">
          <div class="icon-and-text">
            <img class="icons" src="assets/icons/Icon-Invoice.svg" alt="" />
            <span class="medium2">Ratenzahlung</span>
          </div>
          <img
            *ngIf="precheck.summaryEntries.length > 0"
            src="assets/icons/Icon-Checkmark-Green.svg"
            alt=""
          />
          <img
            *ngIf="precheck.summaryEntries.length == 0"
            src="assets/icons/Icon-Cross.svg"
            alt=""
          />
        </div>
        <div
          *ngFor="let item of precheck.summaryEntries; let last = last"
          [ngClass]="{ 'no-border': last }"
          class="list-row"
        >
          <div>
            {{ item.monthlyCost }} € mtl. für {{ item.numberOfMonths }} Mon
          </div>
          <div>{{ item.nominalInterest }} % Zinssatz</div>
        </div>
        <div
          *ngIf="precheck.summaryEntries.length == 0"
          class="installments-items-wrapper"
        >
          <div class="approved-amount">
            <span>{{
              precheck.value | value: localeService.getCurrentLocale()
            }}</span>
          </div>
          <div class="denied-text">
            <span
              >Der gewählte Betrag ist zu niedrig für diese
              Zahlungsmethode.</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="info-text">
      Bitte beachten Sie, dass das Ergebnis nicht verbindlich ist und sich zum
      Zeitpunkt des Kaufs noch ändern kann.
    </div>
  </ng-container>
  <ng-container *ngIf="precheck.status === PrecheckStatus.DENIED">
    <div class="result-container denied">
      <div class="denied-amount">
        <img src="assets/icons/Icon-Cross-Cerise.svg" alt="" />
        <span>{{
          precheck.value | value: localeService.getCurrentLocale()
        }}</span>
      </div>
      <div class="denied-text">
        Leider sieht es derzeit nicht so aus, als würde der Betrag gewährt
        werden. Senden Sie einen neuen Link, falls der Kunde einen niedrigeren
        Betrag ausprobieren möchte.
      </div>
    </div>
  </ng-container>
</div>
