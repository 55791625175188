import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {CashoutBalanceResponseDto} from '@core/dto/cashout/incoming/CashoutBalanceResponseDto';
import {environment} from '@environments/environment';
import { WebsocketListener, WsService, SubTopic } from './ws.service';

@Injectable({
    providedIn: 'root'
})
export class CashoutBalanceService implements WebsocketListener<CashoutBalanceResponseDto> {
    private listeners: CashoutBalanceListener[] = [];

    constructor(private http: HttpClient, private wsService: WsService) {
        wsService.registerTopicListener(SubTopic.CashoutBalance, this);
    }

    public getBalance(): Observable<CashoutBalanceResponseDto> {
        return this.http.get<CashoutBalanceResponseDto>(
            `${environment.apiUrl}/cashout/balance`,
            { withCredentials: true }
        );
    }

    public registerMessageListener(listener: CashoutBalanceListener): void {
        this.listeners.push(listener);
    }

    public unregisterMessageListener(listener: CashoutBalanceListener): void {
        this.listeners = this.listeners.filter(l => l !== listener);
    }

    handleMessage(message: CashoutBalanceResponseDto): void {
        this.listeners.forEach(l => l.onBalanceUpdated(message));
    }
}

export interface CashoutBalanceListener {
    onBalanceUpdated(balance: CashoutBalanceResponseDto): void;
}
