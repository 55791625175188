<ng-container *ngIf="serverError">
  <app-page-error
    [class.in-admin]="displayingInCompanyAdminPage"
    *ngIf="errorMessage"
    [errorMessage]="errorMessage"
  ></app-page-error>
</ng-container>
<ng-container *ngIf="!fetchingResponse && !serverError">
  <ng-container *ngIf="!companyRegistered">
    <div class="card">
      <div class="card-heading">
        <h2>Kontaktinformation</h2>
      </div>
      <div class="card-body">
        <form [formGroup]="contactInformationForm" novalidate>
          <div class="input-fields">
            <zaver-form-input
              [title]="'Ditt mobilnummer'"
              [placeholder]="'Ange mobilnummer'"
              [error]="
                contactInformationForm.controls.phone.invalid &&
                showContactFormError
              "
              [errorMessage]="getPhoneErrorMessage()"
              formControlName="phone"
              [inputMode]="'tel'"
              [inputType]="'tel'"
              [size]="'large'"
            >
            </zaver-form-input>
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-heading">
        <h2>Ange bolagsform</h2>
      </div>
      <div class="card-body">
        <zaver-dropdown
          id="dropdown"
          [title]="'Välj bolagsform'"
          [inputModel]="selectedCompanyType"
          [selectOptions]="companyTypeOptions"
          (inputModelChange)="onCompanyTypeChange($event)"
        >
        </zaver-dropdown>
      </div>
    </div>
  </ng-container>

  <app-company-form
    #companyForm
    *ngIf="
      selectedCompanyType === 'Aktiebolag' ||
      selectedCompanyType === 'Handelsbolag' ||
      selectedCompanyType === 'Kommanditbolag'
    "
    (onSaved)="savedLegalEntity()"
    [companyType]="selectedCompanyType"
    [signupLegalEntity]="legalEntity"
    [companyRegistered]="companyRegistered"
    [displayingInCompanyAdminPage]="displayingInCompanyAdminPage"
    [displayingInCompanyInformationTab]="displayingInCompanyInformationTab"
    [displayingInCompanyOwnerInformationTab]="
      displayingInCompanyOwnerInformationTab
    "
    [contactInformationForm]="contactInformationForm"
    (showContactFormError)="showContactFormError = true"
  >
  </app-company-form>

  <app-sole-trader-form
    #soloTraderForm
    *ngIf="selectedCompanyType === 'private'"
    [signupPrivateCompany]="privateCompany"
    [companyRegistered]="companyRegistered"
    (onSaved)="savedPrivateCompany()"
    (showContactFormError)="showContactFormError = true"
    [displayingInCompanyAdminPage]="displayingInCompanyAdminPage"
    [contactInformationForm]="contactInformationForm"
    (showContactFormError)="showContactFormError = true"
  >
  </app-sole-trader-form>
</ng-container>
