<div id="footer-container">
  <div class="grey">Copyright © {{ currentYear }} Frink AB. All rights reserved.</div>
  <div id="links">
    <div id="links-div">
      <a
        *ngIf="currentLoale === 'sv'"
        class="links"
        id="terms-link"
        target="_blank"
        [href]="'https://zaver.tawk.help/'"
        >Hjälpcenter</a
      >
      <a
        class="links"
        id="terms-link"
        target="_blank"
        [href]="termsLink()"
        i18n="@@footer.links.terms"
        >Användarvillkor</a
      >
      <a
        class="links"
        id="contact-link"
        target="_blank"
        [href]="contactLink()"
        i18n="@@footer.links.contact"
        >Kontakt</a
      >
    </div>
    <div id="social-media-icons">
      <!-- Instagram icon -->
      <a
        target="_blank"
        href="https://www.instagram.com/zaver.io/?hl=sv"
        id="instagram-icon"
      >
      </a>

      <!-- Linkedin icon-->
      <a
        target="_blank"
        href="https://www.linkedin.com/company/zaver"
        id="linkedin-icon"
      >
      </a>

      <!-- Facebook icon -->
      <a
        target="_blank"
        href="https://www.facebook.com/officialzaver"
        id="facebook-icon"
      >
      </a>

      <!-- Twitter icon-->
      <a target="_blank" href="https://twitter.com/IoZaver" id="twitter-icon">
      </a>
    </div>
  </div>
</div>
