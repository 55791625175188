<ng-container *ngIf="!displayingInCompanyAdminPage">
  <div class="card" *ngIf="!signing">
    <div class="card-heading">
      <h2>Signera avtal</h2>
      <span>Läs igenom avtalet och signera med&nbsp;BankID</span>
    </div>
    <div class="card-body">
      <button
        [disabled]="loading"
        id="download-button"
        (click)="downloadAgreementClick()"
      >
        <span>Ladda ner avtalet</span>
      </button>

      <div class="button-container">
        <zaver-primary-button
            [text]="'Signera avtalet'"
            (click)="signClick()"
            [disabled]="loading"
            [logo]="'assets/logo/bank_id_logo_white.svg'"
        ></zaver-primary-button>
      </div>

    </div>
  </div>

  <div class="card" *ngIf="signing">
    <app-bank-id (onSuccess)="onSuccess($event)" [signingInsteadOfAuth]=true></app-bank-id>
  </div>

  <div *ngIf="errorMessage" id="app-error">
    <img
      src="assets/icons/Icon-Error.svg"
      alt="Error circle"
      style="margin-right: 20px"
    />
    <span [innerHTML]="errorMessage"></span>
  </div>
</ng-container>

<div
  *ngIf="displayingInCompanyAdminPage"
  (click)="downloadAgreementClick()"
  class="admin-link link"
>
  <span i18n="@@agreement.admin.text">Ladda&nbsp;ned&nbsp;avtalet</span>
</div>
