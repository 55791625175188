<app-widget-header
  [text]="'View order'"
  [setView]="setView"
  [context]="context"
  [urlNavigate]="context === 'ZFB'"
></app-widget-header>

<div class="buttons-wrapper">
  <p>Amount to capture: {{ availableForCapture | amountPipe : currency }}</p>
  <div class="buttons-container">
    <zaver-secondary-button-subtle
      *ngIf="availableForCapture > 0"
      [text]="'Edit order'"
      (click)="setView(VIEW.PAYMENT_UPDATE)"
      [size]="'x-small'"
    ></zaver-secondary-button-subtle>
    <zaver-secondary-button-subtle
      *ngIf="totalRefundableAmount > 0"
      [text]="'Create refund'"
      (click)="
        setView(
          fixedAmountRefundLineItems.length > 0
            ? VIEW.REFUND_FIXED_AMOUNT
            : VIEW.REFUND_LINE_ITEM
        )
      "
      [size]="'x-small'"
    ></zaver-secondary-button-subtle>

    <zaver-primary-button
      *ngIf="availableForCapture !== 0"
      [text]="'Capture payment'"
      [size]="'x-small'"
      (click)="setView(VIEW.PARTIAL_CAPTURE)"
    ></zaver-primary-button>
  </div>
</div>

<div class="additional-wrapper">
  <p>
    Initial order amount:
    {{ initialPaymentRequestAmount | amountPipe : currency }}
  </p>
  <div class="vertical-divider"></div>
  <p>Captured amount: {{ capturedAmount | amountPipe : currency }}</p>
</div>

<ng-container *ngIf="uncapturedLineItems?.length > 0">
  <app-simple-line-item-table
    [headingText]="'Awaiting capture'"
    [simpleLineItemVM]="uncapturedLineItems"
    [currency]="currency"
  ></app-simple-line-item-table>
</ng-container>

<ng-container *ngIf="capturedLineItems?.length > 0">
  <app-simple-line-item-table
    [headingText]="'Captured'"
    [simpleLineItemVM]="capturedLineItems"
    [currency]="currency"
  ></app-simple-line-item-table>
</ng-container>

<ng-container *ngIf="pendingLineItemRefundLineItems?.length > 0">
  <app-simple-line-item-table
    [headingText]="'Refund in progress'"
    [helpHeadingText]="'Awaiting attestation'"
    [simpleLineItemVM]="pendingLineItemRefundLineItems"
    [currency]="currency"
  ></app-simple-line-item-table>
</ng-container>

<ng-container *ngIf="pendingFixedAmountRefundLineItems?.length > 0">
  <app-simple-line-item-table
    [headingText]="'Refund in progress (fixed amount)'"
    [helpHeadingText]="'Awaiting attestation'"
    [simpleLineItemVM]="pendingFixedAmountRefundLineItems"
    [firstColumnText]="'Description'"
    [currency]="currency"
  ></app-simple-line-item-table>
</ng-container>

<ng-container *ngIf="refundedLineItems?.length > 0">
  <app-simple-line-item-table
    [headingText]="'Refunded'"
    [simpleLineItemVM]="refundedLineItems"
    [currency]="currency"
  ></app-simple-line-item-table>
</ng-container>

<ng-container *ngIf="fixedAmountRefundLineItems?.length > 0">
  <app-simple-line-item-table
    [headingText]="'Refunded (fixed amount)'"
    [simpleLineItemVM]="fixedAmountRefundLineItems"
    [firstColumnText]="'Description'"
    [currency]="currency"
  ></app-simple-line-item-table>
</ng-container>

<ng-container *ngIf="removedLineItems?.length > 0">
  <app-simple-line-item-table
    [headingText]="'Removed / Cancelled items'"
    [simpleLineItemVM]="removedLineItems"
    [currency]="currency"
  ></app-simple-line-item-table>
</ng-container>
