<!-- First out of three pages. Collects personalnumber, phonenumber, title and amount-->
<div id="form-container" class="spacing-container" *ngIf="showCashoutInfoForm">
  <form [formGroup]="cashoutInfoForm" novalidate>
    <div
      class="input-fields"
      [class.error]="
        cashoutInfoForm.controls.payeePersonalNumber.invalid &&
        showCashoutInfoFormError
      "
    >
      <zaver-form-input
        [showContent]="true"
        [title]="'Mottagarens personnummer'"
        [placeholder]="'ååååmmddxxxx'"
        [error]="
          cashoutInfoForm.controls.payeePersonalNumber.invalid &&
          showCashoutInfoFormError
        "
        [errorMessage]="'Vänligen ange ett giltigt personnummer (ååååmmddxxxx)'"
        formControlName="payeePersonalNumber"
        [inputMode]="'numeric'"
        [size]="'large'"
      >
        <img src="assets/icons/Icon-User-Single-Black-No-Circle.svg" />
      </zaver-form-input>
    </div>
    <div
      class="input-fields"
      [class.error]="
        cashoutInfoForm.controls.payeePhone.invalid && showCashoutInfoFormError
      "
    >
      <zaver-form-input
        [showContent]="true"
        [title]="'Mobilnummer'"
        [placeholder]="'Ange mobilnummer'"
        [error]="
          cashoutInfoForm.controls.payeePhone.invalid &&
          showCashoutInfoFormError
        "
        [errorMessage]="'Vänligen kontrollera mobilnumret'"
        formControlName="payeePhone"
        [inputMode]="'tel'"
        [size]="'large'"
      >
        <img src="assets/icons/Icon-Phone.svg" />
      </zaver-form-input>
    </div>
    <div
      class="input-fields"
      [class.error]="
        cashoutInfoForm.controls.amount.invalid && showCashoutInfoFormError
      "
    >
      <zaver-form-input
        [showContent]="true"
        [title]="'Belopp'"
        [placeholder]="'Belopp'"
        [error]="
          cashoutInfoForm.controls.amount.invalid && showCashoutInfoFormError
        "
        [errorMessage]="
          'Ange ett belopp mellan 1 kr - ' +
          (maxAllowedAmount | spacedAmount) +
          ' kr'
        "
        formControlName="amount"
        [inputMode]="'numeric'"
        [size]=""
      >
        <span style="text-align: center; font-weight: 400; font-size: 14px">
          SEK
        </span>
      </zaver-form-input>
    </div>
    <div
      class="input-fields"
      [class.error]="
        cashoutInfoForm.controls.reference.invalid && showCashoutInfoFormError
      "
    >
      <zaver-form-input
        [title]="prFormTitleAlias"
        [placeholder]="prFormTitleAlias"
        formControlName="reference"
        [error]="
          cashoutInfoForm.controls.reference.invalid && showCashoutInfoFormError
        "
        [errorMessage]="'Fältet får inte lämnas tomt, ange max 255 tecken'"
        [maxLength]="255"
        [lengthOfValue]="cashoutInfoForm.controls.reference.value.length"
        [showCounter]="true"
        [size]="'large'"
      >
      </zaver-form-input>
    </div>
    <div class="input-fields" *ngIf="displayBankingOptions">
      <div class="input-section">
        <div class="input-heading">Bankkontouppgifter</div>
        <div class="radio-button-group">
          <div
            class="radio-button-with-label"
            (click)="clickedBankingOption(false)"
          >
            <zaver-zfb-radio-button
              [color]="'green'"
              [selected]="!manualBankInfoChoosen"
            ></zaver-zfb-radio-button>
            <span class="radio-button-label">Bankuppkoppling</span>
          </div>
          <div
            class="radio-button-with-label"
            (click)="clickedBankingOption(true)"
          >
            <zaver-zfb-radio-button
              [color]="'green'"
              [selected]="manualBankInfoChoosen"
            ></zaver-zfb-radio-button>
            <span class="radio-button-label">Fyll i manuellt</span>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="continue-back-buttons-container" id="hide-close-btn-on-desktop">
    <zaver-primary-button [text]="'Fortsätt'" (click)="continueToBankInfo()">
    </zaver-primary-button>
    <zaver-secondary-button-subtle
      *ngIf="deviceIsMobile()"
      [text]="'Stäng'"
      (click)="onClose.emit()"
    ></zaver-secondary-button-subtle>
  </div>
</div>

<!-- Second of three pages. Collect the bankinformation and verifies that the account number is a correct one-->
<div class="spacing-container" *ngIf="showBankInfoForm">
  <div>
    <form [formGroup]="bankInfoForm" novalidate>
      <div
        class="input-fields"
        [class.error]="
          bankInfoForm.controls.clearingNr.invalid && showBankInfoFormError
        "
      >
        <zaver-form-input
          [showContent]="true"
          [title]="'Clearingnummer'"
          [placeholder]="'Clearingnummer'"
          [error]="
            bankInfoForm.controls.clearingNr.invalid && showBankInfoFormError
          "
          [errorMessage]="
            'Vänligen ange ett giltigt clearingnummer, 4 eller 5 siffror långt.'
          "
          formControlName="clearingNr"
          [inputMode]="'numeric'"
          [size]="'large'"
        >
          <span style="text-align: center; font-weight: 400; font-size: 14px">
            #
          </span>
        </zaver-form-input>
      </div>
      <div
        class="input-fields"
        [class.error]="
          bankInfoForm.controls.accountNr.invalid && showBankInfoFormError
        "
      >
        <zaver-form-input
          [showContent]="true"
          [title]="'Kontonummer'"
          [placeholder]="'Kontonummer'"
          [error]="
            bankInfoForm.controls.accountNr.invalid && showBankInfoFormError
          "
          [errorMessage]="
            'Vänligen ange ett giltigt kontonummer, mellan 7 och 10 siffror långt.'
          "
          formControlName="accountNr"
          [inputMode]="'numeric'"
          [size]="'large'"
        >
          <span style="text-align: center; font-weight: 400; font-size: 14px">
            #
          </span>
        </zaver-form-input>
      </div>
      <div
        class="input-fields"
        [class.error]="
          bankInfoForm.controls.bankName.invalid && showBankInfoFormError
        "
      >
        <zaver-form-input
          [showContent]="true"
          [title]="'Bank'"
          [placeholder]="'Hämtas från clearing-nr'"
          formControlName="bankName"
          [error]="
            bankInfoForm.controls.bankName.invalid && showBankInfoFormError
          "
          [errorMessage]="'Banknamnet kan inte vara längre än 50 tecken långt.'"
          [size]="'large'"
        >
          <img src="assets/icons/Icon-Bank.svg" />
        </zaver-form-input>
      </div>
    </form>
    <div class="error-container">
      <app-form-error
        *ngIf="displayValidBankAccountError && !validBankAccount"
        [errorText]="'Det bankkontonummer du angivit är inte giltigt.'"
      >
      </app-form-error>
      <app-form-error *ngIf="remoteError" [errorText]="remoteError">
      </app-form-error>
    </div>
  </div>

  <div class="continue-back-buttons-container">
    <zaver-primary-button
      [text]="'Fortsätt'"
      (click)="setBankingInfo()"
      [loading]="validatingBankInfo"
    >
    </zaver-primary-button>
    <zaver-secondary-button-subtle
      [text]="'Tillbaka'"
      (click)="returnToCashoutTaskInfo()"
      [disabled]="validatingBankInfo"
    ></zaver-secondary-button-subtle>
  </div>
</div>

<!-- Last of three pages. Displays the entered information for confirmaiton. Displays a warning if a cashout task has been issued to the same recipient recently.-->
<div class="spacing-container" *ngIf="showConfirmation">
  <div>
    <div id="confirmation-heading" class="medium">
      {{ cashoutTaskToBeCreated.payeeDetailsReference }}
    </div>

    <div class="list-container">
      <div class="list-row">
        <div class="icon-and-text">
          <img
            class="icons"
            src="assets/icons/Icon-User-Single-Black-No-Circle.svg"
            alt=""
          />
          <span class="medium2">Säljare</span>
        </div>
        <div>
          {{ sellerName }}
        </div>
      </div>
      <div class="list-row">
        <div class="icon-and-text">
          <img class="icons" src="assets/icons/Icon-Phone.svg" alt="" />
          <span class="hide-mobile-s medium2">Säljarens mobilnummer</span>
          <span class="show-mobile-s medium2">Mobilnummer</span>
        </div>
        <div>
          {{ cashoutTaskToBeCreated.payeePhoneNumber }}
        </div>
      </div>
      <div class="list-row">
        <div class="icon-and-text">
          <img class="icons" src="assets/icons/Icon-Money-Wallet.svg" alt="" />
          <span class="medium2">Belopp</span>
        </div>
        <div>
          {{
            cashoutTaskToBeCreated.amount | currency: 'SEK ':'code':'1.2-2':'sv'
          }}
        </div>
      </div>
      <div class="list-row">
        <div class="icon-and-text">
          <img class="icons" src="assets/icons/Icon-Bank.svg" alt="" />
          <span class="medium2">Bankkontonummer</span>
        </div>
        <div>
          {{ bankInfoDisplay }}
        </div>
      </div>
    </div>

    <div class="errors-container">
      <app-form-error *ngIf="remoteError" [errorText]="remoteError">
      </app-form-error>
      <div *ngIf="remoteWarnings.length > 0">
        <div class="remote-warning" *ngFor="let warning of remoteWarnings">
          <span>{{ warning }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="continue-back-buttons-container">
    <zaver-primary-button
      *ngIf="remoteWarnings.length > 0"
      [text]="'Bekräfta ändå'"
      [loading]="creationInProgress"
      (click)="confirmAndSend()"
    >
    </zaver-primary-button>
    <zaver-primary-button
      *ngIf="remoteWarnings.length === 0"
      [text]="'Bekräfta och skicka'"
      [loading]="creationInProgress"
      (click)="confirmAndSend()"
    ></zaver-primary-button>
    <zaver-secondary-button-subtle
      [text]="'Tillbaka'"
      (click)="returnToBankInfo()"
      [disabled]="creationInProgress"
    ></zaver-secondary-button-subtle>
  </div>
</div>
