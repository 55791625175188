<div id="profile-select-container">
  <div id="profile-selection-header" class="cerise-bg cerise-border">
    <span i18n="@@selectProfile.header">Välj profil</span>
  </div>
  <div id="content-div" class="spacing-container">
    <div id="profile-selection-list">
      <app-profile-selection-list></app-profile-selection-list>
    </div>

    <div id="profile-select-cta">
      <zaver-primary-button
        text="Logga ut"
        i18n-text="@@selectProfile.button.logout"
        (click)="logout()"
      >
      </zaver-primary-button>
    </div>
  </div>
</div>
