import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CashoutTaskCreationRespDto } from '@core/dto/cashout/incoming/CashoutTaskCreationRespDto';
import { CashoutTaskDto } from '@core/dto/cashout/incoming/CashoutTaskDto';
import { CreateCashoutTaskDto } from '@core/dto/cashout/outgoing/create-cashout-task.dto';
import { CashoutDetailsProvider } from '@core/dto/user-details';
import { NUMBER_PATTERN } from '@core/patterns';
import { AppStateService } from '@core/service/app-state.service';
import {
  CashoutTaskService,
  ConfirmationPageDetailsDto,
} from '@core/service/cashout-task.service';
import { swedishNationalIdentificationNumberValidator, swedishNationalIdentificationRegex } from '@shared/validators/identification-number-validators';
import { FormValidators } from '@shared/validators/validators';
import { forkJoin } from 'rxjs';
import {
  formatPhoneNumber,
  phoneNumberValidator,
} from '../../../../../../web-component-library/projects/component-library/src/common/phone-number-validator.directive';

export class BankInformationDto {
  fetchType: FetchType;
  clearingNumber: string;
  accountNumber: string;
  bankName: string;
}

export enum FetchType {
  RIDE = 'RIDE',
  PREFILLED = 'PREFILLED',
  TINK = 'TINK',
}

@Component({
  selector: 'app-create-cashout-task',
  templateUrl: './create-cashout-task.component.html',
  styleUrls: ['./create-cashout-task.component.scss'],
})
export class CreateCashoutTaskComponent implements OnInit {
  cashoutInfoForm: FormGroup;
  showCashoutInfoFormError = false;
  bankInfoForm: FormGroup;
  showBankInfoFormError = false;

  showCashoutInfoForm = false;
  showBankInfoForm = false;
  showConfirmation = false;

  maxAllowedAmount: number;
  cashoutTaskToBeCreated: CreateCashoutTaskDto;
  merchantCashoutDetailsProviders: CashoutDetailsProvider[];
  sellerName: string;
  validBankAccount: boolean;
  validatingBankInfo = false;
  displayValidBankAccountError = false;
  bankInformation: BankInformationDto;
  bankInfoDisplay: string;
  bankinfoFetchedAutomatically: boolean;
  displayBankingOptions = false;
  manualBankInfoChoosen: boolean;

  creationInProgress = false;

  remoteError: string = null;
  remoteWarnings: string[] = [];

  prFormTitleAlias = this.getAliasForTitleInForm();

  @Output() updateModalTextAndColor = new EventEmitter<{
    text: string;
    color: string;
  }>();
  @Output() onCashoutTaskCreated = new EventEmitter<CashoutTaskDto>();
  @Output() onClose = new EventEmitter();

  constructor(
    private appStateService: AppStateService,
    private cashoutTaskService: CashoutTaskService
  ) {}

  ngOnInit(): void {
    this.setup();
  }

  setup() {
    this.showCashoutInfoForm = true;
    this.showBankInfoForm = false;
    this.showConfirmation = false;
    this.merchantCashoutDetailsProviders =
      this.appStateService.getCashoutDetailsProviders();
    if (
      this.merchantCashoutDetailsProviders.indexOf(
        CashoutDetailsProvider.RIDE
      ) > -1 ||
      (this.merchantCashoutDetailsProviders.length === 1 &&
        this.merchantCashoutDetailsProviders[0] === CashoutDetailsProvider.TINK)
    ) {
      this.remoteError = null;

      if (
        this.merchantCashoutDetailsProviders.indexOf(
          CashoutDetailsProvider.RIDE
        ) > -1
      ) {
        this.bankInfoDisplay = 'Inhämtas automatiskt';
        this.bankInformation = {
          fetchType: FetchType.RIDE,
          clearingNumber: null,
          accountNumber: null,
          bankName: null,
        };
        this.bankinfoFetchedAutomatically = true;
      } else {
        this.bankInfoDisplay = 'Inhämtas vid betalning';
        this.bankInformation = {
          fetchType: FetchType.TINK,
          clearingNumber: null,
          accountNumber: null,
          bankName: null,
        };
        this.bankinfoFetchedAutomatically = true;
        this.displayBankingOptions = false;
        this.manualBankInfoChoosen = false;
      }
    } else if (
      this.merchantCashoutDetailsProviders.indexOf(
        CashoutDetailsProvider.TINK
      ) > -1 &&
      this.merchantCashoutDetailsProviders.indexOf(
        CashoutDetailsProvider.MANUAL
      ) > -1
    ) {
      // Merchant has both TINK and MANUAL, display choices
      this.displayBankingOptions = true;
      this.manualBankInfoChoosen = false;
      this.bankInfoDisplay = 'Inhämtas vid betalning';
      this.bankInformation = {
        fetchType: FetchType.TINK,
        clearingNumber: null,
        accountNumber: null,
        bankName: null,
      };
    } else if (
      this.merchantCashoutDetailsProviders.indexOf(
        CashoutDetailsProvider.MANUAL
      ) > -1
    ) {
      this.displayBankingOptions = false;
      this.manualBankInfoChoosen = true;
    }
    this.maxAllowedAmount = this.appStateService.getCashoutMaxAllowedAmount();
    this.cashoutInfoForm = new FormGroup({
      payeePhone: new FormControl('', [
        Validators.required,
        phoneNumberValidator(this.appStateService.getSupportedCountryCodes()),
      ]),
      payeePersonalNumber: new FormControl('', [
        Validators.required,
        swedishNationalIdentificationNumberValidator(),
        Validators.pattern(
          swedishNationalIdentificationRegex
        ),
      ]),
      reference: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(255),
      ]),
      amount: new FormControl('', [
        Validators.required,
        FormValidators.number(1, this.maxAllowedAmount),
      ]),
    });
    if (this.displayBankingOptions || this.manualBankInfoChoosen) {
      this.bankInfoForm = new FormGroup({
        clearingNr: new FormControl('', [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(5),
          Validators.pattern(NUMBER_PATTERN),
        ]),
        accountNr: new FormControl('', [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(10),
          Validators.pattern(NUMBER_PATTERN),
        ]),
        bankName: new FormControl('', [
          Validators.required,
          Validators.maxLength(50),
        ]),
      });
      this.bankInfoForm.controls.bankName.disable();
      this.onChanges();
    }
  }

  onChanges(): void {
    // When updating form values inside valueChanges, setValue() may not emit events.
    // That would trigger valueChanges again, causing an infinite loop
    this.bankInfoForm.controls['clearingNr'].valueChanges.subscribe((val) => {
      if (this.bankInfoForm.controls['clearingNr'].valid) {
        this.cashoutTaskService
          .getBankName(this.bankInfoForm.controls['clearingNr'].value)
          .subscribe((res) => {
            this.bankInfoForm.controls['bankName'].setValue(res.bankName, {
              emitEvent: false,
            });
          });
      } else {
        this.bankInfoForm.controls['bankName'].setValue('', {
          emitEvent: false,
        });
      }
    });
  }

  clickedBankingOption(bool: boolean) {
    this.manualBankInfoChoosen = bool;
    if (!this.manualBankInfoChoosen) {
      this.bankInfoDisplay = 'Inhämtas vid betalning';
      this.bankInformation = {
        fetchType: FetchType.TINK,
        clearingNumber: null,
        accountNumber: null,
        bankName: null,
      };
    }
  }

  returnToCashoutTaskInfo() {
    this.remoteWarnings = [];
    this.displayValidBankAccountError = false;
    this.remoteError = '';
    this.updateModalTextAndColor.emit({
      text: 'Ny utbetalning',
      color: '#00A880',
    });
    this.showCashoutInfoForm = true;
    this.showBankInfoForm = false;
    this.showConfirmation = false;
  }

  continueToBankInfo() {
    if (this.cashoutInfoForm.valid) {
      if (
        (this.bankinfoFetchedAutomatically && this.bankInformation) || // The merchant has only RIDE or only TINK
        (this.displayBankingOptions &&
          !this.manualBankInfoChoosen &&
          this.bankInformation)
      ) {
        // The merchant has both TINK and MANUAL, but has choosen TINK
        this.continueToConfirmation();
      } else if (
        (!this.bankinfoFetchedAutomatically && !this.bankInformation) ||
        this.manualBankInfoChoosen
      ) {
        this.updateModalTextAndColor.emit({
          text: 'Ange mottagarens kontouppgifter',
          color: '#00A880',
        });
        this.showCashoutInfoForm = false;
        this.showBankInfoForm = true;
        this.showConfirmation = false;
      }
    } else {
      this.showCashoutInfoFormError = true;
    }
  }

  setBankingInfo() {
    if (this.bankInfoForm.valid) {
      this.validatingBankInfo = true;
      this.bankInformation = {
        fetchType: FetchType.PREFILLED,
        clearingNumber: this.bankInfoForm.controls['clearingNr'].value,
        accountNumber: this.bankInfoForm.controls['accountNr'].value,
        bankName: this.bankInfoForm.controls['bankName'].value,
      };
      this.bankInfoDisplay =
        this.bankInformation.clearingNumber +
        ' - ' +
        this.bankInformation.accountNumber;

      this.continueToConfirmation();
    } else {
      this.showBankInfoFormError = true;
    }
  }

  returnToBankInfo() {
    this.remoteWarnings = [];
    this.displayValidBankAccountError = false;
    this.remoteError = '';
    if (
      (this.bankinfoFetchedAutomatically && this.bankInformation) || // The merchant has only RIDE or only TINK
      (this.displayBankingOptions &&
        !this.manualBankInfoChoosen &&
        this.bankInformation)
    ) {
      // The merchant has both TINK and MANUAL, but has choosen TINK
      this.returnToCashoutTaskInfo();
    } else if (!this.bankinfoFetchedAutomatically) {
      this.updateModalTextAndColor.emit({
        text: 'Ange mottagarens kontouppgifter',
        color: '#00A880',
      });
      this.showCashoutInfoForm = false;
      this.showBankInfoForm = true;
      this.showConfirmation = false;
    }
  }

  continueToConfirmation() {
    this.displayValidBankAccountError = false;

    let payeePersonalNumber: string =
      this.cashoutInfoForm.controls['payeePersonalNumber'].value;
    payeePersonalNumber = payeePersonalNumber.replace(/-/g, '');
    let reference: string = this.cashoutInfoForm.controls['reference'].value;
    reference = reference.trim();
    this.cashoutTaskToBeCreated = new CreateCashoutTaskDto(
      formatPhoneNumber(this.cashoutInfoForm.controls['payeePhone'].value),
      this.cashoutInfoForm.controls['amount'].value.replace(/\s/g, '').replace(',', '.'),
      payeePersonalNumber,
      reference,
      this.bankInformation
    );

    const clearingNumber: string =
      this.bankInformation.fetchType === FetchType.PREFILLED
        ? this.bankInformation.clearingNumber
        : null;
    const accountNumber: string =
      this.bankInformation.fetchType === FetchType.PREFILLED
        ? this.bankInformation.accountNumber
        : null;

    // Joining two Observables together
    forkJoin({
      confirmationPageDetails:
        this.cashoutTaskService.getConfirmationPageDetails(
          payeePersonalNumber,
          clearingNumber,
          accountNumber
        ),
      remoteWarnings: this.cashoutTaskService.getRemoteWarnings(
        this.cashoutTaskToBeCreated
      ),
    }).subscribe(
      (res: {
        confirmationPageDetails: ConfirmationPageDetailsDto;
        remoteWarnings: string[];
      }) => {
        this.sellerName = res.confirmationPageDetails.personName;
        this.validBankAccount = res.confirmationPageDetails.validBankAccount;
        if (res.remoteWarnings.length > 0) {
          this.remoteWarnings = res.remoteWarnings;
        }
        if (!this.validBankAccount) {
          this.displayValidBankAccountError = true;
          this.validatingBankInfo = false;
        } else {
          this.displayConfirmation();
          this.validatingBankInfo = false;
        }
      },
      (err) => {
        this.remoteError = err.error.error;
        this.validatingBankInfo = false;
      }
    );
  }

  displayConfirmation() {
    this.updateModalTextAndColor.emit({
      text: 'Bekräfta uppgifterna',
      color: '#00A880',
    });
    this.showCashoutInfoForm = false;
    this.showBankInfoForm = false;
    this.showConfirmation = true;
  }

  // When the user has confirmed the entered details, the cashouttask is created
  confirmAndSend() {
    this.creationInProgress = true;
    this.cashoutTaskService
      .create(this.cashoutTaskToBeCreated)
      .subscribe((res: CashoutTaskCreationRespDto) => {
        this.creationInProgress = false;

        if (res.couldCreateTask) {
          // CashoutTask has been created
          this.onCashoutTaskCreated.emit(res.task);
        } else {
          // CashoutTask has not been created and error are shown
          this.remoteError = res.feedback.curatedMessage
            ? res.feedback.curatedMessage
            : res.feedback.providerMessage;
        }
      });
  }

  getAliasForTitleInForm(): string {
    const guiCustomizations = this.appStateService.getGuiCustomizations();

    if (guiCustomizations !== null && guiCustomizations.prFormTitleAlias) {
      return guiCustomizations.prFormTitleAlias;
    }
    return 'Titel';
  }

  deviceIsMobile() {
    return window.innerWidth <= 520;
  }
}
