import { NgModule, ErrorHandler } from '@angular/core';
import { AppStateService } from './service/app-state.service';
import { AccountService } from './service/account.service';
import { AgreementService } from './service/agreement.service';
import { AuthenticationService } from './service/authentication.service';
import { BankIdService } from './service/bank-id.service';
import { CompanyRepresentativeService } from './service/company-representative.service';
import { HrefService } from './service/href.service';
import { LegalEntityService } from './service/legal-entity.service';
import { PaymentRequestService } from './service/payment-request.service';
import { PrivateCompanyService } from './service/private-company.service';
import { QuestionsService } from './service/questions.service';
import { AccessControlService } from './service/access-control.service';
import { CashoutTaskService } from '@core/service/cashout-task.service';
import { CashoutBalanceService } from '@core/service/cashout-balance.service';
import { StoreService } from './service/store.service';
import { ReportService } from './service/report.service';
import { BannerService } from './service/banner.service';
import { PrecheckService } from './service/precheck.service';
import { LoggingService } from './service/logging.service';
import { ErrorResponseInterceptor } from './error-response-interceptor';
import { LoggingErrorHandler } from './logging-error-handler';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RoutingHistoryService } from './service/routing-history.service';
import { SessionTimeoutInterceptor } from '../shared/interceptor/session-timeout-interceptor';
import { ContextIdInterceptor } from '@shared/interceptor/context-id.interceptor';
import { RepresentativeInterceptor } from '@shared/interceptor/representative.interceptor';
import { SignupService } from './service/signup.service';
import { CalendarService } from './service/calendar.service';
import { ToastMessageService } from './service/toast-message.service';
import { RefundService } from './service/refund.service';
import { UserAgentService } from './service/user-agent.service';
import { EventsService } from './service/events.service';
import { ReportRecipientService } from './service/report-recipient.service';
import { LocaleInterceptor } from '@shared/interceptor/locale.interceptor';
import { PhoneNumberService } from './service/phone-number.service';
import { CheckoutEventsService } from './service/checkout-events.service';

@NgModule({
  providers: [
    AccessControlService,
    AccountService,
    AgreementService,
    AppStateService,
    AuthenticationService,
    BankIdService,
    CashoutBalanceService,
    CashoutTaskService,
    CompanyRepresentativeService,
    HrefService,
    LegalEntityService,
    LoggingService,
    PaymentRequestService,
    CheckoutEventsService,
    PrivateCompanyService,
    QuestionsService,
    ReportService,
    PrecheckService,
    BannerService,
    RoutingHistoryService,
    SignupService,
    StoreService,
    CalendarService,
    ToastMessageService,
    RefundService,
    UserAgentService,
    EventsService,
    ReportRecipientService,
    PhoneNumberService,
    {
      provide: ErrorHandler,
      useClass: LoggingErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorResponseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionTimeoutInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ContextIdInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RepresentativeInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LocaleInterceptor,
      multi: true,
    },
  ],
  exports: [],
})
export class CoreModule {}
