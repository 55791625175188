<div class="heading-container">
  <h1>Registrering steg 1/3</h1>
</div>
<ng-container *ngIf="!basicQuestionsDoneFetching">
  <div class="page-loader-container">
    <app-page-loader></app-page-loader>
  </div>
</ng-container>
<div style="width: 100%" [class.hide]="!basicQuestionsDoneFetching">
  <app-basic-questions
    (onSaved)="saved()"
    (doneFetchingResponse)="basicQuestionsDoneFetching = true"
  >
  </app-basic-questions>
</div>
