import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@core/base.component';
import { Refund } from '@core/dto/refund/Refund';
import { AuthenticationService } from '@core/service/authentication.service';
import { RefundService } from '@core/service/refund.service';
import { ToastMessageService } from '@core/service/toast-message.service';

@Component({
  selector: 'app-decline-refund',
  templateUrl: './decline-refund.component.html',
  styleUrls: ['./decline-refund.component.css']
})
export class DeclineRefundComponent extends BaseComponent implements OnInit {

  @Input() refund: Refund;
  
  cancelling = false;
  serverErrorMessage = 'Returen nekades inte. Vänligen försök igen eller avbryt genom att stänga. <a class="link" href="mailto:merchants@zaver.se" target="_blank">Kontakta&nbsp;oss</a> om problemet kvarstår.';


  @Output() return = new EventEmitter<Refund>();
  @Output() cancelled = new EventEmitter();
  @Output() closeModal = new EventEmitter();

  constructor(
    auth: AuthenticationService,
    private refundService: RefundService,
    private toastService: ToastMessageService
  ) {
    super(auth);
  }

  ngOnInit(): void {}

  cancel() {
    this.cancelling = true;
    this.refundService.cancel(this.refund.id)
    .then(() => {
      this.toastService.displaySuccess('Returen har nekats');
      this.cancelled.emit();
    })
    .catch((error: HttpErrorResponse) => {
      this.handleError(error);
      this.cancelling = false;
    });
  }

}
