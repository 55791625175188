import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup-step1',
  templateUrl: './step-1.component.html',
  styleUrls: ['../../page-shared.scss', '../signup-shared.css'],
})
export class Step1Component implements OnInit {
  basicQuestionsDoneFetching = false;

  constructor(private router: Router) {}

  ngOnInit() {}

  saved() {
    this.router.navigate(['/signup/step2'], { skipLocationChange: false });
  }
}
