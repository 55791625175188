import { ValidatorFn, AbstractControl } from '@angular/forms';
import {
  PhoneNumber,
  PhoneNumberFormat,
  PhoneNumberUtil,
  PhoneNumberType,
} from 'google-libphonenumber';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

export enum CountryCodes {
  ALL = 'ALL',
}

export function phoneNumberValidator(
  supportedCountryCodes: number[] | CountryCodes
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    let validNumber = false;

    if (!control.value) {
      return null;
    }

    // only allow digits, +, -, () and spaces
    const reg = /\d|[\+\s\-\()]/;
    const invalidCharacter = control.value
      .split('')
      .filter((char) => !reg.test(char));

    if (invalidCharacter.length > 0) {
      return { invalidFormat: true };
    }

    try {
      // allow phone number without country code if only one country code is supported
      const phoneNumber = addCountryCodeIfOneMarketIsSupported(control.value);

      const isMobileNumber =
        phoneNumberUtil.getNumberType(phoneNumber) === PhoneNumberType.MOBILE ||
        phoneNumberUtil.getNumberType(phoneNumber) ===
          PhoneNumberType.FIXED_LINE_OR_MOBILE;

      //check if country code is supported
      const countryCode = phoneNumber.getCountryCode();
      if (
        supportedCountryCodes !== CountryCodes.ALL &&
        supportedCountryCodes.indexOf(countryCode) === -1
      ) {
        return { unsupportedCountryCode: true };
      } else {
        validNumber =
          isMobileNumber && phoneNumberUtil.isValidNumber(phoneNumber);
      }
    } catch (e) {
      return { invalidFormat: true };
    }
    return validNumber ? null : { invalidFormat: true };
  };
}

export const formatPhoneNumber = (value: string): string => {
  const phoneNumber = addCountryCodeIfOneMarketIsSupported(value);

  return phoneNumberUtil.format(phoneNumber, PhoneNumberFormat.E164);
};

const addCountryCodeIfOneMarketIsSupported = (value: string): PhoneNumber => {
  if (value.slice(0, 1) !== '+') {
    return phoneNumberUtil.parse(value, 'SE');
  } else {
    return phoneNumberUtil.parse(value);
  }
};

export const nordicCountries = [46, 45, 47, 358, 49];
