import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {VerificationTokenService} from '@core/service/verification-token.service';
import {RestBase} from '@core/rest-base';
import {HttpClient} from '@angular/common/http';
import {HrefService} from '@core/service/href.service';
import { ColorService } from '@core/service/color.service';

@Component({
  selector: 'app-user-pending-approval',
  templateUrl: './user-pending-admin-approval.component.html',
  styleUrls: [
    './../login-pages.scss',
    './user-pending-admin-approval.component.css']
})

export class UserPendingAdminApprovalComponent extends RestBase implements OnInit {
  email: string;
  company: string;

  constructor(
      private router: Router,
      private tokenService: VerificationTokenService,
      private httpClient: HttpClient,
      private hrefService: HrefService,
      public colorService: ColorService
  ) {
    super(httpClient, hrefService.getApiUrl());
  }

  ngOnInit() {
    this.email = this.tokenService.email;
    super.get('/auth/userSignup/merchantName/?email=' + this.email).then(
        res => this.company = res.company
    );

  }


  backToLogin() {
    this.router.navigate(['/login']);
  }
}
