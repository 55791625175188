<div class="pagination">
  <div class="paginate-button-wrapper">
    <button [disabled]="lastItemOnPage <= itemsPerPage" (click)="first()">
      <img src="assets/icons/Icon-Navigation-Back.svg" />
    </button>
    <button [disabled]="lastItemOnPage <= itemsPerPage" (click)="prev()">
      <img src="assets/icons/Icon-Navigation-Arrow-left.svg" />
    </button>
  </div>

  <p>{{ lastItemOnPage / itemsPerPage }} of {{ numberOfPages }}</p>

  <div class="paginate-button-wrapper">
    <button [disabled]="isLastPage()" (click)="next()">
      <img src="assets/icons/Icon-Navigation-Arrow-Right.svg" />
    </button>
    <button [disabled]="isLastPage()" (click)="last()">
      <img src="assets/icons/Icon-Navigation-Forward.svg" />
    </button>
  </div>
</div>
