import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: 'https://f5e5c31dbd1f4e30bb06ea57d39e6643@o1026969.ingest.sentry.io/5997493',
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [
        'https://business.staging.zaver.com',
        'https://business.test.zaver.com',
        'https://business.zaver.com',
      ],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  environment: environment.environment,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.05,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
