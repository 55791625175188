import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'zaver-loading-dots',
  templateUrl: './loading-dots.component.html',
  styleUrls: ['./loading-dots.component.scss'],
})
export class LoadingDotsComponent implements OnInit {
  @Input() diameter: number = 13;
  @Input() color: string;
  dots = ['dot1', 'dot2', 'dot3'];
  constructor() {}

  ngOnInit(): void {}
}
