<div class="textarea-container">
  <div class="title" *ngIf="!!title">
    <span>{{ title }}</span
    >&nbsp;<span style="color: #6a7381; font-weight: 400">{{ helpTitle }}</span>
  </div>

  <textarea
    [ngClass]="{ invalid: error }"
    [class.small]="small"
    type="text"
    placeholder="{{ placeholder }}"
    [attr.rows]="numRows ? numRows : '2'"
    [ngModel]="value"
    (ngModelChange)="onChange($event)"
    [disabled]="disabled"
    (blur)="onTouch()"
  >
  </textarea>

  <div class="error" *ngIf="error">
    <span>{{ errorMessage }}</span>
  </div>
</div>
