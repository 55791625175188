<div class="card">
  <h1>
    <span i18n="@@userPendingUserApproval.title"
      >Vi behöver verifiera ditt konto 🔒</span
    >
  </h1>

  <p>
    <span i18n="@@userPendingUserApproval.firstParagraph"
      >Vi har precis skickat ett viktigt mail till
      <span class="email">{{ email }}</span
      >. Vänligen kontrollera din e-post omgående och följ instruktionerna för
      att kunna logga in på ditt konto igen.</span
    >
  </p>
  <p id="last-paragraph">
    <span i18n="@@userPendingUserApproval.lastParagraph"
      ><span class="cerise-text medium2">Tips:</span> Kontrollera om mailet kan
      ha hamnat i skräppost om det inte dyker upp inom kort.</span
    >
  </p>

  <div class="button-wrapper">
    <zaver-primary-button
      text="Tillbaka"
      i18n-text="@@userPendingUserApproval.button"
      (click)="backToLogin()"
      [color]="colorService.getCeriseColorCodeAsType()"
    ></zaver-primary-button>
  </div>
</div>
