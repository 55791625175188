<ul
  class="select-item-dropdown"
  role="listbox"
  aria-expanded="false"
  *ngIf="showDropdown"
  [class.shorter-dropdown]="shorterDropdown"
>
  <li
    *ngFor="let item of items; let i = index"
    [attr.child_id]="i"
    role="option"
    tabindex="-1"
    class="select-item-container"
    (click)="setSelectedItem(item)"
    [class.selected]="selectedItem === item"
    (keydown)="onKeydown($event)"
  >
    <img *ngIf="item.imgSrc" src="{{ item.imgSrc }}" style="height: 100%" />
    <span>{{ item.label }}</span>
  </li>
</ul>
<div
  class="dropdown-background"
  (click)="closeDropdown()"
  *ngIf="showDropdown"
></div>

<div
  class="dropdown-background"
  (click)="closeDropdown()"
  *ngIf="showDropdown"
></div>
