<div id="container-div">
  <div id="counter-div">
    <span i18n="@@tablePagination.counter"
      >Visar: {{ paginationState.numOfItems }} av totalt
      {{ paginationState.total }} träffar</span
    >
  </div>
  <div id="options-div">
    <div id="page-size-div">
      <span id="page-size-span" i18n="@@tablePagination.pageSize"
        >Rader på sida:</span
      >
      <div class="table-filter">
        <div class="header-text" (click)="toggleList()">
          {{ paginationState.pageSize }}
        </div>
        <div class="table-filter-options" [ngClass]="{ isActive: showList }">
          <div
            *ngFor="let option of pageSizeOptions"
            class="table-filter-option"
            [class.activeOption]="option === paginationState.pageSize"
            (click)="pageSizeChange(option)"
          >
            {{ option }}
          </div>
        </div>
      </div>
    </div>
    <div id="page-number-div">
      <span i18n="@@tablePagination.pageNumber"
        >Sida {{ paginationState.page }} av {{ lastPage }}</span
      >
    </div>
    <div id="page-buttons-div">
      <button
        class="page-button"
        id="page-button-first"
        [disabled]="disableButtons.first"
        (click)="toFirstPage()"
      >
        <svg
          width="12"
          height="12"
          viewBox="0 0 16 17"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip1)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 1C0 0.447715 0.447716 0 1 0C1.55228 0 2 0.447715 2 1L2 16C2 16.5523 1.55229 17 1 17C0.447716 17 9.53674e-07 16.5523 9.53674e-07 16L0 1ZM14.2489 0.277585C14.6495 -0.0925284 15.299 -0.0925284 15.6996 0.277585C16.1001 0.647699 16.1001 1.24777 15.6996 1.61789L8.25096 8.5L15.6996 15.3821C16.1001 15.7522 16.1001 16.3523 15.6996 16.7224C15.299 17.0925 14.6495 17.0925 14.2489 16.7224L6.46062 9.52642L6.4601 9.52595C6.31433 9.39133 6.19867 9.2315 6.11972 9.05558C6.04068 8.87945 6 8.69066 6 8.5C6 8.30934 6.04068 8.12055 6.11972 7.94442C6.19876 7.76829 6.31461 7.60829 6.46062 7.47358L14.2489 0.277585Z"
            />
          </g>
          <defs>
            <clipPath id="clip1">
              <rect
                width="16"
                height="17"
                fill="white"
                transform="matrix(-1 0 0 1 16 0)"
              />
            </clipPath>
          </defs>
        </svg>
      </button>
      <button
        class="page-button"
        id="page-button-prev"
        [disabled]="disableButtons.prev"
        (click)="toPrevPage()"
      >
        <svg
          width="8"
          height="12"
          viewBox="0 0 10 17"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.69957 0.277585C10.1001 0.647699 10.1001 1.24777 9.69956 1.61789L2.25096 8.5L9.69956 15.3821C10.1001 15.7522 10.1001 16.3523 9.69957 16.7224C9.29899 17.0925 8.64952 17.0925 8.24894 16.7224L0.460618 9.52642C0.460446 9.52626 0.460274 9.5261 0.460102 9.52595C0.314328 9.39132 0.198665 9.2315 0.119719 9.05558C0.0406824 8.87946 0 8.69066 0 8.5C0 8.30934 0.0406824 8.12054 0.119719 7.94442C0.198665 7.7685 0.314328 7.60868 0.460102 7.47405C0.460227 7.47394 0.460352 7.47382 0.460477 7.47371C0.460524 7.47366 0.460571 7.47362 0.460618 7.47358L8.24894 0.277585C8.64952 -0.0925284 9.29899 -0.0925284 9.69957 0.277585ZM1.91021 8.18517C1.91038 8.18533 1.91055 8.18549 1.91073 8.18565L1.91021 8.18517Z"
          />
        </svg>
      </button>
      <button
        class="page-button"
        id="page-button-next"
        [disabled]="disableButtons.next"
        (click)="toNextPage()"
      >
        <svg
          width="8"
          height="12"
          viewBox="0 0 10 17"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.300434 0.277585C0.701013 -0.0925284 1.35048 -0.0925284 1.75106 0.277585L9.53938 7.47358C9.68539 7.60829 9.80124 7.76829 9.88028 7.94442C9.95932 8.12055 10 8.30934 10 8.5C10 8.69066 9.95932 8.87945 9.88028 9.05558C9.80133 9.2315 9.68567 9.39133 9.5399 9.52595C9.53973 9.5261 9.53955 9.52626 9.53938 9.52642L1.75106 16.7224C1.35048 17.0925 0.701013 17.0925 0.300434 16.7224C-0.100145 16.3523 -0.100145 15.7522 0.300434 15.3821L7.74904 8.5L0.300434 1.61789C-0.100145 1.24777 -0.100145 0.647699 0.300434 0.277585Z"
          />
        </svg>
      </button>
      <button
        class="page-button"
        id="page-button-last"
        [disabled]="disableButtons.last"
        (click)="toLastPage()"
      >
        <svg
          width="12"
          height="12"
          viewBox="0 0 16 17"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip2)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16 1C16 0.447715 15.5523 0 15 0C14.4477 0 14 0.447715 14 1L14 16C14 16.5523 14.4477 17 15 17C15.5523 17 16 16.5523 16 16L16 1ZM1.75106 0.277585C1.35048 -0.0925284 0.701013 -0.0925284 0.300434 0.277585C-0.100145 0.647699 -0.100145 1.24777 0.300434 1.61789L7.74904 8.5L0.300434 15.3821C-0.100145 15.7522 -0.100145 16.3523 0.300434 16.7224C0.701013 17.0925 1.35048 17.0925 1.75106 16.7224L9.53938 9.52642L9.5399 9.52595C9.68567 9.39133 9.80133 9.2315 9.88028 9.05558C9.95932 8.87945 10 8.69066 10 8.5C10 8.30934 9.95932 8.12055 9.88028 7.94442C9.80124 7.76829 9.68539 7.60829 9.53938 7.47358L1.75106 0.277585Z"
            />
          </g>
          <defs>
            <clipPath id="clip2">
              <rect width="16" height="17" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>
    </div>
  </div>
</div>
<div id="container-div-mobile">
  <div id="page-buttons-div-mobile">
    <button
      class="page-button"
      id="page-button-first"
      [disabled]="disableButtons.first"
      (click)="toFirstPage()"
    >
      <svg
        width="12"
        height="12"
        viewBox="0 0 16 17"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip3)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 1C0 0.447715 0.447716 0 1 0C1.55228 0 2 0.447715 2 1L2 16C2 16.5523 1.55229 17 1 17C0.447716 17 9.53674e-07 16.5523 9.53674e-07 16L0 1ZM14.2489 0.277585C14.6495 -0.0925284 15.299 -0.0925284 15.6996 0.277585C16.1001 0.647699 16.1001 1.24777 15.6996 1.61789L8.25096 8.5L15.6996 15.3821C16.1001 15.7522 16.1001 16.3523 15.6996 16.7224C15.299 17.0925 14.6495 17.0925 14.2489 16.7224L6.46062 9.52642L6.4601 9.52595C6.31433 9.39133 6.19867 9.2315 6.11972 9.05558C6.04068 8.87945 6 8.69066 6 8.5C6 8.30934 6.04068 8.12055 6.11972 7.94442C6.19876 7.76829 6.31461 7.60829 6.46062 7.47358L14.2489 0.277585Z"
          />
        </g>
        <defs>
          <clipPath id="clip3">
            <rect
              width="16"
              height="17"
              fill="white"
              transform="matrix(-1 0 0 1 16 0)"
            />
          </clipPath>
        </defs>
      </svg>
    </button>
    <button
      class="page-button"
      id="page-button-prev"
      [disabled]="disableButtons.prev"
      (click)="toPrevPage()"
    >
      <svg
        width="8"
        height="12"
        viewBox="0 0 10 17"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.69957 0.277585C10.1001 0.647699 10.1001 1.24777 9.69956 1.61789L2.25096 8.5L9.69956 15.3821C10.1001 15.7522 10.1001 16.3523 9.69957 16.7224C9.29899 17.0925 8.64952 17.0925 8.24894 16.7224L0.460618 9.52642C0.460446 9.52626 0.460274 9.5261 0.460102 9.52595C0.314328 9.39132 0.198665 9.2315 0.119719 9.05558C0.0406824 8.87946 0 8.69066 0 8.5C0 8.30934 0.0406824 8.12054 0.119719 7.94442C0.198665 7.7685 0.314328 7.60868 0.460102 7.47405C0.460227 7.47394 0.460352 7.47382 0.460477 7.47371C0.460524 7.47366 0.460571 7.47362 0.460618 7.47358L8.24894 0.277585C8.64952 -0.0925284 9.29899 -0.0925284 9.69957 0.277585ZM1.91021 8.18517C1.91038 8.18533 1.91055 8.18549 1.91073 8.18565L1.91021 8.18517Z"
        />
      </svg>
    </button>
    <div id="page-number-div">
      <span i18n="@@tablePagination.pageNumberMobile"
        >{{ paginationState.page }} av {{ lastPage }}</span
      >
    </div>
    <button
      class="page-button"
      id="page-button-next"
      [disabled]="disableButtons.next"
      (click)="toNextPage()"
    >
      <svg
        width="8"
        height="12"
        viewBox="0 0 10 17"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.300434 0.277585C0.701013 -0.0925284 1.35048 -0.0925284 1.75106 0.277585L9.53938 7.47358C9.68539 7.60829 9.80124 7.76829 9.88028 7.94442C9.95932 8.12055 10 8.30934 10 8.5C10 8.69066 9.95932 8.87945 9.88028 9.05558C9.80133 9.2315 9.68567 9.39133 9.5399 9.52595C9.53973 9.5261 9.53955 9.52626 9.53938 9.52642L1.75106 16.7224C1.35048 17.0925 0.701013 17.0925 0.300434 16.7224C-0.100145 16.3523 -0.100145 15.7522 0.300434 15.3821L7.74904 8.5L0.300434 1.61789C-0.100145 1.24777 -0.100145 0.647699 0.300434 0.277585Z"
        />
      </svg>
    </button>
    <button
      class="page-button"
      id="page-button-last"
      [disabled]="disableButtons.last"
      (click)="toLastPage()"
    >
      <svg
        width="12"
        height="12"
        viewBox="0 0 16 17"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip4)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16 1C16 0.447715 15.5523 0 15 0C14.4477 0 14 0.447715 14 1L14 16C14 16.5523 14.4477 17 15 17C15.5523 17 16 16.5523 16 16L16 1ZM1.75106 0.277585C1.35048 -0.0925284 0.701013 -0.0925284 0.300434 0.277585C-0.100145 0.647699 -0.100145 1.24777 0.300434 1.61789L7.74904 8.5L0.300434 15.3821C-0.100145 15.7522 -0.100145 16.3523 0.300434 16.7224C0.701013 17.0925 1.35048 17.0925 1.75106 16.7224L9.53938 9.52642L9.5399 9.52595C9.68567 9.39133 9.80133 9.2315 9.88028 9.05558C9.95932 8.87945 10 8.69066 10 8.5C10 8.30934 9.95932 8.12055 9.88028 7.94442C9.80124 7.76829 9.68539 7.60829 9.53938 7.47358L1.75106 0.277585Z"
          />
        </g>
        <defs>
          <clipPath id="clip4">
            <rect width="16" height="17" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </button>
  </div>
</div>
