import { Component, Input, OnInit } from '@angular/core';
import { AppStateService } from '@core/service/app-state.service';
import { ColorService } from '@core/service/color.service';
import { LocaleService } from '@core/service/locale.service';

@Component({
  selector: 'app-checkout-user-flow-event',
  templateUrl: './checkout-user-flow-event.component.html',
  styleUrls: ['./checkout-user-flow-event.component.scss'],
})
export class CheckoutUserFlowEventComponent implements OnInit {
  @Input() event: CheckoutFlowEvent;
  createdTime: string;
  createdDate: string;
  constructor(
    public appState: AppStateService,
    public colorService: ColorService,
    public localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.createdTime =
      this.event?.created && new Date(this.event.created).toLocaleTimeString();
    this.createdDate =
      this.event.firstEventOfDay &&
      new Date(this.event.created).toDateString().slice(4);
  }
}

export interface CheckoutFlowEvent {
  created: string;
  type: string;
  description: string;
  firstEventOfDay?: boolean;
}
