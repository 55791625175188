import {
  Component,
  EventEmitter,
  ElementRef,
  Input,
  Output,
  OnInit,
  HostListener,
} from '@angular/core';

@Component({
  selector: 'zaver-checkout-modal',
  templateUrl: './checkout-modal.component.html',
  styleUrls: ['./checkout-modal.component.scss'],
})
export class CheckoutModalComponent implements OnInit {
  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent): void {
    if (this.modalActive) {
      this.removeModal();
    }
  }

  @Output() onClose = new EventEmitter<any>();
  @Input() id: string;
  @Input() title: string;
  @Input() logo: string;
  @Input() buttonText: string;
  private element: any;
  private modalActive: boolean = false;

  constructor(private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    this.setupModal();
  }

  private removeModalListener = (event) => {
    if (event.target.className === 'modal') {
      this.removeModal();
    }
  };

  setupModal() {
    this.modalActive = true;
    this.element.addEventListener('click', this.removeModalListener);
  }

  removeModal() {
    this.modalActive = false;
    this.onClose.emit();
  }
}
