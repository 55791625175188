import { Directive, ElementRef, HostListener, Input } from '@angular/core';

/*
 This directive is used to add a class to an element on hover
*/
@Directive({
  selector: '[appHoverClass]',
})
export class HoverClassDirective {
  constructor(public elementRef: ElementRef) {}

  @Input('appHoverClass') hoverClass: string;

  @HostListener('mouseenter') onMouseEnter() {
    this.elementRef.nativeElement.classList.add(this.hoverClass);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.elementRef.nativeElement.classList.remove(this.hoverClass);
  }
}
