import * as FileSaver from 'file-saver';
import {BaseComponent} from '@core/base.component';
import {BidProgress} from '@core/dto/Progress';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AgreementService} from '@core/service/agreement.service';
import {AppStateService} from '@core/service/app-state.service';
import {AuthenticationService} from '@core/service/authentication.service';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.css'],
})
export class AgreementComponent extends BaseComponent {
  loading = false;
  signing = false;

  @Output() onSigned = new EventEmitter();

  @Input() displayingInCompanyAdminPage = false;

  constructor(
      private agreementService: AgreementService,
      protected auth: AuthenticationService,
      private appState: AppStateService
  ) {
    super(auth);
  }

  downloadAgreementClick() {
    super.clearError();
    this.loading = true;
    this.agreementService
    .getAgreement()
    .then((res) => {
      const contentDispositionHeader: string = res.headers.get(
          'Content-Disposition'
      );
      const parts: string[] = contentDispositionHeader.split(';');
      const filename = parts[1].split('=')[1];
      FileSaver.saveAs(res.body, filename);
      this.loading = false;
    })
    .catch((error) => {
      this.loading = false;
      super.handleError(error);
    });
  }

  signClick() {
    this.signing = true;
    this.clearError();
    this.loading = true;
    this.errorMessage = null;
  }

  onSuccess(progress: BidProgress<any>) {
    this.appState.updateCurrentUser(progress.payload);
    this.loading = false;
    this.onSigned.emit('signed');
  }
}
