import {AbstractControl, ValidatorFn} from '@angular/forms';

/**
 * Validator for swedish organization numbers (including individual firms with swedish personal number as organization number)
 */
export function b2bOrganizationNumberValidator(): ValidatorFn {
  const orgNumberPattern = /^\d{6}-?\d{4}$/;
  const personalNumberPattern = /^(19|20){1}\d{6}-?\d{4}$/;

  return (control: AbstractControl): { [key: string]: any } => {
    const invalidValue = {'organizationNumber': control.value};
    let orgNumber: string = control.value;

    if (orgNumberPattern.exec(control.value)) {
      orgNumber = orgNumber.replace('-', '');
    } else if (personalNumberPattern.exec(control.value)) {
      orgNumber = orgNumber.replace('-', '');
      orgNumber = orgNumber.substring(2, 12);
    } else {
      return invalidValue;
    }
    
    return validateModulo10Checksum(orgNumber) ? null : invalidValue;
  };
}

/**
 * Validator for (swedish?) organization numbers. Uses the luhn algorithm.
 */
export function organizationNumberValidator(): ValidatorFn {

  const pattern = /^\d{6}-?\d{4}$/;

  return (control: AbstractControl): { [key: string]: any } => {
    const invalidValue = {'organizationNumber': control.value};
    let orgNumber: string = control.value;

    // Ensure appropriate input.
    if (pattern.exec(control.value)) {
      orgNumber = orgNumber.replace('-', '');
    } else {
      return invalidValue;
    }

    return validateModulo10Checksum(orgNumber) ? null : invalidValue;
  };

}

 /**
   * Validator for Swedish national identification numbers. Essentialy the same as organization numbers, but enforces 
   * full 12-digit Swedish national identification numbers.
   */
 export function swedishNationalIdentificationNumberValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const hasSeparator = control.value.charAt(8) < '0' || control.value.charAt(8) > '9';
    const nin = hasSeparator ? control.value.slice(2, 8) + control.value.slice(9) : control.value.slice(2);
    const valid = luhn(nin);
    return valid ? null : { invalidPersonalNumber: { value: control.value } };
  };
}

/**
 * Rewritten for TS compatibility using:
 * https://gist.github.com/ShirtlessKirk/2134376
 */
const luhn = (number: string) => {
  const arr = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];
  let len = number.length;
  let bit = 1;
  let sum = 0;
  let val = 0;

  while (len) {
    val = parseInt(number.charAt(--len), 10);
    sum += (bit ^= 1) ? arr[val] : val;
  }

  return sum && sum % 10 === 0;
};

export const swedishNationalIdentificationRegex: string = '^((19|20)\\d{2})(0[1-9]|1[0-2])(0[1-9]|[1-2]\\d|3[0-1])[-+]*(\\d{4})$';

/**
 * See the following URL for an explanation of this function.
 * https://www.bankgirot.se/globalassets/dokument/anvandarmanualer/10-modul.pdf
 */
function validateModulo10Checksum(account: string): boolean {
  let sum = 0;
  for (let i = 0; i < account.length - 1; i++) {
    let num: number;
    num = +account.charAt(i);
    num = num * ((i + 1) % 2 + 1);
    sum += num > 9 ? num - 9 : num;
  }
  sum += +account.charAt(9);
  return (sum % 10) === 0;
}
