import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CurrencyCode } from '@core/dto/CurrencyCode';
import { Market } from '@core/dto/Market';
import { AppStateService } from '@core/service/app-state.service';

@Component({
  selector: 'app-create-payment-request',
  templateUrl: './create-payment-request.component.html',
  styleUrls: ['./create-payment-request.component.css'],
})
export class CreatePaymentRequestComponent implements OnInit {
  initialSelection: boolean;
  creation: boolean;
  selectedConsumer: boolean;
  selectedCompany: boolean;
  merchantHasB2B: boolean;
  merchantHasLineItems: boolean = false;
  merchantDefaultCurrency: CurrencyCode;
  @Output() onClose = new EventEmitter();
  @Output() onDone = new EventEmitter<string>();
  @Output() onChangeModalHeader = new EventEmitter<string>();
  market: Market = null;

  constructor(private appStateService: AppStateService) {}

  ngOnInit(): void {
    this.merchantHasB2B = this.appStateService.merchantHasB2bPayments();
    this.merchantHasLineItems = this.appStateService.merchantHasLineItemPayments();
    this.merchantDefaultCurrency = this.appStateService.getDefaultCurrency();
    this.market = this.appStateService.getMarket();
    if (!this.merchantHasB2B) {
      this.selectedConsumer = true;
      this.selectedCompany = false;
      this.initialSelection = false;
      this.creation = true;
    } else {
      this.creation = false;
      this.initialSelection = true;
    }
  }

  selectConsumer() {
    this.onChangeModalHeader.emit(' (B2C)');
    this.selectedConsumer = true;
    this.selectedCompany = false;
    if (this.initialSelection) {
      this.initialSelection = false;
      this.creation = true;
    }
  }
  selectCompany() {
    this.onChangeModalHeader.emit(' (B2B)');
    this.selectedConsumer = false;
    this.selectedCompany = true;
    if (this.initialSelection) {
      this.initialSelection = false;
      this.creation = true;
    }
  }

  openSelection() {
    this.creation = false;
    this.initialSelection = true;
  }
}
