<div class="wrapper">
  <div
    class="title"
    *ngIf="title"
    [class.hide-labels-mobile]="hideLabelsMobile"
  >
    <span>{{ title }}</span
    >&nbsp;<span class="help-title">{{ helpTitle }}</span>
  </div>

  <div class="input-wrapper" [ngClass]="size">
    <div class="content-container" *ngIf="showContent">
      <ng-content></ng-content>
    </div>

    <div class="input-field">
      <input
        [ngClass]="{
          invalid: error,
          disabled: disabled,
          content: showContent
        }"
        [(ngModel)]="value"
        (ngModelChange)="onChange($event)"
        placeholder="{{ placeholder }}"
        [attr.type]="showPassword ? 'text' : inputType"
        [attr.inputmode]="inputMode"
        [attr.maxlength]="maxLength ? maxLength : '524288'"
        [attr.disabled]="disabled ? '' : null"
        [attr.readonly]="readonly ? '' : null"
      />

      <div
        class="toggle-password"
        *ngIf="inputType === 'password'"
        (click)="toggleVisibility()"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          *ngIf="!showPassword"
        >
          <g clip-path="url(#clip0_640:25)">
            <path
              d="M2.78299 21.75C2.57999 21.75 2.38299 21.666 2.24199 21.52C2.10299 21.375 2.02899 21.186 2.03299 20.985C2.03699 20.785 2.11899 20.598 2.26399 20.459L21.014 2.459C21.154 2.324 21.339 2.25 21.533 2.25C21.736 2.25 21.933 2.334 22.074 2.481C22.213 2.625 22.287 2.815 22.283 3.016C22.279 3.216 22.197 3.403 22.053 3.542L3.30299 21.542C3.16199 21.676 2.97699 21.75 2.78299 21.75Z"
              fill="#0A1018"
            />
            <path
              d="M11.9879 20.2501L11.8779 20.2511C10.8299 20.2511 9.77494 20.0881 8.74094 19.7681C8.34594 19.6451 8.12494 19.2241 8.24694 18.8301C8.34494 18.5141 8.63294 18.3021 8.96394 18.3021C9.03894 18.3021 9.11394 18.3131 9.18594 18.3361C10.0739 18.6121 10.9859 18.7521 11.8949 18.7521L12.1439 18.7531C15.9969 18.7531 19.8999 15.9671 22.2689 13.3601C22.5809 13.0141 22.5809 12.4891 22.2669 12.1401C21.3759 11.1621 20.3799 10.2691 19.3079 9.4871C19.1459 9.3691 19.0399 9.1951 19.0089 8.9971C18.9779 8.7991 19.0259 8.6011 19.1439 8.4391C19.2849 8.2461 19.5109 8.1311 19.7499 8.1311C19.9089 8.1311 20.0619 8.1811 20.1919 8.2751C21.3449 9.1161 22.4169 10.0781 23.3789 11.1331C24.2109 12.0561 24.2109 13.4451 23.3819 14.3661C20.7959 17.2121 16.4869 20.2521 12.1449 20.2521L11.9879 20.2501Z"
              fill="#0A1018"
            />
            <path
              d="M3.778 17.011C3.612 17.011 3.449 16.955 3.318 16.853C2.355 16.105 1.449 15.269 0.624995 14.367C-0.207005 13.443 -0.208005 12.054 0.621995 11.133C3.20799 8.28802 7.51799 5.24902 11.858 5.24902L12.095 5.25002C12.941 5.25002 13.782 5.35202 14.595 5.55502C14.996 5.65502 15.241 6.06202 15.142 6.46402C15.059 6.79902 14.759 7.03302 14.414 7.03302C14.353 7.03302 14.292 7.02502 14.232 7.01102C13.538 6.83802 12.822 6.75102 12.103 6.75102H11.874C8.01099 6.75102 4.10299 9.53602 1.734 12.142C1.422 12.488 1.422 13.013 1.736 13.362C2.499 14.197 3.342 14.975 4.239 15.672C4.397 15.795 4.49799 15.972 4.52299 16.171C4.548 16.37 4.494 16.566 4.371 16.724C4.22799 16.905 4.012 17.011 3.778 17.011Z"
              fill="#0A1018"
            />
            <path
              d="M8.25 13.5C7.837 13.5 7.5 13.164 7.5 12.75C7.5 11.55 7.968 10.421 8.818 9.57C9.668 8.719 10.797 8.25 11.997 8.25C12.204 8.25 12.371 8.417 12.372 8.624L12.374 9.374C12.374 9.474 12.335 9.569 12.265 9.64C12.195 9.711 12.1 9.75 12 9.75C11.199 9.75 10.446 10.063 9.879 10.63C9.312 11.197 9 11.95 9 12.75C9 12.95 8.922 13.138 8.781 13.28C8.639 13.422 8.45 13.5 8.25 13.5Z"
              fill="#0A1018"
            />
            <path
              d="M12.001 17.25C11.794 17.25 11.626 17.083 11.626 16.875L11.625 16.125C11.625 16.026 11.664 15.93 11.735 15.86C11.805 15.789 11.901 15.75 12 15.75C12.802 15.75 13.555 15.438 14.122 14.871C14.688 14.304 15 13.55 15 12.749C15 12.335 15.336 11.999 15.75 11.999C16.164 11.999 16.5 12.335 16.5 12.749C16.5 13.951 16.033 15.081 15.183 15.931C14.333 16.781 13.203 17.25 12.001 17.25Z"
              fill="#0A1018"
            />
          </g>
          <defs>
            <clipPath id="clip0_640:25">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          *ngIf="showPassword"
        >
          <g clip-path="url(#clip0_640:26)">
            <path
              d="M11.842 19.499C7.50901 19.499 3.20601 16.459 0.623012 13.615C-0.207988 12.692 -0.207988 11.303 0.621012 10.383C3.20501 7.538 7.51301 4.5 11.855 4.5C11.906 4.5 11.955 4.5 12.006 4.501C12.048 4.5 12.103 4.5 12.159 4.5C16.487 4.5 20.793 7.538 23.378 10.381C24.209 11.304 24.21 12.693 23.38 13.614C20.794 16.46 16.483 19.5 12.135 19.5L11.986 19.499H11.842ZM11.853 5.999C8.00201 5.999 4.10001 8.783 1.73301 11.389C1.42101 11.735 1.42101 12.26 1.73501 12.609C4.10101 15.214 8.00201 17.999 11.855 17.999L11.986 17.998L12.122 17.999C15.989 17.999 19.898 15.214 22.267 12.607C22.579 12.261 22.579 11.736 22.265 11.387C19.897 8.783 15.993 6 12.142 6C12.142 6 11.988 6.001 11.986 6.001L11.853 5.999Z"
              fill="#0A1018"
            />
            <path
              d="M11.998 16.498C10.796 16.498 9.66602 16.029 8.81602 15.179C7.96602 14.329 7.49803 13.199 7.49903 11.997C7.49903 9.51605 9.51802 7.49805 11.999 7.49805C13.201 7.49805 14.331 7.96605 15.181 8.81505C16.031 9.66505 16.499 10.794 16.499 11.996C16.499 13.202 16.03 14.331 15.18 15.181C14.33 16.03 13.201 16.498 11.999 16.498H11.998ZM12 8.99805C10.346 8.99805 9.00002 10.343 9.00002 11.997C9.00002 12.798 9.31203 13.552 9.87803 14.118C10.445 14.685 11.198 14.997 11.999 14.997C12.801 14.997 13.554 14.685 14.12 14.119C14.686 13.553 14.999 12.799 14.999 11.998C14.999 11.194 14.687 10.441 14.12 9.87505C13.554 9.31005 12.801 8.99805 12 8.99805Z"
              fill="#0A1018"
            />
          </g>
          <defs>
            <clipPath id="clip0_640:26">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  </div>

  <div class="counter" *ngIf="showCounter">
    <span>{{ lengthOfValue }}/{{ maxLength }}</span>
  </div>

  <div class="error" *ngIf="error" [class.error-while-counter]="showCounter">
    <span>{{ errorMessage }}</span>
  </div>
</div>
