import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthenticationService,
  LoginType,
} from '@core/service/authentication.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '@core/base.component';
import { HttpErrorResponse } from '@angular/common/http';
import { VerificationTokenService } from '@core/service/verification-token.service';
import { captureMessage } from '@sentry/angular';
import { Market } from '@core/dto/Market';
import { LocaleService } from '@core/service/locale.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['../login-pages.scss', './password-reset.component.css'],
})
export class PasswordResetComponent extends BaseComponent implements OnInit {
  private resetToken: any;
  form: FormGroup;

  formError = '';
  defaultFormError = $localize`:@@passwordReset.defaultFormError:Felaktigt lösenord`;
  showError = false;
  passwordError: string;
  confirmPasswordError: string;

  loginMsg = '';
  processingLogin = false;
  validationErrors: string[];
  public email: string;
  isDeAtMarket: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private tokenService: VerificationTokenService,
    public localeService: LocaleService
  ) {
    super(authenticationService);
  }

  ngOnInit() {
    this.email = this.tokenService.email;
    this.resetToken = this.tokenService.getToken();

    this.form = new FormGroup(
      {
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(30),
        ]),
        passwordConfirm: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(30),
        ]),
      },
      this.checkPasswords
    );
    this.isDeAtMarket =
      this.localeService.getCurrentLocaleMarket() === Market.DE ||
      this.localeService.getCurrentLocaleMarket() === Market.AT;
  }

  submit() {
    if (this.form.disabled || this.processingLogin) {
      return;
    }
    super.clearError();
    this.validationErrors = [];
    if (this.form.valid) {
      this.disableForm();
      this.formError = null;
      this.showError = false;
      this.loginMsg = $localize`:@@passwordReset.loginMsg.starting:Startar...`;
      this.form.markAsUntouched();

      const password = this.form.value.password;

      this.authenticationService
        .updatePassword(this.resetToken, this.email, password)
        .then((response) => {
          switch (response) {
            case LoginType.PASSWORD:
              this.router.navigate(['/login']);
              break;
            case LoginType.PENDING_ADMIN_APPROVAL:
              this.router.navigate(['/pending-admin-approval']);
              break;
            default:
              captureMessage(
                'Unrecognized login type was returned from IDAU when completing a password reset. LoginType: ' +
                  response
              );
              this.router.navigate(['/login']);
              break;
          }
        })
        .catch((error) => {
          this.enableForm();
          const httpError: HttpErrorResponse = JSON.parse(
            JSON.stringify(error)
          );
          if (httpError.status === 400) {
            this.handleError(error);
          } else {
            super.handleError(error);
          }
        });
    } else {
      if (
        !this.form.controls['password'].value ||
        !this.form.controls['password'].valid
      ) {
        this.passwordError = $localize`:@@passwordReset.error.password.invalid:Ange ett giltigt lösenord`;
      } else {
        this.passwordError = null;
      }
      if (
        !this.form.controls['passwordConfirm'].value ||
        !this.form.controls['passwordConfirm'].valid
      ) {
        this.confirmPasswordError = $localize`:@@passwordReset.error.confirmPassword.invalid:Ange ett giltigt lösenord`;
      } else {
        this.confirmPasswordError = null;
      }
      if (this.passwordError || this.confirmPasswordError) {
        this.showError = true;
      } else {
        this.showError = false;
      }
      if (this.form.errors && !this.showError) {
        this.formError = this.form.errors.notSame
          ? $localize`:@@passwordReset.error.password.notMatching:Lösenorden stämmer inte överens. Försök igen.`
          : '';
      }
      this.enableForm();
    }
  }

  private enableForm() {
    this.form.enable();
    this.processingLogin = false;
  }

  private disableForm() {
    this.processingLogin = true;
    this.form.disable();
  }

  handleError(error: Error) {
    const httpError: HttpErrorResponse = JSON.parse(JSON.stringify(error));
    if (httpError.status === 400) {
      this.validationErrors = httpError.error
        .filter(function (item, pos) {
          return httpError.error.indexOf(item) === pos;
        })
        .map((err) => this.getErrorDescription(err));
    } else {
      // should not happen, this should only handle validation errors sent as a list from the back end.
      return;
    }
  }

  getErrorDescription(message: string) {
    switch (message) {
      case 'TOO_SHORT':
        return $localize`:@@passwordReset.error.password.TOO_SHORT:Lösenordet får minst vara 8 tecken`;
      case 'TOO_LONG':
        return $localize`:@@passwordReset.error.password.TOO_LONG:Lösenordet får maximalt vara 30 tecken`;
      case 'INSUFFICIENT_UPPERCASE':
        return $localize`:@@passwordReset.error.password.INSUFFICIENT_UPPERCASE:Lösenordet måste innehålla minst en stor bokstav`;
      case 'TOKEN_EXPIRED':
        return $localize`:@@passwordReset.error.password.TOKEN_EXPIRED:Verifieringskoden har hunnit bli för gammal. Stäng webbläsaren och försök igen.`;
      case 'TOKEN_INVALID':
        return $localize`:@@passwordReset.error.password.TOKEN_INVALID:Verifieringskoden är ogiltig. Stäng webbläsaren och försök igen.`;
    }
  }

  checkPasswords(group: FormGroup) {
    // here we have the 'passwords' group
    const pass = group.get('password').value;
    const confirmPass = group.get('passwordConfirm').value;
    return pass === confirmPass ? null : { notSame: true };
  }
}
