import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from '@core/service/authentication.service';
import { BaseComponent } from '@core/base.component';
import { CompanyRepresentativeService } from '@core/service/company-representative.service';
import { CompanyRepresentative } from '@core/dto/CompanyRepresentative';
import { AppStateService } from '@core/service/app-state.service';
import { SearchPipe } from '@shared/pipe/search.pipe';
import {
  ColumnDefinition,
  FilterOption,
  TableRows,
} from '../../ui/table/table.component';
import { DatePipe } from '@angular/common';
import { RepresentativeStatus } from '@core/params';
import { QuickAction } from '@zfb/ui/quick-actions/quick-actions.component';
import { AccessControlService } from '@core/service/access-control.service';
import { ToastMessageService } from '@core/service/toast-message.service';
import { Permission } from '@core/dto/user-details';
import { EmptyState } from '@zfb/ui/empty-state/empty-state.component';
import { ColorService } from '@core/service/color.service';
import { LocaleService } from '@core/service/locale.service';
import { PageTab } from '@zfb/ui/page-tabs/page-tabs.component';
import { Page } from 'ngx-pagination/dist/pagination-controls.directive';
import { isThisTypeNode } from 'typescript';
import { PaginationState } from '@zfb/ui/table-pagination/table-pagination.component';

export enum UserPage {
  ALL_USERS = 'ALL_USERS',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
}

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['../page-shared.scss', './users.component.css'],
})
export class UsersComponent extends BaseComponent implements OnInit, OnDestroy {
  constructor(
    private companyRepresentativeService: CompanyRepresentativeService,
    protected auth: AuthenticationService,
    private appState: AppStateService,
    private searchPipe: SearchPipe,
    private access: AccessControlService,
    private toastService: ToastMessageService,
    public colorService: ColorService,
    private localeService: LocaleService
  ) {
    super(auth);
  }
  statePushedToHistory = false;

  representatives: CompanyRepresentative[] = [];
  filteredListOfRepresentatives: CompanyRepresentative[] = [];
  searchedListOfRepresentatives: CompanyRepresentative[] = [];
  currentPageOfRepresentatives: TableRows<
    CompanyRepresentative,
    RepresentativeStatus
  > = {
    getPrimaryText: (row: CompanyRepresentative) => row.user.displayName,
    getSecondaryText: (row: CompanyRepresentative) => row.role.displayName,
    getStatusBarColor: (row: CompanyRepresentative) => row.statusColor,
    getBackgroundColor: (row: CompanyRepresentative) => row.backgroundColor,
    rows: [],
    hideMobileHeader: false,
    rowClickFunction: (it: CompanyRepresentative) => this.open(it),
  };

  numOfPendingApprovals = 0;

  UserPage = UserPage;
  activePage = UserPage.ALL_USERS;
  tabs: PageTab<UserPage>[] = [
    {
      value: UserPage.ALL_USERS,
      label: $localize`:@@users.tabs.allUsers:Alla användare`,
    },
    {
      value: UserPage.PENDING_APPROVAL,
      label: $localize`:@@users.tabs.pendingApprovals:Inväntar godkännande`,
      badgeCount: this.numOfPendingApprovals,
    },
  ];

  roleFilter = '';
  statusFilter = '';

  selectedRepresentative: CompanyRepresentative = null;
  selectedQuickActions: QuickAction<CompanyRepresentative>[];
  filterQuery = '';
  paginationState: PaginationState = {
    page: 1,
    pageSize: 10,
    total: 0,
    numOfItems: 0,
  };
  paginationStateApprovals: PaginationState = {
    page: 1,
    pageSize: 10,
    total: 0,
    numOfItems: 0,
  };
  fetchingResponse: boolean;

  modalActive = false;
  modalTitle: string;
  modalColor: string;

  createNewUser = false;
  displayUser = false;
  editUser = false;

  displayEmptyState = false;
  currentEmptyState: EmptyState;
  noUsersSearchedEmptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Emoji-Monocle.png',
    headingText: $localize`:@@users.emptyState.noUsersSearchedEmptyState.headingText:Vi hittade ingen match för&nbsp;sökningen`,
    bodyText: $localize`:@@users.emptyState.noUsersSearchedEmptyState.bodyText:Ge det ett till försök. Prova&nbsp;igen.`,
  };
  noUsersFilteredEmptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Emoji-Ghost.png',
    headingText: $localize`:@@users.emptyState.noUsersFilteredEmptyState.headingText:Här var det tomt just&nbsp;nu`,
  };

  columns: ColumnDefinition<CompanyRepresentative, RepresentativeStatus>[] = [
    {
      type: 'user-name',
      text: $localize`:@@users.columns.userName.text:Namn`,
      cssClasses: 'medium2 avoid-overflow first-column',
      columnDataTransformer: (companyRepresentative) =>
        companyRepresentative.user.displayName,
      columnColor: (companyRepresentative) => companyRepresentative.roleColor,
    },
    {
      filterable: true,
      cssClasses: 'right-align',
      columnDataTransformer: (companyRepresentative) =>
        companyRepresentative.role.displayName,
      filterOptions: {
        text: $localize`:@@users.columns.roleFilter.text:Roll`,
        value: 'role',
        activeFilter: null,
        options: this.getRoleFilterOptions(),
      },
    },
    {
      text: $localize`:@@users.columns.createdDate.text:Tillagd`,
      cssClasses: 'right-align',
      columnDataTransformer: (companyRepresentative) =>
        new DatePipe(this.localeService.getCurrentLocale()).transform(
          companyRepresentative.created,
          'd MMM y'
        ),
    },
    {
      filterable: true,
      type: 'status',
      text: 'Status',
      filterOptions: {
        text: $localize`:@@users.columns.statusFilter.text:Status`,
        value: 'status',
        activeFilter: null,
        options: [
          {
            headerText: $localize`:@@users.columns.statusFilter.options.all.headerText:alla`,
            displayText: $localize`:@@users.columns.statusFilter.options.all.displayText:Visa alla`,
            value: null,
          },
          {
            headerText: $localize`:@@users.columns.statusFilter.options.pendingUserApproval.headerText:inväntar svar`,
            displayText: $localize`:@@users.columns.statusFilter.options.pendingUserApproval.displayText:Inväntar svar`,
            value: RepresentativeStatus.PENDING_USER_APPROVAL,
          },
          {
            headerText: $localize`:@@users.columns.statusFilter.options.active.headerText:aktiva`,
            displayText: $localize`:@@users.columns.statusFilter.options.active.displayText:Aktiva`,
            value: RepresentativeStatus.ACTIVE,
          },
          {
            headerText: $localize`:@@users.columns.statusFilter.options.inactive.headerText:inaktiva`,
            displayText: $localize`:@@users.columns.statusFilter.options.inactive.displayText:Inaktiva`,
            value: RepresentativeStatus.INACTIVE,
          },
        ],
      },
      columnDataTransformer: (companyRepresentative) =>
        companyRepresentative.statusText,
      columnHeadingStylingTransformer: () => 'width: 210px;',
      columnStylingTransformer: () => 'width: 210px;',
    },
  ];

  quickActions: QuickAction<CompanyRepresentative>[] = [
    {
      text: $localize`:@@users.quickActions.inactivate.text:Inaktivera`,
      iconUrl: 'assets/icons/Icon-smiley-sleepy.svg',
      textColor: '#0A1018',
      disabled: false,
      displayCondition: (companyRepresentative) =>
        this.access.userMay(Permission.EDIT_USER) &&
        companyRepresentative.status === RepresentativeStatus.ACTIVE &&
        this.access.mayEditUser(companyRepresentative),
      topBorder: false,
      function: (companyRepresentative) =>
        this.inactivateRepresentative(companyRepresentative),
    },
    {
      text: $localize`:@@users.quickActions.activate.text:Aktivera`,
      iconUrl: 'assets/icons/Icon-Bolt.svg',
      textColor: '#0A1018',
      disabled: false,
      displayCondition: (companyRepresentative) =>
        this.access.userMay(Permission.EDIT_USER) &&
        companyRepresentative.status === RepresentativeStatus.INACTIVE &&
        this.access.mayEditUser(companyRepresentative),
      topBorder: false,
      function: (companyRepresentative) =>
        this.activateRepresentative(companyRepresentative),
    },
    {
      text: $localize`:@@users.quickActions.edit.text:Redigera`,
      iconUrl: 'assets/icons/Icon-Edit-Box.svg',
      textColor: '#0A1018',
      disabled: false,
      displayCondition: (companyRepresentative) =>
        this.access.userMay(Permission.EDIT_USER) &&
        this.access.mayEditUser(companyRepresentative),
      topBorder: false,
      function: (companyRepresentative) => this.edit(companyRepresentative),
    },
  ];

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    if (this.deviceIsMobile()) {
      if (this.statePushedToHistory) {
        this.statePushedToHistory = false;
      }
      if (this.modalActive) {
        this.closeModal();
      }
    }
  }
  ngOnDestroy(): void {
    if (this.deviceIsMobile()) {
      if (this.modalActive) {
        this.closeModal();
      }
    }
  }

  ngOnInit() {
    if (window.location.hash === '#modal') {
      history.back();
    }
    this.setup();
    window.onbeforeunload = () => this.ngOnDestroy();
  }

  private setup() {
    super.clearError();
    this.fetchingResponse = true;
    this.companyRepresentativeService
      .get()
      .then((result) => {
        this.representatives = result;
        // this.setStatusInfo();
        this.setupRepresentatives();
        this.updateListOfRepresentatives();
        this.paginationState.total = this.searchedListOfRepresentatives.length;
        this.fetchingResponse = false;
      })
      .catch((error) => {
        console.error(error);
        this.fetchingResponse = false;
        super.handleError(error);
      });
  }

  getRoleFilterOptions() {
    const roleOptions: FilterOption<RepresentativeStatus>[] = [
      {
        headerText: $localize`:@@users.roleOptions.all.headerText:alla`,
        displayText: $localize`:@@users.roleOptions.all.displayText:Visa alla`,
        value: null,
      },
    ];
    let order = 1;
    this.appState.getMerchantRoles().forEach((merchantRole) => {
      roleOptions.push({
        headerText: merchantRole.role.displayName.toLowerCase(),
        displayText: merchantRole.role.displayName,
        value: null,
      });
      order++;
    });
    return roleOptions;
  }

  newCompanyRepresentativeCreated() {
    this.paginationState.page = 1;
    this.companyRepresentativeEvent();
  }

  onPageChange($event) {
    this.paginationState.page = $event;
    this.updateListOfRepresentatives();
  }

  onPageSizeChange($event) {
    this.paginationState.pageSize = $event;
    this.paginationState.page = 1;
    this.updateListOfRepresentatives();
  }

  onFilterChange(event: {
    type: string;
    filterOption: FilterOption<RepresentativeStatus>;
  }) {
    if (event.type === 'role') {
      this.roleFilter = event.filterOption.value;
      this.columns[1].filterOptions.activeFilter = event.filterOption.value;
    } else if (event.type === 'status') {
      this.statusFilter = event.filterOption.value;
      this.columns[3].filterOptions.activeFilter = event.filterOption.value;
    }
    this.paginationState.page = 1;
    this.updateListOfRepresentatives();
  }

  updateListOfRepresentatives() {
    this.displayEmptyState = false;
    if (this.roleFilter === '' && this.statusFilter === '') {
      this.filteredListOfRepresentatives = this.representatives;
    } else if (this.roleFilter !== '' && this.statusFilter !== '') {
      this.filteredListOfRepresentatives = this.representatives.filter(
        (item) =>
          item.role.id === this.roleFilter && item.status === this.statusFilter
      );
    } else if (this.roleFilter !== '') {
      this.filteredListOfRepresentatives = this.representatives.filter(
        (item) => item.role.id === this.roleFilter
      );
    } else if (this.statusFilter !== '') {
      this.filteredListOfRepresentatives = this.representatives.filter(
        (item) => item.status === this.statusFilter
      );
    }
    this.searchedListOfRepresentatives = this.searchPipe.transform(
      this.filteredListOfRepresentatives,
      'user.displayName,personalNumber,phone,email,storeRelation.store.name',
      this.filterQuery
    );

    if (this.searchedListOfRepresentatives.length === 0) {
      this.setEmptyState();
    }

    this.updateCurrentPageOfRepresentatives();
  }

  updateCurrentPageOfRepresentatives() {
    this.currentPageOfRepresentatives.rows = this.searchedListOfRepresentatives
      .slice(
        (this.paginationState.page - 1) * this.paginationState.pageSize,
        this.paginationState.page * this.paginationState.pageSize
      )
      .map((user) => ({
        data: user,
        quickActions: this.getQuickActionsForRepresentative(user),
      }));

    this.paginationState.numOfItems =
      this.currentPageOfRepresentatives.rows.length;
  }

  setEmptyState() {
    if (
      this.filterQuery !== '' &&
      this.statusFilter === '' &&
      this.roleFilter === ''
    ) {
      this.currentEmptyState = this.noUsersSearchedEmptyState;
    } else if (this.statusFilter !== '' || this.roleFilter !== '') {
      this.currentEmptyState = this.noUsersFilteredEmptyState;
    }
    this.displayEmptyState = true;
  }

  create() {
    this.modalTitle = $localize`:@@users.modal.create.title:Lägg till användare`;
    this.modalColor = this.colorService.getCeriseColorCode();
    this.createNewUser = true;
    this.activateModal();
  }

  edit(user: CompanyRepresentative) {
    this.selectedRepresentative = user;
    this.modalColor = this.selectedRepresentative.roleColor;
    this.modalTitle = this.selectedRepresentative.role.displayName;
    this.displayUser = false;
    this.editUser = true;
    this.activateModal();
  }

  open(rep: CompanyRepresentative) {
    this.selectedRepresentative = rep;
    this.modalColor = this.selectedRepresentative.roleColor;
    this.modalTitle = this.selectedRepresentative.role.displayName;
    this.displayUser = true;
    this.activateModal();
  }

  companyRepresentativeEvent() {
    this.closeModal();
    this.setup();
  }

  closeModal() {
    this.deactivateModal();
    this.createNewUser = false;
    this.displayUser = false;
    this.editUser = false;
    if (this.statePushedToHistory && this.deviceIsMobile()) {
      this.statePushedToHistory = false;
      history.back();
    }
  }

  activateModal() {
    if (!this.statePushedToHistory && this.deviceIsMobile()) {
      history.pushState(
        null,
        null,
        `${this.localeService.getCurrentLocaleBaseHref()}/admin/users#modal`
      );
      this.statePushedToHistory = true;
    }
    this.modalActive = true;
  }

  deactivateModal() {
    this.modalActive = false;
  }

  setActiveTab(page: UserPage) {
    this.activePage = page;
  }

  showAutoRegistrationApprovals() {
    return this.appState.merchantHasAutoRegistration();
  }

  setupRepresentatives() {
    this.representatives.map((rep) => CompanyRepresentative.setup(rep));
  }

  updateModalTextAndColor(event: { roleText: string; roleColor: string }) {
    if (event.roleColor) {
      this.modalColor = event.roleColor;
    }
    if (event.roleText) {
      this.modalTitle = event.roleText;
    }
  }

  setNumOfPendingApprovals(num: number) {
    this.numOfPendingApprovals = num;
    this.tabs.find(
      (tab) => tab.value === UserPage.PENDING_APPROVAL
    ).badgeCount = num;
  }

  searchUpdatedQuery(event) {
    this.filterQuery = event;
    this.paginationState.page = 1;
    this.updateListOfRepresentatives();
  }

  deviceIsMobile() {
    return window.innerWidth <= 520;
  }

  getQuickActionsForRepresentative(
    representative: CompanyRepresentative
  ): QuickAction<CompanyRepresentative>[] {
    return this.quickActions.filter((action) =>
      action.displayCondition(representative)
    );
  }

  inactivateRepresentative(representative: CompanyRepresentative): void {
    this.companyRepresentativeService
      .setInactive(representative.id)
      .then(() => {
        this.toastService.displaySuccess(
          $localize`:@@users.toast.inactivate.success:Användaren har inaktiverats`
        );
        this.setup();
      })
      .catch((error) => {
        this.handleError(error);
        this.toastService.displayError(
          $localize`:@@users.toast.inactivate.fail:Användaren kunde inte inaktiveras`
        );
      });
  }

  activateRepresentative(representative: CompanyRepresentative): void {
    this.companyRepresentativeService
      .setActive(representative.id)
      .then(() => {
        this.toastService.displaySuccess(
          $localize`:@@users.toast.activate.success:Användaren har aktiverats`
        );
        this.setup();
      })
      .catch((error) => {
        this.handleError(error);
        this.toastService.displayError(
          $localize`:@@users.toast.activate.fail:Användaren kunde inte aktiveras`
        );
      });
  }

  mayCreateNewUser(): boolean {
    return this.access.userMay(Permission.CREATE_USER);
  }
}
