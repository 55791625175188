import { Component, OnInit } from '@angular/core';
import { ToastMessage, ToastMessageService } from '@core/service/toast-message.service';

@Component({
  selector: 'app-toast-messages',
  templateUrl: './toast-messages.component.html',
  styleUrls: ['./toast-messages.component.css']
})
export class ToastMessagesComponent implements OnInit {

  // Stores all the toasts that are visible to the user. 
  toasts: ToastMessage[] = [];

  constructor(toastService: ToastMessageService) {
    toastService.toastMessageSubject.subscribe(message => {
      this.toasts.push(message);
      setTimeout(() => {
        this.removeToastById(message.id);
      }, this.getToastVisibilityTimeMs());
    });
   }

  ngOnInit(): void {
  }

  iconSrcUrl(toast: ToastMessage): string {
    return 'assets/icons/Icon-' + toast.type.charAt(0).toUpperCase() + toast.type.slice(1) + '.svg';
  }

  removeToastById(id: string) {
    this.toasts = this.toasts.filter(toast => toast.id !== id);
  }

  getToastVisibilityTimeMs() {
    // Show the toasts for a shorter period of time on mobile
    if (window.innerWidth <= 768) {
      return 3000;
    } else {
      return 6000;
    }
  }

}
