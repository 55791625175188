export const LANG_DE = {
  weekDays: [
    {
      letter: 'M',
      short: 'Mo',
      full: 'Montag',
    },
    {
      letter: 'D',
      short: 'Di',
      full: 'Dienstag',
    },
    {
      letter: 'M',
      short: 'Mi',
      full: 'Mittwoch',
    },
    {
      letter: 'D',
      short: 'Do',
      full: 'Donnerstag',
    },
    {
      letter: 'F',
      short: 'Fr',
      full: 'Freitag',
    },
    {
      letter: 'S',
      short: 'Sa',
      full: 'Samstag',
    },
    {
      letter: 'S',
      short: 'So',
      full: 'Sonntag',
    },
  ],
  months: [
    {
      index: 0,
      short: 'Jan',
      full: 'Januar',
    },
    {
      index: 1,
      short: 'Feb',
      full: 'Februar',
    },
    {
      index: 2,

      short: 'März',
      full: 'März',
    },
    {
      index: 3,

      short: 'Apr',
      full: 'April',
    },
    {
      index: 4,

      short: 'Mai',
      full: 'Mai',
    },
    {
      index: 5,

      short: 'Jun',
      full: 'Juni',
    },
    {
      index: 6,

      short: 'Jul',
      full: 'Juli',
    },
    {
      index: 7,

      short: 'Aug',
      full: 'August',
    },
    {
      index: 8,

      short: 'Sep',
      full: 'September',
    },
    {
      index: 9,

      short: 'Okt',
      full: 'Oktober',
    },
    {
      index: 10,

      short: 'Nov',
      full: 'November',
    },
    {
      index: 11,
      short: 'Dez',
      full: 'Dezember',
    },
  ],
};
