import { RepresentativeStatus } from '@core/params';

export interface CompanyRepresentativeStatusRepresentation {
  friendlyName: string;
  textColor: string;
  backgroundColor?;
}

export const CompanyRepresentativeStatusRepresentations: Map<
  string,
  CompanyRepresentativeStatusRepresentation
> = new Map([
  // Aktiv
  [
    RepresentativeStatus.ACTIVE,
    {
      friendlyName: $localize`:@@representativeStatus.active:Aktiv`,
      textColor: '#009773',
      backgroundColor: '#E5F5F2'
    },
  ],
  // Inaktiv
  [
    RepresentativeStatus.INACTIVE,
    {
      friendlyName: $localize`:@@representativeStatus.inactive:Inaktiv`,
      textColor: $localize`:@@representativeStatus.inactive.color:#DB305E`,
      backgroundColor: '#FDEBF0'
    },
  ],
  // Inväntar svar
  [
    RepresentativeStatus.PENDING_USER_APPROVAL,
    {
      friendlyName: $localize`:@@representativeStatus.pendingUserApproval:Inväntar svar`,
      textColor: '#4466EE',
      backgroundColor: '#DBE7FF'
    },
  ],
  // Fallback if an unknown status is encountered
  [
    '_default_',
    {
      friendlyName: $localize`:@@representativeStatus.unknown:Okänd`,
      textColor: '#000000',
    },
  ],
]);
