export class Owner {
  type: 'legal'|'natural';
  name: string;
  holdingShare: string;
  votesShare: string;
  organizationNumber: string; // Company
  personalNumber: string;     // Person
  pep: string;                // Person
  owners: Owner[];
  legalEntityId: string;

  constructor() {
    this.owners = [];
  }
}
