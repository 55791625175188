import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '@core/base.component';
import { Store, StoreType } from '@core/dto/Store';
import { AuthenticationService } from '@core/service/authentication.service';
import { CustomizationService } from '@core/service/customization.service';
import { LocaleService } from '@core/service/locale.service';
import { StoreService } from '@core/service/store.service';
import { ToastMessageService } from '@core/service/toast-message.service';

@Component({
  selector: 'app-edit-store',
  templateUrl: './edit-store.component.html',
  styleUrls: ['./edit-store.component.scss'],
})
export class EditStoreComponent extends BaseComponent implements OnInit {
  @Input() store: Store;

  nameInputTitle: string;
  loading = true;
  updatingStore = false;

  selectedStoreType: StoreType;

  form: FormGroup;
  showError = false;
  formNameErrorMessage = $localize`:@@editStore.formNameErrorMessage:Ange namn på ${this.customizationService.getStoreAlias(
    false,
    true
  )}`;

  @Output() onUpdated = new EventEmitter();
  @Output() promptCancel = new EventEmitter();

  constructor(
    private storeService: StoreService,
    auth: AuthenticationService,
    private customizationService: CustomizationService,
    private toastService: ToastMessageService,
    public localeService: LocaleService
  ) {
    super(auth);
  }

  ngOnInit(): void {
    this.setup();
  }

  setup() {
    this.loading = true;
    this.selectedStoreType = this.store.type;
    this.form = new FormGroup({
      name: new FormControl(this.store.name, [Validators.required]),
    });
    this.prepareNameInputTitle();
    this.loading = false;
  }

  submit() {
    this.errorMessage = '';
    if (this.form.valid) {
      this.updatingStore = true;
      const name: string = this.form.value.name;
      const newStore = new Store(name.trim(), this.selectedStoreType);
      newStore.id = this.store.id;
      this.storeService
        .update(newStore)
        .then((response: Store) => {
          this.updatingStore = false;
          const storeAlias = this.customizationService.getStoreAlias(
            false,
            true,
            false,
            true
          );
          this.toastService.displaySuccess(
            $localize`:@@editStore.toast.update.success:${storeAlias} har uppdaterats`
          );
          this.onUpdated.emit();
        })
        .catch((error) => {
          this.handleError(error);
          this.updatingStore = false;
        });
    } else {
      this.showError = true;
    }
  }

  prepareNameInputTitle() {
    this.nameInputTitle = $localize`:@@editStore.nameInputTitle:${this.customizationService.getStoreAlias(
      false,
      true,
      true,
      true
    )} namn`;
  }

  getStoreAliasName(): string {
    return this.customizationService.getStoreAlias(false, false, false, true);
  }

  clickedStoreType(storeType: string) {
    this.selectedStoreType = StoreType[storeType];
  }
}
