import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@core/base.component';
import { SignupLegalEntity } from '@core/dto/signup/owner/SignupLegalEntity';
import { AuthenticationService } from '@core/service/authentication.service';
import { LegalEntityService } from '@core/service/legal-entity.service';

@Component({
  selector: 'app-company-form-confirmation',
  templateUrl: './company-form-confirmation.component.html',
  styleUrls: ['./company-form-confirmation.component.css'],
})
export class CompanyFormConfirmationComponent
  extends BaseComponent
  implements OnInit
{
  @Input() signupLegalEntityToCreate: SignupLegalEntity;

  @Output() onAbort = new EventEmitter();
  @Output() onSubmit = new EventEmitter<SignupLegalEntity>();

  submitting = false;
  serverErrorMessage = $localize`:@@companyFormConfirmation.serverErrorMessage:Ändringarna kunde inte sparas. Vänligen försök igen eller avbryt genom att stänga. <a class="link" href="mailto:merchants@zaver.se" target="_blank">Kontakta&nbsp;oss</a> om problemet kvarstår.`;

  constructor(
    private legalEntityService: LegalEntityService,
    auth: AuthenticationService
  ) {
    super(auth);
  }

  ngOnInit(): void {}

  closeModal() {
    this.onAbort.emit();
  }

  submit() {
    this.submitting = true;
    this.legalEntityService
      .create(this.signupLegalEntityToCreate)
      .then((response) => {
        this.onSubmit.emit(response);
      })
      .catch((error) => {
        this.handleError(error);
        this.submitting = false;
      });
  }
}
