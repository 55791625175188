import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '@core/base.component';
import { SignupPrivateCompany } from '@core/dto/signup/owner/SignupPrivateCompany';
import { Industry, IndustryOptions } from '@core/dto/signup/owner/Bransch';
import { AuthenticationService } from '@core/service/authentication.service';
import { PrivateCompanyService } from '@core/service/private-company.service';
import { PEP_SOLE_TRADER } from '@core/dto/signup/owner/PEP';
import { swedishNationalIdentificationNumberValidator, swedishNationalIdentificationRegex } from '@shared/validators/identification-number-validators';
import { Observable, Subject, Subscription } from 'rxjs';
import { CompanyInfoDto, SignupService } from '@core/service/signup.service';
import { AppStateService } from '@core/service/app-state.service';
import { CompanyRepresentative } from '@core/dto/CompanyRepresentative';
import { ColorService } from '@core/service/color.service';
import { formatPhoneNumber } from '../../../../../../web-component-library/projects/component-library/src/public-api';
import { Market } from '@core/dto/Market';
import { LocaleService } from '@core/service/locale.service';

@Component({
  selector: 'app-sole-trader-form',
  templateUrl: './sole-trader-form.component.html',
  styleUrls: ['../signup-shared.css', './sole-trader-form.component.css'],
})
export class SoleTraderFormComponent extends BaseComponent implements OnInit {
  @Input() signupPrivateCompany: SignupPrivateCompany;
  @Input() companyRegistered: boolean;
  @Input() displayingInCompanyAdminPage = false;
  @Input() contactInformationForm: FormGroup;

  form: FormGroup;
  initialForm;
  formChanged = false;
  formChagedSubscription: Subscription;
  radioId;
  validated = false;
  showError = false;
  formErrorText = $localize`:@@soleTraderForm.formErrorText:Vänligen kontrollera dina svar.`;
  displayCompanyNotFoundError = false;
  industryOptions = IndustryOptions.VALUES;
  pepOptions = PEP_SOLE_TRADER.VALUES;
  submitting = false;
  correctOrganizationNumber = false;
  startOrganizationNumberValidation = false;
  companyNamePlaceholder = 'Hämtas från org.nr.';
  isDeAtMarket: boolean = false;

  modalActive = false;
  modalTitle = '';
  modalColor = this.colorService.getCeriseColorCode();
  displaySubmissionConfirmaiton = false;
  displayCancelConfirmation = false;
  confirmationSubject: Subject<boolean>;

  @Output() onSaved = new EventEmitter<SignupPrivateCompany>();
  @Output() showContactFormError = new EventEmitter();

  constructor(
    private signupService: SignupService,
    private privateCompanyService: PrivateCompanyService,
    protected auth: AuthenticationService,
    private appStateService: AppStateService,
    public colorService: ColorService,
    public localeService: LocaleService
  ) {
    super(auth);
  }

  ngOnInit() {
    this.setupForm();
    this.isDeAtMarket =
      this.localeService.getCurrentLocaleMarket() === Market.DE ||
      this.localeService.getCurrentLocaleMarket() === Market.AT;
  }

  setupForm() {
    if (!!this.signupPrivateCompany && !!this.companyRegistered) {
      // This is an onboarded merchant
      this.form = new FormGroup({
        personalNumber: new FormControl(
          {
            value: this.signupPrivateCompany.personalNumber,
            disabled: this.companyRegistered,
          },
          [Validators.required, swedishNationalIdentificationNumberValidator(), Validators.pattern(swedishNationalIdentificationRegex)]
        ),
        name: new FormControl(
          { value: this.signupPrivateCompany.name, disabled: true },
          [Validators.required]
        ),
        industry: new FormControl(this.signupPrivateCompany.industry, [
          Validators.required,
        ]),
        industryOther: new FormControl(this.signupPrivateCompany.industryOther),
        pep: new FormControl(this.signupPrivateCompany.pep, [
          Validators.required,
        ]),
        website: new FormControl(this.signupPrivateCompany.website),
      });
    } else if (!!this.signupPrivateCompany && !this.companyRegistered) {
      // This is a partner signup
      this.form = new FormGroup({
        personalNumber: new FormControl(
          {
            value: this.signupPrivateCompany.personalNumber,
            disabled: this.companyRegistered,
          },
          [Validators.required, swedishNationalIdentificationNumberValidator(), Validators.pattern(swedishNationalIdentificationRegex)]
        ),
        name: new FormControl(
          { value: this.signupPrivateCompany.name, disabled: true },
          [Validators.required]
        ),
        industry: new FormControl(this.industryOptions[0].value, [
          Validators.required,
        ]),
        industryOther: new FormControl(''),
        pep: new FormControl(this.pepOptions[0].value, [Validators.required]),
        website: new FormControl(''),
      });
      if (this.signupPrivateCompany.personalNumber) {
        // Personal number was supplied on referal-link creation.
        // Try and fetch company name using the personal number
        this.fetchCompanyInformationUsingPersonalNumber();
      }
    } else {
      // This is a normal signup
      this.form = new FormGroup({
        personalNumber: new FormControl('', [
          Validators.required,
          swedishNationalIdentificationNumberValidator(),
          Validators.pattern(swedishNationalIdentificationRegex)
        ]),
        name: new FormControl({ value: '', disabled: true }, [
          Validators.required,
        ]),
        industry: new FormControl(this.industryOptions[0].value, [
          Validators.required,
        ]),
        industryOther: new FormControl(''),
        pep: new FormControl(this.pepOptions[0].value, [Validators.required]),
        website: new FormControl(''),
      });
    }
    this.initialForm = JSON.stringify(this.form.value);
    if (this.formChagedSubscription) {
      this.formChagedSubscription.unsubscribe();
    }
    this.formChagedSubscription = this.form.valueChanges.subscribe((val) => {
      if (this.initialForm === JSON.stringify(this.form.value)) {
        this.formChanged = false;
      } else {
        this.formChanged = true;
      }
    });
    this.onChanges();
    if (this.displayingInCompanyAdminPage || this.companyRegistered) {
      this.correctOrganizationNumber = true;
    }
  }

  onChanges() {
    this.form.controls['personalNumber'].valueChanges.subscribe((val) => {
      this.fetchCompanyInformationUsingPersonalNumber();
    });
  }

  async submit() {
    super.clearError();
    this.validated = true;
    if (
      this.form.valid &&
      (!this.contactInformationForm ||
        this.contactInformationForm.valid ||
        this.contactInformationForm.disabled)
    ) {
      this.showError = false;
      this.errorMessage = '';

      try {
        const activeRep =
          this.appStateService.getCurrentUser().activeRepresentation;

        const compRepUpdate: CompanyRepresentative = new CompanyRepresentative(
          activeRep.id,
          activeRep.personalNumber,
          formatPhoneNumber(this.contactInformationForm.controls.phone.value),
          activeRep.email,
          activeRep.role
        );
        // Save email och phone to the acting representative
        await this.signupService.updateSignupRepresentativeUserInfo(
          compRepUpdate
        );

        const signupPrivateCompanyToCreate = new SignupPrivateCompany(
          this.form.controls.name.value,
          this.form.controls.personalNumber.value,
          this.form.controls.pep.value,
          this.form.controls.website.value,
          this.form.controls.industry.value,
          this.form.controls.industryOther.value
        );

        const response = await this.privateCompanyService.create(
          signupPrivateCompanyToCreate
        );
        if (this.displayingInCompanyAdminPage) {
          this.signupPrivateCompany = response;
          this.formChanged = false;
        } else {
          this.onSaved.emit();
        }
      } catch (error: unknown) {
        this.handleError(error);
      }
    } else {
      this.showError = true;
      this.showContactFormError.emit();
    }
  }

  promptCancelationConfirmation() {
    this.modalTitle = $localize`:@@soleTraderForm.modal.title.cancel:Avbryt`;
    this.modalColor = this.colorService.getCeriseColorCode();
    this.displayCancelConfirmation = true;
    this.modalConfirmation().subscribe((data) => {
      if (data) {
        this.confirmCancelation();
      } else {
        this.closeModal();
      }
    });
  }

  confirmCancelation() {
    this.cancel();
    this.closeModal();
  }

  cancel() {
    this.setupForm();
    this.formChanged = false;
    this.showError = false;
    this.errorMessage = '';
  }

  promptCompanyInfoSubmissionConfirmation() {
    this.clearError();
    this.validated = true;
    if (this.form.valid) {
      this.errorMessage = '';
      this.modalTitle = $localize`:@@soleTraderForm.modal.title.update:Uppdatera företagsinformation`;
      this.modalColor = '#00A880';
      this.displaySubmissionConfirmaiton = true;
      this.modalConfirmation().subscribe((data) => {
        if (data) {
          this.adminSubmit();
        } else {
          this.closeModal();
          this.confirmationSubject.complete();
        }
      });
    } else {
      this.showError = true;
    }
  }

  adminSubmit() {
    this.submitting = true;
    this.errorMessage = '';
    this.formChanged = false;
    const signupPrivateCompanyToCreate = new SignupPrivateCompany(
      this.form.controls.name.value,
      this.form.controls.personalNumber.value,
      this.form.controls.pep.value,
      this.form.controls.website.value,
      this.form.controls.industry.value,
      this.form.controls.industryOther.value
    );

    this.privateCompanyService
      .create(signupPrivateCompanyToCreate)
      .then((response) => {
        this.signupPrivateCompany = response;
        this.setupForm();
        this.formChanged = false;
        this.showError = false;
        this.errorMessage = '';
        this.submitting = false;
        this.closeModal();
        this.confirmationSubject.complete();
      })
      .catch((error) => {
        this.handleError(error);
        this.submitting = false;
      });
  }

  closeModal() {
    this.modalActive = false;
    this.displaySubmissionConfirmaiton = false;
    this.displayCancelConfirmation = false;
  }

  modalConfirmation(): Observable<boolean> {
    this.confirmationSubject = new Subject();
    this.modalActive = true;
    return this.confirmationSubject.asObservable();
  }

  action(value: boolean) {
    this.confirmationSubject.next(value);
  }

  fetchCompanyInformationUsingPersonalNumber() {
    let organizationNumber: string = this.form.controls['personalNumber'].value;
    organizationNumber = organizationNumber.replace(/-/g, '');
    if (organizationNumber.length >= 10) {
      this.startOrganizationNumberValidation = true;
    }
    if (this.form.controls['personalNumber'].valid) {
      let companyNumberToSend = this.form.controls['personalNumber'].value;
      companyNumberToSend = companyNumberToSend.replace(/-/g, '');
      this.signupService
        .getCompanyInfo(companyNumberToSend)
        .then((res) => {
          this.handleGetCompanyInfoResponse(res);
        })
        .catch((err) => {
          this.handleError(err);
          this.form.controls['name'].enable();
          this.companyNamePlaceholder = 'Ange företagsnamn';
          this.correctOrganizationNumber = true;
        });
    } else {
      this.form.controls['name'].setValue('', { emitEvent: false });
      this.displayCompanyNotFoundError = false;
      this.form.controls['name'].disable();
      this.companyNamePlaceholder = 'Hämtas från org.nr.';
    }
  }

  handleGetCompanyInfoResponse(response: CompanyInfoDto): void {
    if (!!response.error) {
      if (response.error === 'COMPANY_NOT_FOUND') {
        this.displayCompanyNotFoundError = true;
      } else if (response.error === 'AWAITING_LOOKUP_RESPONSE') {
        return;
      }
      this.form.controls['name'].setValue('', { emitEvent: false });
      this.form.controls['name'].enable();
      this.companyNamePlaceholder = 'Ange företagsnamn';
    } else {
      this.form.controls['name'].disable();
      this.companyNamePlaceholder = 'Hämtas från org.nr.';
      this.form.controls['name'].setValue(response.companyName, {
        emitEvent: false,
      });
    }
    this.correctOrganizationNumber = true;
  }

  getIndustryValue(): string {
    if (this.form.controls.industry.value === Industry.OTHER) {
      return this.form.controls.industryOther.value;
    } else {
      return this.form.controls.industry.value;
    }
  }

  handleIndustryValueChange(value: string) {
    if (Object.values<string>(Industry).includes(value)) {
      this.form.controls.industry.setValue(Industry[value]);
      this.form.controls.industryOther.clearValidators();
    } else {
      this.form.controls.industry.setValue(Industry.OTHER);
      this.form.controls.industryOther.setValue(value);
      this.form.controls.industryOther.setValidators([Validators.required]);
    }
    this.form.controls.industryOther.updateValueAndValidity({
      onlySelf: false,
      emitEvent: true,
    });
  }
}
