<div class="small-modal-wrapper">
  <ng-container *ngIf="!serverError">
    <span i18n="@@cancelPaymentRequest.mainText"
      >Är du säker på att du vill avbryta betalningsförfrågan? Åtgärden kommer
      inte gå att ångra. 😯</span
    >
    <app-form-error *ngIf="errorMessage" [errorText]="errorMessage">
    </app-form-error>

    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        text="Tillbaka"
        i18n-text="@@cancelPaymentRequest.backButton"
        [disabled]="cancellationInProgress"
        (click)="returnToMainModal()"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        text="Ja, avbryt"
        i18n-text="@@cancelPaymentRequest.confirmButton"
        [loading]="cancellationInProgress"
        [color]="'black'"
        (click)="cancel()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </ng-container>
  <ng-container *ngIf="serverError">
    <app-server-error [errorMessage]="serverErrorMessage"></app-server-error>
    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        text="Stäng"
        i18n-text="@@cancelPaymentRequest.serverError.backButton"
        [disabled]="cancellationInProgress"
        (click)="closeModal()"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        text="Försök igen"
        i18n-text="@@cancelPaymentRequest.serverError.confirmButton"
        [loading]="cancellationInProgress"
        [color]="'black'"
        (click)="cancel()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </ng-container>
</div>
