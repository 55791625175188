<div id="contain-me">
  <div id="unmatched-info-container">
    <div style="margin-bottom: 4px">
      <span class="medium2">OCR/Meddelande:</span>&nbsp;
      <span>{{ nonMatchedPayment.reference }}</span>
    </div>
    <div style="margin-bottom: 12px">
      <span class="medium2">Belopp:</span>&nbsp;
      <span>{{
        nonMatchedPayment.amount | currency: 'SEK ':'code':'1.2-2':'sv'
      }}</span>
    </div>
    <div style="color: #dd1356">
      <span class="medium">Inbetald:</span>&nbsp;
      <span>{{
        nonMatchedPayment.paymentDate | date: 'mediumDate':'':'sv'
      }}</span>
    </div>
  </div>
  <div class="title hide-labels-mobile">
    <span>Sök för att matcha mot rätt betalningsförfrågan</span>
  </div>
  <div style="padding-bottom: 8px">
    <zaver-search
      [placeholder]="'Företag, org nr, belopp, artikel m.m. ...'"
      [size]="'large'"
      id="search-bar"
      [inputModel]="searchQuery"
      (inputModelChange)="searchUpdatedQuery($event)"
    ></zaver-search>
  </div>
  <div *ngIf="formError" style="margin-top: 12px">
    <app-form-error *ngIf="formError" [errorText]="formError"> </app-form-error>
  </div>
  <div *ngIf="fetchingResponse" style="margin-top: 12px">
    <div *ngIf="fetchingResponse" class="page-loader-container">
      <app-page-loader></app-page-loader>
    </div>
  </div>
  <ng-container *ngIf="!fetchingResponse">
    <div id="search-results-container" *ngIf="paymentRequests.length > 0">
      <div
        class="search-result-container"
        *ngFor="let paymentRequest of paymentRequests"
      >
        <div
          class="accordion-button-container"
          (click)="toggleDescription(paymentRequest)"
        >
          <div id="show-button">
            <ng-container *ngIf="!paymentRequest.showDescription">
              <svg
                width="14"
                height="9"
                viewBox="0 0 14 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.228574 0.387718C0.533359 0.0660944 1.02754 0.0660702 1.33235 0.387663L7 6.36731L12.6676 0.387663C12.9725 0.0660702 13.4666 0.0660944 13.7714 0.387718C14.0762 0.709341 14.0762 1.23077 13.7714 1.55236L7.84529 7.80467C7.84515 7.80482 7.84501 7.80497 7.84487 7.80511C7.73401 7.92214 7.6024 8.01499 7.45753 8.07837C7.31249 8.14182 7.15701 8.17448 7 8.17448C6.84299 8.17448 6.68751 8.14182 6.54246 8.07837C6.39759 8.01498 6.26597 7.92212 6.1551 7.80508C6.15497 7.80495 6.15484 7.80481 6.15471 7.80467L0.228625 1.55236C-0.0761884 1.23077 -0.0762113 0.709341 0.228574 0.387718ZM7.25885 6.64041C7.25895 6.64051 7.25904 6.64061 7.25913 6.64071L7.25885 6.64041Z"
                    fill="#6A7381"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="14" height="8.23529" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </ng-container>
            <ng-container *ngIf="paymentRequest.showDescription">
              <svg
                width="14"
                height="9"
                viewBox="0 0 14 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_4190:31674)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7 1.85384L1.33235 7.98789C1.02754 8.31779 0.533359 8.31776 0.228574 7.98783C-0.0762114 7.6579 -0.0761883 7.12301 0.228625 6.79311L6.15471 0.37936C6.15478 0.379283 6.15485 0.379205 6.15493 0.379127C6.15499 0.379054 6.15506 0.37898 6.15513 0.378907C6.26599 0.25886 6.3976 0.163612 6.54246 0.0985996C6.68751 0.0335056 6.84298 0 7 0C7.15702 0 7.31249 0.0335056 7.45753 0.0985996C7.6024 0.163612 7.73401 0.25886 7.84487 0.378907C7.84501 0.379058 7.84515 0.379209 7.84529 0.37936L13.7714 6.79311C14.0762 7.12301 14.0762 7.6579 13.7714 7.98783C13.4666 8.31776 12.9725 8.31779 12.6676 7.98789L7 1.85384ZM7.25927 1.57323C7.25913 1.57338 7.25899 1.57353 7.25885 1.57368L7.25927 1.57323Z"
                    fill="#6A7381"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4190:31674">
                    <rect width="14" height="8.23529" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </ng-container>
          </div>
        </div>
        <div class="text-container">
          <div class="main-text-row">
            <span class="medium2" style="margin-right: 12px"
              >{{ paymentRequest.b2bPaymentData.buyerCompanyName }}:</span
            >
            <span>{{ paymentRequest.b2bPaymentData.nin }}</span>
          </div>
          <div class="main-text-row">
            <span class="medium2" style="margin-right: 12px"
              >OCR/Meddelande:</span
            >
            <span>{{ paymentRequest.b2bPaymentData.invoiceReference }}</span>
          </div>
          <div class="main-text-row" style="margin-bottom: 12px">
            <span class="medium2" style="margin-right: 8px">Belopp:</span>
            <span style="margin-right: 8px">{{
              paymentRequest.value | value: localeService.getCurrentLocale()
            }}</span>
            <span [attr.style]="'color: ' + paymentRequest.statusColor + ';'"
              >({{ paymentRequest.statusText }})</span
            >
          </div>
          <div style="display: flex">
            <span class="medium2 title-text">{{ paymentRequest.title }}</span>
          </div>
          <span *ngIf="paymentRequest.showDescription" class="description">{{
            paymentRequest.description
          }}</span>
        </div>
        <div class="radio-button-container" (click)="select(paymentRequest)">
          <zaver-zfb-radio-button
            [color]="'green'"
            [selected]="selectedPaymentRequest === paymentRequest"
          ></zaver-zfb-radio-button>
        </div>
      </div>
    </div>
    <div
      *ngIf="searchQuery === '' && paymentRequests.length === 0"
      style="height: 40px"
    ></div>
    <app-empty-state
      *ngIf="searchQuery !== '' && paymentRequests.length === 0"
      [emptyState]="noMatchForSearchEmptyState"
    >
    </app-empty-state>
  </ng-container>
</div>

<div id="modal-footer">
  <button
    *ngIf="!processing"
    class="back-button"
    id="cancel-button"
    (click)="close.emit()"
  >
    <img
      src="assets/icons/Icon-Bin.svg"
      alt="Trashcan icon"
      style="margin-right: 8px"
    />
    <span>Avbryt</span>
  </button>
  <button *ngIf="!processing" id="approve-button" (click)="submit()">
    Matcha
  </button>
  <app-page-loader *ngIf="processing"></app-page-loader>
</div>
