<div class="view-representative-container">
  <div id="view-representative-header">
    <div>
      <span *ngIf="representative.personalNumber" id="display-name">
        {{ representative.user.displayName }}
      </span>
    </div>
    <app-widget-event-type-pill
      [text]="representative.statusText"
      [backgroundColor]="representative.backgroundColor"
      [textColor]="representative.statusColor"
    ></app-widget-event-type-pill>
  </div>
  <div class="list-container">
    <div class="list-row" *ngIf="representative.email">
      <div class="icon-and-text" *ngIf="representative.personalNumber">
        <img
          class="icons hide-mobile-s"
          src="assets/icons/Icon-E-mail.svg"
          alt=""
        />
        <span
          class="medium2"
          style="white-space: nowrap"
          i18n="@@viewCompanyRepresentative.email"
          >E-postadress</span
        >
      </div>
      <div class="icon-and-text" *ngIf="!representative.personalNumber">
        <img
          class="icons hide-mobile-s"
          src="assets/icons/Icon-User-Single-Black.svg"
          alt=""
        />
        <span
          class="medium2"
          style="white-space: nowrap"
          i18n="@@viewCompanyRepresentative.user"
          >Användare</span
        >
      </div>
      <div>
        <a
          class="link"
          href="mailto:{{ representative.email }}"
          target="_blank"
          >{{ representative.email }}</a
        >
      </div>
    </div>

    <div class="list-row">
      <div class="icon-and-text">
        <img
          class="icons hide-mobile-s"
          src="assets/icons/Icon-Phone.svg"
          alt=""
        />
        <span class="medium2" i18n="@@viewCompanyRepresentative.phone"
          >Mobilnummer</span
        >
      </div>
      <div *ngIf="representative.phone">
        <a href="tel:{{ representative.phone }}" class="link">{{
          representative.phone
        }}</a>
      </div>
      <div *ngIf="!representative.phone">
        -
      </div>
    </div>
    <div class="list-row" *ngIf="representative.personalNumber">
      <div class="icon-and-text">
        <img
          class="icons hide-mobile-s"
          src="assets/icons/Icon-User-Single-Black.svg"
          alt=""
        />
        <span class="medium2">Personnummer</span>
      </div>
      <div>
        {{ representative.personalNumber }}
      </div>
    </div>

    <div class="list-row" *ngIf="representative.role.mayHaveStoreRelation">
      <div class="icon-and-text">
        <img
          class="icons hide-mobile-s"
          src="assets/icons/Icon-Link.svg"
          alt=""
        />
        <span
          class="medium2"
          i18n="@@viewCompanyRepresentative.storeRelation.heading"
          >Ansluten till</span
        >
      </div>
      <div>
        <span *ngIf="representative.storeRelation">{{
          representative.storeRelation.store.name
        }}</span>
        <span
          *ngIf="!representative.storeRelation"
          i18n="@@viewCompanyRepresentative.storeRelation.noRelation"
          >Ej ansluten till {{ getStoreAlias() }}</span
        >
      </div>
    </div>
    <div class="list-row" *ngIf="representative.storeRelation">
      <div class="icon-and-text">
        <img
          class="icons hide-mobile-s"
          src="assets/icons/Icon-View-On.svg"
          alt=""
        />
        <span
          class="medium2"
          i18n="@@viewCompanyRepresentative.storeRelation.hasRelation.heading"
          >Kan hantera</span
        >
      </div>
      <div>
        <span
          *ngIf="
            representative.storeRelation.paymentRequestPermission ===
            'MANAGE_OWN_PAYMENT_REQUESTS_FOR_STORE'
          "
          class="cerise-text"
          i18n="
            @@viewCompanyRepresentative.storeRelation.hasRelation.permission.ownActvities"
          >Egna aktiviteter</span
        >
        <span
          *ngIf="
            representative.storeRelation.paymentRequestPermission ===
            'MANAGE_ALL_PAYMENT_REQUESTS_FOR_STORE'
          "
          style="color: #00a880"
          i18n="
            @@viewCompanyRepresentative.storeRelation.hasRelation.permission.storeActivities"
          >{{ getStoreAliasCapitalized() }} aktiviteter</span
        >
      </div>
    </div>
  </div>
  <div id="edit-container" *ngIf="mayEditUser()">
    <div id="edit-select-box" (click)="editClicked.emit(representative)">
      <span
        id="edit-text"
        class="link"
        i18n="@@viewCompanyRepresentative.editButton.text"
        >Redigera</span
      >
      <div id="edit-icon-div">
        <div id="edit-icon"></div>
      </div>
    </div>
  </div>
</div>
