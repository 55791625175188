import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@core/base.component';
import { AuthenticationService } from '@core/service/authentication.service';
import { PaymentRequestService, NonMatchingMatchRequest } from '@core/service/payment-request.service';

@Component({
  selector: 'app-confirm-match',
  templateUrl: './confirm-match.component.html',
  styleUrls: ['./confirm-match.component.css']
})
export class ConfirmMatchComponent extends BaseComponent implements OnInit {

  @Input() matchRequest: NonMatchingMatchRequest;

  requestInProgress = false;
  serverErrorMessage = 'Åtgärden kunde inte genomföras. Vänligen försök igen eller avbryt genom att stänga. <a class="link" href="mailto:merchants@zaver.se" target="_blank">Kontakta&nbsp;oss</a> om problemet kvarstår.';

  @Output() onDone = new EventEmitter();
  @Output() onReturnToMainModal = new EventEmitter();
  @Output() onCloseModal = new EventEmitter();

  
  constructor(
    private paymentRequestService: PaymentRequestService,
    auth: AuthenticationService,
  ) { 
    super(auth);
   }

  ngOnInit(): void {
  }

  returnToMainModal() {
    this.onReturnToMainModal.emit();
  }

  closeModal() {
    this.onCloseModal.emit();
  }

  submit() {
    this.errorMessage = '';
    this.requestInProgress = true;
    this.paymentRequestService.matchNonMatchWithPaymentRequest(this.matchRequest)
    .then((res: boolean) => {
      if (res) {
        this.onDone.emit();
      } else {
        this.serverError = true;
        this.requestInProgress = false;
      }
    })
    .catch(error => {
      this.handleError(error);
      this.errorMessage = '';
      this.requestInProgress = false;
    });
  }
}
