<div *ngIf="rows && columns" id="table-container">
  <div class="table">
    <div class="header">
      <div
        class="col medium"
        [ngClass]="column.cssClasses"
        *ngFor="let column of columns"
      >
        <span>{{ column.text }}</span>
      </div>
    </div>
    <div
      class="row"
      [class.hover-color]="!!rows.rowClickFunction"
      *ngFor="let row of rows.data"
    >
      <div
        class="col"
        [ngClass]="column.cssClasses"
        [attr.style]="getColumnStyling(column, row)"
        *ngFor="let column of columns"
        (click)="rowClicked(row)"
      >
        <span [innerHTML]="column.columnDataTransformer(row)"></span>
      </div>
    </div>
  </div>
</div>
