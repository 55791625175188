<div id="form-wrapper" class="spacing-container">
  <form [formGroup]="form" (ngSubmit)="submit()" novalidate *ngIf="!loading">
    <div
      class="input-fields"
      [class.error]="form.controls.name.invalid && showError"
    >
      <zaver-form-input
        [title]="nameInputTitle"
        placeholder="Ange namn"
        i18n-placeholder="@@createStore.form.name.placeholder"
        [error]="form.controls.name.invalid && showError"
        [errorMessage]="formNameErrorMessage"
        formControlName="name"
        [maxLength]="255"
        [size]="'large'"
      >
      </zaver-form-input>
    </div>
    <div class="input-fields">
      <div class="input-section">
        <div class="input-heading">
          <span i18n="@@createStore.form.type.heading">Välj typ</span>
        </div>
        <div class="radio-button-group">
          <div
            class="radio-button-with-label"
            (click)="clickedStoreType('STORE')"
          >
            <zaver-zfb-radio-button
              [color]="'cerise'"
              [selected]="selectedStoreType === 'STORE'"
            ></zaver-zfb-radio-button>
            <span class="radio-button-label">{{ getStoreAliasName() }}</span>
          </div>
          <div
            class="radio-button-with-label"
            (click)="clickedStoreType('E_COMMERCE')"
          >
            <zaver-zfb-radio-button
              [color]="'cerise'"
              [selected]="selectedStoreType === 'E_COMMERCE'"
            ></zaver-zfb-radio-button>
            <span class="radio-button-label" i18n="@@createStore.form.eCommerce"
              >E-handel</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="error-container">
      <app-form-error *ngIf="errorMessage" [errorText]="errorMessage">
      </app-form-error>
    </div>
  </form>
  <div class="single-cta-button">
    <zaver-primary-button
      text="Lägg till"
      i18n-text="@@createStore.form.button.submit"
      [loading]="creatingStore"
      (click)="submit()"
    >
    </zaver-primary-button>
  </div>
</div>
