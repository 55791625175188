import { Component, Inject, LOCALE_ID, OnInit, Renderer2 } from '@angular/core';
import { AppStateService } from './core/service/app-state.service';
import { UserDetails } from './core/dto/user-details';
import { AuthenticationService } from './core/service/authentication.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { WsService } from './core/service/ws.service';
import { Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { LocaleService } from '@core/service/locale.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  user: UserDetails;

  allowLanguageSelection$: Observable<boolean>;

  constructor(
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics, // Import enables auto-tracking.
    private appStateService: AppStateService,
    public authService: AuthenticationService,
    public router: Router,
    private wsService: WsService,
    private activatedRoute: ActivatedRoute,
    private localeService: LocaleService,
    private renderer: Renderer2
  ) {
    this.appStateService.renewContextId();
  }

  ngOnInit() {
    this.appStateService.user$.subscribe((user) => {
      this.user = user;
    });

    /*
    This class is used to set he color code of the cerise color 
    in Z4B using the styling in the global styles.scss file
    */
    if (
      this.localeService.getCurrentLocale() === 'de' ||
      this.localeService.getCurrentLocale() === 'de-AT'
    ) {
      this.renderer.addClass(document.body, 'de');
    }
  }
}
