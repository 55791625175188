import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaginatedList } from '@core/dto/PaginatedList';
import { PaymentRequestResponse } from '@core/dto/PaymentRequestResponse';
import { AuthenticationService } from '@core/service/authentication.service';
import { LocaleService } from '@core/service/locale.service';
import {
  NonMatch,
  NonMatchingMatchRequest,
  PaymentRequestService,
} from '@core/service/payment-request.service';
import { EmptyState } from '@zfb/ui/empty-state/empty-state.component';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-match-payments',
  templateUrl: './match-payments.component.html',
  styleUrls: ['./match-payments.component.css'],
})
export class MatchPaymentsComponent implements OnInit {
  @Input() nonMatchedPayment: NonMatch;

  processing = false;
  fetchingResponse = false;
  formError = '';

  selectedPaymentRequest: PaymentRequestWithShowFlag;

  paymentRequests: PaymentRequestWithShowFlag[] = [];

  noMatchForSearchEmptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Emoji-Monocle.png',
    headingText: 'Vi hittade ingen match för&nbsp;sökningen',
    bodyText: 'Ge det ett till försök. Prova&nbsp;igen.',
    lessMarginTop: true,
  };

  public searchQuery = '';
  private searchQueryChanged: Subject<string> = new Subject<string>();

  @Output() close = new EventEmitter();
  @Output() promptConfirmation = new EventEmitter<NonMatchingMatchRequest>();

  constructor(
    private paymentRequestService: PaymentRequestService,
    protected auth: AuthenticationService,
    public localeService: LocaleService
  ) {
    this.searchQueryChanged
      .pipe(
        map((q) => q.trim()),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((query) => {
        this.searchUnmatched(query);
      });
  }

  ngOnInit(): void {
    this.refreshUnmatched();
  }

  searchUpdatedQuery(query: string) {
    this.formError = '';
    this.searchQueryChanged.next(query);
  }

  searchUnmatched(query) {
    this.selectedPaymentRequest = null;
    this.searchQuery = query.trim();
    this.searchQueryChanged.next(query);
    this.refreshUnmatched();
  }

  refreshUnmatched() {
    this.fetchingResponse = true;
    this.paymentRequestService
      .search({
        search: this.searchQuery,
        buyerType: 'COMPANY',
        pageSize: 16,
      })
      .subscribe(
        (res: PaginatedList<PaymentRequestResponse>) => {
          res.elements.forEach((pr) => PaymentRequestResponse.setup(pr));
          this.paymentRequests = res.elements.map(this.addShowFlag);
          this.fetchingResponse = false;
        },
        (err) => {
          this.handleError(err);
          this.fetchingResponse = false;
        }
      );
  }

  handleError(httpError: HttpErrorResponse) {
    if (httpError.status === 0) {
      this.formError = 'Kontrollera nätverksanslutningen och försök igen.';
      this.processing = false;
      return;
    }
    if (httpError.status === 401 || httpError.status === 403) {
      this.close.emit();
      this.processing = false;
      this.auth.logout();
      return;
    }
    this.formError = httpError.error.message
      ? httpError.error.message
      : httpError.error;
    this.processing = false;
  }

  addShowFlag(paymentRequest: PaymentRequestResponse) {
    return paymentRequest as PaymentRequestWithShowFlag;
  }

  select(paymentRequest: PaymentRequestWithShowFlag) {
    this.formError = '';
    this.selectedPaymentRequest = paymentRequest;
  }

  submit() {
    if (!this.selectedPaymentRequest) {
      if (this.paymentRequests.length > 0) {
        this.formError =
          'Välj en betalningsförfrågan du vill matcha inbetalningen&nbsp;med';
      } else {
        this.formError =
          'Sök efter en betalningsförfrågan du vill matcha inbetalningen&nbsp;med ';
      }
    } else {
      const matchRequest: NonMatchingMatchRequest = {
        paymentRequestToMatchWith: this.selectedPaymentRequest,
        paymentToMatch: this.nonMatchedPayment,
      };
      this.promptConfirmation.emit(matchRequest);
    }
  }

  toggleDescription(paymentRequest: PaymentRequestWithShowFlag) {
    paymentRequest.showDescription = !paymentRequest.showDescription;
  }
}

type PaymentRequestWithShowFlag = PaymentRequestResponse & {
  showDescription: boolean;
};
