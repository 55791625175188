import { ReportDisbursementType } from '@core/params';

export interface ReportRepresentation {
  reportTypeColor: string;
  statusText: string;
}

export const ReportRepresentations: Map<string, ReportRepresentation> = new Map(
  [
    [
      ReportDisbursementType.SALE,
      {
        reportTypeColor: '#4466EE',
        statusText: $localize`:@@reportDisbursementType.SALE:Försäjlning`,
      },
    ],
    [
      ReportDisbursementType.REFUND,
      {
        reportTypeColor: `DB305E`,
        statusText: $localize`:@@reportDisbursementType.REFUND:Avdrag/Retur`,
      },
    ],
    [
      '_default_',
      {
        // fallback in case an unknown status is encountered
        reportTypeColor: '#4466EE',
        statusText: '',
      },
    ],
  ]
);
