<div class="quick-actions-outer-container">
    <div class="button-container" [class.disabled]="disabled" (click)="toggleList()">
        <ng-container *ngIf="verticalButton">
            <svg width="4" height="20" viewBox="0 0 4 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="2" cy="2" r="2"/>
                <circle cx="2" cy="10" r="2"/>
                <circle cx="2" cy="18" r="2"/>
            </svg>
        </ng-container>
        <ng-container *ngIf="!verticalButton">
            <svg width="25" height="6" viewBox="0 0 25 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ellipse cx="2.5" cy="3" rx="2.5" ry="2.5" transform="rotate(-90 2.5 3)"/>
                <ellipse cx="12.5" cy="3" rx="2.5" ry="2.5" transform="rotate(-90 12.5 3)"/>
                <circle cx="22.5" cy="3" r="2.5" transform="rotate(-90 22.5 3)"/>
            </svg>
        </ng-container>
    </div>
    <div 
        *ngIf="showList" 
        class="quick-actions-container" 
        [ngClass]="listCssClasses">
        <div 
            *ngFor="let action of actions"
            class="action-row" 
            (click)="executeQuickAction(action)" 
            [ngClass]="{'border-top': action.topBorder}"
            id="{{action.buttonId}}"
        >
            <img src="{{action.iconUrl}}">
            <span [style.color]="action.textColor">{{action.text}}</span>
        </div>
    </div>
</div>

