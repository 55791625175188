import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LogLevel } from '@environments/environment-types';
import * as StackTrace from 'stacktrace-js'
import { Observable, of, from } from 'rxjs';
import { map, flatMap, catchError } from 'rxjs/operators';
import { RoutingHistoryService } from './routing-history.service';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  messageStash = [];
  
  constructor(private http: HttpClient,
    private routeingHistoryService: RoutingHistoryService,
    ) {
  }

  public exception(message: string, error: Error, ...args: any[]) {
    this._log(message, args, LogLevel.ERROR, error);
  }

  public debug(message: string, ...args: any[]): void {
    this._log(message, args, LogLevel.DEBUG, null);
  }

  public log(message: string, ...args: any[]): void {
    this._log(message, args, LogLevel.LOG, null);
  }

  public warn(message: string, ...args: any[]): void {
    this._log(message, args, LogLevel.WARN, null);
  }

  public error(message: string, ...args: any[]): void {
    this._log(message, args, LogLevel.WARN, null);
  }

  public manualReport() {
    this._reportCrash("Manually triggered debug dump", null);
  }

  public reportCrash(message: string, stackTrace: StackTrace.StackFrame[], ...args: any[]): void {
    if (environment.logSettings.minLevels.telemetry > LogLevel.LOG) {
      this._reportCrash(message, stackTrace, args);
    }
  }

  private _reportCrash(message: string, stackTrace: StackTrace.StackFrame[], ...args: any[]): void {
    let dump: crashDump = {
      level: LogLevel[LogLevel.ERROR],
      entry: this.addErrorContext(message) + JSON.stringify(args),
      stackTrace: stackTrace ? stackTrace.slice(0,5) : null,
      userAgent: navigator.userAgent,
      routeHistory: this.routeingHistoryService.getHistory(),
      logDump: this.getMessageStash().map((item) => { return { ...item, ...{ stackTrace: null } } })
    }
    this.http.post(`${environment.apiUrl}/log/crash`, dump)
      .subscribe(() => { }, () => { });
  }
  
  private _log(message: string, args?: any[], logLevel?: LogLevel, error?: Error) {
    let fullMessage: LogMessage = null;
    if (environment.logSettings.minLevels.console <= logLevel || this.isDebug('console')) {
      console.log(message);
      if (args && args.length > 0 ){
        console.log(args);
      }
    }
    if (environment.logSettings.minLevels.stash <= logLevel) {
      buildMessage.bind(this)(error)
        .subscribe((message) => { this.stashMessage(message) });
    }
    if (environment.logSettings.minLevels.telemetry <= logLevel || this.isDebug('telemetry')) {
      buildMessage.bind(this)(error)
        .pipe(
          flatMap((message) => this.http.post(`${environment.apiUrl}/log/`, message))
        )
        .subscribe(() => { }, () => { });
    }

    function buildMessage(error): Observable<LogMessage> {
      if (!fullMessage) {
        return from((error ? StackTrace.fromError(error) : StackTrace.get()))
          .pipe(
            map(
              (trace) => {
                fullMessage = {
                  timestamp: new Date().toUTCString(),
                  level: LogLevel[logLevel],
                  entry: this.addErrorContext(error ? error.message : message) + JSON.stringify(args),
                  stackTrace: trace.slice(0,5),
                  userAgent: navigator.userAgent
                }
                return fullMessage;
              })
          )
      }
      return of(fullMessage);
    }
  }
  isDebug(channel: string): boolean {
    return window.location.search.search(channel) > -1;
  }


  stashMessage(message: any) {
    if(localStorage.getItem("zaverLogStash" ) != null) {
      let loaded = JSON.parse(localStorage.getItem("zfbLogStash"))
      this.messageStash = loaded != null ? loaded : [];
    }
    this.messageStash.push(message);
    localStorage.setItem('zaverLogStash', JSON.stringify(this.messageStash));
  }


  getMessageStash() {
    if(localStorage.getItem("zaverLogStash" ) != null) {
      this.messageStash = JSON.parse(localStorage.getItem("zfbLogStash"));
    }
    return this.messageStash != null ? this.messageStash : [];
  }



  private addErrorContext(message: string): string {
    return `message: ${message}, location: ${window.location.href}}`
  }

}

export interface LogMessage {
  timestamp: string
  level: string
  entry: string,
  stackTrace: StackTrace.StackFrame[],
  userAgent: string
}


export interface crashDump {
  level: string
  entry: string,
  stackTrace: StackTrace.StackFrame[],
  userAgent: string,
  routeHistory: string[],
  logDump: LogMessage[]
}
