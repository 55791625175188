import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AuthenticationService,
  LoginType,
} from '@core/service/authentication.service';
import { NavigationExtras, Router } from '@angular/router';
import { BaseComponent } from '@core/base.component';
import { VerificationTokenService } from '@core/service/verification-token.service';
import { Market } from '@core/dto/Market';
import { LocaleService } from '@core/service/locale.service';
import { EMAIL_PATTERN } from '@core/patterns';
import { LoggingService } from '@core/service/logging.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['../login-pages.scss', './email.component.css'],
})
export class EmailComponent extends BaseComponent implements OnInit, OnDestroy {
  form: FormGroup;
  formError = '';
  showError = false;

  loginMsg = '';
  processingLogin = false;

  EMAIL_KEY = 'email';

  emailInputPlaceholder = $localize`:@@login.email.input.email.placeholder:Ange jobbmail`;
  emailInputErrorMessage = $localize`:@@login.email.input.email.errorMessage:Vänligen ange en giltig e-postadress`;

  representativeMarkets: Market[];

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    protected auth: AuthenticationService,
    private tokenService: VerificationTokenService,
    private loggingService: LoggingService,
    public localeService: LocaleService
  ) {
    super(auth);
  }

  ngOnInit() {
    let storedEmail = null;
    try {
      storedEmail = localStorage.getItem(this.EMAIL_KEY);
      if (storedEmail === 'undefined' || storedEmail === 'null') {
        storedEmail = null;
      }
    } catch (e) {}
    const shouldRememberMe = storedEmail !== '';

    this.form = new FormGroup({
      email: new FormControl(storedEmail ? storedEmail : '', [
        Validators.required,
        Validators.pattern(EMAIL_PATTERN),
      ]),
      rememberMe: new FormControl(shouldRememberMe),
    });
  }

  ngOnDestroy() {}

  submit() {
    if (this.processingLogin) {
      return;
    }
    super.clearError();
    if (this.form.valid) {
      this.disableForm();
      this.formError = null;
      this.loginMsg = $localize`:@@login.email.loginMsg:Startar ...`;
      this.form.markAsUntouched();

      const email = this.form.value.email;
      const rememberMe = this.form.value.rememberMe;

      this.authenticationService
        .getLoginTypeForEmail(email)
        .then((loginTypeResponse) => {
          if (rememberMe && email) {
            localStorage.setItem(this.EMAIL_KEY, email);
          }
          this.representativeMarkets = loginTypeResponse.marketRepresentations;

          switch (loginTypeResponse.loginType) {
            case LoginType.BANKID:
              this.router.navigate(['/bankid-login'], {
                queryParams: { email: email },
              });
              break;
            case LoginType.NO_USER:
              this.router
                .navigate(['/email-not-found'], {
                  skipLocationChange: false,
                  replaceUrl: true,
                  queryParams: { email: email },
                })
                .then(() => false);
              break;
            case LoginType.CREATE_USER:
              this.tokenService.email = email;
              this.navigate('/signup-token');
              // the domain name of the email-adress is white listed for account creation, go into account creation flow.
              // this is currently handled entirely in the back end, but that might change if we want to give better feedback.
              break;
            case LoginType.PENDING_ADMIN_APPROVAL:
              this.tokenService.email = email;
              this.navigate('/pending-admin-approval');
              break;
            case LoginType.PENDING_USER_APPROVAL:
              this.tokenService.email = email;
              this.navigate('/pending-user-approval');
              break;
            case LoginType.PENDING_IDENTIFICATION:
              this.tokenService.email = email;
              this.navigate('/identify-signatory-token', {
                queryParams: { email: encodeURIComponent(email) },
              });
              break;
            case LoginType.VERIFY_EMAIL_ACCESS:
              this.tokenService.email = email;
              this.navigate('/email-renew', {
                queryParams: { email: encodeURIComponent(email) },
              });
              break;
            case LoginType.SET_PASSWORD:
              this.tokenService.email = email;
              this.navigate('/token-entry', {
                queryParams: { email: encodeURIComponent(email) },
              });
              break;
            case LoginType.PASSWORD:
            case LoginType.TWO_FACTOR:
              this.navigate('/password-entry', {
                queryParams: { email: encodeURIComponent(email) },
              });
              break;
          }
        })
        .catch((error) => {
          this.enableForm();
          super.handleError(error);
          this.loggingService.reportCrash("LoginType not found: " + error, null);
        });
    } else {
      this.showError = true;
      this.enableForm();
    }
  }

  setFormError(error: string) {
    this.formError = error;
    this.form.get('email').setErrors({ key: error });
  }

  private enableForm() {
    this.form.enable();
    this.processingLogin = false;
  }

  private disableForm() {
    this.processingLogin = true;
    this.form.disable();
  }

  rememberMeClicked(): void {
    if (!this.form.value.rememberMe) {
      // if the user un-checks the remember-me-checkbox we will set the value to '' (in contrast to just undefined);
      // this indicates that the user does not want to be remembered
      localStorage.setItem(this.EMAIL_KEY, '');
    }
  }

  isNotProduction(): boolean {
    return !environment.production;
  }

  navigate(url: string, navigationExtras?: NavigationExtras) {
    const currentMarket = this.localeService.getCurrentLocaleMarket();

    if (
      (!!currentMarket &&
        !!this.representativeMarkets &&
        this.representativeMarkets.includes(currentMarket)) ||
      !this.representativeMarkets
    ) {
      // The user is a representative for at least one merchant with the current locale, stay on this locale, or
      // it is not supported on any markets, probably becuase the email is not connected to any user
      this.router.navigate([url], navigationExtras).then(() => false);
      return;
    } else if (
      !!currentMarket &&
      !!this.representativeMarkets &&
      !this.representativeMarkets.includes(currentMarket) &&
      this.representativeMarkets.length === 1
    ) {
      let market = this.representativeMarkets[0];
      market = market === Market.NO ? Market.SE : market;
      switch (market) {
        case Market.SE:
        case Market.DE:
        case Market.AT:
          let newUrl = '/' + market.toLowerCase() + url;
          if (
            !!navigationExtras.queryParams &&
            navigationExtras.queryParams.email
          ) {
            newUrl += '?email=' + navigationExtras.queryParams.email;
          }
          location.href = newUrl;
          return;
        default:
          console.error('Unsupported merchant market: ' + market);
          return;
      }
    } else {
      console.error('We somehow got here');
    }
  }
}
