<div class="header-container">
  <div class="title-text-container">
    <h1 class="title">Kaufkraft testen</h1>
    <span class="heading-text">
      Durch die Prüfung der Bonität des Kunden erhalten Sie eine vorläufige
      Einschätzung darüber ob der Kunde die Zahlungsart Rechnung oder
      Ratenzahlung in Anspruch nehmen kann. Hierzu erhält ihr Kunde einen Link
      per SMS und muss sich einer Bonitätsprüfung unterziehen. Das Ergebnis der
      Bonitätsprüfung wird im Anschluss unten dargestellt.
    </span>
  </div>
  <zaver-zfb-add-button
    [text]="'Link senden'"
    (click)="create()"
    id="main-create-button"
  ></zaver-zfb-add-button>
</div>

<ng-container *ngIf="fetchingPrechecks">
  <div class="page-loader-container">
    <app-page-loader></app-page-loader>
  </div>
</ng-container>
<ng-container *ngIf="!fetchingPrechecks">
  <app-table
    [columns]="columns"
    [values]="prechecks"
    (filterChange)="onFilterChange($event)"
  >
  </app-table>

  <app-empty-state *ngIf="displayEmptyState" [emptyState]="currentEmptyState">
  </app-empty-state>

  <app-table-pagination
    *ngIf="!displayEmptyState"
    [paginationState]="paginationState"
    (pageChanged)="onPageChange($event)"
    (pageSizeChanged)="onPageSizeChange($event)"
  >
  </app-table-pagination>

  <zaver-zfb-add-button
    [text]="'Link senden'"
    (click)="create()"
    class="mobile-cta"
  ></zaver-zfb-add-button>
</ng-container>

<!-- Modal -->
<zaver-form-modal
  *ngIf="modalActive"
  (onClose)="closeModal()"
  [title]="modalTitle"
  [color]="modalColor"
  [ngSwitch]="activeModalComponent"
>
  <app-create-precheck
    *ngSwitchCase="Modal.CREATE"
    [merchantPrecheckLimits]="merchantPrecheckLimits"
    (created)="handleCreatedPrecheck()"
  >
  </app-create-precheck>
  <app-view-precheck
    *ngSwitchCase="Modal.VIEW"
    [precheck]="selectedPrecheck"
    (resendSms)="resendSms($event)"
  >
  </app-view-precheck>
</zaver-form-modal>
