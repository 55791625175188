import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface EmptyState {
  imgSrc: string;
  headingText: string;
  bodyText?: string;
  ctaButtonText?: string;
  ctaClickFunction?: () => void;
  lessMarginTop?: boolean;
  placedInModal?: boolean;
}

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
})
export class EmptyStateComponent implements OnInit {
  @Input() emptyState: EmptyState;

  constructor() {}

  ngOnInit(): void {}

  ctaPressed() {
    if (this.emptyState && this.emptyState.ctaClickFunction) {
      this.emptyState.ctaClickFunction();
    }
  }
}
