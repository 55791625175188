<div class="click-area" [ngClass]="{ disabled: disabled }">
  <div
    class="container"
    [ngClass]="{
      selected: selected,
      cerise: color === 'cerise',
      green: color === 'green'
    }"
  >
    <div class="outer-dot">
      <div class="inner-dot"></div>
    </div>
  </div>
  <div *ngIf="text" class="radio-button-text">{{ text }}</div>
</div>
