import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild} from '@angular/router';
import {Observable} from 'rxjs';
import {AppStateService} from '@core/service/app-state.service';

@Injectable()
export class MustNotBeUnapprovedCompany implements CanActivateChild {

  constructor(private router: Router, private appStateService: AppStateService) {
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.appStateService.isRejected()) {
      this.router.navigate(['/unApproved'], {skipLocationChange: true, replaceUrl: true});
      return false;
    } else {
      return true;
    }
  }
}
