import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';

@Injectable()
export class HrefService {

  private baseUrl;

  constructor() {
    this.baseUrl = window.location.origin;
  }

  getApiUrl(): string {
    return environment.apiUrl;
  }

  getWebUrl(): string {
    return this.baseUrl;
  }

}
