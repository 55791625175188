import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RefundApprovalErrors } from '@assets/language/RefundApprovalErrors';
import { BaseComponent } from '@core/base.component';
import { Refund, RefundApprovalResponse } from '@core/dto/refund/Refund';
import { AuthenticationService } from '@core/service/authentication.service';
import { RefundService } from '@core/service/refund.service';
import { ToastMessageService } from '@core/service/toast-message.service';

@Component({
  selector: 'app-approve-refund',
  templateUrl: './approve-refund.component.html',
  styleUrls: ['./approve-refund.component.css'],
})
export class ApproveRefundComponent extends BaseComponent implements OnInit {
  @Input() refund: Refund;

  approving = false;
  serverErrorMessage = $localize`:@@approveRefund.serverErrorMessage:Returen godkändes inte. Vänligen försök igen eller avbryt genom att stänga. <a class="link" href="mailto:merchants@zaver.se" target="_blank">Kontakta&nbsp;oss</a> om problemet kvarstår.`;
  approvalError = false;
  approvalErrorHeading = $localize`:@@approveRefund.approvalErrorHeading:Returen kunde inte genomföras.`;
  approvalErrorText = '';

  @Output() return = new EventEmitter<Refund>();
  @Output() approved = new EventEmitter();
  @Output() closeModal = new EventEmitter();

  constructor(
    auth: AuthenticationService,
    private refundService: RefundService,
    private toastService: ToastMessageService
  ) {
    super(auth);
  }

  ngOnInit(): void {}

  approve() {
    this.approving = true;
    this.refundService
      .approve(this.refund.id)
      .then((response) => {
        if (response.approved) {
          this.onSuccessfulApproval();
        } else {
          this.onUnsuccessfulApproval(response);
        }
        this.approving = false;
      })
      .catch((error: HttpErrorResponse) => {
        this.handleError(error);
        this.approving = false;
      });
  }

  onSuccessfulApproval() {
    this.toastService.displaySuccess(
      $localize`:@@approveRefund.toast.success:Returen har godkänts`
    );
    this.approved.emit();
  }

  onUnsuccessfulApproval(response: RefundApprovalResponse) {
    if (response.error) {
      const approvalErrorText = RefundApprovalErrors.get(response.error);
      if (approvalErrorText) {
        this.approvalErrorText = approvalErrorText;
        this.approvalError = true;
      } else {
        // If the RefundApprovalError from IDAU is not implemented in Z4B, show ServerError
        this.onServerError();
      }
    } else {
      // The approval was unsuccessful but no error message is set in the backend -> Present Server Error message
      this.onServerError();
    }
  }
}
