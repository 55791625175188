<div class="back-button-container" (click)="goBackClick()">
  <img
    class="arrow-img"
    src="assets/icons/Icon-Navigation-Arrow Left.svg"
    alt="icon-left"
  />
  <span>Tillbaka</span>
</div>

<div class="card">
  <app-bank-id (onSuccess)="handleSuccess($event)"></app-bank-id>
</div>
