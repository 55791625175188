import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CookieService} from 'ngx-cookie';

/**
 * An interceptor for setting CSRF token header on outgoing requests.
 */
@Injectable()
export class CsrfInterceptor implements HttpInterceptor {

  header_name = 'X-XSRF-TOKEN';
  cookie_name = 'XSRF-TOKEN';

  constructor(private cookies: CookieService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // This cookie should be set by Spring security.
    const token = this.cookies.get(this.cookie_name);

    // Non-mutating requests don't require CSRF protection.
    if (req.method === 'GET' || req.method === 'HEAD') {
      return next.handle(req);
    }

    // Add token to headers unless already set.
    if (token && !req.headers.has(this.header_name)) {
      const cloneReq = req.clone({headers: req.headers.set(this.header_name, token)});
      return next.handle(cloneReq);
    }

    // Pass through if no condition matched.
    return next.handle(req);
  }
}