<div class="spacing-container">
  <div>
    <h1>
      <span i18n="@@createRefundReason.title">Välj orsaken för returen</span>
    </h1>
    <form [formGroup]="form">
      <label *ngFor="let reason of reasons">
        <span>{{ reason.label }}</span>
        <input
          style="display: none"
          type="radio"
          value="{{ reason.value }}"
          formControlName="reason"
        />
        <zaver-zfb-radio-button
          [selected]="form.controls.reason.value === reason.value"
          [color]="'cerise'"
        >
        </zaver-zfb-radio-button>
      </label>
    </form>
  </div>
  <div class="button-container">
    <zaver-primary-button
      text="Fortsätt"
      i18n-text="@@createRefundReason.button"
      (click)="changeStep.emit()"
    >
    </zaver-primary-button>
  </div>
</div>
