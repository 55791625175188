import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@core/base.component';
import { Currency } from '@core/dto/CurrencyCode';
import { PaymentRequestResponse } from '@core/dto/PaymentRequestResponse';
import { Refund, RefundReason } from '@core/dto/refund/Refund';
import { Permission } from '@core/dto/user-details';
import { RefundStatus } from '@core/params';
import { AccessControlService } from '@core/service/access-control.service';
import { AuthenticationService } from '@core/service/authentication.service';
import { LocaleService } from '@core/service/locale.service';
import { PaymentRequestService } from '@core/service/payment-request.service';
import { RefundService } from '@core/service/refund.service';

@Component({
  selector: 'app-view-refund',
  templateUrl: './view-refund.component.html',
  styleUrls: ['./view-refund.component.css'],
})
export class ViewRefundComponent extends BaseComponent implements OnInit {
  @Input() refund: Refund;

  paymentRequest: PaymentRequestResponse;
  showTitleAndDescription = false;
  loading = true;
  approveButtonDisabled: boolean;
  cancelButtonDisabled: boolean;

  refundValue: Currency;
  refundedValue: Currency;

  activePage: 'overview' | 'events' = 'overview';

  @Output() approve = new EventEmitter<Refund>();
  @Output() decline = new EventEmitter<Refund>();

  constructor(
    auth: AuthenticationService,
    private paymentRequestService: PaymentRequestService,
    private refundService: RefundService,
    private access: AccessControlService,
    public localeService: LocaleService
  ) {
    super(auth);
  }

  ngOnInit(): void {
    this.setup();
  }

  setup() {
    this.paymentRequestService
      .getOne(this.refund.paymentRequestId)
      .then((pr) => {
        this.paymentRequest = pr;
        this.approveButtonDisabled =
          !this.access.userMay(Permission.APPROVE_REFUND) ||
          !this.refundService.currentUserMayApproveRefund(this.refund);
        this.cancelButtonDisabled = !this.access.userMay(
          Permission.CANCEL_REFUND
        );
        this.refundValue = {
          amount:
            this.refund.refundValue.amount > 0
              ? -this.refund.refundValue.amount
              : this.refund.refundValue.amount,
          currencyCode: this.refund.refundValue.currencyCode,
        };
        if (
          this.paymentRequest.refundedValue &&
          this.paymentRequest.refundedValue.amount > 0
        ) {
          this.refundedValue = {
            amount: -this.paymentRequest.refundedValue.amount,
            currencyCode: this.paymentRequest.refundedValue.currencyCode,
          };
        }
        this.loading = false;
      })
      .catch((error: HttpErrorResponse) => {
        this.handleError(error);
      });
  }

  getReasonText(reason: RefundReason): string {
    return this.refundService.getRefundReasonText(reason);
  }

  toggleTitleAndDescription() {
    this.showTitleAndDescription = !this.showTitleAndDescription;
  }

  showButtons() {
    return this.refund.status === RefundStatus.PENDING_MERCHANT_APPROVAL;
  }

  activePageChange(page: 'overview' | 'events') {
    this.activePage = page;
  }

  mayViewEvents() {
    return this.access.userMay(Permission.GET_EVENTS);
  }
}
