export enum PaymentRequestEventType {
  INITIALIZED = 'INITIALIZED',
  INITIALIZED_API = 'INITIALIZED_API',
  SETTLED = 'SETTLED',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',

  // Refund specific events
  REFUND_INITIALIZED = 'REFUND_INITIALIZED',
  REFUND_APPROVED = 'REFUND_APPROVED',
  REFUND_CANCELLED = 'REFUND_CANCELLED',
  REFUND_CANCELLED_BY_SYSTEM = 'REFUND_CANCELLED_BY_SYSTEM',
  REFUND_SWISH_TEMPORARY_ERROR = 'REFUND_SWISH_TEMPORARY_ERROR',
  REFUND_SWISH_MERCHANT_INSUFFICIENT_FUNDS = 'REFUND_SWISH_MERCHANT_INSUFFICIENT_FUNDS',
  REFUND_SWISH_PAYMENT_TOO_OLD = 'REFUND_SWISH_PAYMENT_TOO_OLD',
  REFUND_SWISH_MERCHANT_NUMBER_CHANGED = 'REFUND_SWISH_MERCHANT_NUMBER_CHANGED',
  REFUND_SWISH_MERCHANT_ORG_NUMBER_CHANGED = 'REFUND_SWISH_MERCHANT_ORG_NUMBER_CHANGED',
  REFUND_SWISH_CUSTOMER_NUMBER_CHANGED = 'REFUND_SWISH_CUSTOMER_NUMBER_CHANGED',
  REFUND_SWISH_AMOUNT_EXCEEDS_REFUNDABLE_AMOUNT = 'REFUND_SWISH_AMOUNT_EXCEEDS_REFUNDABLE_AMOUNT',
  REFUND_SWISH_OTHER_ERROR = 'REFUND_SWISH_OTHER_ERROR',

  // Z4B specific events
  MERCHANT_NOTE = 'MERCHANT_NOTE',

  // Communication specific events
  SMS_RESENT = 'SMS_RESENT',

  // Swish specific events
  CANCELLED_SWISH_DEPOSIT = 'CANCELLED_SWISH_DEPOSIT',
}
